import React, { useEffect, useState } from 'react';
import { Form, Input, Select, Collapse, Drawer, Checkbox, Tooltip } from 'antd';
import { IoMdAddCircle } from "react-icons/io";
import RegistarModeloCalculoProposta_ISV_IUC_ES from './RegistarModeloCalculoProposta_ISV_IUC_ES';
import { FaRobot } from "react-icons/fa6";

const { Option } = Select;
const { Panel } = Collapse;

export default function FormCalculoPropostaES({ setDataListas, dataUser, linkToken, propostaInfo, isvIucData, setIsvIucData, setTriggerCalculo, onFormChange, dataListas }) {
    const [form] = Form.useForm(); // Cria uma instância do formulário

    const [isModalOpenModelo, setIsModalOpenModelo] = useState(false); // Controle do Drawer de modelo

    // Atualiza os valores do formulário quando isvData mudar
    useEffect(() => {
        form.setFieldsValue({
            ...isvIucData,
            // wltp: isvIucData?.wltp, // Garante que o valor do checkbox seja booleano
            // hibridoPlugIn: isvIucData?.hibridoPlugIn,
            hibridoPlugIn: isvIucData?.hibridoPlugIn,
        });

        // Valida manualmente os campos quando os valores são inválidos
        if (isvIucData.provincia == '0' || isvIucData.provincia === 0 || isvIucData.provincia === "") {
            form.validateFields(['provincia']);
        }
        if (isvIucData.municipio == '0' || isvIucData.municipio === 0 || isvIucData.municipio === "") {
            form.validateFields(['municipio']);
        }
        if (isvIucData.idMarca == '0' || isvIucData.idMarca === 0 || isvIucData.idMarca === "") {
            form.validateFields(['idMarca']);
        }
        if (isvIucData.idModelo == '0' || isvIucData.idModelo === 0 || isvIucData.idModelo === "") {
            form.validateFields(['idModelo']);
        }
        if (isvIucData.emissoesCO2 == '0' || isvIucData.emissoesCO2 === 0 || isvIucData.emissoesCO2 === "") {
            form.validateFields(['emissoesCO2']);
        }
        if (isvIucData.cilindrada == '0' || isvIucData.cilindrada === 0 || isvIucData.cilindrada === "") {
            form.validateFields(['cilindrada']);
        }
        if (isvIucData.dataPrimeiroRegisto == "" || !isvIucData.dataPrimeiroRegisto) {
            form.validateFields(['dataPrimeiroRegisto']);
        }

    }, [isvIucData, form]);


    const handleAbrirRegistoModelo = () => {
        setIsModalOpenModelo(true);
    };

    const handleCancelModelo = () => {
        setIsModalOpenModelo(false);
    };


    return (
        <div className='card grid gap-4'>
            <div className='flex justify-between items-center gap-4'>
                <div className='grid gap-1'>
                    <p className='text-lg font-semibold'>Calculo del IVTM</p>
                </div>
            </div>
            <hr />
            <Form
                layout="vertical"
                className='form-de-envio-docs'
                form={form} // Define o formulário a ser utilizado
                onValuesChange={(_, allValues) => {
                    // //console.log(allValues)
                    onFormChange(allValues); // Dispara ao editar algum valor
                }}
            >
                <div className="grid gap-4">
                    <div className='grid grid-cols-1 sm:grid-cols-2 gap-4 text-white'>
                        <div className='flex flex-col items-stretch gap-2'>
                            <p className='text-white font-semibold'>Provincias</p>
                            <Form.Item
                                name="provincia"
                                rules={[
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value) {
                                                return Promise.reject('¡Por favor, ingrese la provincia!'); // Mensaje para campo vacío
                                            }
                                            if (value === '0' || value === 0) {
                                                return Promise.reject('¡Por favor, ingrese un valor válido para la provincia!'); // Mensaje para valor inválido (0)
                                            }

                                            return Promise.resolve();
                                        },
                                    }),
                                ]}

                            >
                                <Select
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    onChange={(value) => {

                                        const cidadeSeleccionado = dataListas.listaCidades.find(
                                            (cidade) => cidade.id_cidade === value
                                        );

                                     

                                        setIsvIucData((prevData) => ({
                                            ...prevData,
                                            nomeProvincia: cidadeSeleccionado.nome_cidade,
                                            municipio:''
                                        }));

                                        setTriggerCalculo((prevData) => ({
                                            ...prevData,
                                            nomeProvincia: cidadeSeleccionado.nome_cidade,
                                            municipio:''
                                        }));


                                        form.resetFields(['municipio']);

                                    }}

                                >
                                    {dataListas.listaCidades.map((cidade) => (
                                        <Option key={cidade.id_cidade} value={cidade.id_cidade}>
                                            {cidade.nome_cidade}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </div>


                        <div className='flex flex-col items-stretch gap-2'>
                            <p className='text-white font-semibold'>Munícipio</p>
                            <Form.Item
                                name="municipio"
                                rules={[
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value) {
                                                return Promise.reject('¡Por favor, ingrese el municipio!'); // Mensaje para campo vacío
                                            }
                                            if (value === '0' || value === 0) {
                                                return Promise.reject('¡Por favor, ingrese un valor válido para el municipio!'); // Mensaje para valor inválido (0)
                                            }

                                            return Promise.resolve();
                                        },
                                    }),
                                ]}
                            >
                                <Select
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    onChange={(value) => {

                                        const municipioSeleccionado = dataListas.listaMunicipios.find(
                                            (municipio) => municipio.id === value
                                        );

                                        setIsvIucData((prevData) => ({
                                            ...prevData,
                                            nomeMunicipio: municipioSeleccionado.nome_municipio
                                        }));

                                        setTriggerCalculo((prevData) => ({
                                            ...prevData,
                                            nomeMunicipio: municipioSeleccionado.nome_municipio
                                        }));

                                    }}

                                >
                                    {dataListas.listaMunicipios.map(municipio => (
                                        <Option key={municipio.id} value={municipio.id}>{municipio.nome_municipio}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </div>

                        {dataListas.listaMarcas && (
                            <div className='flex flex-col items-stretch gap-2'>
                                <p className='text-white font-semibold'>Marca</p>
                                <Form.Item name="idMarca">
                                    <Select
                                        showSearch
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        onChange={(value) => {

                                            const marcaSeleccionada = dataListas.listaMarcas.find(
                                                (marca) => marca.id_marca === value
                                            );


                                            setIsvIucData((prevData) => ({
                                                ...prevData,
                                                nomeMarca: marcaSeleccionada.nome_marca
                                            }));

                                            setTriggerCalculo((prevData) => ({
                                                ...prevData,
                                                nomeMarca: marcaSeleccionada.nome_marca
                                            }));

                                        }}

                                    >
                                        {dataListas.listaMarcas.map(marca => (
                                            <Option key={marca.id_marca} value={marca.id_marca}>{marca.nome_marca}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </div>
                        )}

                        {dataListas.listaModelos && (
                            <div className='flex flex-col items-stretch gap-2'>
                                <p className='text-white flex gap-2 justify-between font-semibold items-center'>Modelo <IoMdAddCircle size={20} onClick={handleAbrirRegistoModelo} className='cursor-pointer' /></p>
                                <Form.Item
                                    name="idModelo"
                                    rules={[
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                if (!value) {
                                                    return Promise.reject('¡Por favor, ingrese el modelo de la viatura!'); // Mensaje para campo vacío
                                                }
                                                if (value === '0' || value === 0) {
                                                    return Promise.reject('¡Por favor, ingrese un valor válido para el modelo de la viatura!'); // Mensaje para valor inválido (0)
                                                }

                                                return Promise.resolve();
                                            },
                                        }),
                                    ]}
                                >
                                    <Select
                                        showSearch
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        onChange={(value) => {

                                            // //console.log(dataListas.listaModelos)

                                            const modeloSeleccionado = dataListas.listaModelos.find(
                                                (modelo) => modelo.id_modelo === value
                                            );

                                            // //console.log(modeloSeleccionado)

                                            setIsvIucData((prevData) => ({
                                                ...prevData,
                                                valorViaturaNova: modeloSeleccionado.valor_novo,
                                                nomeModelo: modeloSeleccionado.nome_modelo,
                                                nomeMarca: modeloSeleccionado.nome_marca
                                            }));

                                            setTriggerCalculo((prevData) => ({
                                                ...prevData,
                                                valorViaturaNova: modeloSeleccionado.valor_novo,
                                                nomeModelo: modeloSeleccionado.nome_modelo,
                                                nomeMarca: modeloSeleccionado.nome_marca
                                            }));


                                        }}
                                    >
                                        {dataListas.listaModelos.map(modelo => (
                                            <Option key={modelo.id_modelo} value={modelo.id_modelo}>{modelo.nome_modelo}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </div>
                        )}


                        <div className='flex flex-col items-stretch gap-2'>
                            <p className='text-white font-semibold'>Nuevo / Usado</p>
                            <Form.Item name="novoUsado">
                                <Select>
                                    <Option value="1">Nuevo</Option>
                                    <Option value="0">Usado</Option>
                                </Select>
                            </Form.Item>
                        </div>


                        <div className='flex flex-col items-stretch gap-2'>
                            <p className='text-white font-semibold'>Fecha de primer registro</p>
                            <Form.Item name="dataPrimeiroRegisto">
                                <Input value={isvIucData.dataPrimeiroRegisto || "Sin información"} />
                            </Form.Item>
                        </div>



                        <div className='flex flex-col items-stretch gap-2'>
                            <p className='text-white font-semibold'>Valor del Vehículo Nuevo</p>
                            <Form.Item name="valorViaturaNova">
                                <Input value={isvIucData.valorViaturaNova || "Sin información"} />
                            </Form.Item>
                        </div>


                        {isvIucData.tipoCombustivel !== "Eletrico" && (
                            <div className='flex flex-col items-stretch gap-2'>
                                <p className='text-white font-semibold'>Cilindrada</p>
                                <Form.Item name="cilindrada">
                                    <Input value={isvIucData.cilindrada || "Sin información"} />
                                </Form.Item>
                            </div>
                        )}


                        <div className='flex flex-col items-stretch gap-2'>
                            <p className='text-white font-semibold'>Tipo de Combustible</p>
                            <Form.Item name="tipoCombustivel">
                                <Select>
                                    <Option value="Gasolina">Gasolina</Option>
                                    <Option value="Diesel">Diesel</Option>
                                    <Option value="Eletrico">Elétrico</Option>
                                    <Option value="gas_natural">Gas Natural</Option>
                                </Select>
                            </Form.Item>
                            {isvIucData.tipoCombustivel !== "Eletrico" && (
                                <div className='flex flex-col items-stretch gap-2'>
                                    <Form.Item
                                        name="hibridoPlugIn"
                                        valuePropName="checked"
                                    >
                                        <div className='flex items-center gap-2'>
                                            <Checkbox checked={isvIucData?.hibridoPlugIn == "1"} />
                                            <p className='text-white font-semibold'>Híbrido Enchufable</p>
                                        </div>
                                    </Form.Item>
                                </div>
                            )}
                        </div>




                        {isvIucData.tipoCombustivel !== "Eletrico" && (
                            <div className='flex flex-col items-stretch gap-2'>
                                <div className='flex items-center justify-between gap-1'>
                                    <p className='text-white font-semibold'>Emisiones de Gases CO2</p>
                                    {isvIucData?.emissoesPorAI == 1 && (
                                        <Tooltip title="Las emisiones fueron proporcionadas por IA. Confirma las emisiones para crear la propuesta.">
                                            <FaRobot size={20} className='icon-question' />
                                        </Tooltip>
                                    )}
                                </div>
                                <Form.Item
                                    name="emissoesCO2"
                                    rules={[
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                if (!value) {
                                                    return Promise.reject('¡Por favor, ingrese las emisiones de CO2!'); // Mensaje para campo vacío
                                                }
                                                if (value === '0' || value === 0) {
                                                    return Promise.reject('¡Por favor, ingrese un valor válido para las emisiones de CO2!'); // Mensaje para valor inválido (0)
                                                }
                                                return Promise.resolve();
                                            },
                                        }),
                                    ]}
                                >
                                    <Input type='number' />
                                </Form.Item>

                            </div>
                        )}

                        {propostaInfo.erro && (
                            <span className='text-sm text-[#fffd00] opacity-90'>{propostaInfo.erro}</span>
                        )}
                    </div>
                </div>
            </Form>

            <Drawer
                title="Registrar Modelo de Coche"
                placement="right"
                className='detalhes-de-log-email'
                open={isModalOpenModelo}
                onClose={handleCancelModelo}
            >
                <RegistarModeloCalculoProposta_ISV_IUC_ES
                    dataListas={dataListas}
                    setDataListas={setDataListas}
                    dataUser={dataUser}
                    linkToken={linkToken}
                    setIsvIucData={setIsvIucData}
                    setTriggerCalculo={setTriggerCalculo}
                    setIsModalOpenModelo={setIsModalOpenModelo}
                />
            </Drawer>
        </div>
    );
};
