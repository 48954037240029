import React, { useState } from "react";
import TabelaDocumentosTraduzidos from "../../components/TraduzirDocumentos/TabelaDocumentosTraduzidos";
import TraduzirDocumentos from "../../components/TraduzirDocumentos/TraduzirDocumentos/TraduzirDocumentos";
import DocumentosTraduzidos from "../../components/TraduzirDocumentos/DocumentosTraduzidos/DocumentosTraduzidos";
import DocumentosTraduzir from "../../components/TraduzirDocumentos/DocumentosTraduzidos/DocumentosTraduzidos";


export default function TradutorDocumentos({ dataUser }) {

    const [filesParaUploud, setFilesParaUploud] = useState([]);
    const [files, setFiles] = useState([]);
    const [mediaSegundos, setMediaSegundos] = useState(0);

    // const [tempoDecorrido, setTempoDecorrido] = useState(0);
    // const [contadorAtivo, setContadorAtivo] = useState(false);

    const [data, setData] = useState({
        documentos: [],
    });

    // Verifica se há pelo menos um arquivo com status diferente de null
    const hasProcessedFiles = files.some(file => file.status !== null);

    // Verifica se há arquivos com status "processing"
    const hasPendingFiles = files.some(file => file.status === "processing");

    // Verifica se há arquivos com status "completed"
    const hasCompletedFiles = files.some(file => file.status === "completed");

    // Verifica se há arquivos com status "erro"
    const hasErrorFiles = files.some(file => file.status === "error");

    // console.log(hasProcessedFiles)
    // console.log(filesParaUploud)
    // console.log(files)

    return (

        <>
            <TraduzirDocumentos
                dataUser={dataUser}
                files={files}
                setFiles={setFiles}
                filesParaUploud={filesParaUploud}
                setFilesParaUploud={setFilesParaUploud}
                mediaSegundos={mediaSegundos}
                hasPendingFiles={hasPendingFiles}
            />

            {hasProcessedFiles && (
                <DocumentosTraduzir
                    dataUser={dataUser}
                    mediaSegundos={mediaSegundos}
                    setMediaSegundos={setMediaSegundos}
                    files={files}
                    setFiles={setFiles}
                    filesParaUploud={filesParaUploud}
                    // setFilesParaUpdoud={setFilesParaUpdoud}
                    setData={setData}
                    hasPendingFiles={hasPendingFiles}
                    hasCompletedFiles={hasCompletedFiles}
                />
            )}

            <TabelaDocumentosTraduzidos
                dataUser={dataUser}
                setMediaSegundos={setMediaSegundos}
                setFiles={setFiles}
                data={data}
                setData={setData}
            />
        </>
    );
}
