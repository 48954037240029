import { message, Select } from 'antd';
import React from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

export default function FasesDaProposta({ propostaInfo }) {
    const { t } = useTranslation();

    // Define a cor de fundo baseada no estado da proposta
    const getBackgroundColor = () => {
        switch (propostaInfo.estadoDaProposta) {
            case 'proposta_aceite':
                return 'bg-[#0D9F57]'; // Verde
            case 'proposta_aberta':
                return 'bg-gray-400';  // Cinza
            case 'aceitou_outra_proposta':
                return 'bg-yellow-500'; // Amarelo
            default:
                return 'bg-gray-200';   // Cor padrão (cinza claro)
        }
    };

    // Define o texto baseado no valor da proposta, utilizando a função de tradução
    const getStatusText = () => {
        switch (propostaInfo.estadoDaProposta) {
            case 'proposta_aceite':
                return t('tables.proposta_aceite');
            case 'proposta_aberta':
                return t('tables.proposta_em_aberto');
            case 'proposta_oculta':
                return t('tables.proposta_oculta');
            case 'aceitou_outra_proposta':
                return t('tables.aceitou_outra_proposta');
            default:
                return '';
        }
    };

    return (
        <>
            <div className='flex justify-between items-center'>
                <p className="text-black text-base">
                    {t('gerar_proposta.estado_proposta')}:
                </p>
                <div className="flex justify-between gap-4">
                    <div className={`${getBackgroundColor()} rounded-full font-semibold px-3 w-auto inline-flex items-center text-white`}>
                        {getStatusText()}
                    </div>
                </div >
            </div >

            {propostaInfo.estadoDaProposta == 'proposta_aceite' && (
                <div className='flex justify-between items-center'>
                    <p className="text-black text-base">
                        {t('gerar_proposta.data_proposta_aceite')}
                    </p>
                    <div className="flex justify-between gap-4">
                        <div className={`font-semibold text-black`}>
                            {propostaInfo.dataPropostaAceite}
                        </div>
                    </div >
                </div >
            )}

        </>
    );
}
