import { Input, Select } from "antd";
import React, { useState } from "react";
import { DatePicker } from 'antd';
import dayjs from "dayjs";
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);

const { Option } = Select;

export default function AutoTransporteParticular({
    tipo,
    tipoContrato,
    tipoSociedade,
    nomeIndividual,
    setNomeIndividual,
    nomeSocioEmpresa,
    setNomeSocioEmpresa,
    numeroCCEmpresa,
    setNumeroCCEmpresa,
    nomeEmpresa,
    setNomeEmpresa,
    nifEmpresa,
    setNifEmpresa,
    numeroCCIndividual,
    setNumeroCCIndivual,

    transportadora,
    setTransportadora,
    nifTransportadora,
    setNifTransportadora,

    marcaViatura,
    setMarcaViatura,
    modeloViatura,
    setModeloViatura,
    vinViatura,
    setVinViatura,
    idDoNegocio,
    setIDdoNegocio,
    dataDoDocumento,
    setDataDoDocumento,
    alertaDataDocumento,
    setDataSociosQuatro,
    alertaDataValidadeCC,
}) {

    const [numSocios, setnumSocios] = useState('0');
    const [inputs, setInputs] = useState([]);


    //Particular
    const handleNomeChange = (e) => {
        setNomeIndividual(e.target.value);
    };




    //Empresa
    const handleNomeSocioChange = (e) => {
        setNomeSocioEmpresa(e.target.value);
    };
    const handleNumeroIndividualCCChange = (e) => {
        setNumeroCCIndivual(e.target.value);
    };
    const handleNumeroCCEmpresaChange = (e) => {
        setNumeroCCEmpresa(e.target.value);
    };
    const handleNomeEmpresaChange = (e) => {
        setNomeEmpresa(e.target.value);
    };
    const handleNifEmpresaChange = (e) => {
        setNifEmpresa(e.target.value);
    };




    //Geral
    const handleTransportadoraChange = (e) => {
        setTransportadora(e);
        if (e === "cascao") {
            setTransportadora('Transportes Cascão e Manuela')
            setNifTransportadora('505 844 427')
        } else if (e === "transtomas") {
            setTransportadora('TransTomás - Comércio e Transporte de Mercadorias Lda')
            setNifTransportadora('502 332 506')
        } else if (e === "transportes_matos") {
            setTransportadora('Transportes Matos')
            setNifTransportadora('500 154 759')
        } else if (e === "osd_gmbh") {
            setTransportadora('OSD Gmbh')
            setNifTransportadora('305 202 927')
        } else if (e === "fehrenkotter") {
            setTransportadora('Fehrenkötter')
            setNifTransportadora('126 733 482')
        } else {
            setNifTransportadora('')
        }
    };

    const handleNIFTransportadoraChange = (e) => {
        setNifTransportadora(e.target.value);
    };

    const handleNomeTransportadoraChange = (e) => {
        setTransportadora(e.target.value);
    };



    const handleMarcaDaViaturaChange = (e) => {
        setMarcaViatura(e.target.value);
    };

    const handleModeloDaViaturaChange = (e) => {
        setModeloViatura(e.target.value);
    };

    const handleVINDaViaturaChange = (e) => {
        setVinViatura(e.target.value);
    };

    const handleDataChange = (e) => {
        let day = "";
        let month = "";
        let year = "";

        if (e && e instanceof Object && e.hasOwnProperty('$y') && e.hasOwnProperty('$M') && e.hasOwnProperty('$D')) {
            // Extract day, month, and year
            day = e.$D.toString().padStart(2, '0');
            month = (e.$M + 1).toString().padStart(2, '0'); // Month is zero-based
            year = e.$y.toString();
        }

        setDataDoDocumento(day + '/' + month + '/' + year);
    };


    const dayjsDate = (date) => {
        if (date && date !== '') {
            return dayjs(date, 'DD/MM/YYYY')
        }
    }

    const handleIDdoNegocioChange = (e) => {
        setIDdoNegocio(e.target.value);
    };





    const handleAdicionarMaisSocios = (value) => {
        setnumSocios(value);
        const numInputs = parseInt(value);
        const currentInputs = [...inputs]; // Copia os inputs existentes
        if (currentInputs.length > numInputs) {
            const updatedInputs = currentInputs.slice(0, numInputs); // Remove inputs extras se houverem
            setInputs(updatedInputs);
            setDataSociosQuatro(updatedInputs)
        } else {
            const newInputs = [];
            for (let i = currentInputs.length; i < numInputs; i++) {
                if (tipo === "novo_negocio") {
                    newInputs.push({ nome_gerente: "", numero_cc: "", pais_emissor_cc: "", validade_cc: "", nif_gerente: "" });
                } else {
                    newInputs.push({ nome_gerente: "", numero_cc: "", pais_emissor_cc: "", validade_cc: "" });
                }
            }
            const updatedInputs = [...currentInputs, ...newInputs]; // Adiciona novos inputs aos existentes
            setInputs(updatedInputs);
            setDataSociosQuatro(updatedInputs)
        }
    };


    const handleNomeGerenteChange = (index, value) => {
        const newInputs = [...inputs];
        newInputs[index].nome_gerente = value;
        setInputs(newInputs);
        // //console.log(inputs)
        setDataSociosQuatro(newInputs)
    };


    const handleNumCCSocioChange = (index, value) => {
        const newInputs = [...inputs];
        newInputs[index].numero_cc = value;
        setInputs(newInputs);
        setDataSociosQuatro(inputs)
    };



    return (
        <>

            <div className="grid grid-cols-2 gap-2">
                <div className='grid gap-2'>
                    <p>ID do negócio</p>
                    <Input
                        placeholder="ID do negócio"
                        value={idDoNegocio}
                        onChange={handleIDdoNegocioChange}
                        type="text"
                        required
                    />
                </div>

                <div className='grid gap-2'>
                    <p>Data do documento</p>
                    <DatePicker placeholder="dd/mm/aaaa"
                        defaultValue={dayjsDate(dataDoDocumento)}
                        value={dayjsDate(dataDoDocumento)}
                        format={'DD/MM/YYYY'}
                        // picker="month"
                        onChange={handleDataChange}

                    />
                    {alertaDataDocumento && <small className="text-red-500">A data do documento é obrigatória</small>}
                </div>
            </div >

            <p className="font-semibold">Detalhes da Transportadora:</p>

            <div className="grid grid-cols-2 gap-2">

                <div className='grid gap-2'>
                    <p>Transportadora</p>
                    <Select
                        placeholder="Selecione o tipo de transportadora"
                        value={transportadora ? transportadora : undefined}
                        onChange={handleTransportadoraChange}
                    >
                        <Option value="cascao">Cascão</Option>
                        {/* <Option value="transtomas">TransTomás</Option> */}
                        <Option value="transportes_matos">Transportes Matos</Option>
                        <Option value="osd_gmbh">OSD Gmbh</Option>
                        <Option value="fehrenkotter">Fehrenkötter</Option>
                        <Option value="outro">Outro</Option>
                    </Select>
                </div>

                <div className='grid gap-2'>
                    <p>Nome da transportadora</p>
                    <Input
                        placeholder="Nome da transportadora"
                        value={transportadora}
                        onChange={handleNomeTransportadoraChange}
                        type="text"
                        required
                    />
                </div>

                <div className='grid gap-2'>
                    <p>NIF da transportadora</p>
                    <Input
                        placeholder="NIF da transportadora"
                        value={nifTransportadora}
                        onChange={handleNIFTransportadoraChange}
                        type="text"
                        required
                    />
                </div>

            </div>



            {tipoSociedade === "particular" ? (
                <>
                    <p className="font-semibold">Detalhes do Cliente:</p>

                    <div className="grid grid-cols-2 gap-2">
                        <div className='grid gap-2'>
                            <p>Nome do Cliente</p>
                            <Input
                                placeholder="Nome do cliente"
                                value={nomeIndividual}
                                onChange={handleNomeChange}
                                type="text"
                                required
                            />
                        </div>

                        <div className='grid gap-2'>
                            <p>Nº Cartão de Cidadão</p>
                            <Input
                                placeholder="Nº Cartão de Cidadão"
                                value={numeroCCIndividual}
                                onChange={handleNumeroIndividualCCChange}
                                type="text"
                                required
                            />
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <p className="font-semibold">Detalhes do Cliente:</p>
                    <div className="grid grid-cols-2 gap-2">
                        <div className='grid gap-2'>
                            <p>Nome do sócio gerente</p>
                            <Input
                                placeholder="Nome do Sócio gerente"
                                value={nomeSocioEmpresa}
                                onChange={handleNomeSocioChange}
                                type="text"
                                required
                            />
                        </div>
                        <div className='grid gap-2'>
                            <p>Nº cartão de cidadão do sócio gerente</p>
                            <Input
                                placeholder="Nº cartão de cidadão do sócio gerente"
                                value={numeroCCEmpresa}
                                onChange={handleNumeroCCEmpresaChange}
                                type="text"
                                required
                            />
                        </div>
                    </div>
                    <p className="font-semibold">Detalhes da Empresa:</p>
                    <div className="grid grid-cols-2 gap-2">
                        <div className='grid gap-2'>
                            <p>Nome da empresa</p>
                            <Input
                                placeholder="Nome da empresa"
                                value={nomeEmpresa}
                                onChange={handleNomeEmpresaChange}
                                type="text"
                                required
                            />
                        </div>
                        <div className='grid gap-2'>
                            <p>NIF da empresa</p>
                            <Input
                                placeholder="NIF da empresa"
                                value={nifEmpresa}
                                onChange={handleNifEmpresaChange}
                                type="text"
                                required
                            />
                        </div>
                    </div>
                </>
            )}





            <p className="font-semibold">Detalhes da Viatura:</p>

            <div className="grid grid-cols-2 gap-2">

                {tipoContrato !== "venda" ? (
                    <div className='grid gap-2'>
                        <p>Marca e modelo da viatura</p>
                        <Input
                            placeholder="Marca e modelo da viatura"
                            value={marcaViatura}
                            onChange={handleMarcaDaViaturaChange}
                            type="text"
                            required
                        />
                    </div>
                ) : null}


                <div className='grid gap-2'>
                    <p>VIN da viatura</p>
                    <Input
                        placeholder="VIN da viatura"
                        value={vinViatura}
                        onChange={handleVINDaViaturaChange}
                        type="text"
                        required
                    />
                </div>

            </div>


            {tipoSociedade === "empresa" && tipo !== "novo_negocio" ? (
                <div className="grid grid-col-2 gap-2">
                    <p>Número de Sócios</p>
                    <span className="text-sm">Preencha apenas os sócios para além do gerente já preenchido acima.</span>
                    <Select
                        placeholder="Selecione o numSocios"
                        value={numSocios}
                        onChange={handleAdicionarMaisSocios}
                    >
                        <Option value="0">0</Option>
                        <Option value="1">+1</Option>
                        <Option value="2">+2</Option>
                        <Option value="3">+3</Option>
                        <Option value="4">+4</Option>
                        <Option value="5">+5</Option>
                    </Select>
                    <div className="grid grid-cols-1 gap-2">
                        {inputs.map((input, index) => (
                            <div className="grid grid-cols-2 gap-2 separador-socios" key={index}>
                                <div className="grid gap-2">
                                    <p>Nome do Sócio gerente {index + 1}</p>
                                    <Input
                                        placeholder="Nome do Sócio gerente"
                                        value={input.nome}
                                        onChange={(e) => handleNomeGerenteChange(index, e.target.value)}
                                        type="text"
                                        required
                                    />
                                </div>
                                <div className="grid gap-2">
                                    <p>Nº Cartão de Cidadão {index + 1}</p>
                                    <Input
                                        placeholder="Nº Cartão de Cidadão"
                                        value={input.cartaodecidadao}
                                        onChange={(e) => handleNumCCSocioChange(index, e.target.value)}
                                        type="text"
                                        required
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            ) : null}



        </>
    )
}

