import { Tooltip } from 'antd';
import React, { useState } from 'react';
import { AiOutlineInfoCircle } from 'react-icons/ai';


export default function EstimativaDeComissao({ valoresTotais, formatarNumero }) {



    return (
        <div className=" h-auto hide-on-capture flex items-center justify-between gap-2 text-md border-card bg-black">
            <span className="flex gap-1 text-xs sm:text-sm text-neutral-500 font-semibold justify-center items-center">
                Estimativa de comissão
                <Tooltip title="O valor apresentado é um cálculo baseado na média de comissões de 2024, sendo que pode variar consoante os custos do deal (por exemplo: transporte mais caro).">
                    <AiOutlineInfoCircle className='icon-question' />
                </Tooltip>
            </span>
            <span className="flex text-sm font-bold justify-center items-center">
                {valoresTotais.taxaDeServico !== '' && `${formatarNumero(valoresTotais.comissao)}`}
            </span>
        </div>
    );
};
