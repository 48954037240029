import React, { useState, useEffect } from 'react';
import { Button, Modal } from 'antd';
import { FiExternalLink } from 'react-icons/fi';
import { MdShare } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import { FaHubspot } from 'react-icons/fa';
import PartilharClientePropostaPT from './PT/PartilharClientePropostaPT';
import PartilharClientePropostaES from './ES/PartilharClientePropostaES';

export default function PartilharClienteProposta({ propostaInfo, dataCarros, valoresTotais, titulo, formatarNumero, dataUser, propostas, dataPropostas }) {

    const { t } = useTranslation();
    const [isModalVisible, setIsModalVisible] = useState(false); //modal para os ctas de copiar textos 
    //valores para propostas para Empresa
    const [taxaDeServicoSemIva, setTaxaDeServicoSemIva] = useState(null);
    const [precoFinalChaveNaMaoSIva, setPrecoFinalChaveNaMaoSIva] = useState(null);
    const [precoFinalChaveNaMaoCIva, setPrecoFinalChaveNaMaoCIva] = useState(null);
    const [valorTotalCaucaoParticular, setValorTotalCaucaoParticular] = useState(null);
    const [valorTotalCaucaoEmpresa, setValorTotalCaucaoEmpresa] = useState(null);

    useEffect(() => {
        if (valoresTotais) {

            //console.log(valoresTotais)

            const taxaDeServicoSemIva = valoresTotais.taxaDeServico / 1.23;
            setTaxaDeServicoSemIva(taxaDeServicoSemIva);

            //valores com iva - para Particular
            let precoChaveNaMaoComIva =
                valoresTotais.precoViaturaComIva +
                valoresTotais.taxaDeServico +
                valoresTotais.valorTotalISV;

            //console.log(precoChaveNaMaoComIva)    

            if (valoresTotais.taxaDeFinanciamentoAtivo) {
                precoChaveNaMaoComIva += valoresTotais.taxaDeFinanciamento;
            }

            setPrecoFinalChaveNaMaoCIva(precoChaveNaMaoComIva);

            const precoCaucaoComIva = precoChaveNaMaoComIva * 0.2
            setValorTotalCaucaoParticular(precoCaucaoComIva)

            //valores sem iva - para Empresa
            let precoChaveNaMaoSemIva =
                (valoresTotais.precoViaturaSemIva + valoresTotais.taxaDeServico) +
                valoresTotais.valorTotalISV;

            if (valoresTotais.taxaDeFinanciamentoAtivo) {
                precoChaveNaMaoSemIva += valoresTotais.taxaDeFinanciamento;
            }

            setPrecoFinalChaveNaMaoSIva(precoChaveNaMaoSemIva);

            const precoCaucaoSemIva = precoChaveNaMaoSemIva * 0.2
            setValorTotalCaucaoEmpresa(precoCaucaoSemIva)
        }
    }, [valoresTotais]);


    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };


    return (
        <div className={`flex items-center ${titulo ? 'gap-4' : 'gap-1'}`}>
            <Modal
                title={t('gerar_proposta.partilhar_com_cliente')}
                open={isModalVisible}
                onOk={handleCancel}
                onCancel={handleCancel}
                okText="Fechar"
                footer={null}
            >

                <div className="text-black grid gap-6 mt-6">

                    {dataUser.language === "pt" ? (
                        <PartilharClientePropostaPT
                            precoFinalChaveNaMaoCIva={precoFinalChaveNaMaoCIva}
                            precoFinalChaveNaMaoSIva={precoFinalChaveNaMaoSIva}
                            valorTotalCaucaoParticular={valorTotalCaucaoParticular}
                            valorTotalCaucaoEmpresa={valorTotalCaucaoEmpresa}
                            propostaInfo={propostaInfo}
                            valoresTotais={valoresTotais}
                            dataCarros={dataCarros}
                            formatarNumero={formatarNumero}
                            dataUser={dataUser}
                        />
                    ) : (
                        <PartilharClientePropostaES
                            precoFinalChaveNaMaoCIva={precoFinalChaveNaMaoCIva}
                            precoFinalChaveNaMaoSIva={precoFinalChaveNaMaoSIva}
                            valorTotalCaucaoParticular={valorTotalCaucaoParticular}
                            valorTotalCaucaoEmpresa={valorTotalCaucaoEmpresa}
                            propostaInfo={propostaInfo}
                            valoresTotais={valoresTotais}
                            dataCarros={dataCarros}
                            formatarNumero={formatarNumero}
                            dataUser={dataUser}
                        />
                    )}

                </div>


            </Modal>

            <Button
                type="primary"
                onClick={showModal}
                className='shadow-none font-bold text-black bg-white'>
                <div className='flex items-center gap-2'>
                    <MdShare />
                    {titulo ? t('gerar_proposta.partilhar_com_cliente') : null}
                </div>
            </Button>


            <Button
                type="primary"
                onClick={() => window.open(propostaInfo.linkDaProposta, '_blank')}
                className='shadow-none font-bold text-black bg-white'>
                <div className='flex items-center gap-2'>
                    <FiExternalLink />
                    {titulo ? t('gerar_proposta.abrir') : null}
                </div>
            </Button>


            {propostas && (
                <Button
                    type="primary"
                    onClick={() => window.open(`/${t('menu.hubspot').toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/ /g, '-')}/${t('hubspot.perfil_deal').toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/ /g, '-')}/?id_deal=${dataPropostas.id_deal_hubspot}`, '_blank')}
                    className="shadow-none font-bold text-black bg-white"
                >
                    <div className="flex items-center gap-2">
                        <FaHubspot />
                    </div>
                </Button>
            )}

        </div>
    );
}
