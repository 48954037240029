import React, { useState } from "react";
import { FiCopy, FiCheck } from "react-icons/fi";
import { message } from "antd";
import { useTranslation } from "react-i18next";

export default function WhatsappParticularPT({
  dataCarros,
  dataUser,
  propostaInfo,
  valoresTotais,
  formatarNumero,
}) {
  const { t } = useTranslation();
  const [copiedItem, setCopiedItem] = useState(null);

  const handleOptionClick = async (value) => {
    if (value !== 1 || !propostaInfo || !valoresTotais || !dataCarros || !dataUser) return;

    const text = `*${dataCarros.titulo}*\n\n` +
      `${t("partilhar_email.preco_carro")}: ${formatarNumero(valoresTotais.precoViaturaComIva)} ${dataUser.language === "es" ? "_(POR NEGOCIAR)_" : ""
      }\n` +
      `${t("partilhar_email.isv")}: ${valoresTotais.valorTotalISV == "0" || valoresTotais.valorTotalISV == 0
        ? t("gerar_proposta.isento")
        : formatarNumero(valoresTotais.valorTotalISV)
      }\n` +
      `${t("partilhar_email.despesas_de_importacao")}: ${formatarNumero(valoresTotais.taxaDeServico)}\n` +
      `${t("partilhar_email.preco_final")}: ${formatarNumero(valoresTotais.valorPrecoChaveNaMaoParticular)}\n\n` +
      `*${t("gerar_proposta.link_proposta")}: ${propostaInfo.linkDaProposta}*`;

    try {
      await navigator.clipboard.writeText(text);
      message.success("Copiado para a área de transferência!");
      setCopiedItem(value);
      setTimeout(() => setCopiedItem(null), 2000);
    } catch (error) {
      console.error("Erro ao copiar:", error);
      message.error("Erro ao copiar o conteúdo.");
    }
  };

  return (
    <div className="flex items-center gap-2">
      <label className="flex items-center gap-2 cursor-pointer">
        {copiedItem === 1 ? (
          <FiCheck
            size={18}
            title="Copiado!"
          />
        ) : (
          <FiCopy
            size={18}
            title={t("gerar_proposta.copiar_texto_para_whats_particular")}
            onClick={() => handleOptionClick(1)}
          />
        )}
        <span
          className='text-sm'
          onClick={() => handleOptionClick(1)}
        >
          {t("gerar_proposta.copiar_texto_para_whats_particular")}
        </span>
      </label>
    </div>
  );
}
