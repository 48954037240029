import React, { useState, useEffect } from 'react';
import { Button, Input, message, Form, Spin, Select } from 'antd';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { LoadingOutlined } from '@ant-design/icons';

const { Option } = Select;

const antIcon = (
    <LoadingOutlined
        style={{
            fontSize: 25,
        }}
        spin
    />
);



export default function FromEditarProposta({
    dataUser,
    propostaInfo,
    valoresTotais,
    setValoresTotais,
    isvIucData,
    setIsvIucData,
    setPropostaInfo,
    drawerVisibleEditProposta
}) {

    const { t } = useTranslation();
    const [form] = Form.useForm(); // Cria uma instância do formulário

    const [isvCalculado, setIsvCalculado] = useState(valoresTotais.valorTotalISV); // Estado local para armazenar o resultado do cálculo do ISV
    const [usouCalculo, setUsouCalculo] = useState(false);
    const [timeoutId, setTimeoutId] = useState(null);
    const [isCalculating, setIsCalculating] = useState(false); // Novo estado

    const [newTipoProposta, setNewTipoProposta] = useState(propostaInfo.tipoProposta); // tipo da Proposta


    const [dataListas, setDataListas] = useState({
        // listaMarcas: [],
        // listaModelos: [],
        listaCidades: [],
        listaMunicipios: []
    });


    const formatarNumero = (valor) => {
        if (!valor || isNaN(valor)) return '0 €';
        return new Intl.NumberFormat('pt-PT', {
            style: 'currency',
            currency: 'EUR',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        }).format(valor);
    };


    // Função para alterar os valores de todos os inputs
    const handleValueChange = (e) => {
        const { name, value } = e.target;

        // Converte para número, ou 0 caso não seja um número válido
        const numericValue = parseFloat(value) || 0;
        //console.log('numericValue', numericValue)

        setValoresTotais((prevData) => ({
            ...prevData,
            [name]: numericValue // Garante que é armazenado como número
        }));
    };



    ////console.log('valoresTotais', valoresTotais)

    // Função para calcular o ISV baseado nas emissões de CO2
    useEffect(() => {

        console.log(isCalculating)
        if (isCalculating) return;

        if (isvIucData.tipoCombustivel == "Eletrico") {
            setUsouCalculo(true);
            setIsCalculating(false);
            return;
        }

        if (!isvIucData.emissoesCO2) {
            message.error(t('editar_proposta.error'));
            return;
        }

        // const handleNovasEmissoes = (e) => {
        // setUsouCalculo(false);
        setIsCalculating(true); // Inicia o cálculo


        const novoValorEmissoes = isvIucData.emissoesCO2;
        //console.log(novoValorEmissoes)

        if (timeoutId) {
            clearTimeout(timeoutId);
        }



        if (novoValorEmissoes) {

            // console.log('entrou')

            const newTimeoutId = setTimeout(() => {

                let pedido
                let data

                if (dataUser.language === 'pt') {
                    pedido = process.env.REACT_APP_PEDIDO_ISV

                    //console.log(isvIucData)

                    let tipoDeVeiculoFinal;
                    if (isvIucData.hibridoPlugIn == 1) {
                        tipoDeVeiculoFinal = "HibridosPlugIn";
                    } else {
                        tipoDeVeiculoFinal = isvIucData.tipoDeVeiculoParaOutros;
                    }

                    const hibridoMais25kmAutonomia = isvIucData.hibridoMais25kmAutonomia == true ? 1 : 0;

                    data = {
                        id_proposta: propostaInfo.idProposta,
                        novo: isvIucData.novoUsado,
                        tipo_de_testes_co2: isvIucData.wltp == 1 ? 'WLTP' : 'NEDC',
                        tipo_carro: tipoDeVeiculoFinal,
                        combustivel: isvIucData.tipoCombustivel,
                        particulas: isvIucData.particulas,
                        co2: novoValorEmissoes,
                        cilindrada: isvIucData.cilindrada,
                        ano: isvIucData.dataPrimeiroRegisto,
                        isencao: isvIucData.tipoDeVeiculoParaInsencoes,
                        hibrido_mais_25km_autonomia: hibridoMais25kmAutonomia,
                        user_id: dataUser.iDTokenAuthenticated,
                        token: dataUser.saveToken,
                    };

                } else {
                    pedido = process.env.REACT_APP_PEDIDO_ISV_ES

                    data = {


                        cilindrada: isvIucData.cilindrada,
                        novo: isvIucData.novoUsado,
                        combustivel: isvIucData.tipoCombustivel,
                        co2: novoValorEmissoes,
                        ano: isvIucData.dataPrimeiroRegisto,
                        isencao: isvIucData.tipoDeVeiculoParaInsencoes,
                        id_modelo: isvIucData.idModelo,
                        id_cidade: isvIucData.provincia,
                        valor_viatura_nova: isvIucData.valorViaturaNova,

                        // tipo_de_testes_co2: isvIucData.wltp,
                        // tipo_carro: isvIucData.tipoDeVeiculoParaOutros,
                        // particulas: isvIucData.tipoCombustivel,

                        id_proposta: propostaInfo.idProposta,
                        user_id: dataUser.iDTokenAuthenticated,
                        token: dataUser.saveToken,
                    };

                    // //console.log(data)
                }



                axios.post(pedido, data)
                    .then((response) => {
                        // //console.log(response)
                        if (response.data.sucesso) {
                            setIsvCalculado(response.data.data.isv);
                            setValoresTotais((prevData) => ({
                                ...prevData,
                                valorTotalISV: parseFloat(response.data.data.isv)
                            }));
                            setUsouCalculo(true);
                        } else {
                            message.error(response.data.mensagem);
                        }
                    })
                    .catch(() => {
                        message.error(t('editar_proposta.error'));
                    })
                    .finally(() => {
                        setIsCalculating(false); // Finaliza o cálculo
                    });

            }, 1000);

            setTimeoutId(newTimeoutId);

        } else {
            console.log('entrou aqui')
            message.error(t('editar_proposta.error'));
            // setUsouCalculo(true);
            // setIsCalculating(false); // Inicia o cálculo
        }

    }, [isvIucData.emissoesCO2, isvIucData.provincia, isvIucData.municipio]);

    // //console.log(valoresTotais)

    // Função para finalizar a edição da proposta
    const finalizarEdiçãodeProposta = (values) => {


        console.log('valoresTotais', values)


        let dados;
        ////console.log(values)
        //setIsCalculating(true); // Inicia o cálculo

        // Certifique-se de que os valores são convertidos para números
        const precoViaturaComIva = parseFloat(values.precoViaturaComIva) || 0;
        const precoViaturaSemIva = parseFloat(values.precoViaturaSemIva) || 0;
        //const taxaDeServico = parseFloat(values.taxaDeServico) || 0;
        const isvNewCalculado = parseFloat(isvCalculado) || 0;
        ////console.log(values.isvCalculado)

        // Cálculo do total para "Chave na Mão"
        const calculoTotalChaveNaMaoParticular = precoViaturaComIva + values.taxaDeServico + isvNewCalculado;
        const calculoTotalChaveNaMaoEmpresa = precoViaturaSemIva + values.taxaDeServico + isvNewCalculado;

        // Verifique se valoresTotais está definido antes de acessá-lo
        const compsISV = values.valoresTotais?.compsISV || {};

        if (dataUser.language === 'pt') {


            dados = {
                tipo_proposta: values.tipoProposta,
                emissoes_numerico: values.emissoesCO2, // Pega o valor atualizado
                taxadeservico: values.taxaDeServico,
                valor_chavenamao_particular: calculoTotalChaveNaMaoParticular,
                valor_chavenamao_empresa: calculoTotalChaveNaMaoEmpresa,
                preco_final_carro: values.precoViaturaComIva,
                preco_final_carro_sem_iva: values.precoViaturaSemIva,
                isv: isvCalculado,
                isv_componente_cilindrada: compsISV.isvcomponentecilindrada,
                isv_componente_ambiental: compsISV.isvcomponenteambiental,
                isv_reducao_anos_uso_cilindrada: compsISV.isvReducaoAnosUsoCilindrada,
                isv_reducao_anos_uso_ambiental: compsISV.isvReducaoAnosUsoAmbiental,
                isv_agravamento_particulas: compsISV.isvAgravamentoParticulas,
                isv_reducao_anos_uso_particulas: compsISV.isvReducaoAnosUsoParticulas,
                isv_taxa_aplicavel_tabela: compsISV.isvTaxaAplicavelTabela,
            };

        } else {
            dados = {
                emissoes_numerico: values.emissoesCO2, // Pega o valor atualizado
                taxadeservico: values.taxaDeServico,
                valor_chavenamao_particular: calculoTotalChaveNaMaoParticular,
                valor_chavenamao_empresa: calculoTotalChaveNaMaoEmpresa,
                preco_final_carro: values.precoViaturaComIva,
                preco_final_carro_sem_iva: values.precoViaturaSemIva,
                isv: isvCalculado,


                isv_base_imponible: compsISV.isvBaseImponible,
                isv_base_imponible_final: compsISV.isvBaseImponibleFinal,
                isv_comunidad: compsISV.isvComunidad,
                isv_cuota_tributaria: compsISV.isvCuotaTributaria,
                isv_minoracion: compsISV.isvMinoracion,
                isv_porcentaje_aplicar: compsISV.isvPorcentajeAplicar,
                isv_reduccion_base_imponible: compsISV.isvReduccionBaseImponible,
                isv_total_pagar: compsISV.isvTotalPagar,
                isv_valoracion_inicial: compsISV.isvValoracionInicial,

                id_cidade: isvIucData.provincia,
                nome_cidade: isvIucData.nomeProvincia,
                id_municipio: isvIucData.municipio,
                nome_municipio: isvIucData.nomeMunicipio,
            };
        }



        const data = {
            id_proposta: propostaInfo.idProposta,
            user_id: dataUser.iDTokenAuthenticated,
            token: dataUser.saveToken,
            array_dados: dados,
        };


        axios.post(`${process.env.REACT_APP_URL_EDITAR_PROPOSTA}`, data)
            .then((response) => {
                // //console.log(response)
                if (response.data.sucesso) {
                    message.success(response.data.mensagem);
                    setPropostaInfo((prevData) => ({
                        ...prevData,
                        tipoProposta: values.tipoProposta,
                    }))
                } else {
                    message.error(response.data.mensagem);
                }
            })
            .catch(() => {
                message.error(t('editar_proposta.error'));
            })
            .finally(() => {
                setIsCalculating(false); // Finaliza o cálculo
            });
    };


    //console.log(valoresTotais)
    //console.log(dataListas)


    const fetchCidade = async () => {
        try {

            const requestUrl = `${process.env.REACT_APP_GET_BUSCAR_CIDADES}`;

            const response = await axios.get(requestUrl);

            setDataListas((prevData) => ({
                ...prevData,
                listaCidades: response.data.mensagem,
            }));

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };


    useEffect(() => {

        //setIsCalculating(true);

        const fetchMunicipio = async () => {
            try {

                const requestUrl = `${process.env.REACT_APP_GET_BUSCAR_MUNICIPIOS}?id_cidade=${isvIucData.provincia}`;
                const response = await axios.get(requestUrl);

                setDataListas((prevData) => ({
                    ...prevData,
                    listaMunicipios: response.data.mensagem,
                }));

                setIsCalculating(false); // Finaliza o cálculo

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        if (isvIucData.provincia) {

            fetchMunicipio();
        }

    }, [isvIucData.provincia]);


    useEffect(() => {
        fetchCidade();
    }, [drawerVisibleEditProposta]);


    //console.log(isvIucData)

    return (
        <Form
            layout="vertical"
            onFinish={finalizarEdiçãodeProposta}
            form={form}
            initialValues={{
                tipoProposta: newTipoProposta,
                taxaDeServico: valoresTotais.taxaDeServico,
                precoViaturaComIva: valoresTotais.precoViaturaComIva,
                precoViaturaSemIva: valoresTotais.precoViaturaSemIva,
                emissoesCO2: isvIucData.emissoesCO2,
                provincia: isvIucData.nomeProvincia,
                municipio: isvIucData.nomeMunicipio
            }}
        >

            <div className='flex flex-col items-stretch gap-2'>
                <p className='font-semibold'>{t('tables.tipo')}</p>
                <Form.Item
                    name="tipoProposta"
                >
                    <Select
                        placeholder={t('tables.tipo')}
                        onChange={(value) => setNewTipoProposta(value)}
                    >
                        <Select.Option value="proposta_cliente">{t('tables.proposta_cliente')}</Select.Option>
                        <Select.Option value="proposta_interna">{t('tables.proposta_interna')}</Select.Option>
                        <Select.Option value="proposta_de_content">{t('tables.proposta_de_content')}</Select.Option>
                    </Select>
                </Form.Item>

            </div>

            <div className='flex flex-col items-stretch gap-2'>
                <p className='font-semibold'>{t('editar_proposta.taxa_servico')}</p>
                <Form.Item name="taxaDeServico">
                    <Input
                        placeholder={t('editar_proposta.taxa_servico')}
                        value={valoresTotais.taxaDeServico}
                        onChange={handleValueChange}
                        name="taxaDeServico"
                        type="number"
                    />
                </Form.Item>
            </div>

            <div className='flex flex-col items-stretch gap-2'>
                <p className='font-semibold'>{t('editar_proposta.valor_v_particular')}</p>
                <Form.Item name="precoViaturaComIva">
                    <Input
                        placeholder={t('editar_proposta.valor_v_particular')}
                        value={formatarNumero(valoresTotais.precoViaturaComIva)} // Aplica a formatação
                        onChange={handleValueChange}
                        name="precoViaturaComIva"
                        type="number"
                    />
                </Form.Item>
            </div>


            <div className='flex flex-col items-stretch gap-2'>
                <p className='font-semibold'>{t('editar_proposta.valor_v_empresa')}</p>
                <Form.Item name="precoViaturaSemIva">
                    {/* <Input
                        placeholder={t('editar_proposta.valor_v_empresa')}
                        value={valoresTotais.precoViaturaSemIva}
                        onChange={handleValueChange}
                        name="precoViaturaSemIva"
                        type="number"
                    /> */}
                    <Input
                        placeholder={t('editar_proposta.valor_v_empresa')}
                        value={formatarNumero(valoresTotais.precoViaturaSemIva)} // Aplica a formatação
                        onChange={handleValueChange}
                        name="precoViaturaSemIva"
                        type="number" // Alterado para "text" para evitar problemas de formatação com inputs numéricos
                    />
                </Form.Item>
            </div>



            {dataUser.language === 'es' && (
                <>
                    <div className='flex flex-col items-stretch gap-2'>
                        <p className='font-semibold'>Provincias</p>
                        <Form.Item
                            name="provincia"
                            rules={[
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value) {
                                            return Promise.reject('¡Por favor, ingrese la provincia!'); // Mensaje para campo vacío
                                        }
                                        if (value === '0' || value === 0) {
                                            return Promise.reject('¡Por favor, ingrese un valor válido para la provincia!'); // Mensaje para valor inválido (0)
                                        }

                                        return Promise.resolve();
                                    },
                                }),
                            ]}

                        >
                            <Select
                                showSearch
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                onChange={(value) => {

                                    const cidadeSeleccionado = dataListas.listaCidades.find(
                                        (cidade) => cidade.id_cidade === value
                                    );

                                    //console.log(cidadeSeleccionado)

                                    setIsvIucData((prevData) => ({
                                        ...prevData,
                                        nomeProvincia: cidadeSeleccionado.nome_cidade,
                                        provincia: cidadeSeleccionado.id_cidade,
                                        nomeMunicipio: '',
                                        municipio: null
                                    }));

                                    setDataListas((prevData) => ({
                                        ...prevData,
                                        listaMunicipios: [],
                                    }));

                                    // Reseta o campo do formulário
                                    form.setFieldsValue({
                                        municipio: "", // Define o município como vazio no formulário
                                    });

                                    message.warning("Seleccione nuevamente el municipio después de cambiar la provincia.");

                                }}

                            >
                                {dataListas.listaCidades.map((cidade) => (
                                    <Option key={cidade.id_cidade} value={cidade.id_cidade}>
                                        {cidade.nome_cidade}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </div>

                    <div className='flex flex-col items-stretch gap-2'>
                        <p className='font-semibold'>Munícipio</p>
                        <Form.Item
                            name="municipio"
                            rules={[
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value) {
                                            return Promise.reject('¡Por favor, ingrese el municipio!'); // Mensaje para campo vacío
                                        }
                                        if (value === '0' || value === 0) {
                                            return Promise.reject('¡Por favor, ingrese un valor válido para el municipio!'); // Mensaje para valor inválido (0)
                                        }

                                        return Promise.resolve();
                                    },
                                }),
                            ]}
                        >
                            <Select
                                showSearch
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                onChange={(value) => {

                                    const municipioSeleccionado = dataListas.listaMunicipios.find(
                                        (municipio) => municipio.id === value
                                    );

                                    //console.log(municipioSeleccionado)

                                    setIsvIucData((prevData) => ({
                                        ...prevData,
                                        nomeMunicipio: municipioSeleccionado.nome_municipio,
                                        municipio: municipioSeleccionado.id
                                    }));

                                    // setTriggerCalculo((prevData) => ({
                                    //     ...prevData,
                                    //     nomeMunicipio: municipioSeleccionado.nome_municipio
                                    // }));

                                }}

                            >
                                {dataListas.listaMunicipios.map(municipio => (
                                    <Option key={municipio.id} value={municipio.id}>{municipio.nome_municipio}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </div>

                </>
            )}




            {isvIucData.tipoCombustivel !== "Eletrico" && (
                <>
                    <div className='flex flex-col items-stretch gap-2'>
                        <p className='font-semibold'>{t('editar_proposta.emissoes')}</p>
                        <Form.Item name="emissoesCO2">
                            <Input
                                placeholder={t('editar_proposta.emissoes')}
                                name="emissoesCO2"
                                type="number"
                                onChange={(e) => {
                                    const novoValor = Number(e.target.value);
                                    setIsvIucData((prevData) => ({
                                        ...prevData,
                                        emissoesCO2: novoValor,
                                    }));
                                }}
                            />
                        </Form.Item>
                    </div>
                    {usouCalculo && (
                        <div className='flex flex-col items-stretch gap-2 mb-6'>
                            <p className='font-semibold'>{t('tables.isv')}</p>
                            <Input
                                placeholder={t('tables.isv')}
                                value={parseFloat(isvCalculado)} // Aplica a formatação
                                onChange={(e) => {
                                    const numericValue = parseFloat(e.target.value.replace(/[^\d]/g, '')) || 0;
                                    setIsvCalculado(numericValue);
                                }} // Permitir edição manual sem formatação de moeda
                                type="number" // Alterado para "text" para evitar problemas de formatação com inputs numéricos
                            />
                        </div>
                    )}
                </>
            )}



            <Form.Item>
                {!isCalculating ? ( // Mostra o botão apenas se não estiver calculando
                    <Button type="primary" htmlType="submit" className="shadow-none font-bold text-black bg-green-400">
                        {t('editar_proposta.guardar')}
                    </Button>
                ) : (
                    <Spin indicator={antIcon} />
                )}
            </Form.Item>

        </Form>
    );
}
