import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { message, Spin } from 'antd';
import flagPortugal from '../../../assets/flags/flag-portugal.svg';
import flagSpain from '../../../assets/flags/flag-spain.svg';
import CardDestaqueMinimalista from '../../AllCards/CardDestaqueMinimalista';

export default function AnalyticsAutomacoes({ comparadorVisible, dataUser, filters }) {

    const [data, setData] = useState({
        analytics: {},
        datas: {}
    }); // Inicializa como um objeto para evitar erros


    useEffect(() => {
        const obterData = {
            ...filters,
            user_id: dataUser?.iDTokenAuthenticated || null,
            token: dataUser?.saveToken || '',
            automacoes: 1
        };

        const fetchData = async () => {
            const url = `${process.env.REACT_APP_GET_ANALYTICS_TABELA}`;

            try {
                const response = await axios.get(url, { params: obterData });

                if (response.data.sucesso) {
                    setData({ analytics: response.data.data || {} });
                } else {
                    message.warning(response.data.mensagem);
                }
            } catch (error) {
                console.error('Erro na requisição:', error);
                message.error('Erro ao carregar os dados.');
            } finally {
            }
        };

        fetchData();
    }, [dataUser?.iDTokenAuthenticated, dataUser?.language, filters]); // Mantém dependências seguras


    return (
        <>
            {data.analytics?.automacoes ? (
                <div className="border-card bg-black card-cinza grid gap-4">
                    
                    {comparadorVisible && (
                        <div className='text-white'>
                            <p>A comparar com: <span className='font-semibold'>{data.analytics.compara_com}</span></p>
                        </div>
                    )}

                    <div className="grid grid-cols-4 gap-4">
                        {(dataUser.role === 'administrator' ? ['pt', 'es'] : [dataUser.language]).map(language =>
                            (data.analytics?.automacoes?.[language] || []).map((item, index) => (
                                <CardDestaqueMinimalista
                                    key={`${language}-${item?.nome}-${index}`} // Aqui garantimos um key único
                                    text={item?.nome}
                                    number={item?.total}
                                    comparadorNumero={item.diferenca_percentagem}
                                    comparadorPercentagem={item.diferenca_percentagem}
                                    icon={language === 'pt' ? flagPortugal : flagSpain}
                                    style={false}
                                    automacao={true}
                                    descricao={item?.descricao}
                                    subCategorias={item?.categorias_de_envio}
                                    comparadorVisible={comparadorVisible}
                                />
                            ))
                        )}
                    </div>
                </div>
            ) : null}
        </>
    );
}

