import React, { useEffect, useState } from 'react';
import flagPortugal from '../../assets/flags/flag-portugal.svg';
import flagSpain from '../../assets/flags/flag-spain.svg';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { Tooltip, Image, Button, Input, message } from 'antd';
import redbubble from '../../assets/red-circle.svg';
import greenbubble from '../../assets/green-circle.svg';
import { FaHourglass, FaCheckCircle, FaEye, FaClock } from 'react-icons/fa';
import CustomTable from '../Tables/CustomTables';
import CustomColumns from '../Tables/CustomColumns';
import { LuFilter } from 'react-icons/lu';
import CustomFilters from '../Tables/CustomFilters';
import ModalPerfilDeal from '../Hubspot/PerfilDeal/ModalPerfilDeal';
import { SearchOutlined } from '@ant-design/icons';
import PartilharClienteProposta from '../PerfilProposta/PartilharProposta/PartilharClienteProposta';
import { IoMdCloseCircle } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';

export default function TableTodasPropostas({ dataUser }) {

    const { t } = useTranslation();
    const navigate = useNavigate();


    const [data, setData] = useState({
        propostas: [],
    });

    const [loading, setLoading] = useState(true); // Estado de loading

    const [filtersSelected, setFiltersSelected] = useState([]); //Filtros selecionados
    const [searchQuery, setSearchQuery] = useState(''); // Pesquisa pelas propostas
    const [drawerVisibleFilters, setDrawerVisibleFilters] = useState(false); // Open a drawer do Filtros
    const [openModalPerfilDeal, setOpenModalPerfilDeal] = useState(false); //Open Modal do Perfil do Hubspot

    const [configs, setConfigs] = useState({
        porPagina: '20',
    });

    const [hubspotInfo, setHubspotInfo] = useState({
        idDoDeal: '',
        nomeDoClienteDeal: '',
        emailDoClienteDeal: '',
        etapaDoDeal: '',
        pipelineDoDeal: '',
    });


    const [configsTable, setConfigsTable] = useState({
        modalColunas: false,
    });


    // State to control visible columns
    const [visibleColumns, setVisibleColumns] = useState([
        'pais',
        'data',
        'criado_por',
        'nomedaproposta',
        'tipo',
        'estado',
        'estado_documentacao',
        // 'taxadeservico',
        'precochavenamao',
        // 'acedido',
        // 'associado',
        'acoes'
    ]);


    const formatarNumero = (valor) => {
        if (!valor || isNaN(valor)) return '0 €';
        return new Intl.NumberFormat('pt-PT', {
            style: 'currency',
            currency: 'EUR',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        }).format(valor);
    };


    useEffect(() => {

        const searchParams = new URLSearchParams(window.location.search); // Usa URLSearchParams.
        const estadoDocumentacaoURL = searchParams.get('estado_documentacao'); // Obtém o nome da proposta.
        const propostaAceiteURL = searchParams.get('deal_hubspot_fechado'); // Obtém o nome da proposta.


        const fetchData = async () => {
            setLoading(true);

            let url = `${process.env.REACT_APP_GET_PROPOSTAS}?token=${dataUser.saveToken}&user_id=${dataUser.iDTokenAuthenticated}&lang=${dataUser.language}&query=${searchQuery}`;

            const { dealHubspotFechado, id_criou, paisPropostas, dataCriacaoInicio, dataCriacaoFim, tipo_proposta, range_slider_preco_chave_da_mao, range_slider_preco_viatura, range_slider_taxadeservico, qtd_por_pagina, total_de_pedidos, estado_documentacao } = filtersSelected;


            // if (dealHubspotFechado !== undefined) url += `&deal_hubspot_fechado=${dealHubspotFechado}`;
            if (propostaAceiteURL || dealHubspotFechado) {
                url += `&deal_hubspot_fechado=${dealHubspotFechado || propostaAceiteURL}`;
            }

            if (id_criou !== undefined) url += `&id_criou=${id_criou.toString()}`; // Se necessário, converta para string
            if (paisPropostas !== undefined) url += `&pais=${paisPropostas}`;
            if (dataCriacaoInicio) url += `&data_criacao_inicio=${dataCriacaoInicio}`;
            if (dataCriacaoFim) url += `&data_criacao_fim=${dataCriacaoFim}`;

            if (tipo_proposta) url += `&tipo_proposta=${tipo_proposta}`;

            if (estadoDocumentacaoURL || estado_documentacao) {
                url += `&estado_documentacao=${estado_documentacao || estadoDocumentacaoURL}`;
            }

            if (range_slider_preco_chave_da_mao !== undefined) {
                url += `&valor_chavenamao_min=${range_slider_preco_chave_da_mao[0]}`;
                url += `&valor_chavenamao_max=${range_slider_preco_chave_da_mao[1]}`;
            }
            if (range_slider_preco_viatura !== undefined) {
                url += `&preco_carro_min=${range_slider_preco_viatura[0]}`;
                url += `&preco_carro_max=${range_slider_preco_viatura[1]}`;
            }
            if (range_slider_taxadeservico !== undefined) {
                url += `&taxadeservico_min=${range_slider_taxadeservico[0]}`;
                url += `&taxadeservico_max=${range_slider_taxadeservico[1]}`;
            }

            if (qtd_por_pagina) {
                setConfigs({
                    porPagina: qtd_por_pagina
                });
            }

            if (total_de_pedidos) url += `&limit=${total_de_pedidos}`;


            try {
                const response = await axios.get(url);

                //console.log(response.data.data.propostas)

                setData({
                    propostas: response.data.data.propostas,
                });

                setLoading(false);

            } catch (error) {
                console.error('Error occurred:', error);
            }
        };

        const timer = setTimeout(() => {
            fetchData();
        }, 1000);

        return () => clearTimeout(timer); // Limpa o timeout se o efeito for reexecutado ou desmontado

    }, [dataUser.iDTokenAuthenticated, filtersSelected, searchQuery]);




    const showModalPerfilDeal = async (valueHub, valueProposta) => {

        try {
            const url = `${process.env.REACT_APP_GET_PROCURAR_DEALS}?token=${dataUser.saveToken}&user_id=${dataUser.iDTokenAuthenticated}&id_deal=${valueHub}&lang=${dataUser.language}&id_proposta=${valueProposta}`;
            const response = await axios.get(url);

            setHubspotInfo({
                idDoDeal: response.data.deal.id,
                nomeDoClienteDeal: response.data.deal.contacto.properties.firstname,
                emailDoClienteDeal: response.data.deal.contacto.properties.email,
                etapaDoDeal: response.data.deal.properties.dealstage,
                pipelineDoDeal: response.data.deal.properties.pipeline,
            });

            setOpenModalPerfilDeal(true);

        } catch (error) {
            console.error('Error occurred:', error);
        }

    };



    //definir colunas para a tabela
    const allColumns = [
        ...(dataUser.role === 'administrator' ? [{
            title: t('tables.pais'),
            key: 'pais',
            width: 70,
            render: (proposta) =>
                proposta.pais === 'pt' ? (
                    <img src={flagPortugal} alt="Portugal" style={{ marginRight: '8px', width: '35px', height: '22px' }} />
                ) : (
                    <img src={flagSpain} alt="Spain" style={{ marginRight: '8px', width: '35px', height: '22px' }} />
                ),
        }] : []),
        {
            title: t('tables.criado_por'),
            key: 'criado_por',
            width: 190,
            render: (proposta) => (
                <div className="flex items-center gap-2">
                    <img
                        src={proposta.user_data.foto_perfil}
                        style={{ width: '29px', height: '29px', maxWidth: 'none' }}
                        className="object-contain rounded-full"
                        alt={proposta.user_data.nome_criou}
                    />
                    <span className="truncate max-w-xs">{proposta.user_data.nome_criou}</span>
                </div>
            ),
        },
        {
            title: t('tables.nome_da_proposta'),
            key: 'nomedaproposta',
            render: (proposta) => {

                // Generate the URL with lowercased and accent-removed proposal name
                const linkUrl = `/${t('menu.proposta')
                    .toLowerCase()
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '')
                    .replace(/ /g, '-')
                    }/?n=${proposta.url_acesso}`;

                return (
                    <Tooltip placement="topRight" title={proposta.nome_proposta}>
                        <a href={linkUrl} target="_blank" rel="noopener noreferrer" className='hover:underline hover:text-white'>
                            {proposta.nome_proposta.length > 21
                                ? `${proposta.nome_proposta.slice(0, 21)}...`
                                : proposta.nome_proposta}
                        </a>
                    </Tooltip>
                );
            },
        },
        {
            title: t('tables.data'),
            key: 'data',
            render: (proposta) => <span>{proposta.data_criacao}</span>,
        },
        {
            title: 'Tipo de Proposta',
            key: 'tipo',
            render: (proposta) => {
                if (proposta.tipo_proposta === 'proposta_cliente') {
                    return (
                        <span className='rounded-full bg-blue-400 text-white px-3 w-auto inline-table'>
                            {t('tables.proposta_cliente')}
                        </span>
                    );
                }
                if (proposta.tipo_proposta === 'proposta_de_content') {
                    return (
                        <span className='rounded-full bg-green-600 text-white px-3 w-auto inline-table'>
                            {t('tables.proposta_de_content')}
                        </span>
                    );
                }
                if (proposta.tipo_proposta === 'proposta_interna') {
                    return (
                        <span className='rounded-full bg-orange-600 text-white px-3 w-auto inline-table'>
                            {t('tables.proposta_interna')}
                        </span>
                    );
                }
                return null;
            },
        },
        {
            title: t('tables.estado'),
            key: 'estado',
            render: (proposta) =>
                proposta.estado_proposta_hubspot === 'proposta_aceite' ? (
                    <div className="rounded-full bg-verde text-white px-3 w-auto inline-table">
                        <span className="flex gap-2 items-center">
                            <FaCheckCircle /> {t('tables.proposta_aceite')}
                        </span>
                    </div>
                ) : (
                    <div className="rounded-full bg-[#fff6] text-black px-3 w-auto inline-table">
                        <span className="flex gap-2 items-center text-white">
                            <FaHourglass /> {t('tables.proposta_em_aberto')}
                        </span>
                    </div>
                ),
        },
        {
            title: t('tables.estado_da_documentacao'),
            key: 'estado_documentacao',
            render: (proposta) => {
                const estado = proposta.estado_documentacao;

                switch (estado) {
                    case "":
                        return (
                            <span className="flex gap-2 items-center text-white">
                                <FaHourglass style={{ color: "#EAB308" }} />
                                {t('tables.em_negociacao')}
                            </span>
                        );
                    case "pending":
                        return (
                            <span className="flex gap-2 items-center text-white">
                                <IoMdCloseCircle style={{ color: "#EAB308" }} />
                                {t('tables.doc_por_submeter')}
                            </span>
                        );
                    case "completed":
                        return (
                            <span className="flex gap-2 items-center text-white">
                                <FaCheckCircle style={{ color: "green" }} />
                                {t('tables.doc_validada')}
                            </span>
                        );
                    case "validation":
                        return (
                            <span className="flex gap-2 items-center text-white">
                                <FaClock style={{ color: "#EAB308" }} />
                                {t('tables.doc_por_validar')}
                            </span>
                        );
                    case "rejected":
                        return (
                            <span className="flex gap-2 items-center text-white">
                                <IoMdCloseCircle style={{ color: "#F44336" }} />
                                {t('tables.doc_rejeitada')}
                            </span>
                        );
                    default:
                        return t('tables.proposta_em_aberto');
                }
            },
        },
        {
            title: t('tables.imagem_viatura'),
            className: 'text-center',
            key: 'imagem-carro',
            render: (proposta) => (
                <Image style={{ textAlign: 'center', objectFit: 'contain' }} width={60} height={45} src={proposta.caracteristicas?.imagens?.[0]} />
            ),
        },
        {
            title: t('tables.marca'),
            key: 'marca',
            render: (proposta) => <span>{proposta.caracteristicas.marca}</span>,
        },
        {
            title: t('tables.modelo'),
            key: 'modelo',
            render: (proposta) => <span>{proposta.caracteristicas.modelo}</span>,
        },
        // {
        //     title: t('tables.sociedade'),
        //     key: 'sociedade',
        //     render: (proposta) =>
        //         proposta.info.empresa === '0' ? <span>Particular</span> : proposta.info.empresa === '1' ? <span>Empresa</span> : <span>Sem dados</span>,
        // },
        {
            title: t('tables.preco_da_viatura'),
            key: 'precoviatura',
            render: (proposta) => (proposta.info.preco_final_carro ? `${formatarNumero(proposta.info.preco_final_carro)}` : '------'),
        },
        {
            title: t('tables.isv'),
            key: 'isv',
            render: (proposta) => {
                const isv = proposta.info.isv;
                if (isv === "") {
                    return '------';
                } else if (isv === "0€") {
                    return 'Isento';
                } else if (isv === "0") {
                    return 'Isento';
                } else if (isv === 0) {
                    return 'Isento';
                } else {
                    return formatarNumero(isv);
                }
            },
        },
        {
            title: t('tables.taxa_de_servico'),
            key: 'taxadeservico',
            render: (proposta) => (proposta.info.taxadeservico ? `${formatarNumero(proposta.info.taxadeservico)}` : '------'),
        },
        {
            title: t('tables.comissaodeservico'),
            key: 'comissaodeservico',
            render: (proposta) => (proposta.info.comissaodeservico ? `${formatarNumero(proposta.info.comissaodeservico)}` : '------'),
        },
        {
            title: t('tables.preco_chave_da_mao'),
            key: 'precochavenamao',
            render: (proposta) => (proposta.info.valor_chavenamao_particular ? `${formatarNumero(proposta.info.valor_chavenamao_particular)}` : '------'),
        },
        {
            title: t('tables.acedido'),
            key: 'acedido',
            render: (proposta) =>
                // proposta.acedido == 0 ? (
                //     <span className="flex justify-center">
                //         <img style={{ height: '20px' }} src={redbubble} alt="Red Bubble" />
                //     </span>
                // ) : (
                <div className="grid gap-1">
                    <span className="flex gap-2 flex-row items-center justify-center">
                        {/* <img style={{ height: '20px' }} src={greenbubble} alt="Green Bubble" /> */}
                        {proposta.acedido} <FaEye size={20} />
                    </span>
                    {/* <span className="label-tabelaproposta text-center">{proposta.ultimo_acesso}</span> */}
                </div>
            // ),
        },
        {
            title: t('tables.disponivel'),
            key: 'expirou',
            render: (proposta) =>
                proposta.expirou === "1" ? (
                    <span className="flex justify-center">
                        <img style={{ height: '20px' }} src={redbubble} alt="Red Bubble" />
                    </span>
                ) : (
                    <span className="flex justify-center">
                        <img style={{ height: '20px' }} src={greenbubble} alt="Green Bubble" />
                    </span>
                ),
        },
        {
            title: t('tables.associado'),
            key: 'associado',
            render: (proposta) =>
                proposta.id_deal_hubspot ? (
                    <span className='flex justify-center cursor-pointer' onClick={() => showModalPerfilDeal(proposta.id_deal_hubspot)}>
                        <img style={{ height: '20px' }} src={greenbubble} alt="Green Bubble" />
                    </span>
                ) : (
                    <span className='flex justify-center'>
                        <img style={{ height: '20px' }} src={redbubble} alt="Red Bubble" />
                    </span>

                )
        },
        {
            title: t('tables.acoes'),
            key: 'acoes',
            render: (proposta) => {

                //console.log(proposta)

                const dadoslinkDaProposta = {
                    linkDaProposta: proposta.link_proposta_view,
                    tipoProposta: proposta.tipo_proposta,
                    outros: {
                        nome_proposta: proposta.nome_proposta,
                        modelo: proposta.info.nome_modelo,
                    },
                };

                const tituloProposta = {
                    titulo: proposta.nome_proposta
                }

                ////console.log(proposta.info)

                const valores = {
                    precoViaturaComIva: Math.round(proposta.info.preco_final_carro),
                    precoViaturaSemIva: Math.round(proposta.info.preco_final_carro_sem_iva),
                    taxaDeServico: Math.round(proposta.info.taxadeservico),
                    taxaDeFinanciamento: Math.round(proposta.info.comissao_credito),
                    taxaDeFinanciamentoAtivo: proposta.info.check_comissao_credito, // garante boolean
                    valorExpress: Math.round(proposta.info.comissao_express),
                    valorExpressAtivo: proposta.info.check_comissao_express, // garante boolean
                    comissao: Math.round(proposta.info.comissaodeservico),
                    valorTotalISV: Math.round(proposta.info.isv),
                    valorTotalIUC: Math.round(proposta.info.iuc),
                    valorPrecoChaveNaMaoParticular: Math.round(proposta.info.valor_chavenamao_particular),
                    valorPrecoChaveNaMaoEmpresa: Math.round(proposta.info.valor_chavenamao_empresa),
                };


                //console.log(valores)

                return (
                    <div>
                        <PartilharClienteProposta
                            dataCarros={tituloProposta}
                            valoresTotais={valores}
                            formatarNumero={formatarNumero}
                            propostaInfo={dadoslinkDaProposta}
                            dataPropostas={proposta}
                            titulo={false}
                            propostas={true}
                            dataUser={dataUser}
                        />
                    </div>
                );
            },
        },

    ].filter(Boolean); // Filtra colunas undefined


    //Gestão das Colunas visiveis e escondidas
    const columns = allColumns.filter(col => visibleColumns.includes(col.key));
    //Abrir popup para gerir as Colunas
    const showModal = () => {
        setConfigsTable((prevState) => ({
            ...prevState,
            modalColunas: true,  // Define modalColunas como false
        }));
    };

    // Função para abrir o Drawer dos Filtros
    const showDrawerFilters = () => {
        setDrawerVisibleFilters(true);
    };

    // Defina os filtros específicos para esta tabela
    const tablePropostasFilters = [
        ...(dataUser.role === 'administrator' ? [
            {
                type: 'select',
                label: 'Business Developer',
                key: 'id_criou',
            }
        ] : []),
        ...(dataUser.role === 'administrator' ? [
            {
                type: 'select',
                label: t('tables.pais'),
                key: 'pais',
            }
        ] : []),
        {
            type: 'select',
            label: t('tables.tipo'),
            key: 'tipo_proposta',
        },
        {
            type: 'select',
            label: t('tables.estado_proposta'),
            key: 'estado_proposta',
        },
        {
            type: 'select',
            label: t('tables.estado_da_documentacao'),
            key: 'estado_documentacao',
        },
        {
            type: 'date',
            label: t('tables.data_registo'),
            key: 'data_registo',
        },
        {
            type: 'range_taxadeservico',
            label: t('tables.taxadeservico'),
            key: 'range_slider_taxadeservico',
        },
        {
            type: 'range_preco_viatura',
            label: t('tables.preco_da_viatura'),
            key: 'range_slider_preco_viatura',
        },
        {
            type: 'range_preco_chave_da_mao',
            label: t('tables.preco_chave_da_mao'),
            key: 'range_slider_preco_chave_da_mao',
        },
        {
            type: 'select',
            label: t('tables.qtd_por_pagina'),
            key: 'qtd_por_pagina',
        },
        {
            type: 'select',
            label: t('tables.total_de_pedidos'),
            key: 'total_de_pedidos',
        },
    ];


    const handleSearchInputChange = (event) => {
        setSearchQuery(event.target.value);
    };


    return (
        <>

            <div className='flex justify-between gap-4'>
                <Input
                    type="text"
                    placeholder={t('propostas.input')}
                    value={searchQuery}
                    onChange={handleSearchInputChange}
                    prefix={<SearchOutlined />}
                />
                <div className='flex justify-end gap-4'>
                    <Button
                        type="primary"
                        onClick={showDrawerFilters}
                        className='shadow-none font-bold text-black bg-white'>
                        <div className='flex items-center gap-2'>
                            <LuFilter />
                            <p>{t('propostas.filtros')}</p>
                        </div>
                    </Button>
                    <Button
                        type="primary"
                        onClick={showModal}
                        className='shadow-none font-bold text-black bg-white'>
                        <div className='flex items-center gap-2'>
                            <FaEye size={20} style={{ cursor: 'pointer' }} />
                            <p>{t('propostas.colunas')}</p>
                        </div>
                    </Button>
                </div>
            </div>

            <CustomTable
                columns={columns}
                data={data.propostas.map((record, index) => ({ ...record, key: record.id || index }))}
                pagination={{ pageSize: configs.porPagina, showSizeChanger: false, position: ['bottomCenter'] }}
                loading={loading}
            />


            <CustomFilters
                filters={tablePropostasFilters}
                configs={configs}
                setConfigs={setConfigs}
                filtersSelected={filtersSelected}
                setFiltersSelected={setFiltersSelected}
                drawerVisibleFilters={drawerVisibleFilters}
                setDrawerVisibleFilters={setDrawerVisibleFilters}
                dataUser={dataUser}
            />


            <CustomColumns
                allColumns={allColumns}
                visibleColumns={visibleColumns}
                setVisibleColumns={setVisibleColumns}
                configsTable={configsTable}
                setConfigsTable={setConfigsTable}
            />


            <ModalPerfilDeal
                hubspotInfo={hubspotInfo}
                openModalPerfilDeal={openModalPerfilDeal}
                setOpenModalPerfilDeal={setOpenModalPerfilDeal}
            />


        </>
    )
};
