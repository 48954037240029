import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import CustomTable from '../Tables/CustomTables';
import { Button, Input, message } from 'antd';
import { FaEye } from 'react-icons/fa';
import CustomColumns from '../Tables/CustomColumns';
import flagPortugal from '../../assets/flags/flag-portugal.svg';
import flagSpain from '../../assets/flags/flag-spain.svg';
import { SearchOutlined } from '@ant-design/icons';


export default function TableListaDeUtilizadores({ dataUser }) {

    const { t } = useTranslation();

    const [searchQuery, setSearchQuery] = useState(''); // Pesquisa 

    const [data, setData] = useState({
        utilizadores: [],
    });

    const [loading, setLoading] = useState(false); // Estado de loading

    const [configsTable, setConfigsTable] = useState({
        modalColunas: false,
    });

    // State to control visible columns
    const [visibleColumns, setVisibleColumns] = useState([
        'id_user',
        'user_login',
        'user_ativo',
        'pais',
        'nome_criou',
        'email_interno',
        'cargo',
        'numero_whatsapp',
    ]);


    useEffect(() => {

        const fetchData = async () => {
            setLoading(true);

            let url = `${process.env.REACT_APP_OBTER_UTILIZADORES}?token=${dataUser.saveToken}&user_id=${dataUser.iDTokenAuthenticated}&lang=${dataUser.language}`;

            try {
                const response = await axios.get(url);
                if (response.data.sucesso) {
                    setData({
                        utilizadores: response.data.mensagem,
                    });
                } else {
                    message.error('Erro, tente novamente.');
                }

                setLoading(false);
            } catch (error) {
                console.error('Error occurred:', error);
            }
        };

        fetchData();

    }, [dataUser.iDTokenAuthenticated]);


    const allColumns = [
        {
            title: 'Id',
            dataIndex: 'id_user',
            key: 'id_user',
        },
        {
            title: 'País',
            dataIndex: 'pais',
            key: 'pais',
            render: (pais) => (
                pais[0] === "pt" ? (
                    <img
                        src={flagPortugal}
                        alt="Portugal"
                        style={{ marginRight: '8px', width: '35px', height: '22px' }}
                    />
                ) : (
                    <img
                        src={flagSpain}
                        alt="Spain"
                        style={{ marginRight: '8px', width: '35px', height: '22px' }}
                    />
                )
            )
        },
        {
            title: 'Nome Utilizador',
            dataIndex: 'nome_criou',
            key: 'nome_criou',
            render: (text, record) => {
                return (
                    <div className='flex items-center gap-2 overflow-hidden'>
                        <img
                            src={record.foto_perfil}
                            className='w-8 object-cover h-8 rounded-full'
                            alt='User Profile'
                        />
                        <span>{record.first_name}</span>
                    </div>
                );
            }
        },
        {
            title: 'Login',
            dataIndex: 'user_login',
            key: 'user_login',
        },
        {
            title: 'Cargo',
            dataIndex: 'cargo',
            key: 'cargo',
        },
        {
            title: 'Email',
            dataIndex: 'email_interno',
            key: 'email_interno',
            render: (text, record) => {
                return (
                    <span>{record.email_interno || record.user_email}</span>
                );
            }
        },
        {
            title: 'Whatsapp',
            dataIndex: 'numero_whatsapp',
            key: 'numero_whatsapp',
            render: (text, record) => {
                return (
                    <span>{record.numero_whatsapp ? `${record.numero_whatsapp}` : '-------'}</span>
                );
            }
        },
        {
            title: 'Estado',
            dataIndex: 'user_ativo',
            key: 'user_ativo',
            render: (user_ativo) => {
                if (user_ativo) {
                    return (
                        <span className='rounded-full bg-green-600 text-white px-3 w-auto inline-table'>
                            Ativo
                        </span>
                    );
                } else {
                    return (
                        <span className='rounded-full bg-red-600 text-white px-3 w-auto inline-table'>
                            Inativo
                        </span>
                    );
                }

            },
        }
        // {
        //     title: '',
        //     key: 'actions',
        //     render: (text, record) => (
        //         <div style={{ display: 'flex', justifyContent: 'center' }}>
        //             <Dropdown
        //                 overlay={
        //                     <Menu>
        //                         <Menu.Item>
        //                             <Button onClick={() => showModalEditar(record.id_user)} className='flex items-center gap-2'><MdModeEdit /> Editar Password </Button>
        //                         </Menu.Item>
        //                         {/* <Menu.Item>
        //                             <Button onClick={() => showModal(record.id_user)} className='flex items-center gap-2 eliminar-cta bg-red-500 text-white hover:text-white'><MdDelete /> Eliminar Utilizador </Button>
        //                         </Menu.Item> */}
        //                     </Menu>
        //                 }
        //                 trigger={['click']}
        //             >
        //                 <Button className="bg-white p-2">
        //                     <HiOutlineDotsVertical />
        //                 </Button>
        //             </Dropdown>
        //         </div>
        //     )
        // }
    ].filter(Boolean);


    //Gestão das Colunas
    const columns = allColumns.filter(col => visibleColumns.includes(col.key));

    const showModal = () => {
        setConfigsTable((prevState) => ({
            ...prevState,
            modalColunas: true,  // Define modalColunas como false
        }));
    };


    const handleInputChange = (event) => {
        setSearchQuery(event.target.value);
    };


    const todosUtilizadores = data.utilizadores.filter((data) => {

        const nome = data.first_name.toLowerCase();
        const searchQueryLowerCase = searchQuery.toLowerCase();

        return (
            nome.includes(searchQueryLowerCase)
        );

    });


    return (
        <>
            <div className='flex justify-between gap-4'>
                <Input
                    type="text"
                    placeholder={t('lista_utilizadores.input')}
                    value={searchQuery}
                    onChange={handleInputChange}
                    prefix={<SearchOutlined />}
                />
                <div className='flex justify-end gap-4'>
                    <Button
                        type="primary"
                        onClick={showModal}
                        className='shadow-none font-bold text-black bg-white'>
                        <div className='flex items-center gap-2'>
                            <FaEye size={20} style={{ cursor: 'pointer' }} />
                            <p>{t('propostas.colunas')}</p>
                        </div>
                    </Button>
                </div>
            </div>

            <CustomTable
                columns={columns}
                data={todosUtilizadores.map((record, index) => ({ ...record, key: record.id || index }))}
                pagination={{ pageSize: 20, showSizeChanger: false, position: ['bottomCenter'] }}
                loading={loading}
            />
            <CustomColumns
                allColumns={allColumns}
                visibleColumns={visibleColumns}
                setVisibleColumns={setVisibleColumns}
                configsTable={configsTable}
                setConfigsTable={setConfigsTable}
            />

        </>
    )
};
