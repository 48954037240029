import React, { useState, useEffect, useRef } from 'react';
import { Form, Input, Select, Button, DatePicker, message, Space, Tabs, Badge } from 'antd';
import dayjs from 'dayjs';
import axios from 'axios';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { FaFileAlt } from 'react-icons/fa';
import { GoAlert } from "react-icons/go";
import { CheckOutlined } from '@ant-design/icons';

dayjs.extend(customParseFormat);
const { Option } = Select;

export default function DocumentTabs({
    documents,
    dataUser,
    setData,
    data,
    editarDocumento,
    setLoading
}) {

    const [form] = Form.useForm();

    const [conditionalFields, setConditionalFields] = useState({});
    // const [globalTipoIdentificacao, setGlobalTipoIdentificacao] = useState(null); // Valor global

    // const selectRefs = useRef({}); // Referências dos selects por documento

    const [codigoPostalInicio, setCodigoPostalInicio] = useState('');
    const [codigoPostalFim, setCodigoPostalFim] = useState('');
    const [codigoPostalCompleto, setCodigoPostalCompleto] = useState('');


    const [completedTabs, setCompletedTabs] = useState({});
    const [activeTabKey, setActiveTabKey] = useState(documents[0]?.nome || "");


    const formatDayjsDates = (values) => {
        return Object.fromEntries(
            Object.entries(values).map(([key, value]) => {
                const isDayjs = dayjs.isDayjs(value); // Check if value is a dayjs object
                return [key, isDayjs ? value.format('DD/MM/YYYY') : value]; // Format if dayjs
            })
        );
    };

    //console.log(data.tipoDocumento)


    // const handleTabClick = (docName) => {
    //     if (globalTipoIdentificacao) {
    //         const doc = documents.find((d) => d.nome === docName);
    //         if (doc) {
    //             const field = Object.values(doc.doc.fields).find((f) => f.slug === 'tipo_identificacao');
    //             if (field) {
    //                 form.setFieldsValue({ [field.slug]: globalTipoIdentificacao }); // Define o valor global
    //                 handleSelectChange(field.slug, globalTipoIdentificacao, docName); // Atualiza os campos condicionais
    //             }
    //         }
    //     }
    // };

    const handleCodigoPostalChange = (value, part) => {
        if (part === 'inicio') {
            setCodigoPostalInicio(value);
            setCodigoPostalCompleto(`${value}-${codigoPostalFim}`);
        } else if (part === 'fim') {
            setCodigoPostalFim(value);
            setCodigoPostalCompleto(`${codigoPostalInicio}-${value}`);
        }
    };


    const handleSubmit = (values) => {
        ////console.log('Valores do formulário:', values);
        setLoading(true);

        // formatar os fotmatos dayjs em data
        const formattedData = formatDayjsDates(values);

        // //console.log(data.idProposta)

        const dataGerarDocumento = {
            ...formattedData,
            id_documento: editarDocumento || '',
            // id_deal: data.idProposta || '',
            tipo_documento: data.tipoDocumento || data.tipo_documento_selecionado,
            tipo_sociedade: data.tipoSociedade || data.tipo_sociedade_selecionado,
            tipo_contrato: data.tipoContrato || data.tipo_contrato_selecionado,
            user_id: dataUser.iDTokenAuthenticated,
            token: dataUser.saveToken,
        };

        axios.post(`${process.env.REACT_APP_GERAR_DOCUMENTOS}`, dataGerarDocumento)
            .then((response) => {
                if (response.data.sucesso) {
                    ////console.log(response.data.data)
                    message.success(response.data.mensagem);
                    setData(prev => ({
                        ...prev,
                        linkDownload: [response.data.data] || [],
                        fields: {},
                    }));

                    form.resetFields()


                } else {
                    message.error(response.data.mensagem);
                }
            })
            .finally(() => setLoading(false));
    };


    const handleSelectChange = (fieldSlug, selectedKey, docName) => {
        const selectedDocument = documents.find((doc) => doc.nome === docName);

        if (!selectedDocument) {
            console.error(`Documento "${docName}" não encontrado.`);
            return;
        }

        const selectedField = selectedDocument.doc.fields[fieldSlug];

        if (!selectedField) {
            console.error(`Campo "${fieldSlug}" não existe no documento "${docName}".`);
            return;
        }

        const selectedOption = selectedField.opcoes.find((option) => option.slug === selectedKey);

        if (!selectedOption) {
            console.error(`Opção "${selectedKey}" não encontrada para o campo "${fieldSlug}" no documento "${docName}".`);
            return;
        }

        // Atualiza os campos condicionais com base na seleção
        setConditionalFields((prev) => ({
            ...prev,
            [docName]: {
                ...prev[docName],
                [fieldSlug]: selectedOption.mostra || [],
            },
        }));

        // Define valores automáticos para os campos
        const updatedFields = {};
        (selectedOption.mostra || []).forEach((field) => {
            if (field.auto_complete_value) {
                updatedFields[field.slug] = field.auto_complete_value;
            }
        });

        form.setFieldsValue({
            ...updatedFields, // Aplica valores automáticos
        });

        // Atualiza o valor global do `tipo_identificacao`
        // if (fieldSlug === 'tipo_identificacao') {
        //     setGlobalTipoIdentificacao(selectedKey);
        // }
    };



    //Forçar o selectchange
    useEffect(() => {
        documents.forEach((doc) => {
            Object.values(doc.doc.fields).forEach((field) => {
                if (field.tipo === 'select' && field.auto_complete_value) {
                    handleSelectChange(field.slug, field.auto_complete_value, doc.nome);
                }
            });
        });
    }, [documents]);


    // Função para verificar se os campos de uma aba estão preenchidos
    const validateTabFields = async (docName) => {
        const fields = Object.values(documents.find((doc) => doc.nome === docName).doc.fields);
        const fieldNames = fields.map((field) => field.slug);
        try {
            await form.validateFields(fieldNames);
            return true;
        } catch (error) {
            return false;
        }
    };


    // Lidar com a mudança de aba
    const handleTabChange = async (key) => {
        const isValid = await validateTabFields(activeTabKey);
        setCompletedTabs((prev) => ({
            ...prev,
            [activeTabKey]: isValid,
        }));
        setActiveTabKey(key);
    };


    // Render Individual Fields
    const renderField = (field, docName) => {
        switch (field.tipo) {
            case 'string':
                return (
                    <Form.Item
                        name={field.slug}
                        label={field.nome}
                        rules={[{ required: true, message: `${field.nome} é obrigatório!` }]}
                        key={field.slug + Math.random()}
                        initialValue={field.auto_complete_value || ''} // Configura o valor inicial
                    >
                        <Input placeholder={field.nome} />
                    </Form.Item>
                );
            case 'number':
                return (
                    <Form.Item
                        name={field.slug}
                        label={field.nome}
                        rules={[{ required: true, message: `${field.nome} é obrigatório!` }]}
                        key={field.slug + Math.random()}
                        initialValue={field.auto_complete_value || ''}
                    >
                        <Input type="number" placeholder={field.nome} />
                    </Form.Item>
                );

            case 'date':
                return (
                    <Form.Item
                        name={field.slug}
                        label={field.nome}
                        initialValue={field.auto_complete_value
                            ? dayjs(field.auto_complete_value, 'DD/MM/YYYY') // Ajuste para usar o formato correto da data fornecida
                            : null}
                        rules={[{ required: true, message: `${field.nome} é obrigatório!` }]}
                        key={field.slug}
                    >
                        <DatePicker
                            format="YYYY-MM-DD" // Formato exibido pelo DatePicker
                            placeholder={field.nome}
                            style={{ width: '100%' }}
                        />
                    </Form.Item>
                );

            case 'select':

                return (
                    <Form.Item
                        name={field.slug}
                        label={field.nome}
                        rules={[{ required: true, message: `${field.nome} é obrigatório!` }]}
                        key={field.slug + Math.random()}
                    >
                        <Select
                            placeholder={`Selecione ${field.nome}`}
                            onChange={(value) => handleSelectChange(field.slug, value, docName)}

                        >
                            {field.opcoes.map((option, index) => (
                                <Option key={index} value={option.slug}>
                                    {option.nome}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                );


            case 'matricula_viatura':
                return (
                    <div className='flex flex-col items-stretch gap-2 flex-1' key={field.slug}>
                        {/* <p className={`font-semibold ${white ? 'text-black ' : 'text-white '}`}>{field.nome}</p> */}
                        <Form.Item
                            name={field.slug}
                            label={field.nome}
                            rules={[{ required: true, message: `${field.nome} é obrigatório!` }]}
                            key={field.slug + Math.random()}
                            initialValue={field.auto_complete_value || ''} // Configura o valor inicial
                        >
                            <Input placeholder="XX-XX-XX" autoComplete='off' value={field.auto_complete_value ? field.auto_complete_value : ''} />
                        </Form.Item>
                    </div>
                );
            case 'zip_code':
                return (
                    <div className='flex flex-col items-stretch gap-2 flex-1' key={field.slug}>
                        {/* <p className={`font-semibold ${white ? 'text-black ' : 'text-white '}`}>{field.nome}</p> */}
                        <Form.Item
                            name={field.slug}
                            label={field.nome}
                            rules={[{ required: true, message: `${field.nome} é obrigatório!` }]}
                        >
                            <Space.Compact>
                                <Input
                                    style={{ width: '40%' }}
                                    placeholder="XXXX"
                                    value={codigoPostalInicio}
                                    onChange={(e) => handleCodigoPostalChange(e.target.value, 'inicio')}
                                    type="text"
                                    required
                                    maxLength={4}
                                />
                                <Input
                                    style={{ width: '40%' }}
                                    placeholder="XXX"
                                    value={codigoPostalFim}
                                    onChange={(e) => handleCodigoPostalChange(e.target.value, 'fim')}
                                    type="text"
                                    required
                                    maxLength={3}
                                />
                                <Input
                                    style={{ width: '100%' }}
                                    value={codigoPostalCompleto}
                                    readOnly
                                    disabled
                                />
                            </Space.Compact>
                        </Form.Item>
                    </div>
                );
            case 'repeater':
                return (
                    <Form.List name={field.slug} key={field.slug} initialValue={[]}>
                        {(fields, { add, remove }) => (
                            <div>
                                {fields.map(({ key, name, fieldKey, ...restField }) => (
                                    <div key={key} style={{ display: 'flex', marginBottom: 8, alignItems: 'center' }}>
                                        {field.opcoes.map((subField) => (
                                            <Form.Item
                                                label={subField.nome}
                                                {...restField}
                                                name={[name, subField.slug]}
                                                fieldKey={[fieldKey, subField.slug]}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: `${subField.nome} é obrigatório!`,
                                                    },
                                                ]}
                                                style={{ flex: 1, marginRight: '8px' }}
                                                key={subField.slug}
                                                initialValue={field.auto_complete_value || ''} // Configura o valor inicial
                                            >
                                                <Input placeholder={subField.nome} />
                                            </Form.Item>
                                        ))}
                                        <Button danger onClick={() => remove(name)}>
                                            Remover Sócio
                                        </Button>
                                    </div>
                                ))}
                                <Button type="dashed" onClick={() => add()} block>
                                    Adicionar Sócio
                                </Button>
                            </div>
                        )}
                    </Form.List>
                );
            default:
                return null;
        }
    };


    const renderRepeaterFields = (fields, docName) => {
        return fields
            .filter((field) => field.tipo === 'repeater') // Filtra os campos do tipo 'repeater'
            .map((field) => renderField(field, docName)); // Renderiza os campos do tipo 'repeater'
    };

    const renderNonRepeaterFields = (fields, docName) => {
        return fields
            .filter((field) => field.tipo !== 'repeater') // Filtra os campos que não são 'repeater'
            .map((field) => renderField(field, docName)); // Renderiza os campos que não são 'repeater'
    };


    const tabItems = documents.map((doc) => {
        const fields = Object.values(doc.doc.fields).sort((a, b) => a.order - b.order);
        const docConditionalFields = conditionalFields[doc.nome] || {};

        const isTabComplete = completedTabs[doc.nome];

        return {
            key: doc.nome,
            label: (
                // <Badge
                //     count={

                //     }
                // >
                <span className='flex items-center gap-2 text-white'>
                    {doc.nome}
                    {data.tipoDocumento == "novo_negocio" && (
                        <>
                            {isTabComplete ? <CheckOutlined style={{ color: '#52c41a' }} /> : <GoAlert style={{ color: '#faad14' }} />}
                        </>
                    )}

                </span>
                // </Badge>
            ),
            children: (
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={(values) => handleSubmit(values, doc.nome)}
                >


                    {/* Campos normais */}
                    <div className="grid grid-cols-3 gap-4">
                        {renderNonRepeaterFields(fields, doc.nome)}
                        {/* Renderizar campos condicionais que não sejam repeaters */}
                        {Object.values(docConditionalFields).flat().map((field) =>
                            field.tipo !== 'repeater' ? renderField(field, doc.nome) : null
                        )}
                    </div>

                    {renderRepeaterFields(fields, doc.nome)}
                    {/* Renderizar campos condicionais do tipo repeater */}
                    {Object.values(docConditionalFields).flat().map((field) =>
                        field.tipo === 'repeater' ? renderField(field, doc.nome) : null
                    )}

                    <Form.Item className='mt-5 border-t pt-4'>
                        <Button
                            type="primary"
                            htmlType="submit"
                            className="flex gap-2 items-center bg-[#1A8700] text-white font-bold rounded"
                        >
                            <FaFileAlt />
                            {/* Gerar Documento */}
                            {editarDocumento ? 'Editar Documento' : 'Gerar Documento'}
                        </Button>
                    </Form.Item>

                </Form>
            ),
        };
    });

    return <Tabs items={tabItems} activeKey={activeTabKey} onChange={handleTabChange} />;
}
