import React from 'react';
import CopiarURLcomLinkparaEmail from '../CopiarURLcomLinkparaEmail';
import CopiarURLparaWhatsapp from '../CopiarURLparaWhatsapp';
import EmailParticularPT from './EmailParticularPT';
import EmailEmpresaPT from './EmailEmpresaPT';
import EmailParticularPTemEN from './EmailParticularPTemEN';
import EmailEmpresaPTemEN from './EmailEmpresaPTemEN';
import WhatsappParticularPT from './WhatsappParticularPT';
import WhatsappEmpresaPT from './WhatsappEmpresaPT';
import WhatsappParticularPTemEN from './WhatsappParticularPTemEN';
import { useTranslation } from 'react-i18next';
import WhatsappEmpresaPTemEN from './WhatsappEmpresaPTemEN';
import { FaLink, FaWhatsapp } from 'react-icons/fa6';
import { HiOutlineMail } from "react-icons/hi";

export default function PartilharClientePropostaPT({
    propostaInfo,
    dataCarros,
    valoresTotais,
    formatarNumero,
    dataUser,
    precoFinalChaveNaMaoCIva,
    precoFinalChaveNaMaoSIva,
    valorTotalCaucaoParticular,
    valorTotalCaucaoEmpresa,
}) {

    const { t } = useTranslation();

    return (
        <>

            <div className='grid gap-2'>
                <div className='flex items-center justify-between gap-2'>
                    <span className='font-semibold'>{t('gerar_proposta.link_proposta')}</span>
                    <FaLink size={20} />
                </div>
                <hr></hr>
                <CopiarURLcomLinkparaEmail
                    propostaInfo={propostaInfo}
                    valoresTotais={valoresTotais}
                />
                <hr></hr>
                <CopiarURLparaWhatsapp
                    propostaInfo={propostaInfo}
                    valoresTotais={valoresTotais}
                />
            </div>

            <div className='grid gap-2'>
                <div className='flex items-center justify-between gap-2'>
                    <span className='font-semibold'>Email</span>
                    <HiOutlineMail size={20} />
                </div>
                <hr></hr>
                {/* Copiar Texto para Email Particular */}
                <EmailParticularPT
                    propostaInfo={propostaInfo}
                    valoresTotais={valoresTotais}
                    precoFinalChaveNaMaoCIva={precoFinalChaveNaMaoCIva}
                    valorTotalCaucaoParticular={valorTotalCaucaoParticular}
                    formatarNumero={formatarNumero}
                />
                <hr></hr>
                {/* Copiar Texto para Email Empresa */}
                <EmailEmpresaPT
                    propostaInfo={propostaInfo}
                    valoresTotais={valoresTotais}
                    precoFinalChaveNaMaoSIva={precoFinalChaveNaMaoSIva}
                    valorTotalCaucaoEmpresa={valorTotalCaucaoEmpresa}
                    formatarNumero={formatarNumero}
                />
                <hr></hr>
                {/* Copiar Texto para Email Particular Ingles */}
                <EmailParticularPTemEN
                    propostaInfo={propostaInfo}
                    valoresTotais={valoresTotais}
                    formatarNumero={formatarNumero}
                />
                <hr></hr>
                {/* Copiar Texto para Email Empresa Ingles */}
                <EmailEmpresaPTemEN
                    propostaInfo={propostaInfo}
                    valoresTotais={valoresTotais}
                    formatarNumero={formatarNumero}
                    precoFinalChaveNaMaoSIva={precoFinalChaveNaMaoSIva}
                />
            </div>

            <div className='grid gap-2'>
                <div className='flex items-center justify-between gap-2'>
                    <span className='font-semibold'>Whatsapp</span>
                    <FaWhatsapp size={20} />
                </div>
                <hr></hr>
                <WhatsappParticularPT
                    dataCarros={dataCarros}
                    dataUser={dataUser}
                    propostaInfo={propostaInfo}
                    valoresTotais={valoresTotais}
                    formatarNumero={formatarNumero}
                />
                <hr></hr>
                <WhatsappEmpresaPT
                    dataCarros={dataCarros}
                    dataUser={dataUser}
                    propostaInfo={propostaInfo}
                    valoresTotais={valoresTotais}
                    formatarNumero={formatarNumero}
                    precoFinalChaveNaMaoSIva={precoFinalChaveNaMaoSIva}
                />
                <hr></hr>
                <WhatsappParticularPTemEN
                    dataCarros={dataCarros}
                    dataUser={dataUser}
                    propostaInfo={propostaInfo}
                    valoresTotais={valoresTotais}
                    formatarNumero={formatarNumero}
                />
                <hr></hr>
                <WhatsappEmpresaPTemEN
                    dataCarros={dataCarros}
                    dataUser={dataUser}
                    propostaInfo={propostaInfo}
                    valoresTotais={valoresTotais}
                    formatarNumero={formatarNumero}
                />
            </div>
            
        </>
    );
}
