import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import InputCalculoProposta from '../../../components/CalculoProposta/InputCalculoProposta';
import TabelaDadosCarroProposta from '../../../components/CalculoProposta/TabelaDadosDoCarro';
import TaxaDeServico from '../../../components/CalculoProposta/TaxaDeServicoProposta';
import PrecoFinalProposta from '../../../components/CalculoProposta/PrecoFinalProposta';
import Loading from '../../../components/Elements/Loading';
import FormCalculoPropostaES from '../../../components/CalculoProposta/ES/FormCalculoProposta_ISV_IUC_ES';
import { Button, message } from 'antd';
import RegistarPropostaFormES from '../../../components/CalculoProposta/ES/FormRegistarProposta_ES';
import { HiRefresh } from 'react-icons/hi';
import { useTranslation } from 'react-i18next';
import DuracaoDeProcesso from '../../../components/CalculoProposta/DuracaoDeProcesso';


export default function SimulacionPropuesta({
    dataUser
}) {

    const { t } = useTranslation();

    const [loading, setLoading] = useState(false);
    const [urlCarroLink, setUrlCarroLink] = useState('');
    const [dataCarros, setDataCarros] = useState(null);
    const [linkToken, setLinkToken] = useState('');

    const [estadoDeErro, setEstadoDeErro] = useState(false);

    const [propostaInfo, setPropostaInfo] = useState({
        standData: [],
        linkFora: '',
        outros: [],
        erro: ''
    });


    const [dataListas, setDataListas] = useState({
        listaMarcas: [],
        listaModelos: [],
        listaCidades: [],
        listaMunicipios: []
    });

    const [isvIucData, setIsvIucData] = useState({
        cilindrada: 0,
        novoUsado: '',
        tipoCombustivel: '',
        emissoesCO2: '',
        hibridoPlugIn: 0,
        dataPrimeiroRegisto: '',
        tipoDeVeiculoParaOutros: 'LigeiroPassageiros',
        tipoDeVeiculoParaInsencoes: '',
        idMarca: '',
        nomeMarca: '',
        idModelo: '',
        nomeModelo: '',
        provincia: '',
        nomeProvincia: '',
        municipio: '',
        nomeMunicipio: '',
        valorViaturaNova: '',
        valorTotalISV: 0,
        valorTotalIUC: 0,
        emissoesPorAI: 0,
    });

    const [isvValido, setIsvValido] = useState(true); //bloqueia o button de criar proposta

    const [triggerCalculo, setTriggerCalculo] = useState({
        cilindrada: 0,
        novoUsado: '',
        tipoCombustivel: '',
        emissoesCO2: '',
        hibridoPlugIn: 0,
        dataPrimeiroRegisto: '',
        tipoDeVeiculoParaOutros: 'LigeiroPassageiros',
        tipoDeVeiculoParaInsencoes: '',
        idMarca: '',
        nomeMarca: '',
        idModelo: '',
        nomeModelo: '',
        provincia: '',
        nomeProvincia: '',
        municipio: '',
        nomeMunicipio: '',
        valorViaturaNova: '',
        valorTotalISV: 0,
        valorTotalIUC: 0,
        emissoesPorAI: 0,
    });



    const [valoresTotais, setValoresTotais] = useState({
        precoViaturaComIva: 0,
        precoViaturaSemIva: 0,
        taxaDeServico: 3000,
        taxaDeFinanciamento: 400,
        taxaDeFinanciamentoAtivo: 0,
        valorExpress: 849,
        valorExpressAtivo: 0,
        comissao: 0,
        valorTotalISV: 0,
        valorTotalIUC: 0,
        valorPrecoChaveNaMaoParticular: 0,
        valorPrecoChaveNaMaoEmpresa: 0,
        compsIUC: {
            iucCavalosFiscais: 0,
            iucCidade: '',
            iucMunicipio: '',
            iucPercentagemDescontoEletrico: '',
        },
        compsISV: {
            isvBaseImponible: 0,
            isvBaseImponibleFinal: 0,
            isvComunidad: '',
            isvCuotaTributaria: 0,
            isvMinoracion: 0,
            isvPorcentajeAplicar: 0,
            isvReduccionBaseImponible: 0,
            isvTotalPagar: 0,
            isvValoracionInicial: 0
        }
    });


    // const formatarNumero = (valor) => {
    //     if (!valor || isNaN(valor)) return '0 €';
    //     return new Intl.NumberFormat('pt-PT', {
    //         style: 'currency',
    //         currency: 'EUR',
    //         minimumFractionDigits: 0,
    //         maximumFractionDigits: 0,
    //     }).format(valor);
    // };


    // Função para buscar dados do carro
    const fetchData = useCallback(async (url) => {

        setLoading(true);
        setEstadoDeErro(false)

        try {
            const requestUrl = `${process.env.REACT_APP_PEDIDO_PROPOSTA}?request=${encodeURIComponent(url)}&user_id=${dataUser.iDTokenAuthenticated}&token=${dataUser.saveToken}&lang=${dataUser.language}`;
            const response = await axios.get(requestUrl);
            if (response.data.sucesso) {

                const carro = response.data.data.carro;
                const stand = response.data.data.stand;
                const linkToken = response.data.link_token;
                const calculo = response.data.data.simulacao;

                setDataCarros(carro);
                setLinkToken(linkToken)

                setPropostaInfo((prevData) => ({
                    ...prevData,
                    listaModelos: carro.modelos,
                    standData: stand,
                    outros: stand,
                    propostaExpirado: null,
                    propostaDisponivelOnline: null
                }));

                // Verifica o tipo de combustível híbrido e atribui o valor correto
                let tipoCombustivel = calculo.combustivel_para_simulacao;
                if (tipoCombustivel == "Híbrido (Gasolina/Eletrico)") {
                    tipoCombustivel = "Gasolina";
                } else if (tipoCombustivel == "Híbrido (Gasóleo/Eletrico)") {
                    tipoCombustivel = "Gasoleo";
                }

                const allData = {
                    cilindrada: calculo.cilindrada_numerico,
                    novoUsado: calculo.novo == 1 ? "Nuevo" : "Usado",
                    tipoCombustivel,
                    emissoesCO2: calculo.emissoes_numerico,
                    hibridoPlugIn: calculo.hibrido,
                    dataPrimeiroRegisto: calculo.data_registo_para_simulacao,
                    tipoDeVeiculoParaOutros: calculo.tipo_de_veiculo,
                    tipoDeVeiculoParaInsencoes: '',
                    idMarca: calculo.id_marca,
                    nomeMarca: '',
                    idModelo: '',
                    nomeModelo: '',
                    provincia: '',
                    nomeProvincia: '',
                    municipio: '',
                    nomeMunicipio: '',
                    valorViaturaNova: '',
                    valorTotalISV: 0,
                    valorTotalIUC: 0,
                    emissoesPorAI: calculo.emissoes_numerico_por_ai || 0,
                };

                setIsvIucData(allData);  // Atualiza formulário com dados do carro
                setTriggerCalculo(allData);  // Atualiza dados para cálculo

                setValoresTotais((prevData) => ({
                    ...prevData,
                    precoViaturaComIva: carro.preco_final_carro,
                    precoViaturaSemIva: carro.preco_final_carro_sem_iva,
                }));

                setLoading(false);

            } else {
                message.error(response.data.mensagem)
                setLoading(true);
                setEstadoDeErro(true)
            }

        } catch (error) {
            setLoading(false);
        }

    }, [dataUser.iDTokenAuthenticated, dataUser.saveToken]);


    // GET DAS CIDADES
    const fetchCidade = async () => {
        try {

            const requestUrl = `${process.env.REACT_APP_GET_BUSCAR_CIDADES}`;

            const response = await axios.get(requestUrl);


            console.log(response)

            setDataListas((prevData) => ({
                ...prevData,
                listaCidades: response.data.mensagem,
            }));

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    // GET DAS MARCAS
    const fetchMarca = async () => {
        try {

            const requestUrl = `${process.env.REACT_APP_GET_BUSCAR_MARCAS}`;
            const response = await axios.get(requestUrl);

            setDataListas((prevData) => ({
                ...prevData,
                listaMarcas: response.data.mensagem,
            }));


        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };



    useEffect(() => {
        if (urlCarroLink) {
            setLoading(true);
            fetchData(urlCarroLink);
            fetchCidade();
            fetchMarca()
            setPropostaInfo((prevData) => ({
                ...prevData,
                linkFora: urlCarroLink,
            }));
        }
    }, [urlCarroLink, fetchData]);


    // Efeito para disparar o cálculo quando triggerCalculo for atualizado
    useEffect(() => {
        const fetchISVIUCData = async () => {
            try {

                const cilindrada = triggerCalculo.cilindrada;

                const novo_usado_int = triggerCalculo.novoUsado == "Novo" ? 1 : 0;

                const tipoDeCombustivel = triggerCalculo.tipoCombustivel

                const emissoesGases = triggerCalculo.emissoesCO2;

                const dataDeRegisto = triggerCalculo.dataPrimeiroRegisto;

                const tipoDeVeiculoParaInsencoes = triggerCalculo.tipoDeVeiculoParaInsencoes || '';

                console.log('correu')

                // URL do pedido IVTM
                const pedidoIVTM = `${process.env.REACT_APP_PEDIDO_ISV_ES}?cilindrada=${cilindrada}&novo=${novo_usado_int}&combustivel=${tipoDeCombustivel}&co2=${emissoesGases}&ano=${dataDeRegisto}&isencao=${tipoDeVeiculoParaInsencoes}&id_modelo=${triggerCalculo.idModelo}&id_cidade=${triggerCalculo.provincia}&valor_viatura_nova=${triggerCalculo.valorViaturaNova}`;

                // URL do pedido IDM
                const pedidoIDM = `${process.env.REACT_APP_PEDIDO_IUC_ES}?id_modelo=${triggerCalculo.idModelo}&id_municipio=${triggerCalculo.municipio}&tipo_carro=''`;

                // Fazer os dois pedidos de uma vez
                const [isvResponse, iucResponse] = await Promise.all([
                    axios.get(pedidoIVTM),
                    axios.get(pedidoIDM)
                ]);

                if (isvResponse.data.sucesso && iucResponse.data.sucesso) {

                    const valorISV = isvResponse.data.data.isv;
                    const valorIUC = iucResponse.data.data.iuc;
                    const valorISVTaxas = isvResponse.data.data.mensagem.taxas;
                    const valorIUCTaxas = iucResponse.data.data.mensagem.taxas;



                    // Atualizar ambos os estados com os valores calculados
                    setIsvIucData((prevData) => ({
                        ...prevData,
                        valorTotalISV: valorISV,
                        valorTotalIUC: valorIUC,
                    }));

                    setValoresTotais((prevData) => {
                        const novoEstado = {
                            ...prevData,
                            valorTotalISV: valorISV,
                            valorTotalIUC: valorIUC
                        };

                        if (valorIUCTaxas) {
                            novoEstado.compsIUC = {
                                iucCavalosFiscais: valorIUCTaxas.iuc_cavalos_fiscais,
                                iucCidade: valorIUCTaxas.iuc_cidade,
                                iucMunicipio: valorIUCTaxas.iuc_municipio,
                                iucPercentagemDescontoEletrico: valorIUCTaxas.iuc_percentagem_desconto_eletrico,
                            };
                        }

                        if (valorISVTaxas) {
                            novoEstado.compsISV = {
                                isvBaseImponible: valorISVTaxas.isv_base_imponible,
                                isvBaseImponibleFinal: valorISVTaxas.isv_base_imponible_final,
                                isvComunidad: valorISVTaxas.isv_comunidad,
                                isvCuotaTributaria: valorISVTaxas.isv_cuota_tributaria,
                                isvMinoracion: valorISVTaxas.isv_minoracion,
                                isvPorcentajeAplicar: valorISVTaxas.isv_porcentaje_aplicar,
                                isvReduccionBaseImponible: valorISVTaxas.isv_reduccion_base_imponible,
                                isvTotalPagar: valorISVTaxas.isv_total_pagar,
                                isvValoracionInicial: valorISVTaxas.isv_valoracion_inicial,
                            };
                        }

                        return novoEstado;
                    });

                    setIsvValido(true)

                    if (isvResponse.data.data.mensagem.avisos_isencao.length !== 0) {

                        message.warning(isvResponse.data.data.mensagem.avisos_isencao)

                        setPropostaInfo((prevData) => ({
                            ...prevData,
                            erro: isvResponse.data.data.mensagem.avisos_isencao,
                        }));

                    } else {

                        setPropostaInfo((prevData) => ({
                            ...prevData,
                            erro: '',
                        }));

                    }

                } else {

                    if (!isvResponse.data.sucesso) {
                        message.error(isvResponse.data.mensagem || 'Erro');
                        setPropostaInfo((prevData) => ({
                            ...prevData,
                            erro: isvResponse.data.mensagem,
                        }));

                        setIsvValido(false)


                        setValoresTotais((prevData) => {
                            const novoEstado = {
                                ...prevData,
                                valorTotalISV: 0,
                            };

                            novoEstado.compsISV = {
                                isvBaseImponible: 0,
                                isvBaseImponibleFinal: 0,
                                isvComunidad: 0,
                                isvCuotaTributaria: 0,
                                isvMinoracion: 0,
                                isvPorcentajeAplicar: 0,
                                isvReduccionBaseImponible: 0,
                                isvTotalPagar: 0,
                                isvValoracionInicial: 0,
                            };


                            return novoEstado;
                        });

                    }

                    if (!iucResponse.data.sucesso) {
                        message.error(iucResponse.data.mensagem || 'Erro');
                    }
                }

            } catch (error) {
                message.error('')
                console.error('Erro ao buscar IDM ou IVTM', error);
            }
        };


        const { cilindrada, novoUsado, tipoCombustivel, emissoesCO2, idModelo } = triggerCalculo;

        console.log(triggerCalculo)

        if (
            (novoUsado && tipoCombustivel && idModelo && cilindrada && emissoesCO2) ||
            (novoUsado && tipoCombustivel && idModelo)
        ) {
            fetchISVIUCData();
        }



    }, [triggerCalculo]);


    useEffect(() => {

        ////console.log(triggerCalculo)

        const fetchModelos = async () => {
            try {
                const [month, year] = triggerCalculo.dataPrimeiroRegisto.split('/');
                const requestUrl = `${process.env.REACT_APP_GET_BUSCAR_MODELOS}?id_marca=${isvIucData.idMarca}&ano=${year || ''}`;
                const response = await axios.get(requestUrl);

                //console.log(response)
                if (response.data.sucesso) {
                    setDataListas((prevData) => ({
                        ...prevData,
                        listaModelos: response.data.mensagem,
                    }));
                } else {
                    message.warning(response.data.mensagem)
                }


            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        if ((isvIucData.idMarca && !isvIucData.listaModelos) || (isvIucData.idMarca && isvIucData.listaModelos)) {
            fetchModelos();
        }

    }, [isvIucData.idMarca, isvIucData.dataPrimeiroRegisto]);


    useEffect(() => {

        const fetchMunicipio = async () => {
            try {

                const requestUrl = `${process.env.REACT_APP_GET_BUSCAR_MUNICIPIOS}?id_cidade=${isvIucData.provincia}`;
                const response = await axios.get(requestUrl);

                console.log(response)

                setDataListas((prevData) => ({
                    ...prevData,
                    listaMunicipios: response.data.mensagem,
                }));

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        if (isvIucData.provincia) {
            fetchMunicipio();
        }

    }, [isvIucData.provincia]);





    const handleFormChange = (allValues) => {
        setIsvIucData((prevState) => ({
            ...prevState,
            ...allValues
        }));
        setTriggerCalculo((prevState) => ({
            ...prevState,
            ...allValues
        }));
    };





    return (
        <div className='w-full flex flex-col justify-center items-center gap-4'>

            {!urlCarroLink && (
                <div className='card grid-cols-1 w-full h-full'>
                    <InputCalculoProposta
                        urlCarroLink={urlCarroLink}
                        setUrlCarroLink={setUrlCarroLink}
                        dataUser={dataUser}
                    />
                </div>
            )}

            {loading ? (
                <>
                    <Loading />
                    {estadoDeErro && (
                        <>
                            <div className='flex items-center justify-center'>
                                <Button
                                    type="primary"
                                    onClick={() => {
                                        setLoading(false);
                                        setEstadoDeErro(false);
                                        setUrlCarroLink('');
                                    }} // Corrigi a sintaxe do onClick com uma função de seta
                                    className='shadow-none font-bold text-black bg-white'
                                >
                                    <div className='flex items-center gap-2'>
                                        <HiRefresh /> <p>{t('menu.voltar_a_pesquisar')}</p>
                                    </div>
                                </Button>
                            </div>

                        </>
                    )}
                </>

            ) : (
                <>
                    {(urlCarroLink && dataCarros) && (
                        <div className="w-full text-white grid gap-4">
                            <div>
                                <div className='card grid gap-4'>
                                    <RegistarPropostaFormES
                                        isvIucData={isvIucData}
                                        valoresTotais={valoresTotais}
                                        setUrlCarroLink={setUrlCarroLink}
                                        dataUser={dataUser}
                                        dataCarros={dataCarros}
                                        linkToken={linkToken}
                                        isvValido={isvValido}
                                    />
                                </div>
                            </div>

                            <div className='flex flex-col sm:flex-row gap-4 w-full h-full'>
                                <div className='w-full sm:w-3/5'>
                                    <TabelaDadosCarroProposta
                                        dataCarros={dataCarros}
                                        isvIucData={isvIucData}
                                        urlCarroLink={urlCarroLink}
                                        dataUser={dataUser}
                                        propostaInfo={propostaInfo}
                                        valoresTotais={valoresTotais}
                                    />
                                </div>
                                <div className="w-full sm:w-2/5 flex flex-col gap-4">

                                    <FormCalculoPropostaES
                                        isvIucData={isvIucData}
                                        setIsvIucData={setIsvIucData}
                                        setTriggerCalculo={setTriggerCalculo}
                                        dataListas={dataListas}
                                        setDataListas={setDataListas}
                                        onFormChange={handleFormChange}
                                        propostaInfo={propostaInfo}
                                        dataUser={dataUser}
                                        linkToken={linkToken}
                                    />

                                    <TaxaDeServico
                                        valoresTotais={valoresTotais}
                                        setValoresTotais={setValoresTotais}
                                        dataUser={dataUser}
                                    />

                                    <PrecoFinalProposta
                                        valoresTotais={valoresTotais}
                                        setValoresTotais={setValoresTotais}
                                        dataUser={dataUser}
                                    />

                                    <DuracaoDeProcesso
                                        valoresTotais={valoresTotais}
                                        propostaInfo={propostaInfo}
                                    />

                                </div>
                            </div>
                        </div>
                    )}
                </>
            )}
        </div >
    );
}
