import React, { useState } from "react";
import { FiCopy, FiCheck } from "react-icons/fi";
import { message } from "antd";
import { useTranslation } from "react-i18next";

export default function WhatsappEmpresaES({
  dataCarros,
  dataUser,
  propostaInfo,
  valoresTotais,
  formatarNumero,
  precoFinalChaveNaMaoSIva
}) {
  const { t } = useTranslation();
  const [copiedItem, setCopiedItem] = useState(null);

  //console.log(propostaInfo)


  const handleOptionClick = async (value) => {
    if (value !== 1 || !propostaInfo || !valoresTotais || !dataCarros || !dataUser) return;

    const text = `*${propostaInfo.outros.nome_proposta}*\n` +
      `*${propostaInfo.outros.nome_modelo}*\n\n` +
      `${t('partilhar_email.preco_carro')}: ${formatarNumero(valoresTotais.precoViaturaSemIva)} ${t('partilhar_email.por_negociar')}\n` +
      `Impuesto Matriculación: ${valoresTotais.valorTotalISV === "0" || valoresTotais.valorTotalISV === 0
        ? t('gerar_proposta.isento')
        : formatarNumero(valoresTotais.valorTotalISV)
      } (Resultado orientativo, por consultar documentación original y tablas BOE)\n` +
      `Impuesto Circulación: ${valoresTotais.valorTotalIUC === "0" || valoresTotais.valorTotalIUC === 0
        ? t('gerar_proposta.isento')
        : formatarNumero(valoresTotais.valorTotalIUC)
      }\n` +
      `${t('partilhar_email.despesas_de_importacao')}: ${formatarNumero(valoresTotais.taxaDeServico)}\n` +
      `${valoresTotais.taxaDeFinanciamentoAtivo === 1 ?
        `${t('partilhar_email.taxa_financiamento')}: ${formatarNumero(valoresTotais.taxaDeFinanciamento)}\n` :
        ''
      }` +
      `${t('partilhar_email.preco_final')}: ${formatarNumero(precoFinalChaveNaMaoSIva)} (Precio auto + IDM + IVTM + Fee Importrust)\n\n` +
      `*Enlace de la Propuesta:* ${propostaInfo.linkDaProposta}`;

    try {
      await navigator.clipboard.writeText(text);
      message.success("Copiado para a área de transferência!");
      setCopiedItem(value);
      setTimeout(() => setCopiedItem(null), 2000);
    } catch (error) {
      console.error("Erro ao copiar:", error);
      message.error("Erro ao copiar o conteúdo.");
    }
  };

  return (
    <div className="flex items-center gap-2">
      <label className="flex items-center gap-2 cursor-pointer">
        {copiedItem === 1 ? (
          <FiCheck
            size={18}
            title="Copiado!"
          />
        ) : (
          <FiCopy
            size={18}
            title={t("gerar_proposta.copiar_texto_para_whats_empresa")}
            onClick={() => handleOptionClick(1)}
          />
        )}
        <span className='text-sm' onClick={() => handleOptionClick(1)}>
          {t("gerar_proposta.copiar_texto_para_whats_empresa")}
        </span>
      </label>
    </div>
  );
}
