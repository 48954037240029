import { Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function CardDestaque({ comparadorVisible, text, number, icon, style, comparadorNumero, comparadorPercentagem, labelDataQuadro, link, automacao, comparar }) {

    const { t } = useTranslation();

    const isLoading = number == undefined || number == null;

    // Define a cor com base no valor de `comparadorPercentagem`
    const percentColor = comparadorPercentagem < 0 ? 'text-red-500' : 'text-green-500';


    const [poupanca, setPoupanca] = useState(0);




    // Efeito para calcular a poupança em horas
    useEffect(() => {
        let minutosPorAutomacao = 1; // Valor padrão para evitar problemas

        if (number) {
            if (text === "Automação Sales" && text === "Automatización de Ventas") {
                minutosPorAutomacao = 1; // 1 minuto por tarefa
            } else if (text === "Automação Operações" && text === "Automatización de Operaciones") {
                minutosPorAutomacao = 5; // 5 minutos por tarefa
            }

            const totalMinutos = number * minutosPorAutomacao; // Total de minutos
            const horas = Math.floor(totalMinutos / 60); // Pegando as horas inteiras
            const minutos = totalMinutos % 60; // Pegando os minutos restantes

            const resultado = `${horas}h ${minutos}min`;
            setPoupanca(resultado);

            //console.log(resultado);
        }
    }, [number, text]);


    //console.log(text)


    //console.log(link)

    return (
        <div>
            {isLoading ? (
                // Estado de carregamento quando `number` é indefinido ou nulo
                <div className="bg-main animate-pulse p-4 rounded-lg shadow-lg">
                    <div className="h-16"></div>
                </div>
            ) : (
                // Exibe o conteúdo quando `number` está disponível
                <div className={`${style ? 'border-card bg-black card-cinza' : 'card'} text-white grid content-start gap-3 h-full ${link ? 'hover-border-red hover:shadow-lg transition-shadow duration-300' : ''}`}>

                    <div className='flex justify-between items-center gap-2'>
                        <p className='text-sm font-medium'>{text}</p>
                        {automacao ?
                            <img
                                src={icon}
                                alt="Ícone de automação"
                                style={{
                                    width: '35px',
                                    height: '35px',
                                    border: '1px solid rgb(255, 255, 255)',
                                    borderRadius: '50px',
                                    lineHeight: '37px',
                                    fontSize: '18px',
                                    padding: '4px'
                                }}
                            />
                            :
                            <span>{icon}</span>
                        }
                    </div>
                    <hr></hr>
                    <div className='flex items-center gap-2'>
                        <span className='text-lg font-bold text-center'>
                            {number}
                        </span>
                        {comparadorVisible && (
                            <span className={`text-sm font-bold text-center ${percentColor}`}>
                                <span>
                                    {comparadorNumero !== 0 ? `${comparadorNumero}` : null}
                                </span>
                            </span>
                        )}
                    </div>
                    {(comparadorVisible && comparar) && (
                        <span className='text-[#7b7b7b]'>{t('dashboard.comp_com')} {comparar}</span>
                    )}
                </div>
            )}
        </div>
    );
}
