import React, { useState } from "react";
import axios from "axios";
import { Form, Button, Upload, message, Select } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import TitleSection from "../../Elements/Title";
import { useTranslation } from "react-i18next";
import { files } from "jszip";

const { Dragger } = Upload;

export default function TraduzirDocumentos({
    dataUser,
    // files,
    setFiles,
    filesParaUploud,
    setFilesParaUploud,
    mediaSegundos,
    hasPendingFiles
}) {

    const { t } = useTranslation();

    const [form] = Form.useForm();

    const [lang, setLang] = useState("Portuguese - Portugal"); //Lang para o Select

    const [draggerKey, setDraggerKey] = useState(Date.now()); //Para dar reset ao elemento do uploud


    console.log('filesParaUploud', filesParaUploud)


    const handleFileChange = ({ fileList }) => {

        console.log('fileList', fileList)

        setFilesParaUploud(prevFiles => [
            // ...prevFiles, // Mantém os arquivos antigos
            ...fileList.map(file => ({
                file: file.originFileObj,
                status: null,
                tempo: file.percent,
                name: file.name,
                size: file.size,
                type: file.type,
                resumo: null,
                erroMensagem: null,
            }))
        ]);
    };



    const handleUpload = async () => {

        if (filesParaUploud.length === 0) {
            message.error(
                "Selecione pelo menos um documento ou imagem antes de submeter."
            );
            return;
        }

        setFiles([]);

        console.log(filesParaUploud)

        // Atualiza o status para "processing" nos arquivos que ainda não estão completos
        const updatedFiles = filesParaUploud.map((file) =>
            file.status === "completed" ? file : { ...file, status: "processing" }
        );

        // Move os arquivos para o estado final e limpa o estado de upload
        setFiles((prevFiles) => [...prevFiles, ...updatedFiles]);
        setFilesParaUploud([]);

        // Processa cada arquivo atualizado
        updatedFiles.forEach(async (fileObj, index) => {
            console.log(fileObj)
            if (
                fileObj.type === "pdf" ||
                fileObj.type === "application/pdf"
            ) {
                await uploadSingleFile(fileObj.file, index);
            } else if (fileObj.type.startsWith("image/")) {
                // Caso queira processar imagens, descomente e implemente a função uploadMultipleImages
                // await uploadMultipleImages([fileObj.file], index);
            }
        });

        form.resetFields();
        setDraggerKey(Date.now());
    };





    const uploadSingleFile = async (file, index) => {

        console.log(file)

        const formData = new FormData();
        formData.append("ficheiro", file);
        formData.append("user_id", dataUser.iDTokenAuthenticated);
        formData.append("token", dataUser.saveToken);
        formData.append("target_lang", lang);

        try {
            const response = await axios.post(`${process.env.REACT_APP_GET_DOC_TRADUCAO}`, formData, {
                headers: { "Content-Type": "multipart/form-data" },
                responseType: "json",
            });

            //console.log(response)

            if (response.data.sucesso) {
                setFiles(prevFiles =>
                    prevFiles.map((f, i) =>
                        i === index ? {
                            ...f,
                            status: "completed",
                            name: response.data.data.ficheiro.name,
                            resumo: response.data.data.ficheiro.resumo,
                            type: response.data.data.ficheiro.type
                        } : f
                    )
                );
                // setFilesParaUploud([]);
                message.success(`Ficheiro ${file.name} traduzido com sucesso!`);
            } else {
                setFiles(prevFiles =>
                    prevFiles.map((f, i) =>
                        i === index ? {
                            ...f,
                            status: "failed",
                            erroMensagem: response.data.mensagem
                        } : f
                    )
                );
                message.error(response.data.mensagem || `Erro ao processar ${file.name}.`);
            }
        } catch {
            setFiles(prevFiles =>
                prevFiles.map((f, i) =>
                    i === index ? {
                        ...f,
                        status: "failed",
                        erroMensagem: "Erro. Tente novamente!"
                    } : f
                )
            );
            message.error("Erro. Tente novamente!");
        }
    };




    // const uploadMultipleImages = async (imageFiles, index) => {

    //     const formData = new FormData();
    //     imageFiles.forEach(file => formData.append("ficheiro", file));
    //     formData.append("user_id", dataUser.iDTokenAuthenticated);
    //     formData.append("token", dataUser.saveToken);
    //     formData.append("target_lang", lang);

    //     try {
    //         const response = await axios.post(`${process.env.REACT_APP_GET_DOC_TRADUCAO}`, formData, {
    //             headers: { "Content-Type": "multipart/form-data" },
    //             responseType: "json",
    //         });

    //         //console.log(response)

    //         if (response.data.sucesso) {
    //             setFiles(prevFiles =>
    //                 prevFiles.map((f, i) =>
    //                     i === index ? {
    //                         ...f,
    //                         status: "completed",
    //                         name: response.data.data.ficheiro.name,
    //                         resumo: response.data.data.ficheiro.resumo,
    //                         type: response.data.data.ficheiro.type
    //                     } : f
    //                 )
    //             );
    //             message.success(`Ficheiro ${imageFiles.name} traduzido com sucesso!`);
    //         } else {
    //             setFiles(prevFiles =>
    //                 prevFiles.map((f, i) =>
    //                     i === index ? {
    //                         ...f,
    //                         status: "failed",
    //                         erroMensagem: response.data.mensagem
    //                     } : f
    //                 )
    //             );
    //             message.error(response.data.mensagem || `Erro ao processar ${imageFiles.name}.`);
    //         }
    //     } catch {
    //         setFiles(prevFiles =>
    //             prevFiles.map((f, i) =>
    //                 i === index ? {
    //                     ...f,
    //                     status: "failed",
    //                     erroMensagem: "Erro. Tente novamente"
    //                 } : f
    //             )
    //         );
    //         message.error("Erro. Tente novamente!");
    //     }
    // };






    return (
        <>
            <div className="card grid gap-4 p-4 bg-white rounded-lg shadow-md">
                <TitleSection title={t("traduzirdocs.titulo_principal")} />
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={handleUpload}
                    className="uploud-documentos"
                >
                    <Form.Item>
                        <div className="flex items-center gap-3 justify-between text-white">
                            <div className="grid gap-2">
                                <p>{t("traduzirdocs.upload_documentos")}</p>
                            </div>
                            <div className="flex items-center gap-2">
                                <p className="w-full min-w-max">{t("traduzirdocs.traduzir_para")}</p>
                                <Select value={lang} onChange={setLang}>
                                    <Select.Option value="Portuguese - Portugal">{t("traduzirdocs.idiomas.portugues")}</Select.Option>
                                    <Select.Option value="English">{t("traduzirdocs.idiomas.ingles")}</Select.Option>
                                    <Select.Option value="Spanish">{t("traduzirdocs.idiomas.espanhol")}</Select.Option>
                                </Select>
                            </div>
                        </div>
                    </Form.Item>

                    <Form.Item className="upload-documentos">
                        <Dragger
                            multiple
                            key={draggerKey} // Atualiza a key para resetar o componente
                            beforeUpload={() => false}
                            onChange={handleFileChange}
                            maxCount={3}
                        >
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                            </p>
                            <p className="text-white">{t("traduzirdocs.arrastar_arquivos")}</p>
                            <p className="text-neutral-500 text-sm">{t("traduzirdocs.formatos_suportados")}</p>
                        </Dragger>
                    </Form.Item>

                    <hr className="mb-5" />
                    <div className="flex gap-2 items-center justify-between traduzir-cta">
                        <div className="rounded-full flex items-center gap-2 border border-black px-3">
                            <p className="text-sm text-neutral-500 flex gap-1">
                                Tempo estimado por ficheiro:<b>{mediaSegundos} segundos</b>
                            </p>
                        </div>

                        <Form.Item className="flex justify-end">
                            <Button
                                type="primary"
                                htmlType="submit"
                                className="flex gap-2 items-center font-bold rounded text-black bg-white"
                                disabled={hasPendingFiles}
                            >
                                {t("traduzirdocs.botao_traduzir")}
                            </Button>
                        </Form.Item>
                    </div>

                </Form>

            </div>
        </>
    );
}
