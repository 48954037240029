import React, { useState, useEffect, useCallback } from 'react';
import TitleSection from "../../components/Elements/Title";
import { useTranslation } from "react-i18next";
import { Form, Input, Select, Checkbox, Collapse, message } from "antd";
import axios from "axios";
import InputCalculoProposta from "../../components/CalculoProposta/InputCalculoProposta";
import { validateOperation } from '@apollo/client/link/utils';

const { Option } = Select;
const { Panel } = Collapse;

export default function SimuladorISV({ dataUser }) {
    const { t } = useTranslation();
    const [form] = Form.useForm();

    const formatarNumero = (valor) => {
        if (!valor || isNaN(valor)) return '0 €';
        return new Intl.NumberFormat('pt-PT', {
            style: 'currency',
            currency: 'EUR',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        }).format(valor);
    };

    const [loading, setLoading] = useState(false);
    const [urlCarroLink, setUrlCarroLink] = useState('');
    const [propostaInfo, setPropostaInfo] = useState({
        erro: ''
    });

    const [isvIucData, setIsvIucData] = useState({
        cilindrada: 0,
        novoUsado: '',
        tipoCombustivel: '',
        emissoesCO2: '',
        hibridoPlugIn: 0,
        wltp: 0,
        particulas: 0,
        dataPrimeiroRegisto: '',
        tipoDeVeiculoParaOutros: 'LigeiroPassageiros',
        tipoDeVeiculoParaInsencoes: '',
        outrosServicos: '',
        valorTotalISV: 0,
        valorTotalIUC: 0,
        hibridoMais25kmAutonomia: 0,

        configuracaoEixos: '',
        pesoBruto: '',
        tipoSuspensao: '',

    });

    const [valoresTotais, setValoresTotais] = useState({
        valorTotalISV: null,
        valorTotalIUC: null,
        // valorTotalISV2025: null,
    });

    const fetchData = useCallback(async (url) => {
        setLoading(true);
        try {
            const requestUrl = `${process.env.REACT_APP_PEDIDO_PROPOSTA}?request=${encodeURIComponent(url)}&user_id=${dataUser.iDTokenAuthenticated}&token=${dataUser.saveToken}&lang=${dataUser.language}`;
            const response = await axios.get(requestUrl);

            if (response.data.sucesso) {
                const calculo = response.data.data.simulacao;

                let tipoCombustivel = calculo.combustivel_para_simulacao;
                if (tipoCombustivel === "Híbrido (Gasolina/Eletrico)") {
                    tipoCombustivel = "Gasolina";
                } else if (tipoCombustivel === "Híbrido (Gasóleo/Eletrico)") {
                    tipoCombustivel = "Gasoleo";
                }

                const allData = {
                    cilindrada: calculo.cilindrada_numerico,
                    novoUsado: calculo.novo === 1 ? "Novo" : "Usado",
                    tipoCombustivel,
                    emissoesCO2: calculo.emissoes_numerico,
                    hibridoPlugIn: calculo.hibrido,
                    wltp: calculo.wltp,
                    particulas: calculo.tipo_combustivel_traduzido === "Gasoleo" ? "1" : "0",
                    dataPrimeiroRegisto: calculo.data_registo_para_simulacao,
                    tipoDeVeiculoParaOutros: calculo.tipo_de_veiculo,
                    tipoDeVeiculoParaInsencoes: '',
                    outrosServicos: '',
                };

                setIsvIucData(allData);
            } else {
                message.error(response.data.mensagem);
                setUrlCarroLink('');
                setLoading(false);
            }
        } catch (error) {
            console.error("Erro ao buscar dados:", error);
        } finally {
            setLoading(false);
        }
    }, [dataUser]);





    // Extrair transformações para funções auxiliares
    const normalizarNovoUsado = (novoUsado) => novoUsado === "Novo" ? 1 : 0;

    const normalizarTipoDeTestes = (wltp) => wltp === 1 ? 'WLTP' : 'NEDC';

    const normalizarTipoCombustivel = (combustivel) => {
        switch (combustivel) {
            case "Híbrido (Gasolina/Eletrico)": return "Gasolina";
            case "Híbrido (Gasóleo/Eletrico)": return "Gasoleo";
            default: return combustivel;
        }
    };

    const determinarTipoVeiculo = (hibridoPlugIn, tipoDeVeiculoParaOutros) => {
        return hibridoPlugIn === 1 ? "HibridosPlugIn" : tipoDeVeiculoParaOutros;
    };

    // Função para construir URLs
    const construirUrls = (params) => {
        const {
            novo_usado_int, tipoDeTestes, tipoDeVeiculoFinal, tipoDeCombustivel,
            particulas, emissoesGases, cilindrada, dataDeRegisto,
            tipoDeVeiculoParaInsencoes, hibridoMais25kmAutonomia
        } = params;

        return {
            isvUrl: `${process.env.REACT_APP_PEDIDO_ISV}?novo=${novo_usado_int}&tipo_de_testes_co2=${tipoDeTestes}&tipo_carro=${tipoDeVeiculoFinal}&combustivel=${tipoDeCombustivel}&particulas=${particulas}&co2=${emissoesGases}&cilindrada=${cilindrada}&ano=${dataDeRegisto}&isencao=${tipoDeVeiculoParaInsencoes}&hibrido_mais_25km_autonomia=${hibridoMais25kmAutonomia}`,
            iucUrl: `${process.env.REACT_APP_PEDIDO_IUC}?cilindrada=${cilindrada}&ano=${dataDeRegisto}&combustivel=${tipoDeCombustivel}&co2=${emissoesGases}&tipo_carro=${tipoDeVeiculoFinal}&tipo_de_testes_co2=${tipoDeTestes}&novo=${novo_usado_int}`
        };
    };





    useEffect(() => {

        const fetchISVIUCData = async () => {
            try {
                // Normalizar dados
                const novo_usado_int = normalizarNovoUsado(isvIucData.novoUsado);
                const tipoDeTestes = normalizarTipoDeTestes(isvIucData.wltp);
                const tipoDeCombustivel = normalizarTipoCombustivel(isvIucData.tipoCombustivel);
                const tipoDeVeiculoFinal = determinarTipoVeiculo(
                    isvIucData.hibridoPlugIn,
                    isvIucData.tipoDeVeiculoParaOutros
                );

                const params = {
                    novo_usado_int,
                    tipoDeTestes,
                    tipoDeVeiculoFinal,
                    tipoDeCombustivel,
                    particulas: isvIucData.particulas,
                    emissoesGases: isvIucData.emissoesCO2,
                    cilindrada: isvIucData.cilindrada,
                    dataDeRegisto: isvIucData.dataPrimeiroRegisto,
                    tipoDeVeiculoParaInsencoes: isvIucData.tipoDeVeiculoParaInsencoes || '',
                    hibridoMais25kmAutonomia: isvIucData.hibridoMais25kmAutonomia ? 1 : 0
                };

                const urls = construirUrls(params);

                // Fazer os dois pedidos de uma vez
                const [isvResponse, iucResponse] = await Promise.all([
                    axios.get(urls.isvUrl),
                    axios.get(urls.iucUrl)
                ]);

                if (isvResponse.data.sucesso && iucResponse.data.sucesso) {
                    processarRespostasComSucesso(isvResponse, iucResponse);
                } else {
                    processarRespostasComErro(isvResponse, iucResponse);
                }
            } catch (error) {
                console.error('Erro ao buscar ISV ou IUC:', error);
                message.error('Ocorreu um erro ao processar o cálculo. Por favor, tente novamente.');

                // Resetar estados em caso de erro
                // setIsvValido(false);
                atualizarEstadosComErro();
            }
        };

        const processarRespostasComSucesso = (isvResponse, iucResponse) => {

            //console.log(isvResponse)
            //console.log(iucResponse)

            const valorISV = isvResponse.data.data.isv;
            const valorIUC = iucResponse.data.data.iuc;
            const valorISVTaxas = 0;
            const valorIUCTaxas = 0;

            // Atualizar o estado principal com todos os valores
            atualizarValoresTotais(valorISV, valorIUC, valorISVTaxas, valorIUCTaxas);
            // setIsvValido(true);

            // Verificar avisos de isenção
            const avisos = isvResponse.data.data.mensagem.avisos_isencao;
            //console.log('avisosavisos', avisos)
            if (avisos.length !== 0) {
                message.warning(avisos);
                setPropostaInfo(prev => ({ ...prev, erro: avisos }));
            } else {
                setPropostaInfo(prev => ({ ...prev, erro: '' }));
            }
        };

        const processarRespostasComErro = (isvResponse, iucResponse) => {

            //console.log(isvResponse)
            //console.log(iucResponse)

            if (!isvResponse.data.sucesso) {
                const mensagemErro = isvResponse.data.mensagem || 'Erro no cálculo do ISV';
                message.error(mensagemErro);
                setPropostaInfo(prev => ({ ...prev, erro: mensagemErro }));
                // setIsvValido(false);

                resetarValoresISV();
            }

            if (!iucResponse.data.sucesso) {
                message.error(iucResponse.data.mensagem || 'Erro no cálculo do IUC');
            }
        };

        const atualizarEstadosComErro = () => {

            setValoresTotais(prev => ({
                ...prev,
                valorTotalISV: 0,
                valorTotalIUC: 0,
            }));

            // Atualizar o estado de erro na proposta
            setPropostaInfo(prev => ({
                ...prev,
                erro: 'Erro ao processar o cálculo de ISV/IUC.'
            }));
        }

        const atualizarValoresTotais = (valorISV, valorIUC, valorISVTaxas, valorIUCTaxas) => {

            setValoresTotais(prev => {
                const novoEstado = {
                    ...prev,
                    valorTotalISV: valorISV,
                    valorTotalIUC: valorIUC
                };

                return novoEstado;
            });
        };

        const resetarValoresISV = () => {
            setValoresTotais(prev => ({
                ...prev,
                valorTotalISV: 0,
                valorTotalIUC: 0
            }));
        };

        // Verificar se temos todos os dados necessários para fazer o cálculo
        const dadosCompletos = [
            isvIucData.cilindrada,
            isvIucData.novoUsado,
            isvIucData.tipoCombustivel,
            isvIucData.emissoesCO2,
            (isvIucData.tipoDeVeiculoParaInsencoes || isvIucData.tipoDeVeiculoParaOutros)
        ].every(Boolean);


        if (dadosCompletos) {
            fetchISVIUCData();
        }


    }, [isvIucData]);




    useEffect(() => {
        if (urlCarroLink) {
            fetchData(urlCarroLink);
        }
    }, [urlCarroLink, fetchData]);



    const onFormChange = (allValues) => {
        setIsvIucData({ ...isvIucData, ...allValues });
    };


    useEffect(() => {

        form.setFieldsValue({
            ...isvIucData,
            wltp: isvIucData?.wltp, // Garante que o valor do checkbox seja booleano
            hibridoPlugIn: isvIucData?.hibridoPlugIn,
        });

        // Valida manualmente os campos quando os valores são inválidos
        if (isvIucData.emissoesCO2 == '0' || isvIucData.emissoesCO2 === 0 || isvIucData.emissoesCO2 === "") {
            form.validateFields(['emissoesCO2']);
        }
        if (isvIucData.cilindrada == '0' || isvIucData.cilindrada === 0 || isvIucData.cilindrada === "") {
            form.validateFields(['cilindrada']);
        }
        if (isvIucData.dataPrimeiroRegisto == "" || !isvIucData.dataPrimeiroRegisto) {
            form.validateFields(['dataPrimeiroRegisto']);
        }

    }, [isvIucData, form]);

    return (
        <>
            <div className='card grid gap-4'>
                <TitleSection
                    title={t('simulador_isv.titulo_principal')}
                    description={t('simulador_isv.sub_titulo')}
                />

                <div className='flex flex-col items-stretch gap-2'>
                    <p className='text-white font-semibold'>Link da Viatura</p>
                    <Input
                        placeholder={t('simulador.pesquisa')}
                        value={urlCarroLink}
                        onChange={(e) => setUrlCarroLink(e.target.value)}
                    />
                </div>
                <Form
                    layout="vertical"
                    className='form-de-envio-docs'
                    form={form} // Define o formulário a ser utilizado
                    onValuesChange={(_, allValues) => {
                        // //console.log(allValues)
                        onFormChange(allValues); // Dispara ao editar algum valor
                    }}
                >
                    <div className="grid gap-4">
                        <div className='grid grid-cols-1 sm:grid-cols-2 gap-4 text-white'>

                            {/* Campo Cilindrada */}
                            {isvIucData.tipoCombustivel !== "Eletrico" && (
                                <div className='flex flex-col items-stretch gap-2'>
                                    <p className='text-white font-semibold'>Cilindrada</p>
                                    <Form.Item
                                        name="cilindrada"
                                        rules={[
                                            ({ getFieldValue }) => ({
                                                validator(_, value) {
                                                    if (!value || value === '' || value === '0' || value === 0) {
                                                        return Promise.reject('Por favor, insira um valor válido para a cilindrada!'); // Combinação da validação obrigatória e valor válido
                                                    }
                                                    return Promise.resolve();
                                                },
                                            }),
                                        ]}
                                    >
                                        <Input />
                                    </Form.Item>
                                </div>
                            )}

                            {/* Campo Estado */}
                            <div className='flex flex-col items-stretch gap-2'>
                                <p className='text-white font-semibold'>Estado</p>
                                <Form.Item
                                    name="novoUsado"
                                    rules={[
                                        { required: true, message: 'Por favor, selecione o estado!' },
                                    ]}
                                >
                                    <Select>
                                        <Option value="1">Novo</Option>
                                        <Option value="0">Usado</Option>
                                    </Select>
                                </Form.Item>
                            </div>

                            {/* Campo Tipo de Combustível */}
                            <div className='flex flex-col items-stretch gap-2'>
                                <p className='text-white font-semibold'>Tipo de Combustível</p>
                                <Form.Item
                                    name="tipoCombustivel"
                                    rules={[
                                        { required: true, message: 'Por favor, selecione o tipo de combustível!' },
                                    ]}
                                >
                                    <Select>
                                        <Option value="Gasolina">Gasolina</Option>
                                        <Option value="Gasoleo">Gasoleo</Option>
                                        <Option value="Eletrico">Elétrico</Option>
                                    </Select>
                                </Form.Item>
                            </div>

                            {/* Campo Emissões */}
                            {isvIucData.tipoCombustivel !== "Eletrico" && (
                                <div className='flex flex-col items-stretch gap-2'>
                                    <p className='text-white font-semibold'>Emissões CO2 (g/km)</p>
                                    <Form.Item
                                        name="emissoesCO2"
                                        rules={[
                                            ({ getFieldValue }) => ({
                                                validator(_, value) {
                                                    if (!value) {
                                                        return Promise.reject('Por favor, insira as emissões de CO2!'); // Mensagem para campo vazio
                                                    }
                                                    if (value === '0' || value === 0) {
                                                        return Promise.reject('Por favor, insira um valor válido para as emissões de CO2!'); // Mensagem para valor inválido (0)
                                                    }
                                                    return Promise.resolve();
                                                },
                                            }),
                                        ]}
                                    >
                                        <Input type='number' />
                                    </Form.Item>
                                </div>
                            )}

                            {/* Híbrido Plug-In como Checkbox */}
                            {isvIucData.tipoCombustivel !== "Eletrico" && (
                                <div className='flex flex-col items-stretch gap-2'>
                                    <Form.Item
                                        name="hibridoPlugIn"
                                        valuePropName="checked"
                                    >
                                        <div className='flex items-center gap-2'>
                                            <Checkbox checked={isvIucData?.hibridoPlugIn == "1"} />
                                            <p className='text-white font-semibold'>Híbrido Plug-In</p>
                                        </div>
                                    </Form.Item>
                                </div>
                            )}

                            {/* WLTP como Checkbox */}
                            {isvIucData.tipoCombustivel !== "Eletrico" && (
                                <div className='flex flex-col items-stretch gap-2'>
                                    <Form.Item
                                        name="wltp"
                                        valuePropName="checked"
                                    >
                                        <div className='flex items-center gap-2'>
                                            <Checkbox checked={isvIucData?.wltp == "1"} />  {/* Verifique se wltp é igual a "1" */}
                                            <p className='text-white font-semibold'>WLTP</p>
                                        </div>
                                    </Form.Item>
                                </div>
                            )}


                            {/* Data 1º Registo */}
                            {isvIucData.novoUsado !== "Novo" && (
                                <div className='flex flex-col items-stretch gap-2'>
                                    <p className='text-white font-semibold'>Data 1º Registo</p>
                                    <Form.Item
                                        name="dataPrimeiroRegisto"
                                        rules={[
                                            ({ getFieldValue }) => ({
                                                validator(_, value) {
                                                    if (!value || value === '') {
                                                        return Promise.reject('Por favor, insira a data do primeiro registo!'); // Combina a validação de campo obrigatório e validação personalizada
                                                    }
                                                    return Promise.resolve();
                                                },
                                            }),
                                        ]}
                                    >
                                        <Input />
                                    </Form.Item>
                                </div>
                            )}


                            {/* Campo Particulas */}
                            {isvIucData.tipoCombustivel === "Gasoleo" && (
                                <div className='flex flex-col items-stretch gap-2'>
                                    <p className='text-white font-semibold'>Particulas</p>
                                    <Form.Item
                                        name="particulas"
                                        rules={[
                                            { required: true, message: 'Por favor, selecione as particulas!' },
                                        ]}
                                    >
                                        <Select>
                                            <Option
                                                value="">Selecione</Option>
                                            <Option value="0">{'<'} 0,001
                                                g/km</Option>
                                            <Option value="1">{'>'}= 0,001
                                                g/km</Option>
                                        </Select>
                                    </Form.Item>
                                </div>
                            )}


                            {/* Regra hibridos com data 01/01/2015 a 30/12/2020*/}
                            {isvIucData?.hibridoPlugIn == "1" && (() => {
                                // Verificar e ajustar o formato da data
                                let [month, year] = isvIucData.dataPrimeiroRegisto.split("/"); // Assumindo formato "MM/YYYY"
                                let formattedDate = new Date(`${year}-${month}-01`); // Converter para "YYYY-MM-DD"

                                // Comparação com as datas do intervalo
                                return formattedDate >= new Date("2015-01-01") && formattedDate <= new Date("2020-12-31");
                            })() && (
                                    <div className='flex flex-col items-stretch gap-2'>
                                        <Form.Item
                                            name="hibridoMais25kmAutonomia"
                                            valuePropName="checked"
                                        >
                                            <div className='flex items-center gap-2'>
                                                <Checkbox checked={isvIucData?.hibridoMais25kmAutonomia == "1"} />
                                                <p className='text-white'>
                                                    Confirmo que a viatura possui pelo menos 25km de autonomia
                                                </p>
                                            </div>
                                        </Form.Item>
                                    </div>
                                )}



                        </div>
                    </div>

                    <hr />

                    <Collapse>
                        <Panel header={<span className="custom-collapse-header">Mais Detalhes</span>} key="1">
                            <div className='grid gap-4'>
                                {/* Campo Tipo de Veículo */}
                                <div className='grid gap-2'>
                                    <p>Tipo de Veículo</p>
                                    <Form.Item name="tipoDeVeiculoParaOutros">
                                        <Select
                                            style={{ width: '100%' }}
                                            placeholder="Selecione"
                                            value={isvIucData?.tipoDeVeiculoParaOutros || 'LigeiroPassageiros'}
                                        >
                                            <Option value="LigeiroPassageiros">Ligeiro de Passageiros</Option>
                                            <Option value="LSup2500kg">Ligeiro {'>'} 2500Kg</Option>
                                            <Option value="LMeMerc">Ligeiro Misto (cx 145x130), Mercadorias 4x2</Option>
                                            <Option value="LMercCF">Ligeiro Mercadorias Caixa Fechada</Option>
                                            <Option value="LMerc3Lug">Ligeiro Mercadorias 3 Lugares</Option>
                                            <Option value="LMerc4x4">Ligeiro Mercadorias 4x4</Option>
                                            <Option value="LMerc4x2PB3500">Ligeiro Mercadorias, Cx.Fechada s/Cab. Integrada, PB=3500kg</Option>
                                            <Option value="LMercNT">Ligeiro Mercadorias Não Tributado pela Tabela B</Option>
                                            <Option value="LPGPL">Ligeiro Passageiros a GPL</Option>
                                            <Option value="LPGN">Ligeiro Passageiros a Gás Natural</Option>
                                            <Option value="Taxi">Táxi</Option>
                                            <Option value="Autocaravana">Autocaravana</Option>
                                            <Option value="MotoTriQuad">Motociclo, Triciclo, Quadriciclo</Option>
                                        </Select>
                                    </Form.Item>
                                </div>

                                {/* Campo Isenção */}
                                <div className='grid gap-2'>
                                    <p>Isenção</p>
                                    <Form.Item name="tipoDeVeiculoParaInsencoes">
                                        <Select
                                            style={{ width: '100%' }}
                                            placeholder="Selecione"
                                            value={isvIucData?.tipoDeVeiculoParaInsencoes || ''}
                                        >
                                            <Option value="">Sem Isenção</Option>
                                            <Option value="familias_numerosas">Famílias numerosas</Option>
                                            <Option value="mudanca_residencia">Mudança de residência</Option>
                                            <Option value="pessoas_com_deficiência">Pessoas com deficiência</Option>
                                            <Option value="pessoas_com_deficiência_obriga_caixa_automatica">Pessoas com deficiência que obriga caixa automática</Option>
                                        </Select>
                                    </Form.Item>
                                </div>

                            </div>
                        </Panel>
                    </Collapse>
                </Form>

                {propostaInfo.erro && (
                    <span className='text-sm text-[#fffd00] opacity-90' dangerouslySetInnerHTML={{ __html: propostaInfo.erro }} />
                )}

                {valoresTotais.valorTotalISV && (
                    <div className="border-card bg-black p-4 grid gap-4">
                        <p className="text-white text-lg font-bold">Resultado da Simulação:</p>
                        {/* <div className='grid gap-4'> */}
                        <div className="grid w-full gap-4">
                            <div className="flex items-center justify-between gap-2 bg-[#27272A] text-white p-2.5 rounded border border-[#FFFFFF1A]">
                                <p className="text-[1.2rem]">ISV</p>
                                <span className="text-[1.5rem] font-bold">{formatarNumero(valoresTotais.valorTotalISV)}</span>
                            </div>
                            {/* <div className="flex items-center justify-between gap-2 bg-[#27272A] text-white p-2.5 rounded border border-[#FFFFFF1A]">
                                <p className="text-[1.2rem]">ISV 2025</p>
                                <span className="text-[1.5rem] font-bold">{formatarNumero(valoresTotais.valorTotalISV2025)}</span>
                            </div>
                            {valoresTotais.valorTotalISV !== valoresTotais.valorTotalISV2025 ? (
                                valoresTotais.valorTotalISV < valoresTotais.valorTotalISV2025 ? (
                                    <p className="flex items-center gap-2 justify-center text-white text-lg my-4 text-center">Se comprar a viatura em 2024 consegue poupar {formatarNumero(valoresTotais.valorTotalISV2025 - valoresTotais.valorTotalISV)} <span className='text-2xl'>🤑</span></p>
                                ) : (
                                    <p className="flex items-center gap-2 justify-center text-white text-lg my-4 text-center">Se comprar a viatura em 2025 consegue poupar {formatarNumero(valoresTotais.valorTotalISV - valoresTotais.valorTotalISV2025)} <span className='text-2xl'>🤑</span></p>
                                )
                            ) : (
                                <p className="flex items-center gap-2 justify-center text-white text-lg my-4 text-center">O valor do ISV atual é igual ao valor do ISV para 2025.</p>
                            )} */}
                            <hr></hr>
                            <div className="flex items-center justify-between gap-2 bg-[#27272A] text-white p-2.5 rounded border border-[#FFFFFF1A]">
                                <p className="text-[1.2rem]">IUC</p>
                                <span className="text-[1.5rem] font-bold">{formatarNumero(valoresTotais.valorTotalIUC)}</span>
                            </div>

                        </div>
                    </div>
                )}
            </div >
        </>
    );
}
