import { ConsoleSqlOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlineInfoCircle } from 'react-icons/ai';

export default function PrecoChaveNaMaoProposta({ dataUser, valoresTotais, setValoresTotais, activeTabKey, formatarNumero, incluirIVA }) {

    const { t } = useTranslation();

    const [valorFinal, setValorFinal] = useState(0); //valor final


    const precoViaturaComIva = parseFloat(valoresTotais.precoViaturaComIva) || 0;
    const precoViaturaSemIva = parseFloat(valoresTotais.precoViaturaSemIva) || 0;
    const taxaDeServico = parseFloat(valoresTotais.taxaDeServico) || 0;
    const valorTotalISV = parseFloat(valoresTotais.valorTotalISV) || 0;
    const taxaDeFinanciamento = parseFloat(valoresTotais.taxaDeFinanciamento) || 0;
    const taxaExpress = parseFloat(valoresTotais.valorExpress) || 0;
    const iuc = parseFloat(valoresTotais.iuc) || 0;

    const valorIVAPortugal = 1.23;
    const valorIVAEspanha = 1.21;


    const calcularValorTotal = (precoViatura) => {
        let valorTotal = precoViatura + taxaDeServico + valorTotalISV;

        if (valoresTotais.taxaDeFinanciamentoAtivo) {
            valorTotal += taxaDeFinanciamento;
        }
        if (valoresTotais.valorExpressAtivo) {
            valorTotal += taxaExpress;
        }

        valorTotal += iuc;

        return valorTotal;
    };

    useEffect(() => {


        let valorTotalParticular = calcularValorTotal(parseFloat(precoViaturaComIva));
        let valorTotalEmpresa;

        if (!incluirIVA && valoresTotais.validaSeValorNaoTemIva == 1 && valoresTotais.taxaDeFinanciamentoAtivo) {
            const valorIVA = dataUser.language === 'pt' ? valorIVAPortugal : valorIVAEspanha;
            const precoComIVA = parseFloat(precoViaturaSemIva) * valorIVA;
            valorTotalEmpresa = calcularValorTotal(precoComIVA);
        } else if (incluirIVA && valoresTotais.validaSeValorNaoTemIva == 1 && valoresTotais.taxaDeFinanciamentoAtivo) {
            const valorIVA = dataUser.language === 'pt' ? valorIVAPortugal : valorIVAEspanha;
            const precoComIVA = parseFloat(precoViaturaSemIva) * valorIVA;
            valorTotalParticular = calcularValorTotal(precoComIVA);
        } else {
            valorTotalEmpresa = calcularValorTotal(precoViaturaSemIva);
        }


        const valorTotalFinal = activeTabKey === '1' ? valorTotalParticular : valorTotalEmpresa;
        // //console.log(valorTotalFinal)
        setValorFinal(Number.isNaN(valorTotalFinal) ? 0 : valorTotalFinal);

        setValoresTotais((prevState) => ({
            ...prevState,
            valorPrecoChaveNaMaoParticular: valorTotalParticular,
            valorPrecoChaveNaMaoEmpresa: valorTotalEmpresa,
        }));

    }, [
        valoresTotais.precoViaturaComIva,
        valoresTotais.precoViaturaSemIva,
        valoresTotais.taxaDeServico,
        valoresTotais.valorTotalISV,
        valoresTotais.taxaDeFinanciamentoAtivo,
        valoresTotais.taxaDeFinanciamento,
        valoresTotais.valorExpressAtivo,
        valoresTotais.valorExpress,
        valoresTotais.iuc,
        activeTabKey,
    ]);

    return (
        <div className='flex justify-between items-center'>
            <div className='flex items-center gap-2'>
                <p className='text-black text-base font-semibold'>{t('tables.preco_chave_da_mao')}</p>
                {!incluirIVA && valoresTotais.validaSeValorNaoTemIva == 1 && (
                    <Tooltip title="(Valor da Viatura(s/IVA) * IVA) + ISV + Taxa de Serviço + Taxa de Financiamento">
                        <AiOutlineInfoCircle className='icon-question' />
                    </Tooltip>
                )}
            </div>

            <p className='font-bold text-lg'>
                {formatarNumero(valorFinal)}
            </p>
        </div>
    );
}
