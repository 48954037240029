import React from 'react';
import CopiarURLcomLinkparaEmail from '../CopiarURLcomLinkparaEmail';
import CopiarURLparaWhatsapp from '../CopiarURLparaWhatsapp';
import EmailParticularES from './EmailParticularES';
import EmailEmpresaES from './EmailEmpresaES';
import EmailParticularESemEN from './EmailParticularESemEN';
import EmailEmpresaESemEN from './EmailEmpresaESemEN';
import WhatsappParticularES from './WhatsappParticularES';
import WhatsappEmpresaES from './WhatsappEmpresaES';
import WhatsappParticularESemEN from './WhatsappParticularESemEN';
import { useTranslation } from 'react-i18next';
import WhatsappEmpresaESemEN from './WhatsappEmpresaESemEN';
import { FaLink, FaWhatsapp } from 'react-icons/fa6';
import { HiOutlineMail } from "react-icons/hi";


export default function PartilharClientePropostaES({
    propostaInfo,
    dataCarros,
    valoresTotais,
    formatarNumero,
    dataUser,
    precoFinalChaveNaMaoCIva,
    precoFinalChaveNaMaoSIva,
    valorTotalCaucaoParticular,
    valorTotalCaucaoEmpresa,
}) {

    const { t } = useTranslation();

    return (
        <>

            <div className='grid gap-2'>
                <div className='flex items-center justify-between gap-2'>
                    <span className='font-semibold'>{t('gerar_proposta.link_proposta')}</span>
                    <FaLink size={20} />
                </div>
                <hr></hr>
                <CopiarURLcomLinkparaEmail
                    propostaInfo={propostaInfo}
                    valoresTotais={valoresTotais}
                />
                <hr></hr>
                <CopiarURLparaWhatsapp
                    propostaInfo={propostaInfo}
                    valoresTotais={valoresTotais}
                />
            </div>

            <div className='grid gap-2'>
                <div className='flex items-center justify-between gap-2'>
                    <span className='font-semibold'>Email</span>
                    <HiOutlineMail size={20} />
                </div>
                <hr></hr>
                {/* Copiar Texto para Email Particular */}
                <EmailParticularES
                    propostaInfo={propostaInfo}
                    valoresTotais={valoresTotais}
                    precoFinalChaveNaMaoCIva={precoFinalChaveNaMaoCIva}
                    valorTotalCaucaoParticular={valorTotalCaucaoParticular}
                    formatarNumero={formatarNumero}
                />
                <hr></hr>
                {/* Copiar Texto para Email Empresa */}
                <EmailEmpresaES
                    propostaInfo={propostaInfo}
                    valoresTotais={valoresTotais}
                    precoFinalChaveNaMaoSIva={precoFinalChaveNaMaoSIva}
                    valorTotalCaucaoEmpresa={valorTotalCaucaoEmpresa}
                    formatarNumero={formatarNumero}
                />
                <hr></hr>
                {/* Copiar Texto para Email Particular Ingles */}
                <EmailParticularESemEN
                    propostaInfo={propostaInfo}
                    valoresTotais={valoresTotais}
                    formatarNumero={formatarNumero}
                />
                <hr></hr>
                {/* Copiar Texto para Email Empresa Ingles */}
                <EmailEmpresaESemEN
                    propostaInfo={propostaInfo}
                    valoresTotais={valoresTotais}
                    formatarNumero={formatarNumero}
                    precoFinalChaveNaMaoSIva={precoFinalChaveNaMaoSIva}
                />
            </div>

            <div className='grid gap-2'>
                <div className='flex items-center justify-between gap-2'>
                    <span className='font-semibold'>Whatsapp</span>
                    <FaWhatsapp size={20} />
                </div>
                <hr></hr>
                <WhatsappParticularES
                    dataCarros={dataCarros}
                    dataUser={dataUser}
                    propostaInfo={propostaInfo}
                    valoresTotais={valoresTotais}
                    formatarNumero={formatarNumero}
                    precoFinalChaveNaMaoCIva={precoFinalChaveNaMaoCIva}
                    valorTotalCaucaoParticular={valorTotalCaucaoParticular}
                />
                <hr></hr>
                <WhatsappEmpresaES
                    dataCarros={dataCarros}
                    dataUser={dataUser}
                    propostaInfo={propostaInfo}
                    valoresTotais={valoresTotais}
                    formatarNumero={formatarNumero}
                    precoFinalChaveNaMaoSIva={precoFinalChaveNaMaoSIva}
                />
                <hr></hr>
                <WhatsappParticularESemEN
                    dataCarros={dataCarros}
                    dataUser={dataUser}
                    propostaInfo={propostaInfo}
                    valoresTotais={valoresTotais}
                    formatarNumero={formatarNumero}
                    precoFinalChaveNaMaoCIva={precoFinalChaveNaMaoCIva}
                />
                <hr></hr>
                <WhatsappEmpresaESemEN
                    dataCarros={dataCarros}
                    dataUser={dataUser}
                    propostaInfo={propostaInfo}
                    valoresTotais={valoresTotais}
                    formatarNumero={formatarNumero}
                    precoFinalChaveNaMaoSIva={precoFinalChaveNaMaoSIva}
                />
            </div>
            
        </>
    );
}
