import React, { useState } from 'react';
import { FiCopy, FiCheck } from 'react-icons/fi';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';

export default function CopiarURLparaWhatsapp({ propostaInfo }) {
    const { t } = useTranslation();
    const [copied, setCopied] = useState(false);

    const handleCopy = async () => {
        if (!propostaInfo || !propostaInfo.linkDaProposta) {
            message.error('Erro: Nenhum link disponível.');
            return;
        }

        try {
            await navigator.clipboard.writeText(propostaInfo.linkDaProposta);
            message.success('Link copiado para a área de transferência!');
            setCopied(true);
            setTimeout(() => setCopied(false), 2000);
        } catch (error) {
            console.error("Erro ao copiar:", error);
            message.error('Erro ao copiar o link.');
        }
    };

    return (
        <div className="flex items-center gap-2">
            <label className="flex items-center gap-2 cursor-pointer">
                {copied ? (
                    <FiCheck size={18} title="Copiado!" />
                ) : (
                    <FiCopy
                        size={18}
                        title={t('gerar_proposta.copiar_url')}
                        onClick={handleCopy}
                    />
                )}
                <span className="text-sm" onClick={handleCopy}>
                    {t('gerar_proposta.copiar_url')}
                </span>
            </label>
        </div>
    );
}
