import React, { useState } from 'react';
import { Modal } from 'antd';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { useTranslation } from 'react-i18next';

const ISVComponent = ({ valoresTotais, dataUser, formatarNumero }) => {

    const { t } = useTranslation();

    const [isModalVisiblePT, setIsModalVisiblePT] = useState(false);
    const [isModalVisibleES, setIsModalVisibleES] = useState(false);

    // const formatarOuMostrarSemValor = (valor) => {
    //     return valor === '' || valor === 0 ? 'S/V' : formatarNumero(valor);
    // };

    const showModalPT = () => {
        setIsModalVisiblePT(true);
    };

    const handleOkPT = () => {
        setIsModalVisiblePT(false);
    };

    const handleCancelPT = () => {
        setIsModalVisiblePT(false);
    };


    const showModalES = () => {
        setIsModalVisibleES(true);
    };

    const handleOkES = () => {
        setIsModalVisibleES(false);
    };

    const handleCancelES = () => {
        setIsModalVisibleES(false);
    };

    // //console.log(valoresTotais.compsISV.isvReducaoAnosUsoParticulas)

    return (
        <>
            <div className='flex justify-between items-center'>
                <div className='flex items-center gap-2'>
                    <p className='text-black text-base'>{t('gerar_proposta.isv')}</p>
                    {valoresTotais.valorTotalISV !== 0 && (
                        <>
                            {dataUser.language == "pt" && (
                                <AiOutlineInfoCircle className='icon-question' onClick={showModalPT} />
                            )}
                            {dataUser.language == "es" && (
                                <AiOutlineInfoCircle className='icon-question' onClick={showModalES} />
                            )}
                        </>
                    )}
                </div>

                <p className='font-bold text-lg'>
                    {valoresTotais.valorTotalISV == 0 ? (
                        t('gerar_proposta.isento')
                    ) : (
                        `${formatarNumero(valoresTotais.valorTotalISV)}`
                    )}
                </p>


            </div>

            {/* PT */}
            <Modal
                title="Explicação do cálculo ISV"
                open={isModalVisiblePT}
                onOk={handleOkPT}
                onCancel={handleCancelPT}
                okText="Fechar"
                footer={null}
            >
                <p className="text-black text-sm flex justify-between">Componente cilindrada: <span className="font-bold text-sm">{valoresTotais.compsISV.isvcomponentecilindrada ? `${formatarNumero(valoresTotais.compsISV.isvcomponentecilindrada)}` : "Sem informação"}</span></p>
                <p className="text-black text-sm flex justify-between">Componente ambiental: <span className="font-bold text-sm">{valoresTotais.compsISV.isvcomponenteambiental ? `${formatarNumero(valoresTotais.compsISV.isvcomponenteambiental)}` : "Sem informação"}</span></p>
                <p className="text-black text-sm flex justify-between">Redução anos uso cilindrada: <span className="font-bold text-sm">{valoresTotais.compsISV.isvReducaoAnosUsoCilindrada ? `${valoresTotais.compsISV.isvReducaoAnosUsoCilindrada} €` : "Sem informação"}</span></p>
                <p className="text-black text-sm flex justify-between">Redução anos uso ambiental: <span className="font-bold text-sm">{valoresTotais.compsISV.isvReducaoAnosUsoAmbiental ? `${valoresTotais.compsISV.isvReducaoAnosUsoAmbiental} €` : "Sem informação"}</span></p>
                <p className="text-black text-sm flex justify-between">Agravamento particulas: <span className="font-bold text-sm">{valoresTotais.compsISV.isvAgravamentoParticulas} €</span></p>
                <p className="text-black text-sm flex justify-between">Redução anos uso particulas: <span className="font-bold text-sm">{valoresTotais.compsISV.isvReducaoAnosUsoParticulas} €</span></p>
                <p className="text-black text-sm flex justify-between">Taxa aplicável tabela: <span className="font-bold text-sm">{valoresTotais.compsISV.isvTaxaAplicavelTabela ? `${valoresTotais.compsISV.isvTaxaAplicavelTabela}` : "Sem informação"}</span></p>
            </Modal>

            {/* ES */}
            <Modal
                title="Explicación del cálculo IDM"
                open={isModalVisibleES}
                onOk={handleOkES}
                onCancel={handleCancelES}
                okText="Cerrar"
                footer={null}
            >
                <p className="text-black text-sm flex justify-between">Comunidad: <span className="font-bold text-sm">{valoresTotais.compsISV.isvComunidad ? `${valoresTotais.compsISV.isvComunidad}` : "Sin información"}</span></p>
                <p className="text-black text-sm flex justify-between">Valoración inicial: <span className="font-bold text-sm">{valoresTotais.compsISV.isvValoracionInicial ? `${formatarNumero(valoresTotais.compsISV.isvValoracionInicial)}` : "Sin información"}</span></p>
                <p className="text-black text-sm flex justify-between">Porcentaje a aplicar: <span className="font-bold text-sm">{valoresTotais.compsISV.isvPorcentajeAplicar ? `${valoresTotais.compsISV.isvPorcentajeAplicar} %` : "Sin información"}</span></p>
                <p className="text-black text-sm flex justify-between">Minoración: <span className="font-bold text-sm">{valoresTotais.compsISV.isvMinoracion ? `${formatarNumero(valoresTotais.compsISV.isvMinoracion)}` : "Sin información"}</span></p>
                <p className="text-black text-sm flex justify-between">Base imponible: <span className="font-bold text-sm">{valoresTotais.compsISV.isvBaseImponible ? `${formatarNumero(valoresTotais.compsISV.isvBaseImponible)}` : "Sin información"}</span></p>
                <p className="text-black text-sm flex justify-between">Reducción base imponible: <span className="font-bold text-sm">{formatarNumero(valoresTotais.compsISV.isvReduccionBaseImponible)}</span></p>
                <p className="text-black text-sm flex justify-between">Base imponible final: <span className="font-bold text-sm">{valoresTotais.compsISV.isvBaseImponibleFinal ? `${formatarNumero(valoresTotais.compsISV.isvBaseImponibleFinal)}` : "Sin información"}</span></p>
                <p className="text-black text-sm flex justify-between">Cuota tributaria: <span className="font-bold text-sm">{valoresTotais.compsISV.isvCuotaTributaria ? `${formatarNumero(valoresTotais.compsISV.isvCuotaTributaria)}` : "Sin información"}</span></p>
                <p className="text-black text-sm flex justify-between">Total a pagar: <span className="font-bold text-sm">{valoresTotais.compsISV.isvTotalPagar ? `${formatarNumero(valoresTotais.compsISV.isvTotalPagar)}` : "Sin información"}</span></p>

            </Modal>
        </>
    );
};

export default ISVComponent;
