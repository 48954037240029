import React, { useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import { FiCopy, FiCheck } from 'react-icons/fi';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';

export default function EmailEmpresaESemEN({ propostaInfo, valoresTotais, precoFinalChaveNaMaoSIva, formatarNumero }) {

    const { t } = useTranslation();

    const [copiedItem, setCopiedItem] = useState(null);

    const handleOptionClick = async (value) => {
        if (value !== 1 || !propostaInfo || !valoresTotais) return;

        const text = (
            <div>
                <div>
                    <b>{`${propostaInfo.outros.nome_proposta}`}</b>
                </div>
                <div>
                    <b>{`${propostaInfo.outros.nome_modelo}`}</b>
                </div>
                <div>{t('partilhar_email.preco_carro_en')}: {`${formatarNumero(valoresTotais.precoViaturaSemIva)}`} {t('partilhar_email.por_negociar_en')}</div>
                <div>
                    {`Registration Tax: ${valoresTotais.valorTotalISV === "0" || valoresTotais.valorTotalISV === 0
                        ? t('gerar_proposta.isento')
                        : formatarNumero(valoresTotais.valorTotalISV)
                        }`}  (Indicative result, please consult original documentation and BOE tables)
                </div>
                <div>
                    {`Road Tax: ${valoresTotais.valorTotalIUC === "0" || valoresTotais.valorTotalIUC === 0
                        ? t('gerar_proposta.isento')
                        : formatarNumero(valoresTotais.valorTotalIUC)
                        }`}
                </div>
                <div>{`${t('partilhar_email.despesas_de_importacao_en')}: ${formatarNumero(valoresTotais.taxaDeServico)}`}</div>
                <div>{`${t('partilhar_email.preco_final_chave_mao_en')}: ${formatarNumero(precoFinalChaveNaMaoSIva)}`}  (Car price + Registration Tax + Road Tax + Importrust Fee)</div>
                <div>
                    <b>Proposal Link: <a target="_blank" rel="noopener noreferrer" style={{ cursor: "pointer" }} href={propostaInfo.linkDaProposta}>
                        {`${propostaInfo.linkDaProposta}`}
                    </a></b>
                </div>
            </div>

        );

        try {
            const textString = ReactDOMServer.renderToStaticMarkup(text);
            const blob = new Blob([textString], { type: 'text/html' });
            const clipboardItem = new ClipboardItem({ "text/html": blob });

            await navigator.clipboard.write([clipboardItem]);
            message.success('Copiado!');
        } catch (error) {
            console.error("Erro ao copiar:", error);
            message.error('Erro ao copiar o conteúdo.');
        }
    };

    const handleCopy = (value) => {
        handleOptionClick(value);
        setCopiedItem(value);
        setTimeout(() => setCopiedItem(null), 2000);
    };

    return (
        <div className="flex items-center gap-2">
            <label className="flex items-center gap-2 cursor-pointer">
                {copiedItem === 1 ? (
                    <FiCheck
                        size={18}
                        title="Copiado!"
                    />
                ) : (
                    <FiCopy
                        size={18}
                        title={t('gerar_proposta.copiar_texto_para_email_en_empresa')}
                        onClick={() => handleCopy(1)}
                    />
                )}
                <span className='text-sm' onClick={() => handleCopy(1)}>{t('gerar_proposta.copiar_texto_para_email_en_empresa')}</span>
            </label>
        </div>
    );
}
