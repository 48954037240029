import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { Button, message, Popover, Tooltip } from 'antd';
import { FaEye } from 'react-icons/fa';
import CustomTable from '../Tables/CustomTables';
import CustomColumns from '../Tables/CustomColumns';
import { HiDotsVertical, HiOutlineDownload, HiPencil } from 'react-icons/hi';
import DrawerEditarDocumentosGerados from './VerDocumentos/DrawerEditarDocumentosGerados';
import { Link } from 'react-router-dom';

export default function TableDocumentosGerados({ dataUser }) {

    const { t } = useTranslation();

    const [data, setData] = useState({
        propostas: [],
        tipos_documentos: [],
        tipos_sociedades: [],
        tipos_contratos: [],
        fields: {},
        otherDocs: {},
        linkDownload: []
    });

    const [loading, setLoading] = useState(false); // Estado de loading

    const [configsTable, setConfigsTable] = useState({
        modalColunas: false,
    });

    // const [isDrawerVisibleEditarDocumento, setIsDrawerVisibleEditarDocumento] = useState(false); //Sidebar para Editar o Documento
    // const [selectedDocumento, setSelectedDocumento] = useState(null);

    const [visibleColumns, setVisibleColumns] = useState([
        // 'id_documento',
        'criado_por',
        'data_criacao',
        'nome_documento',
        'tipo_documento',
        'tipo_sociedade',
        'tipo_contrato',
        'acoes'
    ]);

    const eventColors = {
        'Particular': 'bg-blue-500',         // azul médio
        'Empresa': 'bg-red-500',             // vermelho médio
        'Autorizacao Levantamento': 'bg-green-500', // verde médio
        'Procuracao': 'bg-yellow-500',       // amarelo
        'Declaracao Circulacao': 'bg-purple-500',   // roxo médio
        'Contrato': 'bg-teal-500',           // verde-água médio
        'Seguro': 'bg-orange-500',           // laranja médio
        'Credito': 'bg-pink-500',            // rosa médio
        'Importacao': 'bg-indigo-700',       // índigo médio
        'Legalizacao': 'bg-gray-600',        // cinza médio
        'Venda': 'bg-lime-500',              // verde-limão
    };

    useEffect(() => {

        setLoading(true);

        const obterListaDocumentos = {
            limit: 30,
            user_id: dataUser.iDTokenAuthenticated,
            user_token: dataUser.saveToken,
        };

        const fetchData = async () => {

            let url = `${process.env.REACT_APP_URL_CONSULTAR_DOCUMENTOS_GERADOS}`;

            try {

                const response = await axios.get(url, { params: obterListaDocumentos });

                if (response.data.sucesso) {

                    setData({
                        propostas: response.data.data
                    });

                } else {

                    message.warning(response.data.mensagem);

                }

                setLoading(false);

            } catch (error) {
                console.error('Error occurred:', error);
            }
        };

        fetchData();
    }, [dataUser.iDTokenAuthenticated]);

    const handleDownloadFicheiros = async (fileName, extensao) => {
        const nomeCompleto = `${fileName}.${extensao}`;

        try {
            const response = await axios.get(
                `${process.env.REACT_APP_DOWNLOAD_DOS_FICHEIROS}`,
                {
                    params: {
                        token: dataUser.saveToken,
                        user_id: dataUser.iDTokenAuthenticated,
                        nome_ficheiro: nomeCompleto,
                        tipo: 'gerador_documentos'
                    },
                    responseType: 'blob',
                }
            );

            const mimeType = extensao === 'pdf' ? 'application/pdf' :
                extensao === 'docx' ? 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' :
                    'application/octet-stream';

            const url = window.URL.createObjectURL(new Blob([response.data], { type: mimeType }));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', nomeCompleto);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
            message.success('Download do ficheiro com sucesso!');
        } catch (error) {
            message.error('Falha ao fazer download do ficheiro.');
            console.error('Erro ao fazer download do ficheiro:', error);
        }
    };



    // const handleEditarDocumento = async (documento) => {

    //     setData((prevState) => ({
    //         ...prevState,
    //         tipos_documentos: documento.tipo_documento,
    //         tipos_sociedades: documento.tipo_sociedade,
    //         tipos_contratos: documento.tipo_contrato || '',
    //         fields: {},
    //         otherDocs: {}
    //     }));

    //     // Configuração dos parâmetros para a requisição
    //     const editarDocumento = {
    //         tipo_documento: documento.tipo_documento,
    //         tipo_sociedade: documento.tipo_sociedade,
    //         tipo_contrato: documento.tipo_contrato || '',
    //         id_documento: documento.id_documento,
    //         user_id: dataUser.iDTokenAuthenticated,
    //         user_token: dataUser.saveToken,
    //     };

    //     const url = `${process.env.REACT_APP_GET_EDITAR_DOCUMENTOS}`;

    //     try {
    //         // Realiza a requisição GET com os parâmetros
    //         const response = await axios.get(url, { params: editarDocumento });

    //         // //console.log(response);

    //         // Verifica a resposta e exibe o Drawer se houver sucesso
    //         if (response.data.sucesso) {
    //             setSelectedDocumento(documento); // Define o documento selecionado
    //             setIsDrawerVisibleEditarDocumento(true); // Abre o Drawer
    //             setData((prevState) => ({
    //                 ...prevState,
    //                 fields: response.data.data.doc.fields,
    //                 otherDocs: []
    //             }));
    //         } else {
    //             message.warning(response.data.mensagem);
    //         }
    //     } catch (error) {
    //         console.error('Erro ao editar documento:', error);
    //     }
    // };



    const allColumns = [
        {
            title: t('tables.id_documento'),
            key: 'id_documento',
            width: 50,
            render: (proposta) => <span>{proposta.id_documento}</span>,
        },
        {
            title: t('tables.criado_por'),
            key: 'criado_por',
            width: 100,
            render: (proposta) => <span>{proposta.user}</span>,
        },
        {
            title: t('tables.data'),
            key: 'data_criacao',
            width: 150,
            render: (proposta) => <span>{proposta.data_criacao || '-----'}</span>,
        },
        {
            title: t('tables.nome_documento'),
            key: 'nome_documento',
            width: 150,
            render: (proposta) => {
                const displayedName = `${proposta.nome_documento.slice(0, 20)}...`;

                ////console.log(proposta)

                return (
                    <Tooltip placement="topRight" title={proposta.nome_documento}>
                        <span
                            style={{ color: '#fff' }} // Estilo clicável com cor de link
                        >
                            {displayedName}
                        </span>
                    </Tooltip>
                );
            }
        },
        {
            title: t('tables.tipo_documento'),
            key: 'tipo_documento',
            width: 200,
            render: (proposta) => {
                const colorClass = eventColors[proposta.tipo_documento] || 'bg-gray-400';
                // const displayedName = `${proposta.tipo_documento.slice(0, 15)}...`;

                return (
                    <Tooltip placement="topRight" title={proposta.tipo_documento}>
                        <span className={`rounded-full ${colorClass} text-white px-3 w-auto inline-flex items-center`}>
                            {proposta.tipo_documento || '-----'}
                        </span>
                    </Tooltip>
                )
            },
        },
        {
            title: t('tables.tipo_sociedade'),
            key: 'tipo_sociedade',
            width: 150,
            render: (proposta) => {
                const colorClass = eventColors[proposta.tipo_sociedade] || 'bg-gray-400';
                return <span className={`rounded-full ${colorClass} text-white px-3 w-auto inline-flex items-center`}>{proposta.tipo_sociedade || '-----'}</span>;
            },
        },
        {
            title: t('tables.tipo_contrato'),
            key: 'tipo_contrato',
            width: 150,
            render: (proposta) => {
                const colorClass = eventColors[proposta.tipo_contrato] || 'bg-gray-400';
                return <span className={`rounded-full ${colorClass} text-white px-3 w-auto inline-flex items-center`}>{proposta.tipo_contrato || '-----'}</span>;
            },
        },
        {
            title: t('tables.acoes'),
            key: 'acoes',
            width: 50,
            render: (proposta) => (

                <div className="flex items-center gap-1 hover-style">

                    <Button
                        type="link"
                        className="flex items-center shadow-none font-bold text-black bg-white hover:text-black hover:bg-gray-100"
                        onClick={() => handleDownloadFicheiros(proposta.nome_documento, proposta.extensao_documento)}
                    >
                        <HiOutlineDownload />
                    </Button>

                    <Button
                        type="link"
                        href={`https://${proposta.link}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="flex items-center shadow-none font-bold text-black bg-white hover:text-black hover:bg-gray-100"
                    >
                        <FaEye />
                    </Button>


                    <Link to={`/gerar-documentos/?editar_id_documento=${proposta.id_documento}`}>
                        <Button
                            type="link"
                            rel="noopener noreferrer"
                            className="flex items-center shadow-none font-bold text-black bg-white hover:text-black hover:bg-gray-100 w-full"
                        >
                            <HiPencil />
                        </Button>
                    </Link>


                </div>

            ),
        },
    ].filter(Boolean);

    const columns = allColumns.filter(col => visibleColumns.includes(col.key));





    return (
        <>
            {/* <div className='flex justify-end gap-4'>

                <Button
                    type="primary"
                    onClick={() => setConfigsTable({ modalColunas: true })}
                    className='shadow-none font-bold text-black bg-white'>
                    <div className='flex items-center gap-2'>
                        <FaEye size={20} style={{ cursor: 'pointer' }} />
                        <p>{t('propostas.colunas')}</p>
                    </div>
                </Button>

            </div> */}

            <CustomTable
                columns={columns}
                data={data.propostas.map((record, index) => ({ ...record, key: record.id || index }))}
                pagination={{ pageSize: 20, showSizeChanger: false, position: ['bottomCenter'] }}
                loading={loading}
            />


            <CustomColumns
                allColumns={allColumns}
                visibleColumns={visibleColumns}
                setVisibleColumns={setVisibleColumns}
                configsTable={configsTable}
                setConfigsTable={setConfigsTable}
            />


            {/* 
            <DrawerEditarDocumentosGerados
                data={data}
                setData={setData}
                selectedDocumento={selectedDocumento}
                setSelectedDocumento={setSelectedDocumento}
                isDrawerVisibleEditarDocumento={isDrawerVisibleEditarDocumento}
                setIsDrawerVisibleEditarDocumento={setIsDrawerVisibleEditarDocumento}
                dataUser={dataUser}
            /> 
            */}


        </>
    );
}
