import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Tooltip, message } from 'antd';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import AssociarPartilharProposta from '../../../components/PerfilProposta/Associar_e_PartilharProposta';
import TabelaDadosCarroProposta from '../../../components/CalculoProposta/TabelaDadosDoCarro';
import InfoPerfilClienteHubspotProposta from '../../../components/PerfilProposta/HubspotProposta/InfoPerfilClienteHubspotProposta';
import PrecoFinalProposta from '../../../components/CalculoProposta/PrecoFinalProposta';
import Loading from '../../../components/Elements/Loading';
import FasesDaProposta from '../../../components/PerfilProposta/PerfilProposta/FasesDaProposta';
import FormCamposCalculoPropostaES from '../../../components/PerfilProposta/ES/FormCamposCalculoProposta_ISV_IUC_ES';
import DuracaoDeProcesso from '../../../components/CalculoProposta/DuracaoDeProcesso';
import { useTranslation } from 'react-i18next';
import DownloadDoPrintAnuncio from '../../../components/PerfilProposta/PerfilProposta/DownloadDeArquivos/DownloadDoPrintAnuncio';
import DownloadDasImagens from '../../../components/PerfilProposta/PerfilProposta/DownloadDeArquivos/DownloadDasImagens';


export default function PerfilPropuestaES({ dataUser }) {

    const { t } = useTranslation();

    const [loading, setLoading] = useState(true);
    const [dataCarros, setDataCarros] = useState([]);

    const [isSuccessPrintAnuncio, setIsSuccessPrintAnuncio] = useState(false); // Controle de sucesso


    const formatarNumero = (valor) => {
        if (!valor || isNaN(valor)) return '0 €';
        return new Intl.NumberFormat('pt-PT', {
            style: 'currency',
            currency: 'EUR',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        }).format(valor);
    };

    const [propostaInfo, setPropostaInfo] = useState({
        linkFora: '',
        linkDaProposta: '',
        estadoDaProposta: '',
        idProposta: '',
        standData: [],
        tipoNegocio: '',
        tipoProposta: '',
        outros: [],
        propostaExpirou: null,
        propostaDisponivelOnline: null,
        imagensParaDownload: [],
        printAnuncioParaDownload: []
    });

    const [isvIucData, setIsvIucData] = useState({
        cilindrada: 0,
        novoUsado: '',
        tipoCombustivel: '',
        emissoesCO2: '',
        dataPrimeiroRegisto: '',
        tipoDeVeiculoParaOutros: 'LigeiroPassageiros',
        tipoDeVeiculoParaInsencoes: '',
        idMarca: '',
        nomeMarca: '',
        idModelo: '',
        nomeModelo: '',
        provincia: '',
        nomeProvincia: '',
        municipio: '',
        nomeMunicipio: '',
        valorViaturaNova: '',
        valorTotalISV: 0,
        valorTotalIUC: 0
    });

    //Valores Totais
    const [valoresTotais, setValoresTotais] = useState({
        precoViaturaComIva: 0,
        precoViaturaSemIva: 0,
        taxaDeServico: 3000,
        taxaDeFinanciamento: 400,
        taxaDeFinanciamentoAtivo: 0,
        caucaoFinanciamento: 0,
        valorExpress: 849,
        valorExpressAtivo: 0,
        comissao: 0,
        valorTotalISV: 0,
        valorTotalIUC: 0,
        valorPrecoChaveNaMaoParticular: 0,
        valorPrecoChaveNaMaoEmpresa: 0,
        caucaoIvaPaisOrigem: 0,
        compsIUC: {
            iucCavalosFiscais: 0,
            iucCidade: '',
            iucMunicipio: '',
            iucPercentagemDescontoEletrico: '',
        },
        compsISV: {
            isvBaseImponible: 0,
            isvBaseImponibleFinal: 0,
            isvComunidad: '',
            isvCuotaTributaria: 0,
            isvMinoracion: 0,
            isvPorcentajeAplicar: 0,
            isvReduccionBaseImponible: 0,
            isvTotalPagar: 0,
            isvValoracionInicial: 0
        }
    });

    const [hubspotInfo, setHubspotInfo] = useState({
        idDoDeal: '',
        nomeDoClienteDeal: '',
        emailDoClienteDeal: '',
        etapaDoDeal: '',
        pipelineDoDeal: '',
    });


    // Parsea o nome da proposta da URL usando URLSearchParams
    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search); // Usa URLSearchParams.
        const nomeProposta = searchParams.get('n'); // Obtém o nome da proposta.
        const isNew = searchParams.get('new') === 'true'; // Obtém o parametro só para mostrar a notificação de proposta criada.

        if (isNew) {
            message.success('¡Propuesta creada con éxito!')
        }

        if (nomeProposta) {
            // Definir a URL de requisição com base no nomeProposta e dados do usuário
            const requestUrl = `${process.env.REACT_APP_GET_PROPOSTA_ES}?n=${nomeProposta}&user_id=${dataUser.iDTokenAuthenticated}&token=${dataUser.saveToken}&lang=${dataUser.language}`;

            // Inicia o carregamento
            setLoading(true);

            // Faz a requisição à API usando Axios
            axios.get(requestUrl)
                .then((response) => {

                    const allDataCarro = response.data.data.carro;
                    const allHubspot = response.data.data.proposta.hubspot;
                    const allValores = response.data.data.simulacao;
                    const allValoresExtra = response.data.data.simulacao_extras;
                    const allProposta = response.data.data.proposta;
                    const allStand = response.data.data.stand;


                    setDataCarros(allDataCarro);// Atualiza os dados dos carros com a resposta

                    setPropostaInfo((prevData) => ({
                        ...prevData,
                        linkFora: allProposta.link_proposta,
                        linkDaProposta: allProposta.link_proposta_view,
                        estadoDaProposta: allProposta.estado_proposta_hubspot,
                        tipoNegocio: allProposta?.tipo_contrato || "",
                        tipoProposta: allProposta?.tipo_proposta,
                        idProposta: allProposta.id_proposta,
                        dataPropostaAceite: allProposta.data_deal_hubspot_fechado,
                        standData: allStand,
                        outros: allProposta,
                        propostaExpirou: allProposta.expirou,
                        propostaDisponivelOnline: allProposta.indisponivel_online,
                        imagensParaDownload: allProposta.imagens_locais_proposta,
                        printAnuncioParaDownload: allProposta.print_proposta[0],
                    }));

                    //console.log(allValores)


                    setIsvIucData((prevData) => ({
                        ...prevData,
                        cilindrada: allValores.cilindrada,
                        novoUsado: allValores.novo,
                        tipoCombustivel: allValores.tipo_de_combustivel,
                        emissoesCO2: allValores.emissoes_numerico,
                        dataPrimeiroRegisto: allValores.data_registo,
                        tipoDeVeiculoParaOutros: allValores.tipo_de_veiculo,
                        tipoDeVeiculoParaInsencoes: allValores.tipo_de_isencoes,
                        idMarca: allValores.id_marca,
                        nomeMarca: allValores.nome_marca,
                        idModelo: allValores.id_modelo,
                        nomeModelo: allValores.nome_modelo,
                        provincia: allValores.id_cidade,
                        nomeProvincia: allValores.nome_cidade,
                        municipio: allValores.id_municipio,
                        nomeMunicipio: allValores.nome_municipio,
                        valorViaturaNova: allValores.valor_viatura_nova,
                        valorTotalISV: allValores.isv,
                        valorTotalIUC: allValores.iuc,
                    }));



                    setValoresTotais((prevData) => ({
                        ...prevData,
                        precoViaturaComIva: allDataCarro.preco_final_carro,
                        precoViaturaSemIva: allDataCarro.preco_final_carro_sem_iva,
                        taxaDeServico: allValores.taxadeservico,
                        taxaDeFinanciamento: allValores.comissao_credito,
                        taxaDeFinanciamentoAtivo: allValores.check_comissao_credito,
                        valorExpress: allValores.comissao_express,
                        valorExpressAtivo: allValores.check_comissao_express,
                        comissao: allValores.comissaodeservico,
                        valorTotalISV: allValores.isv,
                        valorTotalIUC: allValores.iuc,
                        valorPrecoChaveNaMaoParticular: allValores.valor_chavenamao_particular,
                        valorPrecoChaveNaMaoEmpresa: allValores.valor_chavenamao_empresa,
                        compsIUC: {
                            iucCavalosFiscais: allValoresExtra.iuc_cavalos_fiscais,
                            iucCidade: allValoresExtra.iuc_cidade,
                            iucMunicipio: allValoresExtra.iuc_municipio,
                            iucPercentagemDescontoEletrico: allValoresExtra.iuc_percentagem_desconto_eletrico,
                        },
                        compsISV: {
                            isvBaseImponible: allValoresExtra.isv_base_imponible,
                            isvBaseImponibleFinal: allValoresExtra.isv_base_imponible_final,
                            isvComunidad: allValoresExtra.isv_comunidad,
                            isvCuotaTributaria: allValoresExtra.isv_cuota_tributaria,
                            isvMinoracion: allValoresExtra.isv_minoracion,
                            isvPorcentajeAplicar: allValoresExtra.isv_porcentaje_aplicar,
                            isvReduccionBaseImponible: allValoresExtra.isv_reduccion_base_imponible,
                            isvTotalPagar: allValoresExtra.isv_total_pagar,
                            isvValoracionInicial: allValoresExtra.isv_valoracion_inicial,

                        }
                    }));

                    if (allHubspot?.deal) {
                        setHubspotInfo((prevData) => ({
                            ...prevData,
                            idDoDeal: allHubspot.deal.id_deal_hubspot,
                            nomeDoClienteDeal: allHubspot.contacto.firstname,
                            emailDoClienteDeal: allHubspot.contacto.email,
                            etapaDoDeal: allHubspot.deal.dealstage_name,
                            pipelineDoDeal: allHubspot.deal.pipeline_name,
                        }));
                    }

                    // setLoading(false);

                })
                .catch((error) => {
                    console.error('Erro ao buscar dados da proposta:', error); // Trata erros
                })
                .finally(() => {
                    setLoading(false); // Para o loading depois da requisição
                });
        }
    }, [dataUser.iDTokenAuthenticated]); // Removi nomeProposta das dependências, pois é tratado internamente



    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const isNew = searchParams.get('new') === 'true';

        ////console.log(propostaInfo.idProposta)

        if (isNew && !isSuccessPrintAnuncio && propostaInfo.idProposta && !propostaInfo.printAnuncioParaDownload) {
            const fetchData = async () => {
                // setLoading(true);
                try {
                    const requestUrl = `${process.env.REACT_APP_GET_VALIDACAO_PRINT_IMAGENS}?&user_id=${dataUser.iDTokenAuthenticated}&token=${dataUser.saveToken}&lang=${dataUser.language}&id_proposta=${propostaInfo.idProposta}&tipo=print_proposta`;

                    const response = await axios.get(requestUrl);

                    //console.log(response.data); // Verifica a resposta no console

                    if (response.data.sucesso) {
                        setIsSuccessPrintAnuncio(true); // Para os pedidos futuros
                        message.success(response.data.mensagem)
                        setPropostaInfo((prevData) => ({
                            ...prevData,
                            printAnuncioParaDownload: response.data.print_proposta,
                        }));
                    }
                } catch (error) {
                    console.error('Erro ao buscar dados:', error);
                } finally {
                    // setLoading(false);
                }
            };

            // Configura o intervalo de 3 segundos
            const intervalId = setInterval(fetchData, 3000);

            // Limpa o intervalo ao desmontar ou em caso de sucesso
            return () => clearInterval(intervalId);
        } else {
            setIsSuccessPrintAnuncio(true); // Para os pedidos futuros
        }

    }, [isSuccessPrintAnuncio, propostaInfo.idProposta]); // O useEffect depende do estado `isSuccess`


    //console.log(isvIucData)


    return (
        <div className='w-full flex flex-col justify-center items-center gap-4'>
            {/* Exibe o loading enquanto os dados estão sendo carregados */}
            {loading ? (
                <Loading />
            ) : (
                <>
                    {/* Exibe os dados do carro se existirem */}
                    {dataCarros ? (
                        <div className="w-full text-white grid gap-4">

                            <div>
                                <div className='card grid gap-4'>
                                    <AssociarPartilharProposta
                                        propostaInfo={propostaInfo}
                                        dataUser={dataUser}
                                        dataCarros={dataCarros}
                                        setHubspotInfo={setHubspotInfo}
                                        hubspotInfo={hubspotInfo}
                                        valoresTotais={valoresTotais}
                                        setValoresTotais={setValoresTotais}
                                        setPropostaInfo={setPropostaInfo}
                                        isvIucData={isvIucData}
                                        setIsvIucData={setIsvIucData}
                                        formatarNumero={formatarNumero}
                                    />
                                </div>
                            </div>


                            <div className='flex flex-col sm:flex-row gap-4 w-full h-full'>
                                <div className='w-full sm:w-3/5'>
                                    {/* Tabela com os dados do carro */}
                                    <TabelaDadosCarroProposta
                                        dataCarros={dataCarros}
                                        isvIucData={isvIucData}  // Certifique-se de passar corretamente os dados
                                        urlCarroLink={dataCarros.urlCarroLink}
                                        dataUser={dataUser}
                                        propostaInfo={propostaInfo}
                                        valoresTotais={valoresTotais}
                                    />
                                </div>

                                <div className="w-full sm:w-2/5 flex flex-col gap-4">

                                    <div className='card grid gap-4'>

                                        <div className={`flex items-center gap-4`}>
                                            <span className='text-lg font-semibold'>Información de la Propuesta y del Anuncio</span>
                                        </div>

                                        <hr />


                                        <div className='card-white grid gap-2'>

                                            <div className='flex justify-between items-center'>
                                                <p className="text-black text-base">
                                                    Fecha de Creación
                                                </p>
                                                <div className="flex justify-between gap-4">
                                                    <div className="flex items-center gap-4">
                                                        <div className="font-semibold px-3 w-auto text-black">
                                                            <span className="ml-2">{propostaInfo.outros.data_criacao}</span>
                                                        </div>
                                                    </div>
                                                </div >
                                            </div >

                                            <div className='flex justify-between items-center'>
                                                <p className="text-black text-base">
                                                    Fecha de Expiración
                                                </p>
                                                <div className="flex justify-between gap-4">
                                                    <div className="flex items-center gap-4">
                                                        <div className="font-semibold px-3 w-auto text-black">
                                                            <span className="ml-2">{propostaInfo.outros.data_expira}</span>
                                                        </div>
                                                    </div>
                                                </div >
                                            </div >

                                           {propostaInfo.estadoDaProposta !== "nao_associada" && (
                                                <>
                                                    <FasesDaProposta
                                                        propostaInfo={propostaInfo}
                                                    />
                                                </>
                                            )} 

                                            <div className='flex justify-between items-center'>
                                                <p className="text-black text-base">
                                                    Estado del Anuncio
                                                </p>
                                                <div className="flex justify-between gap-4">
                                                    <div className="flex items-center gap-4">
                                                        {propostaInfo.propostaDisponivelOnline === 0 && (
                                                            <div className="rounded-full bg-[#156813] font-semibold px-3 w-auto inline-flex items-center text-white">
                                                                {/* <RiRadioButtonLine /> */}
                                                                <span>{t('tables.proposta_dispon_online')}</span>
                                                            </div>
                                                        )}
                                                        {propostaInfo.propostaDisponivelOnline === 1 && (
                                                            <div className="rounded-full bg-[#b63911] font-semibold px-3 w-auto inline-flex items-center text-white">
                                                                {/* <RiRadioButtonLine /> */}
                                                                <span>{t('tables.proposta_indispon_online')}</span>
                                                            </div>
                                                        )}
                                                        {propostaInfo.propostaExpirou === 1 && (
                                                            <div className="rounded-full bg-[#b63911] font-semibold px-3 w-auto inline-flex items-center text-white">
                                                                {/* <RiRadioButtonLine /> */}
                                                                <span>{t('tables.proposta_indispon_intern')}</span>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div >
                                            </div >



                                            <hr />

                                            <DownloadDoPrintAnuncio
                                                isSuccessPrintAnuncio={isSuccessPrintAnuncio}
                                                dataUser={dataUser}
                                                propostaInfo={propostaInfo}
                                                setPropostaInfo={setPropostaInfo}
                                            />
                                            <DownloadDasImagens
                                                dataUser={dataUser}
                                                propostaInfo={propostaInfo}
                                                setPropostaInfo={setPropostaInfo}
                                            />
                                        </div>

                                    </div>

                                    {hubspotInfo.idDoDeal !== "" && (
                                        <InfoPerfilClienteHubspotProposta
                                            hubspotInfo={hubspotInfo}
                                        />
                                    )}

                                    <FormCamposCalculoPropostaES
                                        isvIucData={isvIucData}
                                    />

                                    <PrecoFinalProposta
                                        valoresTotais={valoresTotais}
                                        setValoresTotais={setValoresTotais}
                                        dataUser={dataUser}
                                    />

                                    <DuracaoDeProcesso
                                        valoresTotais={valoresTotais}
                                        propostaInfo={propostaInfo}
                                    />

                                </div>
                            </div>
                        </div>
                    ) : null}
                </>
            )}
        </div>
    );
}
