import React, { useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import { FiCopy, FiCheck } from 'react-icons/fi';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';

export default function CopiarURLcomLinkparaEmail({ propostaInfo, valoresTotais }) {

    const { t } = useTranslation();

    const [copiedItem, setCopiedItem] = useState(null);

    const handleOptionClick = async (value) => {
        if (value !== 1 || !propostaInfo || !valoresTotais) return;

        const text = (
            <div><a href={propostaInfo.linkDaProposta}>{`${propostaInfo.linkDaProposta}`}</a></div>
        );

        try {
            const textString = ReactDOMServer.renderToStaticMarkup(text);
            const blob = new Blob([textString], { type: 'text/html' });
            const clipboardItem = new ClipboardItem({ "text/html": blob });

            await navigator.clipboard.write([clipboardItem]);
            message.success('Copiado!');
        } catch (error) {
            console.error("Erro ao copiar:", error);
            message.error('Erro ao copiar o conteúdo.');
        }
    };

    const handleCopy = (value) => {
        handleOptionClick(value);
        setCopiedItem(value);
        setTimeout(() => setCopiedItem(null), 2000);
    };

    return (
        <div className="flex items-center gap-2">
            <label className="flex items-center gap-2 cursor-pointer">
                {copiedItem === 1 ? (
                    <FiCheck
                        size={18}
                        title="Copiado!"
                    />
                ) : (
                    <FiCopy
                        size={18}
                        title={t('gerar_proposta.copiar_url_com_link')}
                        onClick={() => handleCopy(1)}
                    />
                )}
                <span className='text-sm' onClick={() => handleCopy(1)}>{t('gerar_proposta.copiar_url_com_link')}</span>
            </label>
        </div>
    );
}
