import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import InputCalculoProposta from '../../../components/CalculoProposta/InputCalculoProposta';
import TabelaDadosCarroProposta from '../../../components/CalculoProposta/TabelaDadosDoCarro';
import FormCalculoProposta from '../../../components/CalculoProposta/PT/FormCalculoProposta_ISV_IUC';
import TaxaDeServico from '../../../components/CalculoProposta/TaxaDeServicoProposta';
import TaxaDeFinanciamento from '../../../components/CalculoProposta/TaxaDeFinanciamentoProposta';
import PrecoFinalProposta from '../../../components/CalculoProposta/PrecoFinalProposta';
import Loading from '../../../components/Elements/Loading';
import RegistarPropostaForm from '../../../components/CalculoProposta/PT/FormRegistarProposta';
import TaxaDeExpressProposta from '../../../components/CalculoProposta/TaxaDeExpressProposta';
import { useTranslation } from 'react-i18next';
import { Button, message } from 'antd';
import { HiRefresh } from 'react-icons/hi';
import DuracaoDeProcesso from '../../../components/CalculoProposta/DuracaoDeProcesso';
import SimuladorDeCreditoDentroDaProposta from '../../../components/CalculoProposta/PT/SimuladorCreditoDentroDaProposta/SimuladorDeCreditoDentroDaProposta';
import FormCalculoPropostaCamioes from '../../../components/CalculoProposta/PT/FormCalculoProposta_ISV_IUC_Camioes';
import TabelaDadosCamiaoProposta from '../../../components/CalculoProposta/TabelaDadosDoCamiao';

export default function SimulacaoProposta({
    dataUser
}) {


    const { t } = useTranslation();

    const [loading, setLoading] = useState(false);
    const [urlCarroLink, setUrlCarroLink] = useState('');

    const [tipoUrlCarroLink, setTipoUrlCarroLink] = useState('normal');

    const [dataCarros, setDataCarros] = useState(null);
    const [linkToken, setLinkToken] = useState('');

    const [estadoDeErro, setEstadoDeErro] = useState(false);

    const [propostaInfo, setPropostaInfo] = useState({
        standData: [],
        linkFora: '',
        outros: [],
        erro: '',
    });

    const [isvIucData, setIsvIucData] = useState({
        cilindrada: 0,
        novoUsado: '',
        tipoCombustivel: '',
        emissoesCO2: '',
        hibridoPlugIn: 0,
        wltp: 0,
        particulas: 0,
        dataPrimeiroRegisto: '',
        tipoDeVeiculoParaOutros: 'LigeiroPassageiros',
        tipoDeVeiculoParaInsencoes: '',
        outrosServicos: '',
        valorTotalISV: 0,
        valorTotalIUC: 0,
        hibridoMais25kmAutonomia: 0,
        emissoesPorAI: 0,
        // emissoesPorAICheck: 0,

        configuracaoEixos: '',
        pesoBruto: '',
        tipoSuspensao: '',

    });


    const [isvValido, setIsvValido] = useState(true); //bloqueia o button de criar proposta

    const [triggerCalculo, setTriggerCalculo] = useState({
        cilindrada: 0,
        novoUsado: '',
        tipoCombustivel: '',
        emissoesCO2: '',
        hibridoPlugIn: 0,
        wltp: 0,
        particulas: 0,
        dataPrimeiroRegisto: '',
        tipoDeVeiculoParaOutros: 'LigeiroPassageiros',
        tipoDeVeiculoParaInsencoes: '',
        outrosServicos: '',
        hibridoMais25kmAutonomia: 0,
        emissoesPorAI: 0,
        // emissoesPorAICheck: 0,

        configuracaoEixos: '',
        pesoBruto: '',
        tipoSuspensao: '',

    });



    const [valoresTotais, setValoresTotais] = useState({
        precoViaturaComIva: 0,
        precoViaturaSemIva: 0,
        validaSeValorNaoTemIva: 0,
        taxaDeServico: 3000,
        taxaDeFinanciamento: 400,
        taxaDeFinanciamentoAtivo: 0,
        caucaoFinanciamento: 0,
        valorExpress: 849,
        valorExpressAtivo: 0,
        comissao: 0,
        valorTotalISV: 0,
        valorTotalIUC: 0,
        // valorTotalISV2025: null,
        valorPrecoChaveNaMaoParticular: 0,
        valorPrecoChaveNaMaoEmpresa: 0,
        caucaoIvaPaisOrigem: 0,
        compsIUC: {
            iucCilindrada: 0,
            iucCO2: 0,
            iucAdicionalCO2: 0,
            iucCoeficienteAnoMatriculo: 0,
            iucAdicionalGasoleo: 0
        },
        compsISV: {
            isvcomponentecilindrada: 0,
            isvcomponenteambiental: 0,
            isvReducaoAnosUsoCilindrada: 0,
            isvReducaoAnosUsoAmbiental: 0,
            isvAgravamentoParticulas: 0,
            isvReducaoAnosUsoParticulas: 0,
            isvTaxaAplicavelTabela: '',
        }
    });


    // Função para buscar dados do carro
    const fetchData = useCallback(async (url) => {
        setLoading(true);

        try {
            const requestUrl = `${process.env.REACT_APP_PEDIDO_PROPOSTA}?request=${encodeURIComponent(url)}&user_id=${dataUser.iDTokenAuthenticated}&token=${dataUser.saveToken}&lang=${dataUser.language}`;
            const response = await axios.get(requestUrl);

            if (response.data.sucesso) {
                //console.log(response);

                // const tipoDeUrl = response.data.tipo_url;

                // setTipoUrlCarroLink(tipoDeUrl);
                tipoDeUrlNormal(response);
                // if (tipoDeUrl !== 'normal') {
                //     tipoDeUrlCamiao(response);
                // } else {
                //     tipoDeUrlNormal(response);
                // }

            } else {
                message.error(response.data.mensagem);
                setUrlCarroLink('');
                setLoading(false);
            }

        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        }
    }, [dataUser.iDTokenAuthenticated, dataUser.saveToken, dataUser.language]);

    // Função para processar URL normal
    const tipoDeUrlNormal = (response) => {
        const carro = response.data.data.carro;
        const stand = response.data.data.stand;
        const linkToken = response.data.link_token;
        const calculo = response.data.data.simulacao;

        setDataCarros(carro);
        setLinkToken(linkToken);

        setPropostaInfo((prevData) => ({
            ...prevData,
            standData: stand,
            outros: stand,
            propostaExpirado: null,
            propostaDisponivelOnline: null
        }));

        // Verifica o tipo de combustível híbrido e atribui o valor correto
        let tipoCombustivel = calculo.combustivel_para_simulacao;
        if (tipoCombustivel === "Híbrido (Gasolina/Eletrico)") {
            tipoCombustivel = "Gasolina";
        } else if (tipoCombustivel === "Híbrido (Gasóleo/Eletrico)") {
            tipoCombustivel = "Gasoleo";
        }

        const allData = {
            cilindrada: calculo.cilindrada_numerico,
            novoUsado: calculo.novo === 1 ? "Novo" : "Usado",
            tipoCombustivel,
            emissoesCO2: calculo.emissoes_numerico,
            hibridoPlugIn: calculo.hibrido,
            wltp: calculo.wltp,
            particulas: calculo.tipo_combustivel_traduzido === "Gasoleo" ? "1" : "0",
            dataPrimeiroRegisto: calculo.data_registo_para_simulacao,
            tipoDeVeiculoParaOutros: calculo.tipo_de_veiculo,
            tipoDeVeiculoParaInsencoes: '',
            outrosServicos: '',
            emissoesPorAI: calculo.emissoes_numerico_por_ai,
            // emissoesPorAICheck: 0,
        };

        setIsvIucData(allData);  // Atualiza formulário com dados do carro
        setTriggerCalculo(allData);  // Atualiza dados para cálculo

        setValoresTotais((prevData) => ({
            ...prevData,
            precoViaturaComIva: carro.preco_final_carro,
            precoViaturaSemIva: carro.preco_final_carro_sem_iva,
            validaSeValorNaoTemIva: carro.tem_iva,
        }));

        setLoading(false);
    };

    // Função para processar URL de camião
    // const tipoDeUrlCamiao = (response) => {

    //     const carro = response.data.data.carro;
    //     const stand = response.data.data.stand;
    //     const linkToken = response.data.link_token;
    //     const calculo = response.data.data.simulacao;

    //     setDataCarros(carro);
    //     setLinkToken(linkToken);

    //     setPropostaInfo((prevData) => ({
    //         ...prevData,
    //         standData: stand,
    //         outros: stand,
    //         propostaExpirado: null,
    //         propostaDisponivelOnline: null
    //     }));

    //     setIsvIucData((prevData) => ({
    //         ...prevData,
    //         configuracaoEixos: calculo.configuracao_eixos,
    //         dataPrimeiroRegisto: calculo.data_registo_para_simulacao,
    //         pesoBruto: calculo.peso_bruto,
    //         tipoSuspensao: calculo.tipo_suspensao,
    //     }));

    //     setTriggerCalculo((prevData) => ({
    //         ...prevData,
    //         configuracaoEixos: calculo.configuracao_eixos,
    //         dataPrimeiroRegisto: calculo.data_registo_para_simulacao,
    //         pesoBruto: calculo.peso_bruto,
    //         tipoSuspensao: calculo.tipo_suspensao,
    //     }));

    //     setValoresTotais((prevData) => ({
    //         ...prevData,
    //         precoViaturaComIva: calculo.preco_final_carro,
    //         precoViaturaSemIva: calculo.preco_final_carro_sem_iva,
    //         validaSeValorNaoTemIva: 0,
    //     }));

    //     setLoading(false);
    // };


    useEffect(() => {
        if (urlCarroLink) {
            setLoading(true);
            fetchData(urlCarroLink);
            setPropostaInfo((prevData) => ({
                ...prevData,
                linkFora: urlCarroLink,
            }));

        }
    }, [urlCarroLink, fetchData]);



    // Extrair transformações para funções auxiliares
    const normalizarNovoUsado = (novoUsado) => novoUsado == "Novo" ? 1 : 0;

    const normalizarTipoDeTestes = (wltp) => wltp == 1 ? 'WLTP' : 'NEDC';

    const normalizarTipoCombustivel = (combustivel) => {
        switch (combustivel) {
            case "Híbrido (Gasolina/Eletrico)": return "Gasolina";
            case "Híbrido (Gasóleo/Eletrico)": return "Gasoleo";
            default: return combustivel;
        }
    };

    const determinarTipoVeiculo = (hibridoPlugIn, tipoDeVeiculoParaOutros) => {
        return hibridoPlugIn == 1 ? "HibridosPlugIn" : tipoDeVeiculoParaOutros;
    };

    // Função para construir URLs
    const construirUrls = (params) => {
        const {
            novo_usado_int, tipoDeTestes, tipoDeVeiculoFinal, tipoDeCombustivel,
            particulas, emissoesGases, cilindrada, dataDeRegisto,
            tipoDeVeiculoParaInsencoes, hibridoMais25kmAutonomia
        } = params;

        return {
            isvUrl: `${process.env.REACT_APP_PEDIDO_ISV}?novo=${novo_usado_int}&tipo_de_testes_co2=${tipoDeTestes}&tipo_carro=${tipoDeVeiculoFinal}&combustivel=${tipoDeCombustivel}&particulas=${particulas}&co2=${emissoesGases}&cilindrada=${cilindrada}&ano=${dataDeRegisto}&isencao=${tipoDeVeiculoParaInsencoes}&hibrido_mais_25km_autonomia=${hibridoMais25kmAutonomia}`,
            iucUrl: `${process.env.REACT_APP_PEDIDO_IUC}?cilindrada=${cilindrada}&ano=${dataDeRegisto}&combustivel=${tipoDeCombustivel}&co2=${emissoesGases}&tipo_carro=${tipoDeVeiculoFinal}&tipo_de_testes_co2=${tipoDeTestes}&novo=${novo_usado_int}`
        };
    };


    // const construirUrlsCamioes = (params) => {
    //     //console.log(params);

    //     const {
    //         tipo_suspensao, peso_bruto,
    //         dataDeRegisto, configuracao_eixos
    //     } = params;

    //     return {
    //         iucUrl: `${process.env.REACT_APP_PEDIDO_IUC_CAMIOES}?categoria=c&tipo_suspensao=${tipo_suspensao}&peso=${peso_bruto}&ano=${dataDeRegisto}&eixos=${configuracao_eixos}`
    //     };
    // };

    // Efeito refatorado
    useEffect(() => {
        const fetchISVIUCData = async () => {
            try {

                console.log(triggerCalculo)
                // Normalizar dados
                const novo_usado_int = normalizarNovoUsado(triggerCalculo.novoUsado);
                const tipoDeTestes = normalizarTipoDeTestes(triggerCalculo.wltp);
                const tipoDeCombustivel = normalizarTipoCombustivel(triggerCalculo.tipoCombustivel);
                const tipoDeVeiculoFinal = determinarTipoVeiculo(
                    triggerCalculo.hibridoPlugIn,
                    triggerCalculo.tipoDeVeiculoParaOutros
                );

                const params = {
                    novo_usado_int,
                    tipoDeTestes,
                    tipoDeVeiculoFinal,
                    tipoDeCombustivel,
                    particulas: triggerCalculo.particulas,
                    emissoesGases: triggerCalculo.emissoesCO2,
                    cilindrada: triggerCalculo.cilindrada,
                    dataDeRegisto: triggerCalculo.dataPrimeiroRegisto,
                    tipoDeVeiculoParaInsencoes: triggerCalculo.tipoDeVeiculoParaInsencoes || '',
                    hibridoMais25kmAutonomia: triggerCalculo.hibridoMais25kmAutonomia ? 1 : 0,
                    emissoesPorAI: triggerCalculo.emissoesPorAI
                };

                console.log(params)

                const urls = construirUrls(params);

                // Fazer os dois pedidos de uma vez
                const [isvResponse, iucResponse] = await Promise.all([
                    axios.get(urls.isvUrl),
                    axios.get(urls.iucUrl)
                ]);

                if (isvResponse.data.sucesso && iucResponse.data.sucesso) {
                    processarRespostasComSucesso(isvResponse, iucResponse);
                } else {
                    processarRespostasComErro(isvResponse, iucResponse);
                }
            } catch (error) {
                console.error('Erro ao buscar ISV ou IUC:', error);
                message.error('Ocorreu um erro ao processar o cálculo. Por favor, tente novamente.');

                // Resetar estados em caso de erro
                setIsvValido(false);
                atualizarEstadosComErro();
            }
        };



        // const fetchIUCDataCamioes = async () => {
        //     try {

        //         const params = {
        //             dataDeRegisto: triggerCalculo.dataPrimeiroRegisto,
        //             configuracao_eixos: triggerCalculo.configuracaoEixos,
        //             peso_bruto: triggerCalculo.pesoBruto,
        //             tipo_suspensao: triggerCalculo.tipoSuspensao,
        //         };

        //         const urls = construirUrlsCamioes(params);

        //         //console.log(params)

        //         // Fazer os dois pedidos de uma vez
        //         const [iucResponse] = await Promise.all([
        //             axios.get(urls.iucUrl)
        //         ]);

        //         let isvResponse = 0


        //         //console.log('iucResponse', iucResponse)

        //         if (iucResponse.data.sucesso) {
        //             processarRespostasComSucesso(isvResponse, iucResponse);
        //         } else {
        //             processarRespostasComErro(iucResponse);
        //         }

        //     } catch (error) {
        //         // console.error('Erro ao buscar IUC:', error);
        //         // message.error('Ocorreu um erro ao processar o cálculo. Por favor, tente novamente.');

        //         // // Resetar estados em caso de erro
        //         // setIsvValido(false);
        //         // atualizarEstadosComErro();
        //     }
        // };



        const processarRespostasComSucesso = (isvResponse, iucResponse) => {

            //console.log(isvResponse)
            //console.log(iucResponse)

            let valorISV
            let valorISVTaxas

            if (isvResponse == 0) {
                valorISV = 0
                valorISVTaxas = 0
            } else {
                valorISV = isvResponse.data.data.isv;
                valorISVTaxas = isvResponse.data.data.mensagem.taxas;
            }

            // const valorISV = isvResponse.data.data.isv;
            const valorIUC = iucResponse.data.data.iuc;
            console.log(iucResponse)

            // const valorISVTaxas = isvResponse.data.data.mensagem.taxas;
            const valorIUCTaxas = 0;




            // Atualizar o estado principal com todos os valores
            atualizarValoresTotais(valorISV, valorIUC, valorISVTaxas, valorIUCTaxas);
            setIsvValido(true);

            // Verificar avisos de isenção
            const avisos = isvResponse.data.data.mensagem.avisos_isencao;
            //console.log('avisosavisos', avisos)
            if (avisos.length !== 0) {
                message.warning(avisos);
                setPropostaInfo(prev => ({ ...prev, erro: avisos }));
            } else {
                setPropostaInfo(prev => ({ ...prev, erro: '' }));
            }
        };

        const processarRespostasComErro = (isvResponse, iucResponse) => {

            //console.log(isvResponse)
            //console.log(iucResponse)

            if (!isvResponse.data.sucesso) {
                const mensagemErro = isvResponse.data.mensagem || 'Erro no cálculo do ISV';
                message.error(mensagemErro);
                setPropostaInfo(prev => ({ ...prev, erro: mensagemErro }));
                setIsvValido(false);

                resetarValoresISV();
            }

            if (!iucResponse.data.sucesso) {
                message.error(iucResponse.data.mensagem || 'Erro no cálculo do IUC');
            }
        };

        const atualizarEstadosComErro = () => {
            // Resetar o estado de IsvIucData
            setIsvIucData(prev => ({
                ...prev,
                valorTotalISV: 0,
                valorTotalIUC: 0
            }));

            // Resetar os valores totais e componentes
            setValoresTotais(prev => ({
                ...prev,
                valorTotalISV: 0,
                valorTotalIUC: 0,
                compsISV: {
                    isvcomponentecilindrada: 0,
                    isvcomponenteambiental: 0,
                    isvReducaoAnosUsoCilindrada: 0,
                    isvReducaoAnosUsoAmbiental: 0,
                    isvAgravamentoParticulas: 0,
                    isvReducaoAnosUsoParticulas: 0,
                    isvTaxaAplicavelTabela: 0
                },
                compsIUC: {
                    iucCilindrada: 0,
                    iucCO2: 0,
                    iucAdicionalCO2: 0,
                    iucCoeficienteAnoMatriculo: 0,
                    iucAdicionalGasoleo: 0
                }
            }));

            // Atualizar o estado de erro na proposta
            setPropostaInfo(prev => ({
                ...prev,
                erro: 'Erro ao processar o cálculo de ISV/IUC.'
            }));
        }

        const atualizarValoresTotais = (valorISV, valorIUC, valorISVTaxas, valorIUCTaxas) => {

            //console.log(valorISV)
            //console.log(valorIUC)
            //console.log(valorISVTaxas)
            //console.log(valorIUCTaxas)

            setIsvIucData(prev => ({
                ...prev,
                valorTotalISV: valorISV,
                valorTotalIUC: valorIUC
            }));

            setValoresTotais(prev => {
                const novoEstado = {
                    ...prev,
                    valorTotalISV: valorISV,
                    valorTotalIUC: valorIUC
                };

                if (valorIUCTaxas) {
                    novoEstado.compsIUC = {
                        iucCilindrada: valorIUCTaxas.cilindrada,
                        iucCO2: valorIUCTaxas.co2,
                        iucAdicionalCO2: valorIUCTaxas.adicional_co2,
                        iucCoeficienteAnoMatriculo: valorIUCTaxas.coeficiente_ano_matricula,
                        iucAdicionalGasoleo: valorIUCTaxas.adicional_gasoleo
                    };
                }

                if (valorISVTaxas) {
                    novoEstado.compsISV = {
                        isvcomponentecilindrada: valorISVTaxas.componente_cilindrada,
                        isvcomponenteambiental: valorISVTaxas.componente_ambiental,
                        isvReducaoAnosUsoCilindrada: valorISVTaxas.reducao_anos_uso_cilindrada,
                        isvReducaoAnosUsoAmbiental: valorISVTaxas.reducao_anos_uso_ambiental,
                        isvAgravamentoParticulas: valorISVTaxas.agravamento_particulas,
                        isvReducaoAnosUsoParticulas: valorISVTaxas.reducao_anos_uso_particulas,
                        isvTaxaAplicavelTabela: valorISVTaxas.taxa_aplicavel_tabela
                    };
                }

                return novoEstado;
            });
        };

        const resetarValoresISV = () => {
            setValoresTotais(prev => ({
                ...prev,
                valorTotalISV: 0,
                compsISV: {
                    isvcomponentecilindrada: 0,
                    isvcomponenteambiental: 0,
                    isvReducaoAnosUsoCilindrada: 0,
                    isvReducaoAnosUsoAmbiental: 0,
                    isvAgravamentoParticulas: 0,
                    isvReducaoAnosUsoParticulas: 0,
                    isvTaxaAplicavelTabela: 0
                }
            }));
        };

        // Verificar se temos todos os dados necessários para fazer o cálculo
        const dadosCompletos = [
            triggerCalculo.cilindrada,
            triggerCalculo.novoUsado,
            triggerCalculo.tipoCombustivel,
            triggerCalculo.emissoesCO2,
            (triggerCalculo.tipoDeVeiculoParaInsencoes || triggerCalculo.tipoDeVeiculoParaOutros)
        ].every(Boolean);

        const dadosCompletosCamioes = [
            triggerCalculo.pesoBruto,
        ].every(Boolean);

        if (dadosCompletos && tipoUrlCarroLink == 'normal') {
            fetchISVIUCData();
        } else if (dadosCompletosCamioes && tipoUrlCarroLink !== 'normal') {
            message.error('Erro. Contacte a equipa Tech!');
            // fetchIUCDataCamioes()
        }

    }, [triggerCalculo]);



    const handleFormChange = (allValues) => {

        setIsvIucData((prevState) => ({
            ...prevState,
            ...allValues
        }));

        setTriggerCalculo((prevState) => ({
            ...prevState,
            ...allValues
        }));

    };


    return (
        <div className='w-full flex flex-col justify-center items-center gap-4'>

            {!urlCarroLink && (
                <div className='card grid-cols-1 w-full h-full'>
                    <InputCalculoProposta
                        urlCarroLink={urlCarroLink}
                        setUrlCarroLink={setUrlCarroLink}
                        dataUser={dataUser}
                    />
                </div>
            )}

            {loading ? (
                <>
                    <Loading />
                    {estadoDeErro && (
                        <>
                            <div className='flex items-center justify-center'>
                                <Button
                                    type="primary"
                                    onClick={() => {
                                        setLoading(false);
                                        setEstadoDeErro(false);
                                        setUrlCarroLink('');
                                    }} // Corrigi a sintaxe do onClick com uma função de seta
                                    className='shadow-none font-bold text-black bg-white'
                                >
                                    <div className='flex items-center gap-2'>
                                        <HiRefresh /> <p>{t('menu.voltar_a_pesquisar')}</p>
                                    </div>
                                </Button>
                            </div>

                        </>
                    )}
                </>
            ) : (
                <>
                    {(urlCarroLink && dataCarros) && (
                        <div className="w-full text-white grid gap-4">
                            <div>
                                <div className='card grid gap-4'>
                                    <RegistarPropostaForm
                                        isvIucData={isvIucData}
                                        valoresTotais={valoresTotais}
                                        setUrlCarroLink={setUrlCarroLink}
                                        dataUser={dataUser}
                                        dataCarros={dataCarros}
                                        linkToken={linkToken}
                                        setIsvIucData={setIsvIucData}
                                        setValoresTotais={setValoresTotais}
                                        setTriggerCalculo={setTriggerCalculo}
                                        isvValido={isvValido}
                                    />
                                </div>
                            </div>

                            <div className='flex flex-col sm:flex-row gap-4 w-full h-full'>
                                <div className='w-full sm:w-3/5 flex flex-col gap-4'>

                                    {tipoUrlCarroLink !== 'normal' ? (
                                        <TabelaDadosCamiaoProposta
                                            dataCarros={dataCarros}
                                            isvIucData={isvIucData}
                                            propostaInfo={propostaInfo}
                                            tipoUrlCarroLink={tipoUrlCarroLink}
                                        />
                                    ) : (
                                        <TabelaDadosCarroProposta
                                            dataCarros={dataCarros}
                                            isvIucData={isvIucData}
                                            propostaInfo={propostaInfo}
                                            tipoUrlCarroLink={tipoUrlCarroLink}
                                        />
                                    )}

                                    {/* {dataUser.iDTokenAuthenticated == 1 && (
                                        <SimuladorDeCreditoDentroDaProposta
                                            dataCarros={dataCarros}
                                            isvIucData={isvIucData}
                                            urlCarroLink={urlCarroLink}
                                            dataUser={dataUser}
                                            propostaInfo={propostaInfo}
                                            valoresTotais={valoresTotais}
                                        />
                                    )} */}

                                </div>

                                <div className="w-full sm:w-2/5 flex flex-col gap-4">

                                    {tipoUrlCarroLink !== 'normal' ? (
                                        <FormCalculoPropostaCamioes
                                            propostaInfo={propostaInfo}
                                            isvIucData={isvIucData}
                                            onFormChange={handleFormChange}
                                            valoresTotais={valoresTotais}
                                            setValoresTotais={setValoresTotais}
                                        />
                                    ) : (
                                        <FormCalculoProposta
                                            propostaInfo={propostaInfo}
                                            isvIucData={isvIucData}
                                            onFormChange={handleFormChange}
                                            setIsvIucData={setIsvIucData}
                                        />
                                    )}

                                    <TaxaDeServico
                                        valoresTotais={valoresTotais}
                                        setValoresTotais={setValoresTotais}
                                        dataUser={dataUser}
                                    />

                                    <TaxaDeExpressProposta
                                        valoresTotais={valoresTotais}
                                        setValoresTotais={setValoresTotais}
                                        dataUser={dataUser}
                                    />

                                    <TaxaDeFinanciamento
                                        valoresTotais={valoresTotais}
                                        setValoresTotais={setValoresTotais}
                                    />

                                    <PrecoFinalProposta
                                        valoresTotais={valoresTotais}
                                        setValoresTotais={setValoresTotais}
                                        dataUser={dataUser}
                                    />

                                    {propostaInfo.outros.estimativa_entrega && (
                                        <DuracaoDeProcesso
                                            valoresTotais={valoresTotais}
                                            propostaInfo={propostaInfo}
                                        />
                                    )}

                                </div>

                            </div>
                        </div>
                    )}
                </>
            )}
        </div>
    );
}
