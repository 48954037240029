import { Tooltip } from 'antd';
// import { useEffect, useState } from 'react';
// import { useTranslation } from 'react-i18next';

export default function CardDestaqueMinimalista({ icon, comparadorVisible, descricao, subCategorias, text, number, style, comparadorNumero, comparadorPercentagem, link }) {

    // Define a cor com base no valor de `comparadorPercentagem`
    const percentColor = comparadorPercentagem < 0 ? 'text-red-500' : 'text-green-500';


    const formatarNumero = (valor) => {
        if (!valor || isNaN(valor)) return '0';
        return new Intl.NumberFormat('pt-PT', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        }).format(valor);
    };

    return (
        <div className={`${style ? 'border-card bg-black card-cinza' : 'card'} text-white grid content-start gap-3 h-full ${link ? 'hover-border-red hover:shadow-lg transition-shadow duration-300' : ''}`}>

            <img
                src={icon}
                alt="Ícone de automação"
                style={{
                    width: '28px',
                    height: '28px',
                    border: '1px solid rgb(255, 255, 255)',
                    borderRadius: '50px',
                    lineHeight: '37px',
                    fontSize: '18px',
                    padding: '4px'
                }}
            />

            <div className='flex justify-between items-center gap-2'>
                <Tooltip title={descricao}>
                    <p className='text-sm font-medium'>{text}</p>
                </Tooltip>
                <div className='flex items-center gap-2'>
                    <span className='text-lg font-bold text-center'>
                        {formatarNumero(number)}
                    </span>
                    {comparadorVisible && comparadorNumero !== 0 ? (
                        <span className={`text-sm font-bold text-center ${percentColor}`}>
                            {comparadorNumero}%
                        </span>
                    ) : null}
                </div>
            </div>

            {subCategorias.length > 0 && (
                <hr></hr>
            )}

            {subCategorias.map((item, index) => (
                <div key={index} className='flex justify-between items-center gap-2'>
                    <div className='flex items-center gap-2'>
                        <p className='text-xs'>{item.nome}</p>
                    </div>
                    <div className='flex items-center gap-2'>
                        <span className='text-center'>
                            {formatarNumero(item.total)}
                        </span>
                        {(comparadorVisible && item.diferenca_percentagem > 0) && (
                            <span className={`text-center ${item.diferenca_percentagem < 0 ? 'text-red-500' : 'text-green-500'}`}>
                                {item.diferenca_percentagem}%
                            </span>
                        )}
                    </div>
                </div>
            ))}
        </div>
    );
}
