import React, { useState, useEffect } from 'react';
import { Drawer, Select, Input, DatePicker, Button, message } from 'antd';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import dayjs from 'dayjs';

const { Option } = Select;
const { RangePicker } = DatePicker;

export default function DrawerFiltrosDaAutomacao({ filters, setFilters, drawerVisibleFilters, setDrawerVisibleFilters, dataUser }) {
    const { t } = useTranslation();
    const [filtrosData, setFiltrosData] = useState([]);
    const [filtrosEscondidos, setFiltrosEscondidos] = useState({});

    useEffect(() => {
        const obterFiltros = {
            user_id: dataUser?.iDTokenAuthenticated || null,
            token: dataUser?.saveToken || '',
            tipo: 'automacoes'
        };

        const fetchData = async () => {
            const url = 'https://backoffice.importrust.com/apidev/filtros/get';

            try {
                const response = await axios.get(url, { params: obterFiltros });

                if (response.data.sucesso) {
                    const fetchedFilters = response.data.data.filtros || [];
                    setFiltrosData(fetchedFilters);
                    setFiltrosEscondidos(response.data.data.filtros_escondidos || {});

                    const defaultFilters = fetchedFilters.reduce((acc, field) => {
                        if (field.tipo === 'date_range' && field.options) {
                            acc[field.options[0].value] = field.options[0].default_value;
                            acc[field.options[1].value] = field.options[1].default_value;
                        } else if (field.default_value) {
                            acc[field.value] = field.default_value;
                        }
                        return acc;
                    }, {});

                    setFilters((prevFilters) => ({ ...prevFilters, ...defaultFilters }));
                } else {
                    message.warning(response.data.mensagem);
                }
            } catch (error) {
                message.error(error);
            }
        };

        fetchData();
    }, [dataUser?.iDTokenAuthenticated, dataUser?.saveToken]);

    const closeDrawerFilters = () => {
        setDrawerVisibleFilters(false);
    };

    const handleFilterChange = (value, key) => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            [key]: value
        }));
    };

    const handleDateChange = (dates, dateStrings) => {
        setFilters((prev) => ({
            ...prev,
            data_inicio: dateStrings[0] || null,
            data_fim: dateStrings[1] || null,
        }));
    };

    const renderField = (field, index) => {
        // Obtém o valor do select principal (categoria de envio)
        const categoriaSelecionada = filters["categoria_de_envio_analytics"];

        // Verifica se a categoria está ativa e se contém automacoes_operacoes_cliente
        const categoriaAtiva = Array.isArray(categoriaSelecionada) && categoriaSelecionada.includes("automacoes_operacoes_cliente");

        // Obtém os valores selecionados no segundo select
        const automacaoSelecionada = filters[field.value];

        // Verifica se a chave automacaoSelecionada existe dentro de filtrosEscondidos.categoria_de_envio_analytics
        const exibirOpcoes =
            categoriaAtiva &&
            automacaoSelecionada &&
            filtrosEscondidos.categoria_de_envio_analytics &&
            filtrosEscondidos.categoria_de_envio_analytics[automacaoSelecionada];

        //console.log("Categoria Selecionada:", categoriaSelecionada);
        //console.log("Automação Selecionada:", automacaoSelecionada);
        //console.log("Filtros Escondidos:", filtrosEscondidos);
        //console.log("Exibir Opções:", exibirOpcoes);

        switch (field.tipo) {
            case 'select':
                return (
                    <div className='flex flex-col mb-6' key={index}>
                        <p className='mb-2 font-semibold'>{field.label}</p>
                        <Select
                            mode={field.multiple ? "multiple" : undefined}
                            placeholder={field.label}
                            className='select-filters capitalize'
                            onChange={(value) => handleFilterChange(value, field.value)}
                            value={filters[field.value] || (field.multiple ? [] : '')}
                            style={{ width: '100%' }}
                        >
                            {field.select_opcoes.map((option) => (
                                <Option key={option.value} value={option.value} className="capitalize">
                                    {option.nome}
                                </Option>
                            ))}
                        </Select>

                        {/* Renderiza o segundo select se as condições forem atendidas */}
                        {exibirOpcoes && (
                            <div className="mt-4">
                                <p className='mb-2 font-semibold'>{exibirOpcoes[0][0]?.label}</p>
                                <Select
                                    mode="multiple"
                                    placeholder="Selecione a subcategoria"
                                    className='select-filters capitalize'
                                    onChange={(value) => handleFilterChange(value, "tipo_email")}
                                    value={filters["tipo_email"] || []}
                                    style={{ width: '100%' }}
                                >
                                    {exibirOpcoes[0][0]?.select_opcoes.map((option) => (
                                        <Option key={option.value} value={option.value} className="capitalize">
                                            {option.nome}
                                        </Option>
                                    ))}
                                </Select>
                            </div>
                        )}
                    </div>
                );

            case 'string':
                return (
                    <div className='flex flex-col mb-6' key={index}>
                        <p className='mb-2 font-semibold'>{field.label}</p>
                        <Input
                            placeholder={field.label}
                            value={filters[field.value] || ''}
                            onChange={(e) => handleFilterChange(e.target.value, field.value)}
                        />
                    </div>
                );

            case 'date_range':
                return (
                    <div className='flex flex-col mb-6' key={index}>
                        <p className='mb-2 font-semibold'>{field.label}</p>
                        <RangePicker
                            style={{ width: '100%' }}
                            format="YYYY-MM-DD"
                            placeholder={['Data de início', 'Data de fim']}
                            value={[
                                filters['data_inicio'] ? dayjs(filters['data_inicio']) : null,
                                filters['data_fim'] ? dayjs(filters['data_fim']) : null
                            ]}
                            onChange={handleDateChange}
                        />
                    </div>
                );

            default:
                return null;
        }
    };


    return (
        <Drawer
            title={t('tables.filtros')}
            placement="right"
            className='detalhes-de-log-email'
            onClose={closeDrawerFilters}
            open={drawerVisibleFilters}
        >
            {Array.isArray(filtrosData) && filtrosData.length > 0 ? (
                filtrosData.map((item, index) => renderField(item, index))
            ) : (
                <p className="text-center">Nenhum filtro disponível</p>
            )}
        </Drawer>
    );
}
