import React, { useEffect, useState } from 'react';
import { Button, Drawer, Timeline, Tooltip, message } from 'antd';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import TitleSection from '../../components/Elements/Title';
import { useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import Loading from '../../components/Elements/Loading';
import { FaHubspot } from 'react-icons/fa';
import PropostasPerfilDeal from '../../components/Hubspot/PerfilDeal/PropostasPerfilDeal';
import { FiExternalLink } from 'react-icons/fi';
import DocumentacaoPerfilDeal from '../../components/Hubspot/PerfilDeal/DocumentacaoPerfilDeal';
import { MdHistory, MdOutlineEmail } from "react-icons/md";
import CustomTable from '../../components/Tables/CustomTables';
import DrawerDetalhesDaAutomacao from '../../components/Automacoes/DrawerDetalhesDaAutomacao';


export default function PerfilDealHubspot({ dataUser }) {

    const { t } = useTranslation();

    const navigate = useNavigate();

    const location = useLocation();
    const parsed = queryString.parse(location.search); // Parâmetros
    const id_deal = parsed.id_deal; // ID do Deal get parametro

    const [drawerVisibleEmailDetail, setDrawerVisibleEmailDetail] = useState(false); // Controla a visibilidade do Detalhes do Email
    const [drawerEmailDetailData, setDrawerEmailDetailData] = useState(null); // Armazena o registro do Detalhes do Email

    const [drawerVisibleViews, setDrawerVisibleViews] = useState(false);


    const [data, setData] = useState({
        idDeal: '',
        dealsData: [],
        infoDealsData: [],
        propostasDealsData: [],
        documentosData: [],
        emailsData: []
    });

    const [drawerHistoricoRejeicao, setDrawerHistoricoRejeicao] = useState(false); // Open a drawer do Filtros


    useEffect(() => {

        if (id_deal) {
            const url = `${process.env.REACT_APP_GET_PROCURAR_DEALS}?token=${dataUser.saveToken}&user_id=${dataUser.iDTokenAuthenticated}&id_deal=${id_deal}&lang=${dataUser.language}&live`;

            axios.get(url)
                .then((response) => {
                    if (response.data.sucesso) {
                        setData((prevData) => ({
                            ...prevData,
                            idDeal: id_deal,
                            dealsData: response.data.deal,
                            infoDealsData: response.data.deal.properties,
                            propostasDealsData: response.data.deal.propostas,
                            documentosData: response.data.deal.documentacao,
                            emailsData: response.data.deal.emails,
                        }));
                    } else {
                        message.error(response.data.mensagem)
                        setTimeout(() => navigate('/hubspot'), 1000);
                    }
                })
                .catch((error) => {
                    console.error('Error occurred:', error);
                });
        }

    }, [id_deal, dataUser.saveToken, dataUser.iDTokenAuthenticated]);


    // Função para abrir o Drawer do Historico de Rejeicao 
    const showDrawerHistoricoRejeicao = () => {
        setDrawerHistoricoRejeicao(true);
    };

    //Corre todas as propostas e encontra a proposta aceite
    const propostaAceiteExistente = data.propostasDealsData.some(
        item => item.estado_proposta_hubspot === 'proposta_aceite'
    );


    // Função para abrir o Drawer dos Detalhes de Email e definir os dados do registro
    const showDrawerVisibleEmailDetail = (record) => {

        const obterDetalhes = {
            id_log: record.id_log,
            user_id: dataUser.iDTokenAuthenticated,
            user_token: dataUser.saveToken,
        };

        axios.get(`${process.env.REACT_APP_DETALHES_DO_LOGS_DE_EMAILS}`, { params: obterDetalhes })
            .then((response) => {
                setDrawerEmailDetailData(response.data.data);
                setDrawerVisibleEmailDetail(true);
            })
            .catch((error) => {
                setDrawerVisibleEmailDetail(false);
            });

    };


    const eventColors = {
        [t('automacoes_drawer.eventos.enviado')]: 'bg-blue-400',       // Azul para "Enviado"
        [t('automacoes_drawer.eventos.entregue')]: 'bg-green-400',    // Verde para "Entregado"
        [t('automacoes_drawer.eventos.falha')]: 'bg-red-600',          // Rojo para "Fallo"
        [t('automacoes_drawer.eventos.bloqueado')]: 'bg-red-600',      // Rojo para "Bloqueado"
        [t('automacoes_drawer.eventos.aberto')]: 'bg-yellow-400',     // Amarillo para "Abierto"
        [t('automacoes_drawer.eventos.clique')]: 'bg-purple-400',        // Púrpura para "Clic"
        [t('automacoes_drawer.eventos.unsubscribe')]: 'bg-gray-400',  // Gris para "Darse de baja"
        [t('automacoes_drawer.eventos.adiado')]: 'bg-gray-400',  // Gris para "Darse de baja"
        [t('automacoes_drawer.eventos.sales')]: 'bg-red-600',
        [t('automacoes_drawer.eventos.contacto')]: 'bg-blue-400',
        [t('automacoes_drawer.eventos.operações')]: 'bg-green-500',
    };


    // const allColumns = [
    //     {
    //         title: t('tables.data_evento'),
    //         dataIndex: 'enviado_em',
    //         key: 'enviado_em',
    //     },
    //     {
    //         title: t('tables.pipeline'),
    //         dataIndex: 'pipeline',
    //         key: 'pipeline',
    //         render: (text) => {
    //             const translatedText = t(`automacoes_drawer.eventos.${text.toLowerCase()}`);
    //             const colorClass = eventColors[translatedText] || 'bg-gray-400';
    //             return (
    //                 <div className={`rounded-full ${colorClass} text-white px-3 w-auto inline-flex items-center`}>
    //                     <span className='flex gap-2 items-center'>
    //                         {translatedText}
    //                     </span>
    //                 </div>
    //             );
    //         }
    //     },
    //     {
    //         title: t('tables.template_name'),
    //         dataIndex: 'tipo_email',
    //         key: 'tipo_email',
    //     },
    //     {
    //         title: t('tables.assunto'),
    //         dataIndex: 'assunto',
    //         key: 'assunto',
    //         render: (text, record) => { // Corrigido: Mudar `record` para `(text, record)`
    //             const displayedName = `${text.slice(0, 100)}...`; // Use `text` diretamente para acessar o valor da coluna

    //             return (
    //                 <Tooltip placement="topRight" title={text}>
    //                     <span
    //                         style={{ cursor: 'pointer', color: '#fff' }} // Estilo para mostrar que é clicável
    //                         onClick={() => showDrawerVisibleEmailDetail(record)} // Ao clicar, chama a função para abrir o Drawer com o registro completo
    //                     >
    //                         {displayedName}
    //                     </span>
    //                 </Tooltip>
    //             );
    //         }
    //     },
    // ].filter(Boolean); // Filtra colunas undefined


    if (!data.dealsData.id) {
        return (
            <Loading />
        );
    }

    return (
        <>
            {data.dealsData ? (
                <div className='card-hubspot grid gap-4'>
                    <TitleSection
                        title={t('perfil_do_deal.titulo_principal')}
                        description=""
                    />
                    <div className="grid grid-cols-2 sm:grid-cols-3 gap-2">
                        <p className='text-sm text-black font-semibold'>ID: <br /><span className='text-lg text-white font-semibold'>{data.infoDealsData.hs_object_id}</span></p>
                        <p className='text-sm text-black font-semibold'>{t('hubspot.nome')}: <br /><span className='text-lg text-white font-semibold capitalize'>{data.infoDealsData.dealname}</span></p>
                        <p className='text-sm text-black font-semibold'>{t('hubspot.email')}: <br /><span className='text-lg text-white font-semibold'>{data.dealsData?.contacto?.properties?.email}</span></p>
                        <p className='text-sm text-black font-semibold'>{t('hubspot.estado')}: <br /><span className='text-lg text-white font-semibold'>{data.infoDealsData.dealstage}</span></p>
                        <p className='text-sm text-black font-semibold'>{t('hubspot.pipeline')}: <br /><span className='text-lg text-white font-semibold'>{data.infoDealsData.pipeline}</span></p>
                        <p className='text-sm text-black font-semibold'>{t('hubspot.data_de_criacao')}: <br /><span className='text-lg text-white font-semibold'>{data.dealsData.createdAt}</span></p>
                        <p className='text-sm text-black font-semibold'>{t('hubspot.data_de_ultima_atualizacao')}: <br /><span className='text-lg text-white font-semibold'>{data.dealsData.updatedAt}</span></p>
                        {data.documentosData?.info?.tipo_contrato && (
                            <p className='text-sm text-black font-semibold'>{t('hubspot.tipo_contrato')}: <br /><span className='text-lg text-white font-semibold capitalize'>{data.documentosData?.info?.tipo_contrato}</span></p>
                        )}
                    </div>

                    <hr className='borda-white' />

                    <div className='flex justify-end gap-4'>
                        <Button href={data.infoDealsData.link_hubspot} target='_blank' type="primary" className="flex items-center gap-2 bg-white text-black font-bold rounded">
                            <FaHubspot />
                            {t('hubspot.aceder_ao_hubspot')}
                        </Button>

                        {data?.emailsData?.length > 0 && (
                            <Button
                                onClick={() => setDrawerVisibleViews(true)}
                                type="primary"
                                className="flex items-center gap-2 bg-white text-black font-bold rounded"
                            >
                                <MdOutlineEmail />
                                {t('perfil_do_deal.emails')}
                            </Button>
                        )}
                    </div>
                </div>
            ) : null}


            {propostaAceiteExistente && (
                <div className="card grid gap-4">
                    <TitleSection
                        title={t('perfil_do_deal_docs.titulo_principal')}
                        description={data.documentosData ? `${t('hubspot.documentacao_disponivel')}` : `${t('hubspot.documentacao_nao_enviada')}`}
                    />
                    {data.documentosData && (
                        <>
                            <DocumentacaoPerfilDeal
                                data={data}
                                setData={setData}
                                dataUser={dataUser}
                                drawerHistoricoRejeicao={drawerHistoricoRejeicao}
                                setDrawerHistoricoRejeicao={setDrawerHistoricoRejeicao}
                            />
                        </>
                    )}
                    <div className='flex justify-end gap-4'>
                        {data.documentosData?.historico_rejeicoes.length > 0 && (
                            <Button
                                type="primary"
                                onClick={showDrawerHistoricoRejeicao}
                                className="flex items-center gap-2 bg-white text-black font-bold rounded"
                            >
                                <MdHistory />
                                {t('hubspot.historico_documentacao')}
                            </Button>
                        )}
                        <Button href={data.dealsData.link_documentacao} target='_blank' type="primary" className="flex items-center gap-2 bg-white text-black font-bold rounded">
                            <FiExternalLink />
                            {t('hubspot.abrir_documentacao')}
                        </Button>
                    </div>
                </div>
            )}


            <div className="card grid gap-4">
                <div>
                    <TitleSection
                        title={t('perfil_do_deal.propostas')}
                        description={data.propostasDealsData.length > 0 ? `${data.propostasDealsData.length} ${t('perfil_do_deal.propostas_disponiveis')}` : `${t('perfil_do_deal.nenhuma_propostas_disponiveis')}`}
                    />
                </div>
                {data?.propostasDealsData?.length > 0 && (
                    <>
                        <PropostasPerfilDeal
                            data={data}
                            dataUser={dataUser}
                        />
                    </>
                    

                    
                )}
            </div>


            {/* <div className="card grid gap-4">
                <div>
                    <TitleSection
                        title={t('perfil_do_deal.emails')}
                        description={data?.emailsData?.length > 0 ? `${data.emailsData.length} ${t('perfil_do_deal.tem_email')}` : `${t('perfil_do_deal.nenhum_email')}`}

                    />
                </div>
                {data?.emailsData?.length > 0 && (
                    <>
                        <CustomTable
                            columns={allColumns}
                            data={data.emailsData.map((record, index) => ({ ...record, key: record.id || index }))}
                            pagination={false}
                        />

                        <DrawerDetalhesDaAutomacao
                            drawerVisibleEmailDetail={drawerVisibleEmailDetail}
                            setDrawerVisibleEmailDetail={setDrawerVisibleEmailDetail}
                            drawerEmailDetailData={drawerEmailDetailData}
                            setDrawerEmailDetailData={setDrawerEmailDetailData}
                            dataUser={dataUser}
                            eventColors={eventColors}
                        />
                    </>
                )}
            </div> */}

            <Drawer
                title={t('perfil_do_deal.emails')}
                placement="right"
                width={500}
                className="detalhes-de-log-email"
                onClose={() => setDrawerVisibleViews(false)}
                open={drawerVisibleViews}
            >
                <div className="flex flex-col mb-6">
                    <Timeline>
                        {data.emailsData
                            .slice()
                            // .reverse()
                            .map((acesso, index) => {
                                //console.log(acesso); // Agora está dentro de um bloco de código
                                const translatedText = acesso.post_type
                                const colorClass = eventColors[translatedText] || 'bg-gray-400';

                                return (
                                    <Timeline.Item key={index} >
                                        <div className='grid gap-2'>
                                            <p>
                                                <strong>Data</strong>
                                                <br /> {acesso.enviado_em}
                                            </p>
                                            <p>
                                                <strong>Pipeline</strong>
                                                <br />
                                                <div className={`rounded-full ${colorClass} text-white px-3 w-auto inline-flex items-center`}>
                                                    <span className='flex gap-2 items-center'>
                                                        {translatedText}
                                                    </span>
                                                </div>
                                            </p>
                                            <p>
                                                <strong>Tipo</strong>
                                                <br /> {acesso.tipo_email}
                                            </p>
                                            <p
                                                onClick={() => showDrawerVisibleEmailDetail(acesso)}
                                            >
                                                <strong>Assunto</strong>
                                                <br /> <span className='cursor-pointer hover:underline'>{acesso.assunto}</span>
                                            </p>
                                        </div>

                                    </Timeline.Item>
                                );
                            })}
                    </Timeline>
                </div>
            </Drawer>

            <DrawerDetalhesDaAutomacao
                drawerVisibleEmailDetail={drawerVisibleEmailDetail}
                setDrawerVisibleEmailDetail={setDrawerVisibleEmailDetail}
                drawerEmailDetailData={drawerEmailDetailData}
                setDrawerEmailDetailData={setDrawerEmailDetailData}
                dataUser={dataUser}
                eventColors={eventColors}
            />

        </>
    );
}
