import React, { useState } from 'react';
import { Button, Drawer, List, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { FaEye, FaEyeSlash } from 'react-icons/fa'; // Importa os ícones do react-icons
import axios from 'axios';
import { HiOutlineDownload } from 'react-icons/hi';


export default function DrawerDetalhesDaAutomacao({ setDrawerVisibleEmailDetail, setDrawerEmailDetailData, drawerVisibleEmailDetail, drawerEmailDetailData, dataUser, eventColors }) {

    const { t } = useTranslation();

    const [isExpanded, setIsExpanded] = useState(false); // Estado para controlar o tamanho do email

    // Função para fechar o Drawer dos Detalhes de Email
    const closeDrawerVisibleEmailDetail = () => {
        setDrawerVisibleEmailDetail(false);
        setDrawerEmailDetailData(null); // Limpa os dados ao fechar o Drawer
    };

    // Mostrar esconder o botao do email
    const handleToggleExpand = () => {
        setIsExpanded(!isExpanded); // Alterna entre expandido e não expandido
    };


    const handleReenviarEmailFalha = () => {
        const dataReenviarEmailFalha = {
            id_log: drawerEmailDetailData.id_log,
            user_id: dataUser.iDTokenAuthenticated,
            token: dataUser.saveToken,
        };

        axios.post(`${process.env.REACT_APP_REENVIAR_EMAIL_FALHA}`, dataReenviarEmailFalha)
            .then((response) => {

                if (response.data.sucesso) {
                    message.success(response.data.mensagem);

                    setDrawerEmailDetailData((prevRecord) => ({
                        ...prevRecord,
                        can_force_email: response.data.can_force_email,
                        can_force_email_reason: response.data.can_force_email_reason,
                    }));

                } else {
                    message.error(response.data.mensagem);
                }
            })
            .catch((error) => {
                message.error('Erro. Tente novamente!');
            });
    };


    const handleDownloadFicheiros = async (fileName, extensao) => {

        //console.log('fileName', fileName)
        //console.log('extensao', extensao)
        
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_DOWNLOAD_DOS_FICHEIROS}`,
                {
                    params: {
                        token: dataUser.saveToken,
                        user_id: dataUser.iDTokenAuthenticated,
                        nome_ficheiro: fileName,
                        tipo: 'log_emails',
                        id_log: drawerEmailDetailData.id_log
                    },
                    responseType: 'blob',
                }

            );


            const mimeType = extensao === 'pdf' ? 'application/pdf' :
                extensao === 'docx' ? 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' :
                    'application/octet-stream';

            const url = window.URL.createObjectURL(new Blob([response.data], { type: mimeType }));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
            message.success('Download do ficheiro com sucesso!');

        } catch (error) {
            message.error('Falha ao fazer download do ficheiro.');
            console.error('Erro ao fazer download do ficheiro:', error);
        }
    };

    // //console.log(drawerEmailDetailData)

    // const handleDownloadFicheiros = async (fileName) => {
    //     // const nomeCompleto = `${fileName}.${extensao}`;


    //     // //console.log(nomeCompleto)

    //     try {
    //         const response = await axios.get(
    //             `${process.env.REACT_APP_DOWNLOAD_DOS_FICHEIROS}`,
    //             {
    //                 params: {
    //                     token: dataUser.saveToken,
    //                     user_id: dataUser.iDTokenAuthenticated,
    //                     nome_ficheiro: fileName,
    //                     tipo: 'log_emails',
    //                     id_log: drawerEmailDetailData.id_log,
    //                 },
    //                 responseType: 'blob',
    //             }
    //         );

    //         // Verifica se a resposta é realmente um arquivo blob
    //         if (response.headers['content-type'] !== 'application/octet-stream' && !response.data.size) {
    //             const reader = new FileReader();
    //             reader.onload = () => {
    //                 const responseText = reader.result;
    //                 try {
    //                     const jsonResponse = JSON.parse(responseText);
    //                     if (jsonResponse.suceso) {
    //                         message.success('Download do ficheiro com sucesso!');
    //                     } else {
    //                         message.error(jsonResponse.mensagem || 'Falha ao processar o download.');
    //                     }
    //                 } catch (error) {
    //                     message.error('Erro inesperado ao fazer download do ficheiro.');
    //                     console.error('Erro ao interpretar a resposta:', error);
    //                 }
    //             };
    //             reader.readAsText(response.data);
    //             return;
    //         }

    //         // Se a resposta for um blob, continua com o download
    //         const mimeType = extensao === 'pdf'
    //             ? 'application/pdf'
    //             : extensao === 'docx'
    //                 ? 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    //                 : 'application/octet-stream';

    //         const downloadUrl = window.URL.createObjectURL(new Blob([response.data], { type: mimeType }));
    //         const link = document.createElement('a');
    //         link.href = downloadUrl;
    //         link.setAttribute('download', nomeCompleto);

    //         document.body.appendChild(link);
    //         link.click();
    //         document.body.removeChild(link);
    //         window.URL.revokeObjectURL(downloadUrl);

    //         message.success('Download do ficheiro com sucesso!');
    //     } catch (error) {
    //         message.error('Falha ao fazer download do ficheiro.');
    //         console.error('Erro ao fazer download do ficheiro:', error);
    //     }
    // };

//console.log(drawerEmailDetailData)

    return (
        <Drawer
            title={t('automacoes_drawer.detalhe_email')}
            placement="right"
            className="detalhes-de-log-email"
            width={800}
            onClose={closeDrawerVisibleEmailDetail}
            open={drawerVisibleEmailDetail}
        >
            {drawerEmailDetailData && (
                <div className="grid gap-4">
                    <div className="grid gap-2">
                        <p><strong>{t('automacoes_drawer.email.de')}:</strong> {drawerEmailDetailData.from_email}</p>
                        <p><strong>{t('automacoes_drawer.email.para')}:</strong> {drawerEmailDetailData.to_email}</p>
                        <p><strong>{t('automacoes_drawer.email.assunto')}:</strong> {drawerEmailDetailData.subject}</p>
                        <p><strong>{t('automacoes_drawer.email.template')}:</strong> {drawerEmailDetailData.template_name}</p>
                        <p><strong>{t('automacoes_drawer.email.pais')}:</strong> {drawerEmailDetailData.pais}</p>
                    </div>

                    <hr />

                    {drawerEmailDetailData.can_force_email !== undefined && (
                        drawerEmailDetailData.can_force_email ? (
                            <>
                                <div className="flex gap-2 items-center justify-between bg-blue-100 text-blue-600 p-4 rounded-lg">
                                    <p><strong>{t('automacoes_drawer.email.deseja_forcar_reenvio')}</strong></p>
                                    <Button
                                        type="primary"
                                        className="flex gap-2 items-center bg-blue-400 text-white font-bold rounded"
                                        onClick={handleReenviarEmailFalha}
                                    >
                                        {t('automacoes_drawer.email.reenviar_email')}
                                    </Button>
                                </div>
                                <hr />
                            </>
                        ) : (
                            <>
                                <div className="flex gap-2 items-center justify-between bg-blue-100 text-blue-600 p-4 rounded-lg">
                                    <p><strong>{drawerEmailDetailData.can_force_email_reason}</strong></p>
                                </div>
                                <hr />
                            </>
                        )
                    )}

                    {drawerEmailDetailData.body !== "" && (
                        <>
                            <div
                                dangerouslySetInnerHTML={{ __html: drawerEmailDetailData.body }}
                                style={{
                                    borderRadius: '10px',
                                    height: isExpanded ? '100%' : '150px',
                                    overflow: 'hidden',
                                    position: 'relative',
                                    transition: 'height 0.3s ease',
                                }}
                            />
                            {!isExpanded && (
                                <div
                                    style={{
                                        position: 'absolute',
                                        bottom: 0,
                                        left: 0,
                                        right: 0,
                                        height: '50px',
                                        background: 'linear-gradient(transparent, rgba(255, 255, 255, 1))',
                                    }}
                                />
                            )}

                            <div className="flex items-center justify-center">
                                <Button
                                    type="primary"
                                    className="shadow-none font-bold text-black bg-white"
                                    onClick={handleToggleExpand}
                                >
                                    <div className="flex items-center gap-2">
                                        {isExpanded ? <><FaEyeSlash /> {t('automacoes_drawer.acoes.esconder')}</> : <><FaEye /> {t('automacoes_drawer.acoes.ver_mais')}</>}
                                    </div>
                                </Button>
                            </div>

                            <hr />
                        </>
                    )}


                    <div className="grid gap-2">
                        <p><strong>{t('automacoes_drawer.acoes.anexos')}:</strong></p>
                        {drawerEmailDetailData.attachments.length > 0 ? (
                            <List
                                itemLayout="horizontal"
                                dataSource={drawerEmailDetailData.attachments}
                                renderItem={(item) => {

                                    return (
                                        <List.Item>
                                            <div className="flex items-center justify-between gap-4 w-full">
                                                <div className="flex items-center justify-between">
                                                    <span className="flex gap-2 items-center">
                                                        <span className={`rounded-full w-fit text-black inline-flex items-center`}>{item.nome}</span>
                                                    </span>
                                                </div>
                                                <div className="grid gap-2">
                                                    <Button
                                                        icon={<HiOutlineDownload />}
                                                        type="primary"
                                                        className="shadow-none font-bold text-black bg-white"
                                                        onClick={() => handleDownloadFicheiros(item.nome, item.url)}
                                                    >
                                                        {t('tables.download')}
                                                    </Button>
                                                </div>
                                            </div>
                                        </List.Item>
                                    );
                                }}
                            />
                        ) : (
                            <span className="flex gap-2 items-center">
                                <span className={`rounded-full w-fit text-black inline-flex items-center`}>{t('tables.sem_anexos')}</span>
                            </span>
                        )}
                    </div>

                    <hr />

                    {drawerEmailDetailData.events && (
                        <div className="grid gap-2">
                            <p><strong>{t('automacoes_drawer.acoes.lista_eventos')}:</strong></p>
                            <List
                                itemLayout="horizontal"
                                dataSource={drawerEmailDetailData.events}
                                renderItem={(item) => {
                                    const colorClass = eventColors[item.nome_evento] || 'bg-gray-300';

                                    return (
                                        <List.Item>
                                            <div className="grid grid-cols-2 gap-4 w-full">
                                                <div className="flex items-start">
                                                    <span className="flex gap-2 items-center">
                                                        <span className={`rounded-full w-fit ${colorClass} text-white px-3 inline-flex items-center`}>{item.nome_evento}</span>
                                                    </span>
                                                </div>
                                                <div className="grid gap-2">
                                                    <span className="text-black text-xs font-normal sm:text-sm">
                                                        <strong>{t('automacoes_drawer.acoes.data_evento')}:</strong> {item.data_evento}
                                                    </span>
                                                    {item.razao_falha && (
                                                        <span className="text-black text-xs font-normal sm:text-sm grid gap-1">
                                                            <strong>{t('automacoes_drawer.email.motivo_falha')}:</strong>
                                                            <span
                                                                dangerouslySetInnerHTML={{ __html: item.razao_falha }}
                                                                className="grid gap-1"
                                                            />
                                                        </span>
                                                    )}
                                                    {item.explicacao_chatgpt && (
                                                        <span className="grid gap-1 border-card bg-blue-400 text-white text-xs font-normal sm:text-sm">
                                                            <strong>{t('automacoes_drawer.email.explicacao_ai')}:</strong>
                                                            <div
                                                                dangerouslySetInnerHTML={{ __html: item.explicacao_chatgpt }}
                                                                className="grid gap-1"
                                                            />
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                        </List.Item>
                                    );
                                }}
                            />
                        </div>
                    )}

                </div>
            )}
        </Drawer>

    );
};

