import React, { useState } from "react";
import { FiCopy, FiCheck } from "react-icons/fi";
import { message } from "antd";
import { useTranslation } from "react-i18next";

export default function WhatsappEmpresaESemEN({
  dataCarros,
  dataUser,
  propostaInfo,
  valoresTotais,
  formatarNumero,
}) {
  const { t } = useTranslation();
  const [copiedItem, setCopiedItem] = useState(null);

  const handleOptionClick = async (value) => {
    if (value !== 1 || !propostaInfo || !valoresTotais || !dataCarros || !dataUser) return;

    // const text = `*${dataCarros.titulo}*\n\n` +
    //   `${t("partilhar_email.preco_carro_en")}: ${formatarNumero(valoresTotais.precoViaturaSemIva)} ${dataUser.language === "es" ? "_(TO BE NEGOTIATED)_" : ""
    //   }\n` +
    //   `${t("gerar_proposta.isv")}: ${valoresTotais.valorTotalISV == "0" || valoresTotais.valorTotalISV == 0
    //     ? t("gerar_proposta.isento")
    //     : formatarNumero(valoresTotais.valorTotalISV)
    //   }\n` +
    //   `${t("partilhar_email.despesas_de_importacao_en")}: ${formatarNumero(valoresTotais.taxaDeServico)}\n` +
    //   `${t("partilhar_email.preco_final_chave_mao_c_iva_en")}: ${formatarNumero(valoresTotais.valorPrecoChaveNaMaoEmpresa)}\n\n` +
    //   `*${t("gerar_proposta.link_proposta_en")}: ${propostaInfo.linkDaProposta}*`;


    const text = `*${propostaInfo.outros.nome_proposta}*\n` +
      `*${propostaInfo.outros.nome_modelo}*\n\n` +
      `${t('partilhar_email.preco_carro_en')}: ${formatarNumero(valoresTotais.precoViaturaSemIva)} ${t('partilhar_email.por_negociar_en')}\n` +
      `Registration Tax: ${valoresTotais.valorTotalISV === "0" || valoresTotais.valorTotalISV === 0
        ? t('gerar_proposta.isento')
        : formatarNumero(valoresTotais.valorTotalISV)
      } (Indicative result, please consult original documentation and BOE tables)\n` +
      `Road Tax: ${valoresTotais.valorTotalIUC === "0" || valoresTotais.valorTotalIUC === 0
        ? t('gerar_proposta.isento')
        : formatarNumero(valoresTotais.valorTotalIUC)
      }\n` +
      `${t('partilhar_email.despesas_de_importacao_en')}: ${formatarNumero(valoresTotais.taxaDeServico)}\n` +
      `${valoresTotais.taxaDeFinanciamentoAtivo === 1 ?
        `${t('partilhar_email.taxa_financiamento')}: ${formatarNumero(valoresTotais.taxaDeFinanciamento)}\n` :
        ''
      }` +
      `${t('partilhar_email.preco_final_chave_mao_en')}: ${formatarNumero(valoresTotais.valorPrecoChaveNaMaoEmpresa)} (Car price + Registration Tax + Road Tax + Importrust Fee)\n\n` +
      `*${t("gerar_proposta.link_proposta_en")}* ${propostaInfo.linkDaProposta}`;


    try {
      await navigator.clipboard.writeText(text);
      message.success("Copied to clipboard!");
      setCopiedItem(value);
      setTimeout(() => setCopiedItem(null), 2000);
    } catch (error) {
      console.error("Error copying:", error);
      message.error("Failed to copy content.");
    }
  };

  return (
    <div className="flex items-center gap-2">
      <label className="flex items-center gap-2 cursor-pointer">
        {copiedItem === 1 ? (
          <FiCheck
            size={18}
            title="Copiado!"
          />
        ) : (
          <FiCopy
            size={18}
            title={t("gerar_proposta.copiar_texto_para_whats_en_empresa")}
            onClick={() => handleOptionClick(1)}
          />
        )}
        <span className='text-sm' onClick={() => handleOptionClick(1)}>
          {t("gerar_proposta.copiar_texto_para_whats_en_empresa")}
        </span>
      </label>
    </div>
  );
}
