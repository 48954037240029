import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import CustomTable from '../Tables/CustomTables';
import { Button, Checkbox, Tooltip, message } from 'antd';
import { LuFilter } from "react-icons/lu";
import DrawerDetalhesDaAutomacao from './DrawerDetalhesDaAutomacao';
import DrawerFiltrosDaAutomacao from './DrawerFiltrosDaAutomacao';
import AnalyticsAutomacoes from './Analytics/AnalyticsAutomacoes'


export default function TableTodasAutomacoes({ dataUser }) {

    const { t } = useTranslation();

    const [data, setData] = useState({
        automacoes: [],
    });

    const [loading, setLoading] = useState(false); // Estado de loading


    const [filters, setFilters] = useState([]);


    const [drawerVisibleEmailDetail, setDrawerVisibleEmailDetail] = useState(false); // Controla a visibilidade do Detalhes do Email
    const [drawerVisibleFilters, setDrawerVisibleFilters] = useState(false); // Controla a visibilidade do Filtros
    const [drawerEmailDetailData, setDrawerEmailDetailData] = useState(null); // Armazena o registro do Detalhes do Email

    const [comparadorVisible, setComparadorVisible] = useState(null); // Armazena o registro do Detalhes do Email
    const [poupanacaVisible, setPoupancaVisible] = useState(null); // Armazena o registro do Detalhes do Email

    useEffect(() => {

        setLoading(true);

        const filtrosCompletos = {
            ...filters,
            user_id: dataUser?.iDTokenAuthenticated,
            user_token: dataUser?.saveToken
        };

        const fetchData = async () => {
            const url = `${process.env.REACT_APP_OBTER_LOGS_EMAILS}`;

            try {

                const response = await axios.get(url, { params: filtrosCompletos });

                if (response.data.success) {

                    setData({
                        automacoes: response.data.data,
                    });

                } else {

                    message.warning(response.data.mensagem);

                }

                setLoading(false);

            } catch (error) {
                console.error('Error occurred:', error);
            }
        };

        //console.log(filters)

        if (filters.limit) {
            fetchData();
        }


    }, [filters]);


    const eventColors = {
        [t('automacoes_drawer.eventos.enviado')]: 'bg-blue-400',       // Azul para "Enviado"
        [t('automacoes_drawer.eventos.entregue')]: 'bg-green-400',    // Verde para "Entregado"
        [t('automacoes_drawer.eventos.falha')]: 'bg-red-600',          // Rojo para "Fallo"
        [t('automacoes_drawer.eventos.bloqueado')]: 'bg-red-600',      // Rojo para "Bloqueado"
        [t('automacoes_drawer.eventos.aberto')]: 'bg-yellow-400',     // Amarillo para "Abierto"
        [t('automacoes_drawer.eventos.clique')]: 'bg-purple-400',        // Púrpura para "Clic"
        [t('automacoes_drawer.eventos.unsubscribe')]: 'bg-gray-400',  // Gris para "Darse de baja"
        [t('automacoes_drawer.eventos.adiado')]: 'bg-gray-400'  // Gris para "Darse de baja"
    };

    const allColumns = [
        {
            title: t('tables.evento'),
            dataIndex: 'nome_evento',
            key: 'nome_evento',
            render: (text) => {
                const translatedText = t(`automacoes_drawer.eventos.${text.toLowerCase()}`);
                const colorClass = eventColors[translatedText] || 'bg-gray-400';
                return (
                    <div className={`rounded-full ${colorClass} text-white px-3 w-auto inline-flex items-center`}>
                        <span className='flex gap-2 items-center'>
                            {translatedText}
                        </span>
                    </div>
                );
            }
        },
        {
            title: t('tables.data_evento'),
            dataIndex: 'data_evento',
            key: 'data_evento',
        },
        {
            title: t('tables.template_name'),
            dataIndex: 'template_name',
            key: 'template_name',
        },
        {
            title: t('tables.assunto'),
            dataIndex: 'subject',
            key: 'subject',
            render: (text, record) => { // Corrigido: Mudar `record` para `(text, record)`
                const displayedName = `${text.slice(0, 50)}...`; // Use `text` diretamente para acessar o valor da coluna

                return (
                    <Tooltip placement="topRight" title={text}>
                        <span
                            style={{ cursor: 'pointer', color: '#fff' }} // Estilo para mostrar que é clicável
                            onClick={() => showDrawerVisibleEmailDetail(record)} // Ao clicar, chama a função para abrir o Drawer com o registro completo
                        >
                            {displayedName}
                        </span>
                    </Tooltip>
                );
            }
        },
        {
            title: t('tables.de'),
            dataIndex: 'from_email',
            key: 'from_email',
        },
        {
            title: t('tables.para'),
            dataIndex: 'to_email',
            key: 'to_email',
        }
    ].filter(Boolean); // Filtra colunas undefined

    // Função para abrir o Drawer dos Detalhes de Email e definir os dados do registro
    const showDrawerVisibleEmailDetail = (record) => {

        const obterDetalhes = {
            id_log: record.id_log,
            user_id: dataUser.iDTokenAuthenticated,
            user_token: dataUser.saveToken,
        };

        axios.get(`${process.env.REACT_APP_DETALHES_DO_LOGS_DE_EMAILS}`, { params: obterDetalhes })
            .then((response) => {
                setDrawerEmailDetailData(response.data.data);
                setDrawerVisibleEmailDetail(true);
            })
            .catch((error) => {
                setDrawerVisibleEmailDetail(false);
            });

    };

    // Função para abrir o Drawer dos Filtros
    const showDrawerFilters = () => {
        setDrawerVisibleFilters(true);
    };

    // Função para abrir o Drawer dos Filtros
    const showComparador = () => {
        setComparadorVisible(!comparadorVisible);
    };

    const showPoupanca = () => {
        setPoupancaVisible(!poupanacaVisible);
    };


    return (
        <>


            <div className='flex justify-end gap-4'>

                {dataUser.role === 'administrator' && (
                    <Checkbox
                        className='text-white flex items-center'
                        onChange={showPoupanca}
                    >
                        Poupança
                    </Checkbox>
                )}

                {/* {dataUser.role === 'administrator' && ( */}
                <Checkbox
                    className='text-white flex items-center'
                    onChange={showComparador}
                >
                    Comparador
                </Checkbox>
                {/* )} */}

                <Button
                    type="primary"
                    className='shadow-none font-bold text-black bg-white'
                    onClick={showDrawerFilters}
                >
                    <div className='flex items-center gap-2'>
                        <LuFilter />
                        Filtros
                    </div>
                </Button>

            </div>

            {(dataUser.role === 'administrator' && Object.keys(filters).length > 0) && (
                <>
                    <AnalyticsAutomacoes
                        dataUser={dataUser}
                        filters={filters}
                        comparadorVisible={comparadorVisible}
                        poupanacaVisible={poupanacaVisible}
                    />
                </>
            )}

            <CustomTable
                columns={allColumns}
                data={data.automacoes.map((record, index) => ({ ...record, key: record.id || index }))}
                pagination={{ pageSize: '20', showSizeChanger: false, position: ['bottomCenter'] }}
                loading={loading}
            />

            <DrawerDetalhesDaAutomacao
                drawerVisibleEmailDetail={drawerVisibleEmailDetail}
                setDrawerVisibleEmailDetail={setDrawerVisibleEmailDetail}
                drawerEmailDetailData={drawerEmailDetailData}
                setDrawerEmailDetailData={setDrawerEmailDetailData}
                dataUser={dataUser}
                eventColors={eventColors}
            />

            <DrawerFiltrosDaAutomacao
                filters={filters}
                setFilters={setFilters}
                drawerVisibleFilters={drawerVisibleFilters}
                setDrawerVisibleFilters={setDrawerVisibleFilters}
                dataUser={dataUser}
            />

        </>
    );
}
