import React, { useState, useEffect } from "react";
import axios from "axios";
import { Button, message, Progress } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { HiOutlineDownload } from "react-icons/hi";
import { FaCheckCircle } from "react-icons/fa";
import { IoMdCloseCircle } from "react-icons/io";
import { BsFiletypePdf } from "react-icons/bs";


export default function EstadoFicheiroUpload({ file, showModal, dataUser, mediaSegundos }) {

    const [progress, setProgress] = useState(0);

    const [time, SetTime] = useState(file.mediaSegundos);

    // console.log(file)
    // const media = time || mediaSegundos

    console.log(time)

    useEffect(() => {

        if (file.status === "processing" && mediaSegundos > 0) {
            
            let currentProgress = time || 0;

            console.log(currentProgress)
             
            const increment = 100 / mediaSegundos; // Quanto % aumentar a cada segundo
            
            const interval = setInterval(() => {
                currentProgress += increment;
                if (currentProgress >= 99) {
                    setProgress(99);
                    clearInterval(interval);
                } else {
                    setProgress(currentProgress);
                }
            }, 1000); // Atualiza a cada 1 segundo

            return () => clearInterval(interval); // Limpa o intervalo quando o componente desmonta
        }

    }, [file.status, mediaSegundos]); // Só roda se status for "processing"

    if (!file || file.status === null) return null; // Não renderiza se o arquivo não existir

    // console.log(file)

    const handleDownloadFicheiros = async (fileName, extensao) => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_DOWNLOAD_DOS_FICHEIROS}`,
                {
                    params: {
                        token: dataUser.saveToken,
                        user_id: dataUser.iDTokenAuthenticated,
                        nome_ficheiro: fileName,
                        tipo: "translated_docs",
                    },
                    responseType: "blob",
                }
            );

            const mimeType =
                extensao === "pdf"
                    ? "application/pdf"
                    : extensao === "docx"
                        ? "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                        : "application/octet-stream";

            const url = window.URL.createObjectURL(new Blob([response.data], { type: mimeType }));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
            message.success("Download do ficheiro com sucesso!");
        } catch (error) {
            message.error("Falha ao fazer download do ficheiro.");
            console.error("Erro ao fazer download do ficheiro:", error);
        }
    };

    const typeFicheiro = (fileType) => {
        switch (fileType) {
            case 'pdf':
                return 'PDF';
            case 'application/pdf':
                return 'PDF'
            default:
                return
        }
    };


    function formatFileSize(size) {
        if (size < 1024) {
            return `${size} B`; // Menos de 1KB
        } else if (size < 1024 * 1024) {
            return `${(size / 1024).toFixed(2)} KB`; // Converte para KB
        } else {
            return `${(size / (1024 * 1024)).toFixed(2)} MB`; // Converte para MB
        }
    }


    // console.log(file)

    return (
        <div className="flex flex-col gap-4 border-card bg-black card-cinza text-white">
            {/* ✅ Status COMPLETED */}
            {file.status === "completed" && (
                <>

                    <div className="flex items-center justify-between gap-2">
                        <div className="flex items-center gap-2">
                            <div className="bg-green-600 rounded-full p-2">
                                <BsFiletypePdf style={{ fontSize: '25px', color: '#fff' }} />
                            </div>
                            <div className="grid gap-2">
                                <p className="font-semibold text-sm max-w-max">{file.name.slice(0, 55)}...</p>
                                <div className="flex items-center">
                                    <p className="text-xs">{typeFicheiro(file.type)} | {formatFileSize(file.size)}</p>
                                </div>
                            </div>
                        </div>
                        <div className="rounded-full flex items-center gap-2 bg-green-600 text-white px-3">
                            <FaCheckCircle style={{ fontSize: "16px", color: "#fff" }} />
                            <p>Completo</p>
                        </div>
                    </div>

                    <hr />

                    <div className="flex justify-between gap-2">

                        {file.resumo && (
                            <p
                                className="flex items-center gap-2 cursor-pointer"
                                onClick={() => showModal("Resumo da Tradução", file.resumo)}
                            >
                                <InfoCircleOutlined style={{ fontSize: "15px", color: "#fff" }} />
                                Ver Resumo
                            </p>
                        )}

                        <Button
                            className="flex gap-2 items-center bg-green-600 text-white rounded"
                            type="primary"
                            onClick={() => handleDownloadFicheiros(file.name, file.type)}
                        >
                            <HiOutlineDownload /> Download
                        </Button>
                    </div>
                </>
            )}

            {/* ❌ Status FAILED */}
            {file.status === "failed" && (
                <>
                    <div className="flex items-center justify-between gap-2">
                        <div className="flex items-center gap-2">
                            <div className="bg-red-600 rounded-full p-2">
                                <BsFiletypePdf style={{ fontSize: '25px', color: '#fff' }} />
                            </div>
                            <div className="grid gap-2">
                                <p className="font-semibold text-sm max-w-max">{file.name.slice(0, 55)}...</p>
                                <div className="flex items-center">
                                    <p className="text-xs">{typeFicheiro(file.type)} | {formatFileSize(file.size)}</p>
                                </div>
                            </div>
                        </div>
                        <div className="rounded-full flex items-center gap-2 bg-red-600 text-white px-3">
                            <IoMdCloseCircle style={{ fontSize: "16px", color: "#fff" }} />
                            <p>Erro</p>
                        </div>
                    </div>


                    {file.erroMensagem && (
                        <>
                            <hr></hr>
                            <div className="flex justify-end">
                                <Button
                                    className="flex gap-1 items-center bg-red-600 text-white rounded"
                                    type="primary"
                                    onClick={() => showModal("Erro no Processamento", file.erroMensagem)}
                                >
                                    <InfoCircleOutlined /> Ver Erro
                                </Button>
                            </div>
                        </>
                    )}
                </>
            )}

            {/* ⏳ Status PROCESSING */}
            {file.status === "processing" && (
                <>
                    <div className="flex items-center justify-between gap-2">
                        <div className="flex gap-2 items-center processing-status-docs">
                            <div className="bg-yellow-500 rounded-full p-2">
                                <BsFiletypePdf style={{ fontSize: '25px', color: '#fff' }} />
                            </div>
                            <div className="grid gap-2">
                                <p className="font-semibold text-sm max-w-max">{file.name.slice(0, 55)}...</p>
                                <div className="flex items-center">
                                    <p className="text-xs">{typeFicheiro(file.type)} | {formatFileSize(file.size)}</p>
                                </div>
                            </div>
                        </div>

                        <div className="rounded-full flex items-center gap-2 bg-yellow-500 text-white px-3">
                            {progress >= 99 ? (
                                <p className="w-max">A Finalizar...</p>
                            ) : (
                                <p className="w-max">A Processar...</p>
                            )}
                        </div>
                    </div>

                    <hr></hr>

                    {/* ✅ Barra de progresso aparece abaixo do nome */}
                    <Progress percent={Math.round(progress)} status="active" />
                </>
            )}
        </div>
    );
}
