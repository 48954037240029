import { Input } from "antd";
import React from "react";
import { DatePicker } from 'antd';
import dayjs from "dayjs";
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);


export default function CompProcuracaoParticular({
    dataDoDocumento,
    setDataDoDocumento,
    nomeIndividual,
    setNomeIndividual,
    moradaIndividual,
    setMoradaIndividual,
    numeroCCIndividual,
    setNumeroCCIndivual,
    validadeCCIndivual,
    setValidadeCCIndividual,
    paisEmissorIndivual,
    setPaisEmissorIndividual,
    alertaDataDocumento,
    alertaDataValidadeCC,
    idDoNegocio,
    setIDdoNegocio
}) {

    ////console.log('oooooooooalertaDataValidadeCC',alertaDataValidadeCC)

    const handleDataChange = (e) => {
        let day = "";
        let month = "";
        let year = "";

        if (e && e instanceof Object && e.hasOwnProperty('$y') && e.hasOwnProperty('$M') && e.hasOwnProperty('$D')) {
            // Extract day, month, and year
            day = e.$D.toString().padStart(2, '0');
            month = (e.$M + 1).toString().padStart(2, '0'); // Month is zero-based
            year = e.$y.toString();
        }

        setDataDoDocumento(day + '/' + month + '/' + year);
        ////console.log(dataDoDocumento)
    };

    const dayjsDate = (date) => {
        if (date && date !== '') {
            return dayjs(date, 'DD/MM/YYYY')
        }
    }

    const handleNomeChange = (e) => {
        setNomeIndividual(e.target.value);
    };

    const handleMoradaChange = (e) => {
        setMoradaIndividual(e.target.value);
    };

    const handleNumeroCCChange = (e) => {
        setNumeroCCIndivual(e.target.value);
    };

    const handleValidadeCCChange = (e) => {
        // setValidadeCCIndividual(e.target.value);

        let day = "";
        let month = "";
        let year = "";

        if (e && e instanceof Object && e.hasOwnProperty('$y') && e.hasOwnProperty('$M') && e.hasOwnProperty('$D')) {
            // Extract day, month, and year
            day = e.$D.toString().padStart(2, '0');
            month = (e.$M + 1).toString().padStart(2, '0'); // Month is zero-based
            year = e.$y.toString();
        }

        setValidadeCCIndividual(day + '/' + month + '/' + year);
        // //console.log(dataDoDocumento)

    };

    const handlePaisEmissorChange = (e) => {
        setPaisEmissorIndividual(e.target.value);
    };


    // const handleSubmit = () => {
    //     //console.log('enviou')
    // }

    const handleIDdoNegocioChange = (e) => {
        setIDdoNegocio(e.target.value);
    };

    return (

        <>

            <div className="grid grid-cols-2 gap-2">
                <div className='grid gap-2'>
                    <p>ID do negócio</p>
                    <Input
                        placeholder="ID do negócio"
                        value={idDoNegocio}
                        onChange={handleIDdoNegocioChange}
                        type="text"
                        required
                    />
                </div>
                <div className='grid gap-2'>
                    <p>Data do documento</p>
                    <DatePicker placeholder="dd/mm/aaaa"
                        defaultValue={dayjsDate(dataDoDocumento)}
                        value={dayjsDate(dataDoDocumento)}
                        format={'DD/MM/YYYY'}
                        // picker="month"
                        onChange={handleDataChange}

                    />
                    {alertaDataDocumento && <small className="text-red-500">A data do documento é obrigatória</small>}
                </div>
            </div>

            <p className="font-semibold">Detalhes do Cliente:</p>


            <div className="grid grid-cols-2 gap-2">

                <div className='grid gap-2'>
                    <p>Nome do Cliente</p>
                    <Input
                        placeholder="Nome do cliente"
                        value={nomeIndividual}
                        onChange={handleNomeChange}
                        type="text"
                        required
                    />
                </div>

                <div className='grid gap-2'>
                    <p>Morada</p>
                    <Input
                        placeholder="Morada"
                        value={moradaIndividual}
                        onChange={handleMoradaChange}
                        type="text"
                        required
                    />
                </div>

                <div className='grid gap-2'>
                    <p>Nº cartão de cidadão</p>
                    <Input
                        placeholder="Nº cartão de cidadão"
                        value={numeroCCIndividual}
                        onChange={handleNumeroCCChange}
                        type="text"
                        required
                    />
                </div>

                <div className='grid gap-2'>
                    <p>Validade cartão de cidadão</p>
                    <DatePicker placeholder="dd/mm/aaaa"
                        defaultValue={dayjsDate(validadeCCIndivual)}
                        format={'DD/MM/YYYY'}
                        // picker="month"
                        onChange={handleValidadeCCChange}

                    />
                    {alertaDataValidadeCC && <small className="text-red-500">A Validade Cartão de Cidadão é obrigatória</small>}
                </div>

                <div className='grid gap-2'>
                    <p>Entidade emissora</p>
                    <Input
                        placeholder="Entidade emissora"
                        value={paisEmissorIndivual}
                        onChange={handlePaisEmissorChange}
                        type="text"
                        required
                    />
                </div>






            </div>
        </>


    )
}

