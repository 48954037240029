import React, { useState, useEffect, useRef } from "react";
import { Steps, Select, Button, Switch } from 'antd';
import { Tabs } from 'antd';
import axios from "axios";
import CompProcuracaoParticular from "./components/gerador-contratos/procuracao/particular/comp-procuracao-particular";
import CompProcuracaoEmpresa from "./components/gerador-contratos/procuracao/empresa/comp-procuracao-empresa";
import CompContratoParticular from "./components/gerador-contratos/contratos/comp-contrato-particular";
import AutoTransporteParticular from "./components/gerador-contratos/autorizacao-transporte/auto-transporte-particular";
import CompCreditoParticular from "./components/gerador-contratos/credito/comp-credito-particular";
import CompCreditoEmpresa from "./components/gerador-contratos/credito/comp-credito-empresa copy";
import CompModelo9Particular from "./components/gerador-contratos/modelo9/comp-modelo9-particular";
import DadosAnterioresContratos from "./components/gerador-contratos/elementos/dados-anteriores-contratos";
import TabPane from "antd/es/tabs/TabPane";
import CompGerarPDF from "./components/gerador-contratos/comp-gerarpdf";
import InfoBlock from "./components/info-block";
import CompContratoEmpresa from "./components/gerador-contratos/contratos/comp-contrato-empresa";


const { Step } = Steps;
const { Option } = Select;

function addZero(num) {
    return num < 10 ? "0" + num : num;
}

function getDate() {
    const today = new Date();
    const month = addZero(today.getMonth() + 1);
    const year = today.getFullYear();
    const date = addZero(today.getDate());
    const currentDate = date + "/" + month + "/" + year;
    return currentDate;
}

export default function GerarContratoProcuracao({ dataUser }) {


    const [loading, setLoading] = useState(false);
    const [mensagemDeErroAoGerar, setMensagemDeErroAoGerar] = useState('');
    const [currentStep, setCurrentStep] = useState(0);


    const [disabledButtonPrev, setDisabledButtonPrev] = useState(true);
    const [disabledButtonNext, setDisabledButtonNext] = useState(false);

    const [checkedValue, setCheckedValue] = useState(1);

    ////contrato-geral para todos os estados
    const [valorServicoPrestado, setValorServicoPrestado] = useState('');
    const [dataDoDocumento, setDataDoDocumento] = useState(getDate());
    // //console.log('dataDoDocumento',dataDoDocumento)
    const [idDoNegocio, setIDdoNegocio] = useState('');
    const [emailResponsavel, setEmailResponsavel] = useState('');


    //step 1
    const [tipo, setTipo] = useState('');
    const [tipoSociedade, setTipoSociedade] = useState('');
    const [tipoContrato, setTipoContrato] = useState('');

    //step2
    ////procuracao
    //////particular
    const [nomeIndividual, setNomeIndividual] = useState('');
    const [moradaIndividual, setMoradaIndividual] = useState('');
    const [numeroCCIndividual, setNumeroCCIndivual] = useState('');
    const [validadeCCIndivual, setValidadeCCIndividual] = useState('');
    const [paisEmissorIndivual, setPaisEmissorIndividual] = useState('República Portuguesa');
    //////empresa
    const [nomeSocioEmpresa, setNomeSocioEmpresa] = useState('');
    const [numeroCCEmpresa, setNumeroCCEmpresa] = useState('');
    const [validadeCCEmpresa, setValidadeCCEmpresa] = useState('');
    const [paisEmissorEmpresa, setPaisEmissorEmpresa] = useState('República Portuguesa');
    const [nomeEmpresa, setNomeEmpresa] = useState('');
    const [sedeEmpresa, setSedeEmpresa] = useState('');
    const [conservatoriaEmpresa, setConservatoriaEmpresa] = useState('');
    const [nifEmpresa, setNifEmpresa] = useState('');
    const [capitalSocialEmpresa, setCapitalSocialEmpresa] = useState('');
    const [dataSociosDois, setDataSociosDois] = useState([]);
    const [dataSociosQuatro, setDataSociosQuatro] = useState([]);




    ////contrato
    //////particular
    const [nifParticular, setNifParticular] = useState('');
    const [numeroPassaporteParticular, setNumeroPassaporteParticular] = useState('');
    const [emailParticular, setEmailParticular] = useState('');
    //////empresa
    const [emailDaEmpresa, setEmailDaEmpresa] = useState('');
    const [nifGerente, setNifGerente] = useState('');
    //venda - para ambos (particular e empresa)
    const [marcaViatura, setMarcaViatura] = useState('');
    const [modeloViatura, setModeloViatura] = useState('');
    const [matriculaViatura, setMatriculaViatura] = useState('');
    const [vinViatura, setVinViatura] = useState('');


    const [percentagemServicoPrestado, setPercentagemServicoPrestado] = useState('5');
    const [valorDeposito, setValorDeposito] = useState('');
    const [alertaDataDocumento, setAlertaDataDocumento] = useState(false);
    const [alertaDataValidadeCC, setAlertaDataValidadeCC] = useState(false);

    const [escolhaDeDocumento, setEscolhaDeDocumento] = useState('cartaodecidadao');


    //////credito
    const [numeroSocios, setNumeroSocios] = useState('');
    const [paisDeOrigem, setPaisDeOrigem] = useState('');
    const [valorChaveNaMao, setValorChaveNaMao] = useState('');
    const [valorChaveNaMaoExtenso, setValorChaveNaMaoExtenso] = useState('');
    const [quantidaEntregueImportrust, setQuantidaEntregueImportrust] = useState('');
    const [quantidaEntregueImportrustExtenso, setQuantidaEntregueImportrustExtenso] = useState('');
    const [taxasDeJuros, setTaxasDeJuros] = useState('');


    //////transportadora
    const [transportadora, setTransportadora] = useState('');
    const [nifTransportadora, setNifTransportadora] = useState('');


    //---//

    //////modelo 9
    const [telemovelInterno, setTelemovelInterno] = useState('+351939249848');
    const [emailInterno, setEmailInterno] = useState('julia.malaquias@importrust.com');
    const [residenciaParticular, setResidenciaParticular] = useState('');
    const [codigoPostalParticular, setCodigoPostalParticular] = useState('');
    const [telemovelParticular, setTelemovelParticular] = useState('');
    const [categoriaViatura, setCategoriaViatura] = useState('Ligeiro');
    const [tipoViatura, setTipoViatura] = useState('Passageiros');
    const [combustivelViatura, setCombustivelViatura] = useState('');
    const [cilindradaViatura, setCilindradaViatura] = useState('');
    const [matriculaAnteriorViatura, setMatriculaAnteriorViatura] = useState('');
    const [dataPrimeiraMatriculaViatura, setDataPrimeiraMatriculaViatura] = useState('');
    const [paisOrigemViatura, setPaisOrigemViatura] = useState('');
    const [numeroCertidaoPermanente, setNumeroCertidaoPermanente] = useState('');







    //step3
    const [urlPDFGerado, setUrlPDFGerado] = useState('');
    const [activeTabStepDoisKey, setActiveTabStepDoisKey] = useState('1');
    const [activeTabStepTresKey, setActiveTabStepTresKey] = useState('1');




    const buttonForm = useRef(null);
    const Form = useRef(null);




    // const validateForm = () => {
    //     if (Form.current) {
    //         const formInputs = Form.current.querySelectorAll('input, select, textarea');

    //         // Loop through each form input and validate
    //         for (let input of formInputs) {
    //             if (input.required && input.value.trim() === '') {
    //                 return false; // Return false if any required field is empty
    //             }
    //             // You can add additional validation rules here if needed
    //         }

    //         return true; // Return true if all fields are valid
    //     } else {
    //         console.error("Form reference is null");
    //         return false;
    //     }
    // };


    const handleTabsStepDoisChange = (key) => {
        // //console.log(key)
        setActiveTabStepDoisKey(key);
    };



    function handleNext(e) {

        if (currentStep === 1 && tipo === "novo_negocio" && activeTabStepDoisKey <= "3") {
            return
        }

        // PREVENT SUBMIT
        e.preventDefault();
        e.stopPropagation();

        setUrlPDFGerado('');
        setLoading(false);
        setAlertaDataValidadeCC(false);
        setAlertaDataDocumento(false);
        setCurrentStep(currentStep + 1);


        //pedido para gerar PDF
        if (currentStep === 1) {

            setUrlPDFGerado('')

            if (tipo === "procuracao" && tipoSociedade === "empresa") {

                // //console.log('(tipo === "procuracao" && tipoSociedade === "empresa")')
                setLoading(true)
                setMensagemDeErroAoGerar('')


                const dataCriarPDF = {
                    tipo_documento: tipo,
                    tipo_sociedade: tipoSociedade,
                    nome_gerente: nomeSocioEmpresa,
                    numero_cc: numeroCCEmpresa,
                    pais_emissor_cc: paisEmissorEmpresa,
                    validade_cc: validadeCCEmpresa,
                    nome_empresa: nomeEmpresa,
                    sede_empresa: sedeEmpresa,
                    data: dataDoDocumento,
                    conservatoria_empresa: conservatoriaEmpresa,
                    nif_empresa: nifEmpresa,
                    capital_social: capitalSocialEmpresa,
                    user_id: dataUser.iDTokenAuthenticated,
                    token: dataUser.saveToken,
                    negocio_id: idDoNegocio,
                    numero_socios: dataSociosQuatro.length < 0 ? '' : dataSociosQuatro,
                }

                // const dataDosSocios = {
                //     numero_socios
                // }

                ////console.log('dataCriarPDF', dataCriarPDF)

                axios.post(`${process.env.REACT_APP_URL_CREATE_OLD}`, dataCriarPDF, {})
                    .then((response) => {
                        // //console.log(response)

                        if (response.data.sucesso) {
                            setLoading(false)
                            setUrlPDFGerado(response.data.mensagem)
                            // //console.log(urlPDFGerado)
                        } else {
                            setLoading(false)
                            setMensagemDeErroAoGerar(response.data.mensagem)
                        }

                    })
                    .catch((error) => {
                        setLoading(false)
                    })
                    .finally(() => {
                    });

            } else if (tipo === "procuracao" && tipoSociedade === "particular") {

                // //console.log('(tipo === "procuracao" && tipoSociedade === "particular")')
                setLoading(true)
                setMensagemDeErroAoGerar('')

                // //console.log('entrou 2')

                const dataCriarPDF = {
                    tipo_documento: tipo,
                    tipo_sociedade: tipoSociedade,
                    nome_particular: nomeIndividual,
                    residencia_particular: moradaIndividual,
                    numero_cc: numeroCCIndividual,
                    pais_emissor_cc: paisEmissorIndivual,
                    validade_cc: validadeCCIndivual,
                    data: dataDoDocumento,
                    user_id: dataUser.iDTokenAuthenticated,
                    negocio_id: idDoNegocio,
                    token: dataUser.saveToken
                }

                // //console.log('dataCriarPDF', dataCriarPDF)
                // //console.log('tipo', tipo)

                axios.post(`${process.env.REACT_APP_URL_CREATE_OLD}`, dataCriarPDF, {})
                    .then((response) => {
                        // //console.log(response)

                        if (response.data.sucesso) {
                            // //console.log('ei', response.data.mensagem[tipo])
                            setLoading(false)
                            setUrlPDFGerado(response.data.mensagem)
                            // //console.log(urlPDFGerado)
                        } else {
                            setLoading(false)
                            setMensagemDeErroAoGerar(response.data.mensagem)
                        }

                    })
                    .catch((error) => {
                        setLoading(false)
                    })
                    .finally(() => {
                    });

            } else if (tipo === "contrato" && tipoContrato === "legalizacao" || tipo === "contrato" && tipoContrato === "importacao") {

                // //console.log('(tipo === "contrato" && tipoContrato === "legalizacao" || tipo === "contrato" && tipoContrato === "importacao")')

                setLoading(true)
                setMensagemDeErroAoGerar('')

                // //console.log('entrou 3')

                let dataCriarPDF

                if (tipoSociedade === "empresa") {
                    dataCriarPDF = {
                        nome_empresa: nomeEmpresa,
                        nome_gerente: nomeSocioEmpresa,
                        nif_empresa: nifEmpresa,
                        nif_gerente: nifGerente,
                        sede_empresa: sedeEmpresa,
                        valor_servico_prestado: valorServicoPrestado,
                        email_empresa: emailDaEmpresa,
                        data: dataDoDocumento,
                        negocio_id: idDoNegocio,
                        numero_socios: dataSociosDois,
                        tipo_documento: tipo,
                        tipo_sociedade: tipoSociedade,
                        tipo_contrato: tipoContrato,
                        email_responsavel: emailResponsavel,
                        doc_seguro: checkedValue,
                        user_id: dataUser.iDTokenAuthenticated,
                        token: dataUser.saveToken
                    }
                } else {
                    dataCriarPDF = {
                        nome_particular: nomeIndividual,
                        email_particular: emailParticular,
                        nif_particular: nifParticular,
                        tipo_idenficacao: escolhaDeDocumento,
                        numero_passaporte_particular: numeroPassaporteParticular,
                        residencia_particular: moradaIndividual,
                        valor_servico_prestado: valorServicoPrestado,
                        email_responsavel: emailResponsavel,
                        data: dataDoDocumento,
                        negocio_id: idDoNegocio,
                        tipo_documento: tipo,
                        tipo_sociedade: tipoSociedade,
                        tipo_contrato: tipoContrato,
                        user_id: dataUser.iDTokenAuthenticated,
                        doc_seguro: checkedValue,
                        token: dataUser.saveToken
                    }
                }


                axios.post(`${process.env.REACT_APP_URL_CREATE_OLD}`, dataCriarPDF)
                    .then((response) => {
                        ////console.log(response)

                        if (response.data.sucesso) {
                            setLoading(false)
                            setUrlPDFGerado(response.data.mensagem)
                            // //console.log(urlPDFGerado)
                        } else {
                            setLoading(false)
                            setMensagemDeErroAoGerar(response.data.mensagem)
                        }

                    })
                    .catch((error) => {
                        setLoading(false)
                    })
                    .finally(() => {
                    });

            } else if (tipo === "contrato" && tipoContrato === "venda") {

                // //console.log('(tipo === "contrato" && tipoContrato === "venda")')

                setLoading(true)
                setMensagemDeErroAoGerar('')

                // //console.log('entrou 4')

                let dataCriarPDF

                if (tipoSociedade === "empresa") {
                    dataCriarPDF = {
                        nome_gerente: nomeSocioEmpresa,
                        nome_empresa: nomeEmpresa,
                        nif_empresa: nifEmpresa,
                        nif_gerente: nifGerente,
                        sede_empresa: sedeEmpresa,
                        marca_viatura: marcaViatura,
                        modelo_viatura: modeloViatura,
                        matricula_viatura: matriculaViatura,
                        vin_viatura: vinViatura,
                        numero_socios: dataSociosDois,
                        // seguro_viatura: seguroViatura,
                        percentagem_servico_prestado: percentagemServicoPrestado,
                        data: dataDoDocumento,
                        negocio_id: idDoNegocio,
                        tipo_documento: tipo,
                        tipo_sociedade: tipoSociedade,
                        tipo_contrato: tipoContrato,
                        doc_seguro: checkedValue,
                        user_id: dataUser.iDTokenAuthenticated,
                        token: dataUser.saveToken,
                        valor_deposito: valorDeposito,

                    }
                } else {
                    dataCriarPDF = {
                        nome_particular: nomeIndividual,
                        nif_particular: nifParticular,
                        residencia_particular: moradaIndividual,
                        marca_viatura: marcaViatura,
                        modelo_viatura: modeloViatura,
                        matricula_viatura: matriculaViatura,
                        vin_viatura: vinViatura,
                        // seguro_viatura: seguroViatura,
                        percentagem_servico_prestado: percentagemServicoPrestado,
                        data: dataDoDocumento,
                        negocio_id: idDoNegocio,
                        tipo_documento: tipo,
                        tipo_sociedade: tipoSociedade,
                        tipo_contrato: tipoContrato,
                        user_id: dataUser.iDTokenAuthenticated,
                        token: dataUser.saveToken,
                        valor_deposito: valorDeposito,

                    }
                }

                axios.post(`${process.env.REACT_APP_URL_CREATE_OLD}`, dataCriarPDF, {})
                    .then((response) => {
                        // //console.log(response)

                        if (response.data.sucesso) {
                            setLoading(false)
                            setUrlPDFGerado(response.data.mensagem)
                            // //console.log(urlPDFGerado)
                        } else {
                            setLoading(false)
                            setMensagemDeErroAoGerar(response.data.mensagem)
                        }

                    })
                    .catch((error) => {
                        setLoading(false)
                    })
                    .finally(() => {
                    });

            } else if (tipo === "novo_negocio" && tipoSociedade === "empresa") {

                // //console.log('(tipo === "novo_negocio" && tipoSociedade === "empresa")')

                setLoading(true);
                setMensagemDeErroAoGerar('')

                const juntarMarcaeModelo = marcaViatura + ' ' + modeloViatura;


                let dataCriarPDFEmpresa = {
                    nome_gerente: nomeSocioEmpresa,
                    numero_cc: numeroCCEmpresa,
                    pais_emissor_cc: paisEmissorEmpresa,
                    validade_cc: validadeCCEmpresa,
                    nome_empresa: nomeEmpresa,
                    sede_empresa: sedeEmpresa,
                    nif_empresa: nifEmpresa,
                    marca_modelo_viatura: juntarMarcaeModelo,
                    nome_transportadora: transportadora,
                    nif_transportadora: nifTransportadora,
                    conservatoria_empresa: conservatoriaEmpresa,
                    capital_social: capitalSocialEmpresa,
                    numero_socios: dataSociosQuatro,
                    vin_viatura: vinViatura,
                    marca_viatura: marcaViatura,
                    modelo_viatura: modeloViatura,

                    localidade: residenciaParticular,
                    codigo_postal: codigoPostalParticular,
                    telemovel_interno: telemovelInterno,
                    email_interno: emailInterno,
                    categoria_viatura: categoriaViatura,
                    tipo_viatura: tipoViatura,
                    pais_origem_viatura: paisOrigemViatura,
                    data_primeira_matricula_viatura: dataPrimeiraMatriculaViatura,
                    matricula_anterior_viatura: matriculaAnteriorViatura,
                    combustivel_viatura: combustivelViatura,
                    cilindrada_viatura: cilindradaViatura,
                    numero_certidao_permanente: numeroCertidaoPermanente,

                    negocio_id: idDoNegocio,
                    data: dataDoDocumento,
                    tipo_documento: tipo,
                    doc_seguro: checkedValue,
                    tipo_sociedade: tipoSociedade,
                    tipo_contrato: tipoContrato,
                    user_id: dataUser.iDTokenAuthenticated,
                    token: dataUser.saveToken,
                };

                let dataCriarPDFEmpresaVariante = {};

                if (tipoContrato === "venda") {
                    dataCriarPDFEmpresaVariante = {
                        valor_servico_prestado: valorServicoPrestado,
                        nif_gerente: nifGerente,
                        // marca_viatura: marcaViatura,
                        // modelo_viatura: modeloViatura,
                        matricula_viatura: matriculaViatura,
                        // seguro_viatura: seguroViatura,
                        percentagem_servico_prestado: percentagemServicoPrestado,
                        valor_deposito: valorDeposito,
                    };
                }

                if (tipoContrato === "importacao" || tipoContrato === "legalizacao") {
                    dataCriarPDFEmpresaVariante = {
                        nif_gerente: nifGerente,
                        valor_servico_prestado: valorServicoPrestado,
                        email_empresa: emailDaEmpresa,
                        email_responsavel: emailResponsavel,
                    };
                }

                const novoDataCriarPDFEmpresa = { ...dataCriarPDFEmpresa, ...dataCriarPDFEmpresaVariante };

                const arrayDataCriarPDFEmpresa = novoDataCriarPDFEmpresa

                axios.post(`${process.env.REACT_APP_URL_CREATE_OLD}`, arrayDataCriarPDFEmpresa)
                    .then((response) => {
                        // //console.log('response', response)
                        if (response.data.sucesso) {
                            setLoading(false);
                            setUrlPDFGerado(response.data.mensagem)
                        } else {
                            setLoading(false)
                            setMensagemDeErroAoGerar(response.data.mensagem)
                        }
                    })
                    .catch((error) => {
                        setLoading(false);
                        console.error(error);
                    });


            } else if (tipo === "novo_negocio" && tipoSociedade === "particular") {

                // //console.log('(tipo === "novo_negocio" && tipoSociedade === "particular")')

                setLoading(true)
                setMensagemDeErroAoGerar('')
                // //console.log('entrou 6')

                const juntarMarcaeModelo = marcaViatura + ' ' + modeloViatura;


                let dataCriarPDFParticular = {
                    nome_particular: nomeIndividual,
                    numero_cc: numeroCCIndividual,
                    pais_emissor_cc: paisEmissorIndivual,
                    validade_cc: validadeCCIndivual,
                    residencia_particular: moradaIndividual,
                    nif_particular: nifParticular,

                    marca_modelo_viatura: juntarMarcaeModelo,
                    vin_viatura: vinViatura,

                    nome_transportadora: transportadora,
                    nif_transportadora: nifTransportadora,



                    localidade: residenciaParticular,
                    codigo_postal: codigoPostalParticular,
                    telemovel_interno: telemovelInterno,
                    email_interno: emailInterno,
                    categoria_viatura: categoriaViatura,
                    tipo_viatura: tipoViatura,
                    pais_origem_viatura: paisOrigemViatura,
                    data_primeira_matricula_viatura: dataPrimeiraMatriculaViatura,
                    matricula_anterior_viatura: matriculaAnteriorViatura,
                    combustivel_viatura: combustivelViatura,
                    cilindrada_viatura: cilindradaViatura,
                    // morada_particular: moradaIndividual,
                    marca_viatura: marcaViatura,
                    modelo_viatura: modeloViatura,
                    matricula_viatura: matriculaViatura,

                    negocio_id: idDoNegocio,
                    data: dataDoDocumento,
                    tipo_documento: tipo,
                    tipo_sociedade: tipoSociedade,
                    doc_seguro: checkedValue,
                    tipo_contrato: tipoContrato,
                    user_id: dataUser.iDTokenAuthenticated,
                    token: dataUser.saveToken,
                };

                let dataCriarPDFParticularVariante = {};

                if (tipoContrato === "venda") {
                    dataCriarPDFParticularVariante = {

                        marca_viatura: marcaViatura,
                        modelo_viatura: modeloViatura,
                        matricula_viatura: matriculaViatura,
                        percentagem_servico_prestado: percentagemServicoPrestado,
                        valor_deposito: valorDeposito,
                    };
                }

                if (tipoContrato === "importacao" || tipoContrato === "legalizacao") {
                    dataCriarPDFParticularVariante = {
                        nome_transportadora: transportadora,
                        nif_transportadora: nifTransportadora,
                        email_particular: emailParticular,
                        tipo_idenficacao: escolhaDeDocumento,
                        numero_passaporte_particular: numeroPassaporteParticular,
                        valor_servico_prestado: valorServicoPrestado,
                        email_responsavel: emailResponsavel,
                    };
                }

                const novoDataCriarPDFParticular = { ...dataCriarPDFParticular, ...dataCriarPDFParticularVariante };

                const arrayDataCriarPDFParticular = novoDataCriarPDFParticular

                axios.post(`${process.env.REACT_APP_URL_CREATE_OLD}`, arrayDataCriarPDFParticular, {})
                    .then((response) => {
                        if (response.data.sucesso) {
                            setLoading(false)
                            setUrlPDFGerado(response.data.mensagem)
                            // //console.log(urlPDFGerado)
                        } else {
                            setLoading(false)
                            setMensagemDeErroAoGerar(response.data.mensagem)
                        }

                    })
                    .catch((error) => {
                        setLoading(false)
                    })
                    .finally(() => {
                    });

            } else if (tipo === "credito") {


                setLoading(true)
                setMensagemDeErroAoGerar('')

                let dataCriarPDF

                if (tipoSociedade === "empresa") {
                    dataCriarPDF = {


                        nome_gerente: nomeSocioEmpresa,
                        nif_gerente: nifGerente,
                        nome_empresa: nomeEmpresa,
                        sede_empresa: sedeEmpresa,
                        numero_socios: dataSociosQuatro.length < 0 ? '' : dataSociosQuatro,
                        nif_empresa: nifEmpresa,



                        marca_viatura: marcaViatura,
                        vin_viatura: vinViatura,
                        modelo_viatura: modeloViatura,
                        pais_origem_viatura: paisDeOrigem,


                        valor_chave_na_mao: valorChaveNaMao,
                        valor_chave_na_mao_extenso: valorChaveNaMaoExtenso,
                        quantia_entrega_importrust: quantidaEntregueImportrust,
                        quantia_entrega_importrust_extenso: quantidaEntregueImportrustExtenso,
                        taxa_juros: taxasDeJuros,

                        negocio_id: idDoNegocio,
                        user_id: dataUser.iDTokenAuthenticated,
                        token: dataUser.saveToken,
                        tipo_documento: tipo,
                        tipo_sociedade: tipoSociedade,
                        tipo_contrato: tipoContrato,
                        data: dataDoDocumento,

                    }
                } else {
                    dataCriarPDF = {

                        nome_particular: nomeIndividual,
                        nif_particular: nifParticular,
                        numero_passaporte_particular: numeroPassaporteParticular,
                        residencia_particular: residenciaParticular,

                        marca_viatura: marcaViatura,
                        vin_viatura: vinViatura,
                        modelo_viatura: modeloViatura,
                        pais_origem_viatura: paisDeOrigem,


                        valor_chave_na_mao: valorChaveNaMao,
                        valor_chave_na_mao_extenso: valorChaveNaMaoExtenso,
                        quantia_entrega_importrust: quantidaEntregueImportrust,
                        quantia_entrega_importrust_extenso: quantidaEntregueImportrustExtenso,
                        taxa_juros: taxasDeJuros,

                        negocio_id: idDoNegocio,
                        user_id: dataUser.iDTokenAuthenticated,
                        token: dataUser.saveToken,
                        tipo_documento: tipo,
                        tipo_sociedade: tipoSociedade,
                        tipo_contrato: tipoContrato,
                        data: dataDoDocumento,

                    }
                }

                axios.post(`${process.env.REACT_APP_URL_CREATE_OLD}`, dataCriarPDF, {})
                    .then((response) => {

                        ////console.log('asdasasd')

                        if (response.data.sucesso) {
                            setLoading(false)
                            setUrlPDFGerado(response.data.mensagem)
                        } else {
                            setLoading(false)
                            setMensagemDeErroAoGerar(response.data.mensagem)
                        }

                    })
                    .catch((error) => {
                        setLoading(false)
                    })
                    .finally(() => {
                    });


            } else if (tipo === "autorizacao_levantamento") {

                // //console.log('(tipo === "autorizacao_levantamento")')


                setLoading(true)
                setMensagemDeErroAoGerar('')


                let dataCriarPDF

                if (tipoSociedade === "empresa") {
                    dataCriarPDF = {

                        nome_empresa: nomeEmpresa,
                        nif_empresa: nifEmpresa,

                        nome_gerente: nomeSocioEmpresa,
                        numero_cc: numeroCCEmpresa,

                        nome_transportadora: transportadora,
                        nif_transportadora: nifTransportadora,

                        marca_modelo_viatura: marcaViatura,
                        vin_viatura: vinViatura,

                        numero_socios: dataSociosQuatro.length < 0 ? '' : dataSociosQuatro,


                        negocio_id: idDoNegocio,
                        user_id: dataUser.iDTokenAuthenticated,
                        token: dataUser.saveToken,
                        tipo_documento: tipo,
                        tipo_sociedade: tipoSociedade,
                        data: dataDoDocumento,

                    }
                } else {
                    dataCriarPDF = {

                        nome_particular: nomeIndividual,
                        // nif_particular: nifParticular,
                        numero_cc: numeroCCIndividual,

                        nome_transportadora: transportadora,
                        nif_transportadora: nifTransportadora,

                        marca_modelo_viatura: marcaViatura,
                        vin_viatura: vinViatura,

                        negocio_id: idDoNegocio,
                        user_id: dataUser.iDTokenAuthenticated,
                        token: dataUser.saveToken,
                        tipo_documento: tipo,
                        tipo_sociedade: tipoSociedade,
                        data: dataDoDocumento,

                    }
                }



                axios.post(`${process.env.REACT_APP_URL_CREATE_OLD}`, dataCriarPDF, {})
                    .then((response) => {

                        ////console.log('asdasasd')

                        if (response.data.sucesso) {
                            setLoading(false)
                            setUrlPDFGerado(response.data.mensagem)
                        } else {
                            setLoading(false)
                            setMensagemDeErroAoGerar(response.data.mensagem)
                        }

                    })
                    .catch((error) => {
                        setLoading(false)
                    })
                    .finally(() => {
                    });


            } else if (tipo === "modelo_9") {
                // //console.log('(tipo === "modelo_9")')
                setLoading(true)
                setMensagemDeErroAoGerar('')

                //ambos
                let dataCriarPDF = {
                    localidade: residenciaParticular,
                    codigo_postal: codigoPostalParticular,
                    telemovel_interno: telemovelInterno,
                    email_interno: emailInterno,
                    validade_cc: validadeCCIndivual,
                    numero_cc: numeroCCIndividual,
                    // matricula_viatura: matriculaViatura,
                    marca_viatura: marcaViatura,
                    modelo_viatura: modeloViatura,
                    categoria_viatura: categoriaViatura,
                    tipo_viatura: tipoViatura,
                    vin_viatura: vinViatura,
                    combustivel_viatura: combustivelViatura,
                    cilindrada_viatura: cilindradaViatura,
                    matricula_anterior_viatura: matriculaAnteriorViatura,
                    data_primeira_matricula_viatura: dataPrimeiraMatriculaViatura,
                    pais_origem_viatura: paisOrigemViatura,
                    pais_emissor_cc: paisEmissorIndivual,

                    negocio_id: idDoNegocio,
                    user_id: dataUser.iDTokenAuthenticated,
                    token: dataUser.saveToken,
                    tipo_documento: tipo,
                    tipo_sociedade: tipoSociedade,
                    tipo_contrato: tipoContrato,
                    data: dataDoDocumento,
                }

                if (tipoSociedade === "empresa") {
                    Object.assign(dataCriarPDF, {
                        nome_empresa: nomeEmpresa,
                        sede_empresa: sedeEmpresa,
                        numero_certidao_permanente: numeroCertidaoPermanente,
                        nif_empresa: nifEmpresa,
                    })
                } else {
                    Object.assign(dataCriarPDF, {
                        nome_particular: nomeIndividual,
                        residencia_particular: moradaIndividual,
                        nif_particular: nifParticular,
                    })
                }

                axios.post(`${process.env.REACT_APP_URL_CREATE_OLD}`, dataCriarPDF, {})
                    .then((response) => {
                        // //console.log(response)
                        if (response.data.sucesso) {
                            setLoading(false)
                            setUrlPDFGerado(response.data.mensagem)
                        } else {
                            setLoading(false)
                            setMensagemDeErroAoGerar(response.data.mensagem)
                        }
                    })
                    .catch((error) => {
                        setLoading(false)
                    })

                // //console.log('correu')
            }


        }
        // }
        // return true;
    }



    const handlePrev = () => {
        setCurrentStep(currentStep - 1);
        setDataSociosDois([]);
        setDataSociosQuatro([]);
    };


    useEffect(() => {
        // se currentStep for 0
        setDisabledButtonPrev(currentStep === 0);

        //  se currentStep for 2
        setDisabledButtonNext(currentStep === 2);

        setDataSociosDois([]);
        setDataSociosQuatro([]);

    }, [currentStep]);


    const handleStepClick = step => {
        setCurrentStep(step);
    };


    const handleTipoChange = (e) => {
        setTipo(e);
        setDataSociosDois([]);
        setDataSociosQuatro([]);
        if (e === "procuracao") {
            setTipoContrato('');
        }
    };

    const handleTipoSociedadeChange = (e) => {
        setTipoSociedade(e);
        setDataSociosDois([]);
        setDataSociosQuatro([]);
        // //console.log('2')
    };

    const handleTipoContratoChange = (e) => {
        setTipoContrato(e);
        // //console.log('3')
        setDataSociosDois([]);
        setDataSociosQuatro([]);
    };


    const handleNewDocument = () => {
        setCurrentStep(0)
    }


    const onChange = (checked) => {
        setCheckedValue(checked ? 1 : 0); // Define 1 se estiver ativo, 0 se não
    };

    // //console.log(checkedValue)

    return (
        <>
            <div className='card m-auto w-full'>
                <Steps className='text-primary mb-5' current={currentStep}>
                    <Step title="Seleção" onClick={() => handleStepClick(0)} />
                    <Step title="Dados" onClick={() => handleStepClick(1)} />
                    <Step title="Gerar" onClick={() => handleStepClick(2)} status={currentStep === 2 ? "finish" : "process"} />
                </Steps>
                {currentStep === 0 && (
                    <div className='flex flex-col border-2 border-main p-4 rounded gap-4'>
                        <p className="text-base font-semibold text-white">Seleciona o tipo de documento que vamos gerar:</p>
                        <div className='grid grid-cols-2 gap-4 text-white'>
                            <div className='grid gap-2 '>
                                <p>Tipo</p>
                                <Select
                                    // mode="multiple"
                                    placeholder="Selecione o tipo"
                                    value={tipo ? tipo : undefined}
                                    onChange={handleTipoChange}

                                >
                                    <Option value="contrato">Contrato</Option>
                                    <Option value="procuracao">Procuração</Option>
                                    <Option value="autorizacao_levantamento">Autorização de Levantamento Automática</Option>
                                    <Option value="credito">Crédito</Option>
                                    <Option value="modelo_9">Modelo 9</Option>
                                    <Option value="novo_negocio">Novo Negócio</Option>
                                </Select>
                            </div>
                            <div className='grid gap-2'>
                                <p>Cliente</p>
                                <Select

                                    placeholder="Selecione cliente"
                                    value={tipoSociedade ? tipoSociedade : undefined}
                                    onChange={handleTipoSociedadeChange}
                                >
                                    <Option value="particular">Particular</Option>
                                    <Option value="empresa">Empresa</Option>
                                </Select>
                            </div>
                            {tipo === "novo_negocio" || tipo === "contrato" ? (
                                <div className='grid gap-2'>
                                    <p>Tipo de contrato</p>
                                    <Select

                                        placeholder="Selecione o tipo de contrato"
                                        value={tipoContrato ? tipoContrato : undefined}
                                        onChange={handleTipoContratoChange}
                                    >
                                        <Option value="legalizacao">Legalização</Option>
                                        <Option value="venda">Venda</Option>
                                        <Option value="importacao">Importação</Option>
                                    </Select>
                                </div>
                            ) : null}
                        </div>
                    </div>
                )}
                {currentStep === 1 && (
                    <div className='flex flex-col gap-4 border-2 border-main p-4 rounded text-white'>

                        <form ref={Form} className='grid grid-cols-1 gap-4' onSubmit={handleNext}>

                            <div className='flex flex-col gap-4'>
                                <DadosAnterioresContratos tipoProp={tipo} tipoPropSociedade={tipoSociedade}
                                    tipoPropContrato={tipoContrato} />
                            </div>
                            {tipo === "novo_negocio" || tipo === "contrato" ? (
                                // <div className="flex items-center justify-end">
                                //     Anexar Documento do Seguro?
                                //     <Switch className="ml-2" value={checkedValue} onChange={onChange} />
                                // </div>
                                <div className="flex items-center justify-end">
                                    Anexar Documento do Seguro?
                                    <Switch className="ml-2" checked={checkedValue === 1} onChange={onChange} />
                                </div>
                            ) : null}

                            <p className="text-base font-semibold">Prenche todos campos:</p>
                            {tipo === "procuracao" ? (
                                <>
                                    {tipoSociedade === "particular" ? (
                                        <CompProcuracaoParticular
                                            dataDoDocumento={dataDoDocumento}
                                            setDataDoDocumento={setDataDoDocumento}
                                            nomeIndividual={nomeIndividual}
                                            setNomeIndividual={setNomeIndividual}
                                            moradaIndividual={moradaIndividual}
                                            setMoradaIndividual={setMoradaIndividual}
                                            numeroCCIndividual={numeroCCIndividual}
                                            setNumeroCCIndivual={setNumeroCCIndivual}
                                            validadeCCIndivual={validadeCCIndivual}
                                            setValidadeCCIndividual={setValidadeCCIndividual}
                                            paisEmissorIndivual={paisEmissorIndivual}
                                            setPaisEmissorIndividual={setPaisEmissorIndividual}
                                            alertaDataDocumento={alertaDataDocumento}
                                            alertaDataValidadeCC={alertaDataValidadeCC}
                                            idDoNegocio={idDoNegocio}
                                            setIDdoNegocio={setIDdoNegocio}
                                        />
                                    ) : (
                                        <CompProcuracaoEmpresa
                                            dataDoDocumento={dataDoDocumento}
                                            setDataDoDocumento={setDataDoDocumento}
                                            nomeSocioEmpresa={nomeSocioEmpresa}
                                            setNomeSocioEmpresa={setNomeSocioEmpresa}
                                            numeroCCEmpresa={numeroCCEmpresa}
                                            setNumeroCCEmpresa={setNumeroCCEmpresa}
                                            validadeCCEmpresa={validadeCCEmpresa}
                                            setValidadeCCEmpresa={setValidadeCCEmpresa}
                                            paisEmissorEmpresa={paisEmissorEmpresa}
                                            setPaisEmissorEmpresa={setPaisEmissorEmpresa}
                                            nomeEmpresa={nomeEmpresa}
                                            setNomeEmpresa={setNomeEmpresa}
                                            sedeEmpresa={sedeEmpresa}
                                            setSedeEmpresa={setSedeEmpresa}
                                            conservatoriaEmpresa={conservatoriaEmpresa}
                                            setConservatoriaEmpresa={setConservatoriaEmpresa}
                                            nifEmpresa={nifEmpresa}
                                            setNifEmpresa={setNifEmpresa}
                                            capitalSocialEmpresa={capitalSocialEmpresa}
                                            setCapitalSocialEmpresa={setCapitalSocialEmpresa}
                                            alertaDataDocumento={alertaDataDocumento}
                                            alertaDataValidadeCC={alertaDataValidadeCC}
                                            setDataSociosQuatro={setDataSociosQuatro}
                                            tipo={tipo}
                                            idDoNegocio={idDoNegocio}
                                            setIDdoNegocio={setIDdoNegocio}
                                        />
                                    )}
                                </>
                            ) : tipo === "contrato" ? (
                                <>
                                    {tipoSociedade === "particular" ? (
                                        <>
                                            {tipoContrato === "legalizacao" || tipoContrato === "importacao" ? (
                                                <>
                                                    <CompContratoParticular
                                                        tipo={tipo}
                                                        tipoContrato={tipoContrato}
                                                        tipoSociedade={tipoSociedade}
                                                        dataDoDocumento={dataDoDocumento}
                                                        setDataDoDocumento={setDataDoDocumento}
                                                        nomeIndividual={nomeIndividual}
                                                        setNomeIndividual={setNomeIndividual}
                                                        moradaIndividual={moradaIndividual}
                                                        setMoradaIndividual={setMoradaIndividual}
                                                        nifParticular={nifParticular}
                                                        setNifParticular={setNifParticular}
                                                        numeroPassaporteParticular={numeroPassaporteParticular}
                                                        setNumeroPassaporteParticular={setNumeroPassaporteParticular}
                                                        valorServicoPrestado={valorServicoPrestado}
                                                        setValorServicoPrestado={setValorServicoPrestado}
                                                        emailResponsavel={emailResponsavel}
                                                        setEmailResponsavel={setEmailResponsavel}
                                                        escolhaDeDocumento={escolhaDeDocumento}
                                                        setEscolhaDeDocumento={setEscolhaDeDocumento}
                                                        alertaDataDocumento={alertaDataDocumento}
                                                        emailParticular={emailParticular}
                                                        setEmailParticular={setEmailParticular}
                                                        idDoNegocio={idDoNegocio}
                                                        setIDdoNegocio={setIDdoNegocio}

                                                    />
                                                </>
                                            ) : (
                                                <>
                                                    <CompContratoParticular
                                                        tipo={tipo}
                                                        tipoContrato={tipoContrato}
                                                        tipoSociedade={tipoSociedade}
                                                        handleNext={handleNext}
                                                        dataDoDocumento={dataDoDocumento}
                                                        setDataDoDocumento={setDataDoDocumento}
                                                        nomeIndividual={nomeIndividual}
                                                        setNomeIndividual={setNomeIndividual}
                                                        moradaIndividual={moradaIndividual}
                                                        setMoradaIndividual={setMoradaIndividual}
                                                        nifParticular={nifParticular}
                                                        setNifParticular={setNifParticular}
                                                        numeroPassaporteParticular={numeroPassaporteParticular}
                                                        setNumeroPassaporteParticular={setNumeroPassaporteParticular}
                                                        valorServicoPrestado={valorServicoPrestado}
                                                        setValorServicoPrestado={setValorServicoPrestado}
                                                        emailResponsavel={emailResponsavel}
                                                        setEmailResponsavel={setEmailResponsavel}
                                                        escolhaDeDocumento={escolhaDeDocumento}
                                                        setEscolhaDeDocumento={setEscolhaDeDocumento}
                                                        marcaViatura={marcaViatura}
                                                        setMarcaViatura={setMarcaViatura}
                                                        modeloViatura={modeloViatura}
                                                        setModeloViatura={setModeloViatura}
                                                        matriculaViatura={matriculaViatura}
                                                        setMatriculaViatura={setMatriculaViatura}
                                                        vinViatura={vinViatura}
                                                        setVinViatura={setVinViatura}
                                                        // seguroViatura={seguroViatura}
                                                        // setSeguroViatura={setSeguroViatura}
                                                        percentagemServicoPrestado={percentagemServicoPrestado}
                                                        setPercentagemServicoPrestado={setPercentagemServicoPrestado}
                                                        valorDeposito={valorDeposito}
                                                        setValorDeposito={setValorDeposito}
                                                        alertaDataDocumento={alertaDataDocumento}
                                                        idDoNegocio={idDoNegocio}
                                                        setIDdoNegocio={setIDdoNegocio}
                                                    />

                                                </>
                                            )}

                                        </>
                                    ) : (
                                        <>
                                            {tipoContrato === "legalizacao" || tipoContrato === "importacao" ? (
                                                <>
                                                    <CompContratoEmpresa
                                                        dataDoDocumento={dataDoDocumento}
                                                        setDataDoDocumento={setDataDoDocumento}
                                                        nomeSocioEmpresa={nomeSocioEmpresa}
                                                        setNomeSocioEmpresa={setNomeSocioEmpresa}
                                                        numeroCCEmpresa={numeroCCEmpresa}
                                                        setNumeroCCEmpresa={setNumeroCCEmpresa}
                                                        nomeEmpresa={nomeEmpresa}
                                                        setNomeEmpresa={setNomeEmpresa}
                                                        sedeEmpresa={sedeEmpresa}
                                                        setSedeEmpresa={setSedeEmpresa}
                                                        nifEmpresa={nifEmpresa}
                                                        setNifEmpresa={setNifEmpresa}
                                                        emailDaEmpresa={emailDaEmpresa}
                                                        setEmailDaEmpresa={setEmailDaEmpresa}
                                                        valorServicoPrestado={valorServicoPrestado}
                                                        setValorServicoPrestado={setValorServicoPrestado}
                                                        nifGerente={nifGerente}
                                                        setNifGerente={setNifGerente}
                                                        emailResponsavel={emailResponsavel}
                                                        setEmailResponsavel={setEmailResponsavel}
                                                        alertaDataDocumento={alertaDataDocumento}
                                                        setDataSociosDois={setDataSociosDois}
                                                        tipo={tipo}
                                                        idDoNegocio={idDoNegocio}
                                                        setIDdoNegocio={setIDdoNegocio}
                                                    />
                                                </>
                                            ) : (
                                                <>
                                                    <CompContratoEmpresa
                                                        tipoContrato={tipoContrato}
                                                        tipoSociedade={tipoSociedade}

                                                        dataDoDocumento={dataDoDocumento}
                                                        setDataDoDocumento={setDataDoDocumento}
                                                        nomeSocioEmpresa={nomeSocioEmpresa}
                                                        setNomeSocioEmpresa={setNomeSocioEmpresa}
                                                        numeroCCEmpresa={numeroCCEmpresa}
                                                        setNumeroCCEmpresa={setNumeroCCEmpresa}
                                                        nomeEmpresa={nomeEmpresa}
                                                        setNomeEmpresa={setNomeEmpresa}
                                                        sedeEmpresa={sedeEmpresa}
                                                        setSedeEmpresa={setSedeEmpresa}
                                                        nifEmpresa={nifEmpresa}
                                                        setNifEmpresa={setNifEmpresa}
                                                        emailDaEmpresa={emailDaEmpresa}
                                                        setEmailDaEmpresa={setEmailDaEmpresa}
                                                        valorServicoPrestado={valorServicoPrestado}
                                                        setValorServicoPrestado={setValorServicoPrestado}
                                                        nifGerente={nifGerente}
                                                        setNifGerente={setNifGerente}
                                                        emailResponsavel={emailResponsavel}
                                                        setEmailResponsavel={setEmailResponsavel}

                                                        marcaViatura={marcaViatura}
                                                        setMarcaViatura={setMarcaViatura}
                                                        modeloViatura={modeloViatura}
                                                        setModeloViatura={setModeloViatura}
                                                        matriculaViatura={matriculaViatura}
                                                        setMatriculaViatura={setMatriculaViatura}
                                                        vinViatura={vinViatura}
                                                        setVinViatura={setVinViatura}
                                                        // seguroViatura={seguroViatura}
                                                        // setSeguroViatura={setSeguroViatura}
                                                        percentagemServicoPrestado={percentagemServicoPrestado}
                                                        setPercentagemServicoPrestado={setPercentagemServicoPrestado}
                                                        valorDeposito={valorDeposito}
                                                        setValorDeposito={setValorDeposito}
                                                        alertaDataDocumento={alertaDataDocumento}
                                                        setDataSociosDois={setDataSociosDois}
                                                        tipo={tipo}
                                                        idDoNegocio={idDoNegocio}
                                                        setIDdoNegocio={setIDdoNegocio}
                                                    />

                                                </>
                                            )}
                                        </>
                                    )}

                                </>
                            ) : tipo === "novo_negocio" ? (
                                <>
                                    {tipoSociedade === "particular" ? (

                                        <>
                                            <Tabs activeKey={activeTabStepDoisKey} onChange={handleTabsStepDoisChange}>
                                                <TabPane tab="Procuração" key="1">
                                                    <div className="text-white text-base grid grid-cols-1 gap-4">
                                                        <CompProcuracaoParticular
                                                            dataDoDocumento={dataDoDocumento}
                                                            setDataDoDocumento={setDataDoDocumento}
                                                            nomeIndividual={nomeIndividual}
                                                            setNomeIndividual={setNomeIndividual}
                                                            moradaIndividual={moradaIndividual}
                                                            setMoradaIndividual={setMoradaIndividual}
                                                            numeroCCIndividual={numeroCCIndividual}
                                                            setNumeroCCIndivual={setNumeroCCIndivual}
                                                            validadeCCIndivual={validadeCCIndivual}
                                                            setValidadeCCIndividual={setValidadeCCIndividual}
                                                            paisEmissorIndivual={paisEmissorIndivual}
                                                            setPaisEmissorIndividual={setPaisEmissorIndividual}
                                                            numeroPassaporteParticular={numeroPassaporteParticular}
                                                            setNumeroPassaporteParticular={setNumeroPassaporteParticular}
                                                            valorServicoPrestado={valorServicoPrestado}
                                                            setValorServicoPrestado={setValorServicoPrestado}
                                                            emailResponsavel={emailResponsavel}
                                                            setEmailResponsavel={setEmailResponsavel}
                                                            alertaDataDocumento={alertaDataDocumento}
                                                            alertaDataValidadeCC={alertaDataValidadeCC}
                                                            idDoNegocio={idDoNegocio}
                                                            setIDdoNegocio={setIDdoNegocio}
                                                        />
                                                    </div>
                                                </TabPane>
                                                <TabPane tab="Contrato" key="2">
                                                    <div className="text-white text-base grid grid-cols-1 gap-4">
                                                        <CompContratoParticular
                                                            tipoContrato={tipoContrato}
                                                            tipoSociedade={tipoSociedade}

                                                            dataDoDocumento={dataDoDocumento}
                                                            setDataDoDocumento={setDataDoDocumento}
                                                            nomeIndividual={nomeIndividual}
                                                            setNomeIndividual={setNomeIndividual}
                                                            moradaIndividual={moradaIndividual}
                                                            setMoradaIndividual={setMoradaIndividual}
                                                            nifParticular={nifParticular}
                                                            setNifParticular={setNifParticular}
                                                            numeroPassaporteParticular={numeroPassaporteParticular}
                                                            setNumeroPassaporteParticular={setNumeroPassaporteParticular}
                                                            valorServicoPrestado={valorServicoPrestado}
                                                            setValorServicoPrestado={setValorServicoPrestado}
                                                            emailResponsavel={emailResponsavel}
                                                            setEmailResponsavel={setEmailResponsavel}
                                                            emailParticular={emailParticular}
                                                            setEmailParticular={setEmailParticular}
                                                            escolhaDeDocumento={escolhaDeDocumento}
                                                            setEscolhaDeDocumento={setEscolhaDeDocumento}
                                                            marcaViatura={marcaViatura}
                                                            setMarcaViatura={setMarcaViatura}
                                                            modeloViatura={modeloViatura}
                                                            setModeloViatura={setModeloViatura}
                                                            setPaisEmissorIndividual={setPaisEmissorIndividual}
                                                            matriculaViatura={matriculaViatura}
                                                            setMatriculaViatura={setMatriculaViatura}
                                                            vinViatura={vinViatura}
                                                            setVinViatura={setVinViatura}
                                                            // seguroViatura={seguroViatura}
                                                            // setSeguroViatura={setSeguroViatura}
                                                            percentagemServicoPrestado={percentagemServicoPrestado}
                                                            setPercentagemServicoPrestado={setPercentagemServicoPrestado}
                                                            valorDeposito={valorDeposito}
                                                            setValorDeposito={setValorDeposito}
                                                            alertaDataDocumento={alertaDataDocumento}
                                                            idDoNegocio={idDoNegocio}
                                                            setIDdoNegocio={setIDdoNegocio}
                                                        />
                                                    </div>
                                                </TabPane>
                                                {tipoContrato !== "legalizacao" && (
                                                    <TabPane tab="Autorização de Levantamento" key="3">
                                                        <div className="text-white text-base grid grid-cols-1 gap-4">
                                                            <AutoTransporteParticular
                                                                tipo={tipo}
                                                                tipoSociedade={tipoSociedade}
                                                                tipoContrato={tipoContrato}


                                                                nomeIndividual={nomeIndividual}
                                                                setNomeIndividual={setNomeIndividual}
                                                                nomeSocioEmpresa={nomeSocioEmpresa}
                                                                setNomeSocioEmpresa={setNomeSocioEmpresa}
                                                                numeroCCEmpresa={numeroCCEmpresa}
                                                                setNumeroCCEmpresa={setNumeroCCEmpresa}
                                                                nomeEmpresa={nomeEmpresa}
                                                                setNomeEmpresa={setNomeEmpresa}
                                                                nifEmpresa={nifEmpresa}
                                                                setNifEmpresa={setNifEmpresa}
                                                                numeroCCIndividual={numeroCCIndividual}
                                                                setNumeroCCIndivual={setNumeroCCIndivual}

                                                                transportadora={transportadora}
                                                                setTransportadora={setTransportadora}
                                                                nifTransportadora={nifTransportadora}
                                                                setNifTransportadora={setNifTransportadora}

                                                                marcaViatura={marcaViatura}
                                                                setMarcaViatura={setMarcaViatura}
                                                                modeloViatura={modeloViatura}
                                                                setModeloViatura={setModeloViatura}
                                                                vinViatura={vinViatura}
                                                                setVinViatura={setVinViatura}

                                                                dataDoDocumento={dataDoDocumento}
                                                                setDataDoDocumento={setDataDoDocumento}
                                                                alertaDataDocumento={alertaDataDocumento}
                                                                idDoNegocio={idDoNegocio}
                                                                setIDdoNegocio={setIDdoNegocio}

                                                                setDataSociosQuatro={setDataSociosQuatro}
                                                                alertaDataValidadeCC={alertaDataValidadeCC}
                                                            />
                                                        </div>
                                                    </TabPane>
                                                )}
                                                <TabPane tab="Modelo 9" key="4">
                                                    <div className="text-white text-base grid grid-cols-1 gap-4">
                                                        <CompModelo9Particular

                                                            nomeEmpresa={nomeEmpresa}
                                                            setNomeEmpresa={setNomeEmpresa}
                                                            sedeEmpresa={sedeEmpresa}
                                                            setSedeEmpresa={setSedeEmpresa}
                                                            nifEmpresa={nifEmpresa}
                                                            setNifEmpresa={setNifEmpresa}

                                                            nomeIndividual={nomeIndividual}
                                                            setNomeIndividual={setNomeIndividual}
                                                            moradaIndividual={moradaIndividual}
                                                            setMoradaIndividual={setMoradaIndividual}
                                                            residenciaParticular={residenciaParticular}
                                                            setResidenciaParticular={setResidenciaParticular}
                                                            codigoPostalParticular={codigoPostalParticular}
                                                            setCodigoPostalParticular={setCodigoPostalParticular}
                                                            nifParticular={nifParticular}
                                                            setNifParticular={setNifParticular}
                                                            telemovelInterno={telemovelInterno}
                                                            setTelemovelInterno={setTelemovelInterno}
                                                            emailInterno={emailInterno}
                                                            setEmailInterno={setEmailInterno}
                                                            numeroCCIndividual={numeroCCIndividual}
                                                            setNumeroCCIndivual={setNumeroCCIndivual}
                                                            validadeCCIndivual={validadeCCIndivual}
                                                            setValidadeCCIndividual={setValidadeCCIndividual}
                                                            numeroCertidaoPermanente={numeroCertidaoPermanente}
                                                            setNumeroCertidaoPermanente={setNumeroCertidaoPermanente}


                                                            marcaViatura={marcaViatura}
                                                            setMarcaViatura={setMarcaViatura}
                                                            modeloViatura={modeloViatura}
                                                            setModeloViatura={setModeloViatura}
                                                            vinViatura={vinViatura}
                                                            setVinViatura={setVinViatura}
                                                            matriculaViatura={matriculaViatura}
                                                            setMatriculaViatura={setMatriculaViatura}
                                                            categoriaViatura={categoriaViatura}
                                                            setCategoriaViatura={setCategoriaViatura}
                                                            tipoViatura={tipoViatura}
                                                            setTipoViatura={setTipoViatura}
                                                            combustivelViatura={combustivelViatura}
                                                            setCombustivelViatura={setCombustivelViatura}
                                                            cilindradaViatura={cilindradaViatura}
                                                            setCilindradaViatura={setCilindradaViatura}
                                                            matriculaAnteriorViatura={matriculaAnteriorViatura}
                                                            setMatriculaAnteriorViatura={setMatriculaAnteriorViatura}
                                                            dataPrimeiraMatriculaViatura={dataPrimeiraMatriculaViatura}
                                                            setDataPrimeiraMatriculaViatura={setDataPrimeiraMatriculaViatura}
                                                            paisOrigemViatura={paisOrigemViatura}
                                                            setPaisOrigemViatura={setPaisOrigemViatura}
                                                            setPaisEmissorIndividual={setPaisEmissorIndividual}

                                                            tipo={tipo}
                                                            tipoSociedade={tipoSociedade}
                                                            tipoContrato={tipoContrato}
                                                            dataDoDocumento={dataDoDocumento}
                                                            setDataDoDocumento={setDataDoDocumento}
                                                            alertaDataDocumento={alertaDataDocumento}
                                                            idDoNegocio={idDoNegocio}
                                                            setIDdoNegocio={setIDdoNegocio}
                                                        />
                                                    </div>
                                                </TabPane>
                                            </Tabs>

                                        </>
                                    ) : (
                                        <>
                                            <Tabs activeKey={activeTabStepDoisKey} onChange={handleTabsStepDoisChange}>
                                                <TabPane tab="Procuração" key="1">
                                                    <div className="text-white text-base grid grid-cols-1 gap-4">
                                                        <CompProcuracaoEmpresa
                                                            dataDoDocumento={dataDoDocumento}
                                                            setDataDoDocumento={setDataDoDocumento}
                                                            nomeSocioEmpresa={nomeSocioEmpresa}
                                                            setNomeSocioEmpresa={setNomeSocioEmpresa}
                                                            numeroCCEmpresa={numeroCCEmpresa}
                                                            setNumeroCCEmpresa={setNumeroCCEmpresa}
                                                            validadeCCEmpresa={validadeCCEmpresa}
                                                            setValidadeCCEmpresa={setValidadeCCEmpresa}
                                                            paisEmissorEmpresa={paisEmissorEmpresa}
                                                            setPaisEmissorEmpresa={setPaisEmissorEmpresa}
                                                            nomeEmpresa={nomeEmpresa}
                                                            setNomeEmpresa={setNomeEmpresa}
                                                            sedeEmpresa={sedeEmpresa}
                                                            setSedeEmpresa={setSedeEmpresa}
                                                            conservatoriaEmpresa={conservatoriaEmpresa}
                                                            setConservatoriaEmpresa={setConservatoriaEmpresa}
                                                            nifEmpresa={nifEmpresa}
                                                            setNifEmpresa={setNifEmpresa}
                                                            capitalSocialEmpresa={capitalSocialEmpresa}
                                                            setCapitalSocialEmpresa={setCapitalSocialEmpresa}
                                                            alertaDataDocumento={alertaDataDocumento}
                                                            alertaDataValidadeCC={alertaDataValidadeCC}
                                                            setDataSociosQuatro={setDataSociosQuatro}
                                                            tipo={tipo}
                                                            idDoNegocio={idDoNegocio}
                                                            setIDdoNegocio={setIDdoNegocio}
                                                        />
                                                    </div>
                                                </TabPane>
                                                <TabPane tab="Contrato" key="2">
                                                    <div className="text-white text-base grid grid-cols-1 gap-4">
                                                        <CompContratoEmpresa
                                                            tipoContrato={tipoContrato}
                                                            tipoSociedade={tipoSociedade}

                                                            dataDoDocumento={dataDoDocumento}
                                                            setDataDoDocumento={setDataDoDocumento}
                                                            nomeSocioEmpresa={nomeSocioEmpresa}
                                                            setNomeSocioEmpresa={setNomeSocioEmpresa}
                                                            numeroCCEmpresa={numeroCCEmpresa}
                                                            setNumeroCCEmpresa={setNumeroCCEmpresa}
                                                            nomeEmpresa={nomeEmpresa}
                                                            setNomeEmpresa={setNomeEmpresa}
                                                            sedeEmpresa={sedeEmpresa}
                                                            setSedeEmpresa={setSedeEmpresa}
                                                            nifEmpresa={nifEmpresa}
                                                            setNifEmpresa={setNifEmpresa}
                                                            emailDaEmpresa={emailDaEmpresa}
                                                            setEmailDaEmpresa={setEmailDaEmpresa}
                                                            valorServicoPrestado={valorServicoPrestado}
                                                            setValorServicoPrestado={setValorServicoPrestado}
                                                            nifGerente={nifGerente}
                                                            setNifGerente={setNifGerente}
                                                            emailResponsavel={emailResponsavel}
                                                            setEmailResponsavel={setEmailResponsavel}

                                                            marcaViatura={marcaViatura}
                                                            setMarcaViatura={setMarcaViatura}
                                                            modeloViatura={modeloViatura}
                                                            setModeloViatura={setModeloViatura}
                                                            matriculaViatura={matriculaViatura}
                                                            setMatriculaViatura={setMatriculaViatura}
                                                            vinViatura={vinViatura}
                                                            setVinViatura={setVinViatura}
                                                            // seguroViatura={seguroViatura}
                                                            // setSeguroViatura={setSeguroViatura}
                                                            percentagemServicoPrestado={percentagemServicoPrestado}
                                                            setPercentagemServicoPrestado={setPercentagemServicoPrestado}
                                                            valorDeposito={valorDeposito}
                                                            setValorDeposito={setValorDeposito}
                                                            alertaDataDocumento={alertaDataDocumento}
                                                            setDataSociosDois={setDataSociosDois}
                                                            tipo={tipo}
                                                            idDoNegocio={idDoNegocio}
                                                            setIDdoNegocio={setIDdoNegocio}
                                                        />
                                                    </div>
                                                </TabPane>
                                                <TabPane tab="Autorização de Levantamento" key="3">
                                                    <div className="text-white text-base grid grid-cols-1 gap-4">
                                                        <AutoTransporteParticular
                                                            tipo={tipo}
                                                            tipoSociedade={tipoSociedade}
                                                            tipoContrato={tipoContrato}

                                                            nomeIndividual={nomeIndividual}
                                                            setNomeIndividual={setNomeIndividual}
                                                            nomeSocioEmpresa={nomeSocioEmpresa}
                                                            setNomeSocioEmpresa={setNomeSocioEmpresa}
                                                            numeroCCEmpresa={numeroCCEmpresa}
                                                            setNumeroCCEmpresa={setNumeroCCEmpresa}
                                                            nomeEmpresa={nomeEmpresa}
                                                            setNomeEmpresa={setNomeEmpresa}
                                                            nifEmpresa={nifEmpresa}
                                                            setNifEmpresa={setNifEmpresa}
                                                            numeroCCIndividual={numeroCCIndividual}
                                                            setNumeroCCIndivual={setNumeroCCIndivual}

                                                            transportadora={transportadora}
                                                            setTransportadora={setTransportadora}
                                                            nifTransportadora={nifTransportadora}
                                                            setNifTransportadora={setNifTransportadora}

                                                            marcaViatura={marcaViatura}
                                                            setMarcaViatura={setMarcaViatura}
                                                            modeloViatura={modeloViatura}
                                                            setModeloViatura={setModeloViatura}
                                                            vinViatura={vinViatura}
                                                            setVinViatura={setVinViatura}

                                                            idDoNegocio={idDoNegocio}
                                                            setIDdoNegocio={setIDdoNegocio}
                                                            dataDoDocumento={dataDoDocumento}
                                                            setDataDoDocumento={setDataDoDocumento}
                                                            alertaDataDocumento={alertaDataDocumento}
                                                            setDataSociosQuatro={setDataSociosQuatro}
                                                            alertaDataValidadeCC={alertaDataValidadeCC}
                                                        />
                                                    </div>
                                                </TabPane>
                                                <TabPane tab="Modelo 9" key="4">
                                                    <div className="text-white text-base grid grid-cols-1 gap-4">
                                                        <CompModelo9Particular

                                                            nomeEmpresa={nomeEmpresa}
                                                            setNomeEmpresa={setNomeEmpresa}
                                                            sedeEmpresa={sedeEmpresa}
                                                            setSedeEmpresa={setSedeEmpresa}
                                                            nifEmpresa={nifEmpresa}
                                                            setNifEmpresa={setNifEmpresa}

                                                            nomeIndividual={nomeIndividual}
                                                            setNomeIndividual={setNomeIndividual}
                                                            moradaIndividual={moradaIndividual}
                                                            setMoradaIndividual={setMoradaIndividual}
                                                            residenciaParticular={residenciaParticular}
                                                            setResidenciaParticular={setResidenciaParticular}
                                                            codigoPostalParticular={codigoPostalParticular}
                                                            setCodigoPostalParticular={setCodigoPostalParticular}
                                                            nifParticular={nifParticular}
                                                            setNifParticular={setNifParticular}
                                                            telemovelInterno={telemovelInterno}
                                                            setTelemovelInterno={setTelemovelInterno}
                                                            emailInterno={emailInterno}
                                                            setEmailInterno={setEmailInterno}
                                                            numeroCCIndividual={numeroCCIndividual}
                                                            setNumeroCCIndivual={setNumeroCCIndivual}
                                                            validadeCCIndivual={validadeCCIndivual}
                                                            setValidadeCCIndividual={setValidadeCCIndividual}
                                                            numeroCertidaoPermanente={numeroCertidaoPermanente}
                                                            setNumeroCertidaoPermanente={setNumeroCertidaoPermanente}
                                                            paisEmissorIndivual={paisEmissorIndivual}
                                                            setPaisEmissorIndividual={setPaisEmissorIndividual}

                                                            marcaViatura={marcaViatura}
                                                            setMarcaViatura={setMarcaViatura}
                                                            modeloViatura={modeloViatura}
                                                            setModeloViatura={setModeloViatura}
                                                            vinViatura={vinViatura}
                                                            setVinViatura={setVinViatura}
                                                            matriculaViatura={matriculaViatura}
                                                            setMatriculaViatura={setMatriculaViatura}
                                                            categoriaViatura={categoriaViatura}
                                                            setCategoriaViatura={setCategoriaViatura}
                                                            tipoViatura={tipoViatura}
                                                            setTipoViatura={setTipoViatura}
                                                            combustivelViatura={combustivelViatura}
                                                            setCombustivelViatura={setCombustivelViatura}
                                                            cilindradaViatura={cilindradaViatura}
                                                            setCilindradaViatura={setCilindradaViatura}
                                                            matriculaAnteriorViatura={matriculaAnteriorViatura}
                                                            setMatriculaAnteriorViatura={setMatriculaAnteriorViatura}
                                                            dataPrimeiraMatriculaViatura={dataPrimeiraMatriculaViatura}
                                                            setDataPrimeiraMatriculaViatura={setDataPrimeiraMatriculaViatura}
                                                            paisOrigemViatura={paisOrigemViatura}
                                                            setPaisOrigemViatura={setPaisOrigemViatura}

                                                            idDoNegocio={idDoNegocio}
                                                            setIDdoNegocio={setIDdoNegocio}
                                                            tipo={tipo}
                                                            tipoSociedade={tipoSociedade}
                                                            tipoContrato={tipoContrato}
                                                            dataDoDocumento={dataDoDocumento}
                                                            setDataDoDocumento={setDataDoDocumento}
                                                            alertaDataDocumento={alertaDataDocumento}

                                                        />
                                                    </div>
                                                </TabPane>
                                            </Tabs>
                                        </>

                                    )}
                                </>

                            ) : tipo === "credito" ? (

                                <>
                                    <CompCreditoParticular
                                        tipoContrato={tipoContrato}
                                        tipoSociedade={tipoSociedade}
                                        dataDoDocumento={dataDoDocumento}
                                        setDataDoDocumento={setDataDoDocumento}
                                        alertaDataDocumento={alertaDataDocumento}
                                        idDoNegocio={idDoNegocio}
                                        setIDdoNegocio={setIDdoNegocio}

                                        nomeIndividual={nomeIndividual}
                                        setNomeIndividual={setNomeIndividual}
                                        residenciaParticular={residenciaParticular}
                                        setResidenciaParticular={setResidenciaParticular}
                                        nifParticular={nifParticular}
                                        setNifParticular={setNifParticular}
                                        numeroPassaporteParticular={numeroPassaporteParticular}
                                        setNumeroPassaporteParticular={setNumeroPassaporteParticular}

                                        nomeSocioEmpresa={nomeSocioEmpresa}
                                        setNomeSocioEmpresa={setNomeSocioEmpresa}
                                        nomeEmpresa={nomeEmpresa}
                                        setNomeEmpresa={setNomeEmpresa}
                                        sedeEmpresa={sedeEmpresa}
                                        setSedeEmpresa={setSedeEmpresa}
                                        nifEmpresa={nifEmpresa}
                                        setNifEmpresa={setNifEmpresa}
                                        nifGerente={nifGerente}
                                        setNifGerente={setNifGerente}
                                        numeroSocios={numeroSocios}
                                        setNumeroSocios={setNumeroSocios}
                                        setDataSociosQuatro={setDataSociosQuatro}

                                        paisDeOrigem={paisDeOrigem}
                                        setPaisDeOrigem={setPaisDeOrigem}
                                        marcaViatura={marcaViatura}
                                        setMarcaViatura={setMarcaViatura}
                                        modeloViatura={modeloViatura}
                                        setModeloViatura={setModeloViatura}
                                        vinViatura={vinViatura}
                                        setVinViatura={setVinViatura}

                                        valorChaveNaMao={valorChaveNaMao}
                                        setValorChaveNaMao={setValorChaveNaMao}
                                        valorChaveNaMaoExtenso={valorChaveNaMaoExtenso}
                                        setValorChaveNaMaoExtenso={setValorChaveNaMaoExtenso}
                                        quantidaEntregueImportrust={quantidaEntregueImportrust}
                                        setQuantidaEntregueImportrust={setQuantidaEntregueImportrust}
                                        quantidaEntregueImportrustExtenso={quantidaEntregueImportrustExtenso}
                                        setQuantidaEntregueImportrustExtenso={setQuantidaEntregueImportrustExtenso}
                                        taxasDeJuros={taxasDeJuros}
                                        setTaxasDeJuros={setTaxasDeJuros}
                                    />
                                    {/* </>
                                    ) : (
                                        <>
                                            <CompCreditoEmpresa
                                                tipoContrato={tipoContrato}
                                                tipoSociedade={tipoSociedade}
                                                dataDoDocumento={dataDoDocumento}
                                                setDataDoDocumento={setDataDoDocumento}
                                                alertaDataDocumento={alertaDataDocumento}
                                                idDoNegocio={idDoNegocio}
                                                setIDdoNegocio={setIDdoNegocio}


                                                nomeSocioEmpresa={nomeSocioEmpresa}
                                                setNomeSocioEmpresa={setNomeSocioEmpresa}
                                                nomeEmpresa={nomeEmpresa}
                                                setNomeEmpresa={setNomeEmpresa}
                                                sedeEmpresa={sedeEmpresa}
                                                setSedeEmpresa={setSedeEmpresa}
                                                nifEmpresa={nifEmpresa}
                                                setNifEmpresa={setNifEmpresa}
                                                nifGerente={nifGerente}
                                                setNifGerente={setNifGerente}
                                                numeroSocios={numeroSocios}
                                                setNumeroSocios={setNumeroSocios}

                                                alertaDataValidadeCC={alertaDataValidadeCC}
                                                setDataSociosQuatro={setDataSociosQuatro}
                                                tipo={tipo}

                                                paisDeOrigem={paisDeOrigem}
                                                setPaisDeOrigem={setPaisDeOrigem}
                                                marcaViatura={marcaViatura}
                                                setMarcaViatura={setMarcaViatura}
                                                modeloViatura={modeloViatura}
                                                setModeloViatura={setModeloViatura}
                                                vinViatura={vinViatura}
                                                setVinViatura={setVinViatura}

                                                valorChaveNaMao={valorChaveNaMao}
                                                setValorChaveNaMao={setValorChaveNaMao}
                                                valorChaveNaMaoExtenso={valorChaveNaMaoExtenso}
                                                setValorChaveNaMaoExtenso={setValorChaveNaMaoExtenso}
                                                quantidaEntregueImportrust={quantidaEntregueImportrust}
                                                setQuantidaEntregueImportrust={setQuantidaEntregueImportrust}
                                                quantidaEntregueImportrustExtenso={quantidaEntregueImportrustExtenso}
                                                setQuantidaEntregueImportrustExtenso={setQuantidaEntregueImportrustExtenso}
                                                taxasDeJuros={taxasDeJuros}
                                                setTaxasDeJuros={setTaxasDeJuros}

                                            />
                                        </>
                                    )} */}
                                </>
                            ) : tipo === "autorizacao_levantamento" ? (
                                <>
                                    <AutoTransporteParticular
                                        tipoSociedade={tipoSociedade}

                                        nomeIndividual={nomeIndividual}
                                        setNomeIndividual={setNomeIndividual}
                                        nomeSocioEmpresa={nomeSocioEmpresa}
                                        setNomeSocioEmpresa={setNomeSocioEmpresa}
                                        numeroCCEmpresa={numeroCCEmpresa}
                                        setNumeroCCEmpresa={setNumeroCCEmpresa}
                                        nomeEmpresa={nomeEmpresa}
                                        setNomeEmpresa={setNomeEmpresa}
                                        nifEmpresa={nifEmpresa}
                                        setNifEmpresa={setNifEmpresa}
                                        numeroCCIndividual={numeroCCIndividual}
                                        setNumeroCCIndivual={setNumeroCCIndivual}

                                        transportadora={transportadora}
                                        setTransportadora={setTransportadora}
                                        nifTransportadora={nifTransportadora}
                                        setNifTransportadora={setNifTransportadora}

                                        marcaViatura={marcaViatura}
                                        setMarcaViatura={setMarcaViatura}
                                        modeloViatura={modeloViatura}
                                        setModeloViatura={setModeloViatura}
                                        vinViatura={vinViatura}
                                        setVinViatura={setVinViatura}

                                        idDoNegocio={idDoNegocio}
                                        setIDdoNegocio={setIDdoNegocio}
                                        dataDoDocumento={dataDoDocumento}
                                        setDataDoDocumento={setDataDoDocumento}
                                        alertaDataDocumento={alertaDataDocumento}
                                        setDataSociosQuatro={setDataSociosQuatro}
                                        alertaDataValidadeCC={alertaDataValidadeCC}
                                    />
                                </>
                            ) : tipo === "modelo_9" ? (
                                <>
                                    <CompModelo9Particular

                                        nomeEmpresa={nomeEmpresa}
                                        setNomeEmpresa={setNomeEmpresa}
                                        sedeEmpresa={sedeEmpresa}
                                        setSedeEmpresa={setSedeEmpresa}
                                        nifEmpresa={nifEmpresa}
                                        setNifEmpresa={setNifEmpresa}

                                        nomeIndividual={nomeIndividual}
                                        setNomeIndividual={setNomeIndividual}
                                        moradaIndividual={moradaIndividual}
                                        setMoradaIndividual={setMoradaIndividual}
                                        residenciaParticular={residenciaParticular}
                                        setResidenciaParticular={setResidenciaParticular}
                                        codigoPostalParticular={codigoPostalParticular}
                                        setCodigoPostalParticular={setCodigoPostalParticular}
                                        nifParticular={nifParticular}
                                        setNifParticular={setNifParticular}
                                        telemovelInterno={telemovelInterno}
                                        setTelemovelInterno={setTelemovelInterno}
                                        emailInterno={emailInterno}
                                        setEmailInterno={setEmailInterno}
                                        numeroCCIndividual={numeroCCIndividual}
                                        setNumeroCCIndivual={setNumeroCCIndivual}
                                        validadeCCIndivual={validadeCCIndivual}
                                        setValidadeCCIndividual={setValidadeCCIndividual}
                                        numeroCertidaoPermanente={numeroCertidaoPermanente}
                                        setNumeroCertidaoPermanente={setNumeroCertidaoPermanente}
                                        paisEmissorIndivual={paisEmissorIndivual}
                                        setPaisEmissorIndividual={setPaisEmissorIndividual}

                                        marcaViatura={marcaViatura}
                                        setMarcaViatura={setMarcaViatura}
                                        modeloViatura={modeloViatura}
                                        setModeloViatura={setModeloViatura}
                                        vinViatura={vinViatura}
                                        setVinViatura={setVinViatura}
                                        matriculaViatura={matriculaViatura}
                                        setMatriculaViatura={setMatriculaViatura}
                                        categoriaViatura={categoriaViatura}
                                        setCategoriaViatura={setCategoriaViatura}
                                        tipoViatura={tipoViatura}
                                        setTipoViatura={setTipoViatura}
                                        combustivelViatura={combustivelViatura}
                                        setCombustivelViatura={setCombustivelViatura}
                                        cilindradaViatura={cilindradaViatura}
                                        setCilindradaViatura={setCilindradaViatura}
                                        matriculaAnteriorViatura={matriculaAnteriorViatura}
                                        setMatriculaAnteriorViatura={setMatriculaAnteriorViatura}
                                        dataPrimeiraMatriculaViatura={dataPrimeiraMatriculaViatura}
                                        setDataPrimeiraMatriculaViatura={setDataPrimeiraMatriculaViatura}
                                        paisOrigemViatura={paisOrigemViatura}
                                        setPaisOrigemViatura={setPaisOrigemViatura}

                                        tipo={tipo}
                                        tipoSociedade={tipoSociedade}
                                        tipoContrato={tipoContrato}
                                        dataDoDocumento={dataDoDocumento}
                                        idDoNegocio={idDoNegocio}
                                        setIDdoNegocio={setIDdoNegocio}
                                        setDataDoDocumento={setDataDoDocumento}
                                        alertaDataDocumento={alertaDataDocumento}

                                    />
                                </>
                            ) : null}

                            <InfoBlock />

                            <button ref={buttonForm} type="submit" id="submit-step-1" style={{ display: 'none' }}
                                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                                Enviar
                            </button>

                        </form>
                    </div>
                )}
                {currentStep === 2 && (
                    <div className='flex flex-col gap-4 border-2 border-main p-4 rounded'>
                        <div className='flex flex-col gap-4'>
                            <DadosAnterioresContratos tipoProp={tipo} tipoPropSociedade={tipoSociedade}
                                tipoPropContrato={tipoContrato} />
                        </div>
                        {tipo === "procuracao" ? (
                            <CompGerarPDF
                                loading={loading}
                                tipoDocumento="procuracao"
                                urlPDFGerado={urlPDFGerado}
                                mensagemDeErroAoGerar={mensagemDeErroAoGerar}
                                setMensagemDeErroAoGerar={setMensagemDeErroAoGerar}
                            />
                        ) : tipo === "contrato" ? (
                            <CompGerarPDF
                                activeTabStepTresKey={activeTabStepTresKey}
                                setActiveTabStepTresKey={setActiveTabStepTresKey}
                                loading={loading}
                                tipoDocumento="contrato"
                                urlPDFGerado={urlPDFGerado}
                                tipoContrato={tipoContrato}
                                mensagemDeErroAoGerar={mensagemDeErroAoGerar}
                                setMensagemDeErroAoGerar={setMensagemDeErroAoGerar}
                                checkedValue={checkedValue}
                            />
                        ) : tipo === "novo_negocio" ? (
                            <CompGerarPDF
                                activeTabStepTresKey={activeTabStepTresKey}
                                setActiveTabStepTresKey={setActiveTabStepTresKey}
                                loading={loading}
                                tipoDocumento="novo_negocio"
                                urlPDFGerado={urlPDFGerado}
                                tipoContrato={tipoContrato}
                                mensagemDeErroAoGerar={mensagemDeErroAoGerar}
                                setMensagemDeErroAoGerar={setMensagemDeErroAoGerar}
                                checkedValue={checkedValue}
                            />
                        ) : tipo === "credito" ? (
                            <CompGerarPDF
                                activeTabStepTresKey={activeTabStepTresKey}
                                setActiveTabStepTresKey={setActiveTabStepTresKey}
                                loading={loading}
                                tipoDocumento="credito"
                                urlPDFGerado={urlPDFGerado}
                                tipoContrato={tipoContrato}
                                mensagemDeErroAoGerar={mensagemDeErroAoGerar}
                                setMensagemDeErroAoGerar={setMensagemDeErroAoGerar}
                                checkedValue={checkedValue}
                            />
                        ) : tipo === "autorizacao_levantamento" ? (
                            <CompGerarPDF
                                activeTabStepTresKey={activeTabStepTresKey}
                                setActiveTabStepTresKey={setActiveTabStepTresKey}
                                loading={loading}
                                tipoDocumento="autorizacao_levantamento"
                                urlPDFGerado={urlPDFGerado}
                                tipoContrato={tipoContrato}
                                mensagemDeErroAoGerar={mensagemDeErroAoGerar}
                                setMensagemDeErroAoGerar={setMensagemDeErroAoGerar}
                                checkedValue={checkedValue}
                            />
                        ) : tipo === "modelo_9" ? (
                            <CompGerarPDF
                                activeTabStepTresKey={activeTabStepTresKey}
                                setActiveTabStepTresKey={setActiveTabStepTresKey}
                                loading={loading}
                                tipoDocumento="modelo_9"
                                urlPDFGerado={urlPDFGerado}
                                tipoContrato={tipoContrato}
                                mensagemDeErroAoGerar={mensagemDeErroAoGerar}
                                setMensagemDeErroAoGerar={setMensagemDeErroAoGerar}
                                checkedValue={checkedValue}
                            />
                        ) : null}
                    </div>
                )}
                <div className='mt-5 gap-4 flex flex-row items-center justify-center'>
                    {!disabledButtonPrev ? (
                        <Button className='bg-white text-black font-bold rounded' type="primary" onClick={handlePrev}>
                            Voltar
                        </Button>
                    ) : null}
                    {!disabledButtonNext ? (
                        <>
                            <Button className='bg-white text-black font-bold rounded' type="primary"
                                onClick={handleNext}>
                                Próximo
                            </Button>
                        </>
                    ) : (
                        <Button className='bg-white text-black font-bold rounded' type="primary"
                            onClick={handleNewDocument}>
                            Gerar nova
                        </Button>
                    )}
                </div>
            </div >
        </>
    );
};
