import React, { useState, useEffect } from "react";
import axios from "axios";
import { Form, Button, message, Spin, Modal, Progress } from "antd";
import { LoadingOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { HiOutlineDownload, HiOutlineRefresh } from "react-icons/hi";
import { useTranslation } from "react-i18next";
import { FaCheckCircle } from "react-icons/fa";
import { IoMdCloseCircle } from "react-icons/io";
import EstadoFicheiroUpdoud from "./EstadoFicheiroUpdoud";



export default function DocumentosTraduzir({
    dataUser,
    mediaSegundos,
    setMediaSegundos,
    files,
    setFiles,
    // filesParaUpdoud,
    setData,
    hasPendingFiles,
    hasCompletedFiles,
    // filesParaUploud,
}) {

    const { t } = useTranslation();

    const [modalVisible, setModalVisible] = useState(false);
    const [modalContent, setModalContent] = useState({ title: "", message: "" });

    //Modal do Resumo
    const showModal = (title, message) => {
        setModalContent({ title, message });
        setModalVisible(true);
    };


    const handleAtualizarTabela = async () => {

        let url = `${process.env.REACT_APP_GET_DOCUMENTOS_TRADUZIDOS}?token=${dataUser.saveToken}&user_id=${dataUser.iDTokenAuthenticated}`;

        try {
            const response = await axios.get(url);

            if (response.data.sucesso) {

                setData({
                    documentos: response.data.data.documentos,
                });

                // setMediaSegundos(response.data.data.media_segundos.match(/\d+/)[0]);
                setMediaSegundos(21);

                setFiles([])

            }
        } catch (error) {
            console.error("Erro ao buscar dados:", error);
        }
    };

    // console.log('filesParaUpdoud',filesParaUpdoud)

    // console.log(filesParaUploud)

    return (

        <>

            <div className="card grid gap-4">
                <div className="flex justify-between items-center gap-2">
                    <div className="flex items-center gap-2">
                        <p className="text-lg font-bold text-white flex items-center gap-2">
                            Documentos
                        </p>
                        {hasPendingFiles ? (
                            <>
                                <span className="text-lg font-bold text-white">a Traduzir</span>
                            </>
                        ) : (
                            <span className="text-lg font-bold text-white">Traduzidos</span>
                        )}
                    </div>

                    {hasCompletedFiles && (
                        <Button
                            type="primary"
                            htmlType="submit"
                            className="flex gap-2 items-center bg-blue-500 text-white rounded"
                            onClick={handleAtualizarTabela}
                        >
                            <HiOutlineRefresh /> Atualizar Lista
                        </Button>
                    )}

                </div>

                <hr></hr>

                <div className='grid grid-cols-3 gap-4'>
                    {files.map((file, index) => (
                        <EstadoFicheiroUpdoud
                            key={index}
                            file={file}
                            showModal={showModal}
                            dataUser={dataUser}
                            mediaSegundos={mediaSegundos}
                        />
                    ))}
                </div>

            </div>

            <Modal title={modalContent.title} open={modalVisible} footer={null} onCancel={() => setModalVisible(false)} width={800}>
                <hr></hr>
                <div
                    dangerouslySetInnerHTML={{ __html: modalContent.message }}
                    className="grid gap-1 mt-3"
                />
            </Modal>
        </>
    );
}
