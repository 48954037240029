import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { Form, Button, Upload, message, Spin, Select, Modal, Tooltip } from "antd";
import { InfoCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import CustomTable from '../Tables/CustomTables';
import CustomColumns from '../Tables/CustomColumns';
import { FaCheckCircle, FaClock } from "react-icons/fa";
import { IoMdCloseCircle } from "react-icons/io";
import { HiOutlineDownload } from "react-icons/hi";
import TitleSection from '../Elements/Title';

export default function TabelaDocumentosTraduzidos({
    dataUser,
    setMediaSegundos,
    setFiles,
    data,
    setData
}) {

    const { t } = useTranslation();

    const [configsTable, setConfigsTable] = useState({
        modalColunas: false,
    });

    const [modalVisible, setModalVisible] = useState(false);
    const [modalContent, setModalContent] = useState({ title: "", message: "" });

    const [visibleColumns, setVisibleColumns] = useState([
        'etapa_traduzida',
        'etapa',
        'data_criacao',
        'nome_utilizador',
        'nome_documento',
        'resumo',
        'link',
    ]);

    const intervalRef = useRef(null);

    useEffect(() => {
        let finalFetchDone = false; // flag para garantir que a chamada final seja executada apenas uma vez
    
        const fetchData = async (isFinal = false) => {
            const url = `${process.env.REACT_APP_GET_DOCUMENTOS_TRADUZIDOS}?token=${dataUser.saveToken}&user_id=${dataUser.iDTokenAuthenticated}`;
    
            try {
                const response = await axios.get(url);
    
                if (response.data.sucesso) {
                    const documentos = response.data.data.documentos;
                    setData({ documentos });
                    setMediaSegundos(21);
    
                    setFiles(prevFiles => {
                        if (prevFiles.length === 0) {
                            // Primeiro get: adiciona somente os documentos em processing
                            return documentos
                                .filter(doc => doc.etapa === 'processing')
                                .map(doc => ({
                                    status: doc.etapa,
                                    name: doc.name || "Documento",
                                    resumo: doc.resumo || "",
                                    type: doc.type || "",
                                    mediaSegundos: doc.segundos_passados,
                                    size: 8600 || 0,
                                }));
                        } else {
                            // Gets seguintes: atualiza o status dos arquivos já existentes
                            return prevFiles.map(file => {
                                const updatedDoc = documentos.find(
                                    doc => (doc.name || "Documento") === file.name
                                );
                                if (updatedDoc) {
                                    return {
                                        ...file,
                                        status: updatedDoc.etapa,
                                        mediaSegundos: updatedDoc.segundos_passados,
                                    };
                                }
                                return file;
                            });
                        }
                    });
    
                    // Verifica se ainda há documentos em processing
                    const hasProcessing = documentos.some(doc => doc.etapa === 'processing');
    
                    if (hasProcessing) {
                        if (!intervalRef.current) {
                            intervalRef.current = setInterval(() => fetchData(), 5000);
                        }
                    } else {
                        // Se não há mais processing, limpa o intervalo
                        if (intervalRef.current) {
                            clearInterval(intervalRef.current);
                            intervalRef.current = null;
                        }
                        // Chama uma última vez se ainda não foi realizada a chamada final
                        if (!isFinal && !finalFetchDone) {
                            finalFetchDone = true;
                            await fetchData(true);
                        }
                    }
                }
            } catch (error) {
                console.error("Erro ao buscar dados:", error);
            }
        };
    
        fetchData();
    
        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
                intervalRef.current = null;
            }
        };
    }, [dataUser.iDTokenAuthenticated, dataUser.saveToken, setFiles, setData, setMediaSegundos]);
    


    const handleDownloadFicheiros = async (fileName, extensao) => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_DOWNLOAD_DOS_FICHEIROS}`,
                {
                    params: {
                        token: dataUser.saveToken,
                        user_id: dataUser.iDTokenAuthenticated,
                        nome_ficheiro: fileName,
                        tipo: 'translated_docs'
                    },
                    responseType: 'blob',
                }
            );

            const mimeType = extensao === 'pdf'
                ? 'application/pdf'
                : extensao === 'docx'
                    ? 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                    : 'application/octet-stream';

            const url = window.URL.createObjectURL(new Blob([response.data], { type: mimeType }));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
            message.success('Download do ficheiro com sucesso!');
        } catch (error) {
            message.error('Falha ao fazer download do ficheiro.');
            console.error('Erro ao fazer download do ficheiro:', error);
        }
    };

    const allColumns = [
        {
            title: t('traduzirdocstable.id'),
            key: 'id',
            dataIndex: 'id',
        },
        {
            title: t('traduzirdocstable.nome_documento'),
            key: 'name',
            dataIndex: 'name',
        },
        {
            title: t('traduzirdocstable.resumo'),
            key: 'resumo',
            dataIndex: 'resumo',
            render: (resumo) => (
                <p
                    className="flex items-center gap-2 cursor-pointer"
                    onClick={() => showModal("Resumo da Tradução", resumo)}
                >
                    <InfoCircleOutlined style={{ fontSize: '18px', color: '#fff' }} />
                    Ver Resumo
                </p>
            ),
        },
        {
            title: t('traduzirdocstable.nome_utilizador'),
            key: 'nome_utilizador',
            dataIndex: 'nome_utilizador',
        },
        {
            title: t('traduzirdocstable.data_criacao'),
            key: 'data_criacao',
            dataIndex: 'data_criacao',
        },
        {
            title: t('traduzirdocstable.etapa'),
            key: 'etapa',
            render: (text, record) => (
                <div className="max-w-fit rounded-full flex items-center gap-2 bg-green-600 text-white px-3">
                    <FaCheckCircle style={{ fontSize: '16px', color: '#fff' }} />
                    <p>{record.status === 'completed' ? 'Completo' : 'Processando'}</p>
                </div>
            ),
        },
        {
            title: t('traduzirdocstable.link'),
            key: 'link',
            render: (link) => (
                <Button
                    className="flex gap-2 items-center bg-green-600 text-white rounded"
                    type="primary"
                    onClick={() => handleDownloadFicheiros(link?.name, link?.type)}
                >
                    <HiOutlineDownload /> Download
                </Button>
            ),
        },
    ].filter(Boolean);

    const showModal = (title, message) => {
        setModalContent({ title, message });
        setModalVisible(true);
    };

    const filteredData = data.documentos
        .filter((item) => item.etapa_traduzida === 'Completo')
        .map((item) => ({ ...item, key: item.id }));

    return (
        <>
            <div className="card grid gap-4">
                <TitleSection title={t("traduzirdocstable.titulo_principal")} />
                <CustomTable
                    columns={allColumns}
                    data={filteredData}
                    pagination={{ pageSize: 10, showSizeChanger: false, position: ['bottomCenter'] }}
                    loading={false}
                />

                <CustomColumns
                    allColumns={allColumns}
                    visibleColumns={visibleColumns}
                    setVisibleColumns={setVisibleColumns}
                    configsTable={configsTable}
                    setConfigsTable={setConfigsTable}
                />

                <Modal
                    title={modalContent.title}
                    open={modalVisible}
                    footer={null}
                    onCancel={() => setModalVisible(false)}
                    width={800}
                >
                    <hr />
                    <div
                        dangerouslySetInnerHTML={{ __html: modalContent.message }}
                        className="grid gap-1 mt-3"
                    />
                </Modal>
            </div>
        </>
    );
};
