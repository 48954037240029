import { Input } from "antd";
import React from "react";
import { DatePicker } from 'antd';
import dayjs from "dayjs";
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);

export default function CompCreditoEmpresa({
    dataDoDocumento,
    setDataDoDocumento,
    nomeSocioEmpresa,
    setNomeSocioEmpresa,
    nomeEmpresa,
    setNomeEmpresa,
    sedeEmpresa,
    setSedeEmpresa,
    nifEmpresa,
    setNifEmpresa,
    nifGerente,
    setNifGerente,


    marcaViatura,
    setMarcaViatura,
    modeloViatura,
    setModeloViatura,
    vinViatura,
    setVinViatura,

    valorProposta,
    setValorProposta,
    montanteAdiantamento,
    setMontanteAdiantamento,
    montanteIntegral,
    setMontanteIntegral,
    alertaDataDocumento
}) {


    const handleDataChange = (e) => {
        let day = "";
        let month = "";
        let year = "";

        if (e && e instanceof Object && e.hasOwnProperty('$y') && e.hasOwnProperty('$M') && e.hasOwnProperty('$D')) {
            // Extract day, month, and year
            day = e.$D.toString().padStart(2, '0');
            month = (e.$M + 1).toString().padStart(2, '0'); // Month is zero-based
            year = e.$y.toString();
        }

        setDataDoDocumento(day + '/' + month + '/' + year);
        // //console.log(dataDoDocumento)
    };

    const dayjsDate = (date) => {
        if (date && date !== '') {
            return dayjs(date, 'DD/MM/YYYY')
        }
    }

    const handleNomeEmpresaChange = (e) => {
        setNomeEmpresa(e.target.value);
    };

    const handleNIFEmpresaChange = (e) => {
        setNifEmpresa(e.target.value);
    };

    const handleSedeEmpresaChange = (e) => {
        setSedeEmpresa(e.target.value);
    };

    const handleNomeGerenteChange = (e) => {
        setNomeSocioEmpresa(e.target.value);
    };

    const handleNIFGerenteChange = (e) => {
        setNifGerente(e.target.value);
    };



    const handleMarcaDaViaturaChange = (e) => {
        setMarcaViatura(e.target.value);
    };


    const handleModeloDaViaturaChange = (e) => {
        setModeloViatura(e.target.value);
    };


    const handleVINDaViaturaChange = (e) => {
        setVinViatura(e.target.value);
    };




    const handleValorPropostaChange = (e) => {
        setValorProposta(e.target.value);
    };


    const handleMontanteAdiantamentoChange = (e) => {
        setMontanteAdiantamento(e.target.value);
    };


    const handleMontanteIntegralChange = (e) => {
        setMontanteIntegral(e.target.value);
    };



    return (
        <div className="grid grid-cols-2 gap-2">

            <div className='grid gap-2'>
                <p>Nome da empresa</p>
                <Input
                    placeholder="Nome da empresa"
                    value={nomeEmpresa}
                    onChange={handleNomeEmpresaChange}
                    type="text"
                    required
                />
            </div>

            <div className='grid gap-2'>
                <p>NIF da Empresa</p>
                <Input
                    placeholder="NIF da empresa"
                    value={nifEmpresa}
                    onChange={handleNIFEmpresaChange}
                    type="text"
                    required
                />
            </div>


            <div className='grid gap-2'>
                <p>Morada da empresa</p>
                <Input
                    placeholder="Morada da empresa"
                    value={sedeEmpresa}
                    onChange={handleSedeEmpresaChange}
                    type="text"
                    required
                />
            </div>

            <div className='grid gap-2'>
                <p>Nome do Gerente</p>
                <Input
                    placeholder="Nome do gerente"
                    value={nomeSocioEmpresa}
                    onChange={handleNomeGerenteChange}
                    type="text"
                    required
                />
            </div>

            <div className='grid gap-2'>
                <p>NIF do Gerente</p>
                <Input
                    placeholder="NIF do gerente"
                    value={nifGerente}
                    onChange={handleNIFGerenteChange}
                    type="text"
                    required
                />
            </div>


            <div className='grid gap-2'>
                <p>Data do documento</p>
                <DatePicker placeholder="dd/mm/aaaa"
                    defaultValue={dayjsDate(dataDoDocumento)}
                    value={dayjsDate(dataDoDocumento)}
                    format={'DD/MM/YYYY'}
                    // picker="month"
                    onChange={handleDataChange}
                    required
                />
                {alertaDataDocumento && <small className="text-red-500">A data do documento é obrigatória</small>}
            </div>



            <div className='grid gap-2'>
                <p>Marca da viatura</p>
                <Input
                    placeholder="Marca da viatura"
                    value={marcaViatura}
                    onChange={handleMarcaDaViaturaChange}
                    type="text"
                    required
                />
            </div>

            <div className='grid gap-2'>
                <p>Modelo da viatura</p>
                <Input
                    placeholder="Modelo da viatura"
                    value={modeloViatura}
                    onChange={handleModeloDaViaturaChange}
                    type="text"
                    required
                />
            </div>

            <div className='grid gap-2'>
                <p>VIN da viatura</p>
                <Input
                    placeholder="VIN da viatura"
                    value={vinViatura}
                    onChange={handleVINDaViaturaChange}
                    type="text"
                    required
                />
            </div>


            <div className='grid gap-2'>
                <p>Valor da Proposta</p>
                <Input
                    placeholder="Valor da proposta"
                    value={valorProposta}
                    onChange={handleValorPropostaChange}
                    type="text"
                    required
                />
            </div>


            <div className='grid gap-2'>
                <p>Montante do Adiantamento</p>
                <Input
                    placeholder="Montante do adiantamento"
                    value={montanteAdiantamento}
                    onChange={handleMontanteAdiantamentoChange}
                    type="text"
                    required
                />
            </div>



            <div className='grid gap-2'>
                <p>Montante Integral</p>
                <Input
                    placeholder="Montante integral"
                    value={montanteIntegral}
                    onChange={handleMontanteIntegralChange}
                    type="text"
                    required
                />
            </div>



        </div>
    )
}

