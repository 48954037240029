import React, { useState } from "react";
import { LoadingOutlined, SearchOutlined } from '@ant-design/icons';
import { Form, message, Input, Modal, AutoComplete, Spin, Button } from "antd";
import axios from "axios";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { IoEyeOutline } from "react-icons/io5";
import { BiLike } from "react-icons/bi";

const antIcon = (
    <LoadingOutlined
        style={{
            fontSize: 25,
        }}
        spin
    />
);

export default function PesquisaPorPropostaAceite({
    // data,
    setData,
    formSelects,
    dataUser,
    openModal,
    setOpenModal,
    onFormChange,
    dataProposta,
    setDataProposta,
    editarDocumento
}) {

    // //console.log(dataForm)


    const [timeoutId, setTimeoutId] = useState(null);
    const [loading, setLoading] = useState(false); // loading na pesquisa do deal

    const { t } = useTranslation();

    //trocar depois de deal para proposta pois isto é para propostas
    // const [data, setData] = useState({
    //     listaDeContactos: [],
    //     contactoSelecionado: '',
    //     pesquisaProposta: '',
    //     dadosDaPropostaSelecionada: []
    // });


    const handleOk = () => setOpenModal(false);
    const handleCancel = () => setOpenModal(false);

    const handlePesquisaPropostaChange = (value) => {

        setDataProposta((prevData) => ({
            ...prevData,
            contactoSelecionado: '',
            pesquisaProposta: value,
            dadosDaPropostaSelecionada: []
        }));

        if (timeoutId) {
            clearTimeout(timeoutId);
        }

        if (value.length > 3) {
            setLoading(true);
            const newTimeoutId = setTimeout(() => {
                // let url = `${process.env.REACT_APP_GET_PROPOSTAS}?token=${dataUser.saveToken}&user_id=${dataUser.iDTokenAuthenticated}&lang=${dataUser.language}&nome_proposta=${value}&deal_hubspot_fechado=1`;

                let url = `${process.env.REACT_APP_GET_PROCURAR_DEALS}?token=${dataUser.saveToken}&user_id=${dataUser.iDTokenAuthenticated}&lang=${dataUser.language}&id_deal_drive=${value}`;

                axios.get(url)
                    .then((response) => {
                        if (response.data.sucesso) {

                            //console.log(response.data)

                            const data = response.data.data.map((item) => ({
                                value: item.id_deal,
                                label: `${item.dealname}`,
                                id: item.id_deal,
                                other: item.proposta
                            }));

                            //console.log(data)

                            if (response.data.length === 0) {
                                message.warning('Proposta não encontrada. Tente novamente!');
                                setDataProposta((prevData) => ({
                                    ...prevData,
                                    pesquisaProposta: '',
                                    dadosDaPropostaSelecionada: []
                                }));
                                return;
                            }

                            setDataProposta((prevData) => ({
                                ...prevData,
                                listaDeContactos: data,
                            }));
                        } else {
                            message.error(response.data.mensagem);
                        }
                    })
                    .catch((error) => {
                        console.error('Error occurred:', error);
                    })
                    .finally(() => {
                        setLoading(false); // Finaliza o estado de carregamento
                    });
            }, 1000);

            setTimeoutId(newTimeoutId);
        }
    };

    const handlePesquisaPropostaSelect = (value) => {

        //console.log(value)

        //console.log(dataProposta.listaDeContactos)

        const selectedOption = dataProposta.listaDeContactos.find(
            (option) => option.value === value
        );
        // //console.log(selectedOption)
        // //console.log(selectedOption)
        if (selectedOption.id == null || selectedOption.id === "") {
            message.warning('A proposta selecionada não tem ID definido.')
            return
        }

        if (selectedOption) {
            formSelects.setFieldsValue({ propostaAceite: selectedOption.id });

            // Disparar o handleFormChange do pai
            const allValues = formSelects.getFieldsValue();
            ////console.log(allValues)
            if (onFormChange) {
                onFormChange({ propostaAceite: selectedOption.id }, allValues);
            }

            setDataProposta((prevData) => ({
                ...prevData,
                dadosDaPropostaSelecionada: selectedOption,
            }));

            ////console.log(selectedOption.other.id_proposta)
            setData((prevData) => ({
                ...prevData,
                idProposta: selectedOption.other.id_proposta,
            }));

            //console.log(dataProposta.dadosDaPropostaSelecionada)

        }
    };


    // Função para determinar o nome do estado da proposta
    const getPropostaEstado = (tipoProposta) => {
        if (tipoProposta === 'proposta_aceite') return t('hubspot.proposta_aceite');
        // if (tipoProposta === 'aceitou_outra_proposta') return t('hubspot.aceitou_outra_proposta');
        // if (tipoProposta === 'proposta_aberta') return t('hubspot.proposta_aberta');
        return '---';  // Valor padrão caso nenhum estado corresponda
    };


    return (
        <>
            <div className='flex flex-col items-stretch justify-between gap-2 flex-1 transition-all duration-500'>


                <p className='text-white flex gap-2 justify-between font-semibold items-center'>

                    Procura pela Proposta Aceite

                    {!editarDocumento && (
                        <SearchOutlined
                            className="bg-[#1A8700] p-1 rounded"
                            size={20}
                            onClick={() => setOpenModal(true)} />
                    )}

                </p>


                <Form.Item name="propostaAceite">
                    <Input
                        disabled
                        placeholder="ID do Deal da Proposta Aceite"
                        value={formSelects.getFieldValue('propostaAceite') || ''} // Obtem o valor do formulário
                        onChange={(e) => {
                            formSelects.setFieldsValue({ propostaAceite: e.target.value });
                        }}
                    />
                </Form.Item>


            </div>

            <Modal
                title={<span className="flex items-center gap-2">Procura pela Proposta</span>}
                open={openModal}
                onOk={handleOk}
                onCancel={handleCancel}
                okText='Fechar'
                footer={null}
            >

                <div className="flex items-center gap-2">
                    <AutoComplete
                        value={dataProposta.pesquisaProposta}
                        options={dataProposta.listaDeContactos}
                        onChange={handlePesquisaPropostaChange}
                        onSelect={handlePesquisaPropostaSelect}
                        placeholder='Pesquisa pelo ID do Deal'
                        style={{ width: '100%' }}
                    />
                    {loading && (
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Spin indicator={antIcon} />
                        </div>
                    )}
                </div>

                {dataProposta.dadosDaPropostaSelecionada.other && (
                    <>
                        <hr className="my-5" />

                        <div className=''>
                            <Swiper
                                autoplay={{
                                    delay: 2500,
                                    disableOnInteraction: false,
                                }}
                                navigation={true}
                                modules={[Navigation]}
                            >
                                {dataProposta.dadosDaPropostaSelecionada.other?.imagens?.map((image, i) => (
                                    <SwiperSlide key={i}>
                                        <img
                                            style={{ cursor: 'grab', borderRadius: '8px', objectFit: 'cover', width: '100%', height: '250px' }}
                                            src={image}
                                            alt={`Image ${i + 1}`}
                                        />
                                    </SwiperSlide>
                                ))}
                            </Swiper>

                            {/* Informações da proposta */}
                            <div className="grid gap-2 mt-4">
                                <h2 className="font-bold text-lg">{dataProposta.dadosDaPropostaSelecionada.other.nome_proposta}</h2>
                                <hr />
                                <div className='flex items-center gap-2 justify-between'>
                                    <strong>{t('hubspot.estado_da_proposta')}:</strong>
                                    <span className="ml-2 px-2 py-1 rounded text-white bg-verde">
                                        {getPropostaEstado(
                                            dataProposta.dadosDaPropostaSelecionada.other.estado_proposta_hubspot
                                        )}
                                    </span>
                                </div>
                                <div className='flex items-center gap-2 justify-between'>
                                    <strong>ID:</strong>
                                    <span>{dataProposta.dadosDaPropostaSelecionada.id ? dataProposta.dadosDaPropostaSelecionada.id : "---"}</span>
                                </div>
                                {/* <div className='flex items-center gap-2 justify-between'>
                                    <strong>{t('hubspot.criado_por')}:</strong>
                                    <span>{dataProposta.dadosDaPropostaSelecionada.other.user_data.nome_criou}</span>
                                </div> */}
                                <div className='flex items-center gap-2 justify-between'>
                                    <strong>{t('hubspot.data_da_criacao')}:</strong>
                                    <span>{dataProposta.dadosDaPropostaSelecionada.other.data_criacao}</span>
                                </div>
                                <hr />
                                <div className="flex gap-4 justify-end items-center w-full">
                                    <a href={`/${t('menu.hubspot')
                                        .toLowerCase()
                                        .normalize('NFD')
                                        .replace(/[\u0300-\u036f]/g, '')
                                        .replace(/ /g, '-')}/${t('hubspot.perfil_deal')
                                            .toLowerCase()
                                            .normalize('NFD').
                                            replace(/[\u0300-\u036f]/g, '')
                                            .replace(/ /g, '-')}/?id_deal=${dataProposta.dadosDaPropostaSelecionada.other.id_deal_hubspot}`} target="_blank">
                                        <Button
                                            type="primary"
                                            target="_blank"
                                            className="flex gap-2 items-center bg-white text-black font-bold rounded"
                                        >
                                            <IoEyeOutline />
                                            {t('hubspot.ver_perfil')}
                                        </Button>
                                    </a>
                                    <Button
                                        type="primary"
                                        target="_blank"
                                        className="flex gap-2 items-center bg-white text-black font-bold rounded"
                                        onClick={() => setOpenModal(false)}
                                    >
                                        <BiLike /> Ok
                                    </Button>
                                </div>
                            </div>

                        </div>
                    </>
                )}

            </Modal>
        </>
    );
}
