import React, { useState } from 'react';
import { Button, Form, Select, message, Spin } from 'antd';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { LoadingOutlined } from '@ant-design/icons';

const { Option } = Select;

const antIcon = (
    <LoadingOutlined
        style={{
            fontSize: 25,
        }}
        spin
    />
);


export default function FormAlterarEstadoDaProposta({
    dataUser,
    setPropostaInfo,
    propostaInfo,
}) {

    const { t } = useTranslation();

    const [isCalculating, setIsCalculating] = useState(false); // Novo estado

    // Estado local para o estado da proposta
    const [estadoLocalDaProposta, setEstadoLocalDaProposta] = useState(propostaInfo.estadoDaProposta);

    const finalizarEstadodaProposta = (values) => {

        setIsCalculating(true); // Inicia o cálculo

        const dataFecharProposta = {
            id_proposta: propostaInfo.idProposta,
            deal_hubspot_fechado: values.estadoDaProposta,
            tipo_contrato: values.tipoNegocio || propostaInfo.tipoNegocio,
            user_id: dataUser.iDTokenAuthenticated,
            token: dataUser.saveToken,
        };

        axios.post(`${process.env.REACT_APP_PROPOSTA_FECHADA}`, dataFecharProposta)
            .then((response) => {
                if (response.data.sucesso) {
                    message.success(response.data.mensagem);
                    setPropostaInfo((prevData) => ({
                        ...prevData,
                        estadoDaProposta: response.data.estado_proposta,
                        tipoNegocio: values.tipoNegocio || propostaInfo.tipoNegocio,
                    }));
                } else {
                    message.error(response.data.mensagem);
                }
            })
            .catch(() => {
                message.error('Erro ao fechar a proposta. Tente novamente!');
            })
            .finally(() => {
                setIsCalculating(false); // Finaliza o cálculo
            });
    };


    // //console.log(propostaInfo)


    return (
        <div className='flex flex-col mb-6'>
            <Form
                layout="vertical"
                onFinish={finalizarEstadodaProposta} // Submissão do Form
                initialValues={{
                    estadoDaProposta: propostaInfo.estadoDaProposta,
                    tipoNegocio: propostaInfo.tipoNegocio,
                }}
            >
                <div className='flex flex-col items-stretch gap-2'>
                    <p className='font-semibold'>{t('tables.estado')}</p>
                    <Form.Item
                        name="estadoDaProposta"
                        rules={[{ required: true, message: 'Por favor, selecione o estado da proposta!' }]}
                    >
                        <Select
                            placeholder={t('tables.selecione')}
                            value={estadoLocalDaProposta} // Usa o estado local
                            onChange={(value) => setEstadoLocalDaProposta(value)} // Atualiza o estado local
                        >
                            <Option value="proposta_aberta">{t('tables.proposta_em_aberto')}</Option>
                            <Option value="proposta_aceite">{t('tables.proposta_aceite')}</Option>
                            <Option value="proposta_oculta">{t('tables.proposta_oculta')}</Option>
                            <Option value="aceitou_outra_proposta" disabled>{t('tables.aceitou_outra_proposta')}</Option>
                        </Select>
                    </Form.Item>
                </div>

                {estadoLocalDaProposta === 'proposta_aceite' && ( // Exibe o select de "Tipo de Negócio" somente se o estado local for "proposta_aceite"
                    <div className='flex flex-col items-stretch gap-2'>
                        <p className='font-semibold'>{t('tables.tipo_negocio')}</p>
                        <Form.Item
                            name="tipoNegocio"
                            rules={[{ required: true, message: 'Por favor, selecione o tipo de negócio!' }]}
                        >
                            <Select
                                placeholder={t('tables.selecione')}
                                value={propostaInfo?.tipoNegocio || ''} // Mantém o valor atual
                            >
                                <Option value="particular">{t('tables.particular')}</Option>
                                <Option value="empresa">{t('tables.empresa')}</Option>
                            </Select>
                        </Form.Item>
                    </div>
                )}

                <Form.Item>


                    {!isCalculating ? ( // Mostra o botão apenas se não estiver calculando
                        <Button
                            type="primary"
                            htmlType="submit"
                            className="shadow-none font-bold text-black bg-green-400"
                        >
                            {t('tables.guardar_alteracoes')}
                        </Button>
                    ) : (
                        <Spin indicator={antIcon} />
                    )}

                </Form.Item>
            </Form>
        </div>
    );
}
