import React, { useState } from 'react';
import { Spin, Button, List, Popover, Tooltip, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';
import { FaLink } from 'react-icons/fa6';
import CopyIcon from '../Elements/CopyIcon';
import { RiRadioButtonLine } from "react-icons/ri";
import DadosTecnicos from './DadosTecnicos';
import EquipamentosViatura from './EquipamentosViatura';




export default function TabelaDadosCamiaoProposta({ dataCarros, isvIucData, propostaInfo, tipoUrlCarroLink }) {

    const { t } = useTranslation();

    return (
        <>
            <div className='flex flex-col gap-4 text-white font-semibold text-lg '>

                <div className='card grid gap-6'>

                    <div className='grid grid-cols-1 md:grid-cols-1 gap-6 rounded-md'>
                        <div>
                            <Swiper
                                navigation={true}
                                modules={[Navigation]}
                            >
                                {dataCarros.imagens && dataCarros.imagens.length > 0 && dataCarros.imagens.map((imagem, index) => (
                                    <SwiperSlide key={index}>
                                        <img
                                            style={{ borderRadius: '8px', objectFit: 'cover', width: '100%' }}
                                            src={imagem}
                                            alt={`Image ${index + 1}`}
                                        />
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </div>

                        <div className='flex flex-col gap-6'>
                            <p className='font-medium text-2xl' style={{ overflowWrap: 'break-word' }}>
                                <b>{dataCarros.titulo}</b>
                            </p>

                            <div className="grid grid-cols-2 sm:grid-cols-3 gap-2">
                                <p className='text-base text-neutral-500'>{t('tabela_viatura.marca')}: <br /><span className='text-lg text-white font-medium'>{dataCarros.marca || `${t('tabela_viatura.sem_informacao')}`}</span></p>
                                {/* <p className='text-base text-neutral-500'>{t('tabela_viatura.modelo')}: <br /><span className='text-lg text-white font-medium'>{isvIucData.modelo || `${t('tabela_viatura.sem_informacao')}`}</span></p> */}
                                <p className='text-base text-neutral-500'>{t('tabela_viatura.data_registo')}: <br /><span className='text-lg text-white font-medium'>{isvIucData.dataPrimeiroRegisto || `${t('tabela_viatura.sem_informacao')}`}</span></p>
                            </div>
                        </div>

                    </div>
                    {/* <div className='text-white mais-informacoes-box'>
                        <h2 className='pt-2 pr-4 pb-2'>{t('tabela_viatura.mais_informacao')}</h2>
                        <List
                            size="small"
                            dataSource={[
                                { title: `${t('tabela_viatura.acidentes')}`, value: dataCarros.acidentes },
                                { title: `${t('tabela_viatura.tipo_de_caixa')}`, value: dataCarros.tipo_de_caixa },
                                { title: `${t('tabela_viatura.stand')}`, value: propostaInfo.standData.nome_stand },
                                { title: `${t('tabela_viatura.pais')}`, value: propostaInfo.standData.pais_stand },
                                { title: `${t('tabela_viatura.codigo_postal')}`, value: propostaInfo.standData.codigo_postal_stand },
                                { title: `${t('tabela_viatura.morada')}`, value: propostaInfo.standData.morada_stand },
                                { title: `${t('tabela_viatura.contacto')}`, value: propostaInfo.standData.contacto_stand },
                                { title: `${t('tabela_viatura.contacto_2')}`, value: propostaInfo.standData.contacto_2_stand },
                                { title: `${t('gerar_proposta.proposta_n')}`, value: propostaInfo.idProposta },
                            ]}
                            renderItem={(item) => (
                                <List.Item>
                                    <div className='grid grid-cols-2 gap-4 w-full'>
                                        <p className='text-xs sm:text-sm text-white'>{item.title}:</p>
                                        <span className='text-white text-xs font-normal sm:text-sm flex gap-2 items-center'>{item.value ? item.value : `${t('tabela_viatura.sem_informacao')}`}</span>
                                    </div>
                                </List.Item>
                            )}
                        /> */}
                        <hr></hr>
                    <div className='grid grid-cols-2 gap-4 w-full'>
                        <p className='text-xs sm:text-sm text-white'>{t('tabela_viatura.ver_anuncio')}:</p>
                        <div className='flex items-center gap-4'>
                            <a href={propostaInfo.linkFora} target="_blank" className='text-white text-xs font-normal sm:text-sm flex gap-2 items-center'>
                                Link
                                <FaLink />
                            </a>
                            <label className='text-white text-xs font-normal sm:text-sm flex items-center'>
                                Copiar
                                <CopyIcon text={propostaInfo.linkFora} />
                            </label>
                        </div>
                    </div>
                    {/* </div> */}
                </div >

                {dataCarros.dados_tecnicos && (
                    <DadosTecnicos
                        dataCarros={dataCarros}
                        tipoUrlCarroLink={tipoUrlCarroLink}
                    />
                )}

                {/* {dataCarros.equipamento && (
                        <EquipamentosViatura
                            dataCarros={dataCarros}
                        />
                    )}  */}

            </div >
        </>
    );

};
