import React, { useState } from 'react';
import { FiDownload } from "react-icons/fi";
import axios from 'axios';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { Spin, Button, message } from 'antd';
import { useTranslation } from 'react-i18next';

export async function downloadDocuments(links, propostaInfo, t) {
    ////console.log("Links recebidos para download:", links);

    // const { t } = useTranslation();

    if (!links || Object.keys(links).length === 0) {
        console.error('Nenhum link válido fornecido para download.');
        return message.error(t('download_imagens.nenhum_arquivo_para_download'));
    }

    const zip = new JSZip();

    try {
        const promises = Object.entries(links).map(async ([key, fileName]) => {
            if (!fileName) {
                console.warn(`Nome do arquivo vazio para a chave: ${key}`);
                return;
            }

            try {
                //console.log(`Baixando arquivo: ${fileName}`);
                const response = await fetch(fileName);

                if (!response.ok) {
                    console.error(`Erro ao baixar o arquivo: ${fileName} (status: ${response.status})`);
                    return;
                }

                const blob = await response.blob();
                //const cleanFileName = fileName.split('/').pop();
                zip.file(`${key}_${propostaInfo.outros.nome_proposta}.jpg`, blob);
            } catch (error) {
                console.error(`Erro ao processar o arquivo ${fileName}:`, error);
            }
        });

        await Promise.all(promises);

        const zipContent = await zip.generateAsync({ type: "blob" });
        saveAs(zipContent, `${propostaInfo.outros.nome_proposta}-Imagens_Anuncio.zip`);
        message.success(t('download_imagens.download_do_ficheiro_com_sucesso'));
    } catch (error) {
        console.error("Erro durante o processo de download ou zipping:", error);
        message.error(t('download_imagens.erro_ao_fazer_download_zip'));
    }
}

export default function DownloadDasImagens({ dataUser, propostaInfo, setPropostaInfo }) {

    const { t } = useTranslation();

    const [loadingPrint, setLoadingPrint] = useState(false);


    const handleForcarImagensAnuncio = () => {
        setLoadingPrint(true);

        const dataForcarImagensAnuncio = {
            id_proposta: propostaInfo.idProposta,
            user_id: dataUser.iDTokenAuthenticated,
            user_token: dataUser.saveToken,
        };

        axios.post(`${process.env.REACT_APP_FORCAR_IMAGEM_ANUNCIO}`, dataForcarImagensAnuncio)
            .then((response) => {
                if (response.data.sucesso) {
                    //console.log(response);
                    message.success(response.data.mensagem);
                    setPropostaInfo((prevData) => ({
                        ...prevData,
                        imagensParaDownload: response.data.imagens_locais_proposta,
                    }));
                } else {
                    message.warning(response.data.mensagem);
                }
            })
            .catch((error) => {
                console.error("Erro ao forçar imagens:", error);
                message.error(t('download_imagens.erro_ao_fazer_download'));
            })
            .finally(() => {
                setLoadingPrint(false);
            });
    };

    const handleDownloadAll = () => {
        if (!propostaInfo.imagensParaDownload) {
            return message.error(t('download_imagens.nenhuma_imagem_disponivel_para_download'));
        }
        downloadDocuments(propostaInfo.imagensParaDownload, propostaInfo, t);
    };

    return (
        <>
            {propostaInfo.imagensParaDownload ? (
                <div className="flex justify-between items-center">
                    <p className="text-black text-base">
                        {t('download_imagens.download_de_imagens')}
                    </p>
                    <Button
                        type="primary"
                        className="flex gap-2 items-center bg-white text-black font-bold rounded"
                        onClick={handleDownloadAll}
                    >
                        <FiDownload />
                        Download
                    </Button>
                </div>
            ) : (
                <div className="flex justify-between items-center">
                    <p className="text-black text-base">
                        {t('download_imagens.forcar_download_de_imagens')}
                    </p>
                    {loadingPrint ? (
                        <div className="flex gap-2 justify-center items-center font-bold">
                            <Spin />
                            {t('download_imagens.a_gerar_print')}
                        </div>
                    ) : (
                        <Button
                            type="primary"
                            className="flex gap-2 items-center bg-white text-black font-bold rounded"
                            onClick={handleForcarImagensAnuncio}
                        >
                            <FiDownload />
                            {t('download_imagens.forcar')}
                        </Button>
                    )}
                </div>
            )}
        </>
    );
}
