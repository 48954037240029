import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdOutlineExpandMore, MdOutlineKeyboardArrowUp } from 'react-icons/md';

export default function DadosTecnicos({ dataCarros, tipoUrlCarroLink }) {
    
    const { t } = useTranslation();

    const [moreDadosTecnicos, setDadosTecnicos] = useState(tipoUrlCarroLink !== "normal");

    // Função para alternar o estado
    const toggleDadosTecnicos = () => {
        setDadosTecnicos((prevState) => !prevState);
    };

    // Verifica se dataCarros e dados_tecnicos existem
    if (!dataCarros || !dataCarros.dados_tecnicos || !Array.isArray(dataCarros.dados_tecnicos)) {
        return (
            <div className="card grid gap-4">
                <div onClick={toggleDadosTecnicos} className="flex justify-between items-center gap-4 cursor-pointer">
                    <div className="grid gap-1">
                        <p className="text-lg font-semibold">{t('tables.dados_tecnicos')}</p>
                    </div>
                    {moreDadosTecnicos ? (
                        <MdOutlineKeyboardArrowUp size={25} />
                    ) : (
                        <MdOutlineExpandMore size={25} />
                    )}
                </div>
                {moreDadosTecnicos && (
                    <>
                        <hr />
                        <div className="grid grid-cols-1 gap-x-10 gap-y-2">
                            <p>Nenhum dado técnico disponível</p>
                        </div>
                    </>
                )}
            </div>
        );
    }

    return (
        <div className="card grid gap-4">
            <div onClick={toggleDadosTecnicos} className="flex justify-between items-center gap-4 cursor-pointer">
                <div className="grid gap-1">
                    <p className="text-lg font-semibold">{t('tables.dados_tecnicos')}</p>
                </div>
                {moreDadosTecnicos ? (
                    <MdOutlineKeyboardArrowUp size={25} />
                ) : (
                    <MdOutlineExpandMore size={25} />
                )}
            </div>
            {moreDadosTecnicos && (
                <>
                    <hr />
                    <div className="grid grid-cols-1 gap-x-10 gap-y-2">
                        {dataCarros.dados_tecnicos.map((item, index) => {
                            // Cada item é um objeto com uma única propriedade
                            const key = Object.keys(item)[0];
                            const value = item[key];
                            
                            return (
                                <div
                                    key={index}
                                    className="grid grid-cols-2 gap-4 w-full text-sm"
                                >
                                    <span className='font-semibold'>{key}:</span>
                                    <p className="font-normal">
                                        {value}
                                    </p>
                                </div>
                            );
                        })}
                    </div>
                </>
            )}
        </div>
    );
}