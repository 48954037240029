import React, { useState } from 'react';
import { Layout, Menu } from 'antd';
import { FileTextOutlined } from '@ant-design/icons';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { GoTools } from 'react-icons/go';
import { FaHubspot } from 'react-icons/fa6';
import { PiHouse } from 'react-icons/pi';
import { LuUsers } from 'react-icons/lu';
import { HiRefresh } from 'react-icons/hi';
import { TbBrandGoogleAnalytics } from 'react-icons/tb';
import { IoDocumentTextOutline } from 'react-icons/io5';
import logo from '../assets/logo-branco-2.svg';
import logoIcon from '../assets/logo-icon-branco-2.svg';

const { Sider } = Layout;

export default function CustomSidebar({ collapsed, dataUser }) {
    const { t } = useTranslation();
    const location = useLocation(); // Obtém a URL atual
    const [openKey, setOpenKey] = useState(null);

    const formatPath = (text) =>
        text.toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '') // Remove acentos
            .replace(/ /g, '-');

    const menuItems = [
        { key: '/', icon: <PiHouse />, label: <Link to="/">{t('menu.painel')}</Link> },
        {
            key: 'propostas',
            icon: <FileTextOutlined />,
            label: t('menu.propostas'),
            children: [
                { key: `/${formatPath(t('menu.gerar_proposta'))}`, label: <Link to={`/${formatPath(t('menu.gerar_proposta'))}`}>{t('menu.gerar_proposta')}</Link> },
                { key: `/${formatPath(t('menu.ver_propostas'))}`, label: <Link to={`/${formatPath(t('menu.ver_propostas'))}`}>{t('menu.ver_propostas')}</Link> },
                { key: `/${formatPath(t('menu.historico'))}`, label: <Link to={`/${formatPath(t('menu.historico'))}`}>{t('menu.historico')}</Link> },
            ],
        },
        { key: `/${formatPath(t('menu.hubspot'))}`, icon: <FaHubspot />, label: <Link to={`/${formatPath(t('menu.hubspot'))}`}>{t('menu.hubspot')}</Link> },
        { key: `/${formatPath(t('menu.automacoes'))}`, icon: <HiRefresh />, label: <Link to={`/${formatPath(t('menu.automacoes'))}`}>{t('menu.automacoes')}</Link> },
        ...(
            dataUser.language === 'pt'
                ? [
                    {
                        key: 'documentos',
                        icon: <IoDocumentTextOutline />,
                        label: t('menu.documentos'),
                        children: [
                            { key: `/${formatPath(t('menu.gerar_documentos'))}`, label: <Link to={`/${formatPath(t('menu.gerar_documentos'))}`}>{t('menu.gerar_documentos')}</Link> },
                            { key: `/${formatPath(t('menu.ver_documentos'))}`, label: <Link to={`/${formatPath(t('menu.ver_documentos'))}`}>{t('menu.ver_documentos')}</Link> },
                            // { key: `/${formatPath(t('traduzirdocs.traduzirdocs'))}`, label: <Link to={`/${formatPath(t('traduzirdocs.traduzirdocs'))}`}>{t('traduzirdocs.traduzirdocs')}</Link> },
                        ],
                    },
                    {
                        key: 'ferramentas',
                        icon: <GoTools />,
                        label: t('menu.ferramentas'),
                        children: [
                            { key: `/${formatPath(t('menu.simulador_isv'))}`, label: <Link to={`/${formatPath(t('menu.simulador_isv'))}`}>{t('menu.simulador_isv')}</Link> },
                            { key: `/${formatPath(t('menu.gerar_assinatura'))}`, label: <Link to={`/${formatPath(t('menu.gerar_assinatura'))}`}>{t('menu.gerar_assinatura')}</Link> },
                        ],
                    },
                ]
                : []
        ),
        ...(
            dataUser.language === 'es'
                ? [
                    // {
                    //     key: 'documentos',
                    //     icon: <IoDocumentTextOutline />,
                    //     label: t('menu.documentos'),
                    //     children: [
                    //         { key: `/${formatPath(t('traduzirdocs.traduzirdocs'))}`, label: <Link to={`/${formatPath(t('traduzirdocs.traduzirdocs'))}`}>{t('traduzirdocs.traduzirdocs')}</Link> },
                    //     ],
                    // },
                    {
                        key: 'ferramentas',
                        icon: <GoTools />,
                        label: t('menu.ferramentas'),
                        children: [
                            { key: `/${formatPath(t('menu.gerar_assinatura'))}`, label: <Link to={`/${formatPath(t('menu.gerar_assinatura'))}`}>{t('menu.gerar_assinatura')}</Link> },
                        ],
                    },
                ]
                : []
        ),
        ...(
            dataUser.iDTokenAuthenticated === 1
                ? [
                    {
                        key: 'utilizadores',
                        icon: <LuUsers />,
                        label: t('menu.utilizadores'),
                        children: [
                            { key: '/lista-de-utilizadores', label: <Link to="/lista-de-utilizadores">{t('menu.lista_utilizadores')}</Link> },
                        ],
                    },
                    { key: 'divider-1', label: <hr /> },
                    { key: '/analytics', icon: <TbBrandGoogleAnalytics />, label: <Link to="/analytics">Analytics</Link> },
                ]
                : []
        ),
    ];

    return (
        <Sider className="card-sidebar sidebar" trigger={null} collapsible collapsed={collapsed} style={{ position: 'sticky', top: 0 }}>
            <div className="flex justify-center p-2 mt-2 mb-2">
                <Link to="/">
                    <img src={collapsed ? logoIcon : logo} alt="Logo" className="w-40 max-w-40 h-10 object-contain cursor-pointer" />
                </Link>
            </div>
            <Menu
                theme="dark"
                mode="inline"
                selectedKeys={[location.pathname]} // Define dinamicamente o item ativo
                openKeys={[openKey]}
                onOpenChange={(keys) => setOpenKey(keys.find((key) => key !== openKey))}
                className="text-xs font-bold"
                items={menuItems}
            />
        </Sider>
    );
}
