import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import { useTranslation } from 'react-i18next';

export default function PropostasPerfilDeal({ data, dataUser }) {

    const { t } = useTranslation();

    // Definir as cores para os diferentes estados da proposta
    const eventColors = {
        [t('hubspot.proposta_aceite')]: 'bg-verde',
        [t('hubspot.aceitou_outra_proposta')]: 'bg-yellow-600',
        [t('hubspot.proposta_aberta')]: 'bg-gray-400',
    };

    // Função para determinar o nome do estado da proposta
    const getPropostaEstado = (tipoProposta) => {
        if (tipoProposta === 'proposta_aceite') return t('hubspot.proposta_aceite');
        if (tipoProposta === 'aceitou_outra_proposta') return t('hubspot.aceitou_outra_proposta');
        if (tipoProposta === 'proposta_aberta') return t('hubspot.proposta_aberta');
        if (tipoProposta === 'proposta_oculta') return t('hubspot.proposta_oculta');
        return 'Desconhecido';  // Valor padrão caso nenhum estado corresponda
    };

    // Verifica se existe uma proposta aceita
    const propostaAceiteExistente = data.propostasDealsData.some(
        item => getPropostaEstado(item.estado_proposta_hubspot) === t('hubspot.proposta_aceite')
    );



    return (
        <>
            <div className="grid grid-cols-3 gap-4">
                {data.propostasDealsData.map((item, index) => {
                    //console.log(item.estado_proposta_hubspot)
                    const estadoProposta = getPropostaEstado(item.estado_proposta_hubspot);
                    const bgColorClass = eventColors[estadoProposta] || 'bg-gray-400';  // Cor padrão caso não encontre o estado

                    // Define a opacidade se houver uma proposta aceita e o item não for "Proposta Aceite"
                    const isPropostaAceite = estadoProposta === t('hubspot.proposta_aceite');
                    const cardStyle = propostaAceiteExistente && !isPropostaAceite
                        ? { opacity: 0.5, transition: 'opacity 0.3s ease' }
                        : { opacity: 1 };  // Caso não exista proposta aceite, mantém a opacidade 1 para todos


                    const linkUrl = `/${t('menu.proposta').toLowerCase()
                        .normalize('NFD')
                        .replace(/[\u0300-\u036f]/g, '')  // Remove accents
                        .replace(/ /g, '-')}/?n=${item.url_acesso}`;


                    return (
                        <div
                            key={index}
                            className="text-white card-car"
                            style={cardStyle}
                            onMouseEnter={(e) => {
                                if (!isPropostaAceite && propostaAceiteExistente) {
                                    e.currentTarget.style.opacity = 1;
                                }
                            }}
                            onMouseLeave={(e) => {
                                if (!isPropostaAceite && propostaAceiteExistente) {
                                    e.currentTarget.style.opacity = 0.5;
                                }
                            }}
                        >

                            <Swiper
                                autoplay={{
                                    delay: 2500,
                                    disableOnInteraction: false,
                                }}
                                navigation={true}
                                modules={[Navigation]}
                            >
                                {item?.imagens?.map((image, i) => (
                                    <SwiperSlide key={i}>
                                        <img
                                            style={{ cursor: 'grab', borderRadius: '8px', objectFit: 'cover', width: '100%', height: '250px' }}
                                            src={image}
                                            alt={`Image ${i + 1}`}
                                        />
                                    </SwiperSlide>
                                ))}
                            </Swiper>

                            {/* Informações da proposta */}
                            <div className="grid gap-2 mt-4">
                                <a href={linkUrl} target="_blank" rel="noopener noreferrer">
                                    <h2 className="font-bold text-lg hover:text-white hover:underline">{item.nome_proposta}</h2>
                                </a>
                                <hr />
                                <div className='flex items-center gap-2 justify-between'><strong>{t('hubspot.criado_por')}:</strong> <span>{item.nome_criou}</span></div>
                                {/* Estado da proposta com cor condicional */}
                                {/* {dataUser.language === 'pt' && ( */}
                                    <div className='flex items-center gap-2 justify-between'>
                                        <strong>{t('hubspot.estado_da_proposta')}:</strong>
                                        <span className={`ml-2 px-2 py-1 rounded text-white ${bgColorClass}`}>
                                            {estadoProposta}
                                        </span>
                                    </div>
                                {/* )} */}
                                <div className='flex items-center gap-2 justify-between'><strong>{t('hubspot.data_da_criacao')}:</strong><span>{item.data_criacao}</span></div>
                            </div>

                        </div>
                    );
                })}
            </div>
        </>
    );
}
