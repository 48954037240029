import React, { useEffect } from 'react';
import { Form, Input, Select, Checkbox, Collapse } from 'antd';
import { ConsoleSqlOutlined } from '@ant-design/icons';

const { Option } = Select;
const { Panel } = Collapse;

export default function FormCalculoPropostaCamioes({ setValoresTotais, valoresTotais, isvIucData, onFormChange, propostaInfo }) {

    const [formCamioes] = Form.useForm(); // Cria uma instância do formulário

    // Atualiza os valores do formulário quando isvData mudar
    useEffect(() => {
        formCamioes.setFieldsValue({
            ...isvIucData,
            precoViaturaComIva: valoresTotais.precoViaturaComIva,
            precoViaturaSemIva: valoresTotais.precoViaturaSemIva
        });

        if (isvIucData.dataPrimeiroRegisto == "" || !isvIucData.dataPrimeiroRegisto) {
            formCamioes.validateFields(['dataPrimeiroRegisto']);
        }

        if (isvIucData.pesoBruto == "" || !isvIucData.pesoBruto) {
            formCamioes.validateFields(['pesoBruto']);
        }

        if (isvIucData.configuracaoEixos == "" || !isvIucData.configuracaoEixos) {
            formCamioes.validateFields(['configuracaoEixos']);
        }

        if (valoresTotais.precoViaturaComIva == "" || !valoresTotais.precoViaturaComIva) {
            formCamioes.validateFields(['precoViaturaComIva']);
        }

        if (valoresTotais.precoViaturaSemIva == "" || !valoresTotais.precoViaturaSemIva) {
            formCamioes.validateFields(['precoViaturaSemIva']);
        }

        if (valoresTotais.precoViaturaSemIva !== valoresTotais.precoViaturaComIva) {
            setValoresTotais((prevData) => ({
                ...prevData,
                validaSeValorNaoTemIva: 1,
            }));
        }
    }, [isvIucData, formCamioes]);


    const handlePriceChange = (field, value) => {
        setValoresTotais(prevData => ({
            ...prevData,
            [field]: value,
            // Definir validaSeValorNaoTemIva como 1 se os preços forem diferentes
            validaSeValorNaoTemIva: field === 'precoViaturaComIva'
                ? (value !== valoresTotais.precoViaturaSemIva ? 1 : 0)
                : (value !== valoresTotais.precoViaturaComIva ? 1 : 0)
        }));
    };

    const handleFormChange = (changedValues, allValues) => {
        // Filtrar precoViaturaComIva e precoViaturaSemIva dos valores passados para onFormChange
        const { precoViaturaComIva, precoViaturaSemIva, ...otherValues } = allValues;
        onFormChange(otherValues);
    };

    return (
        <div className='card grid gap-4'>
            <div className='flex justify-between items-center gap-4'>
                <div className='grid gap-1'>
                    <p className='text-lg font-semibold'>Cálculo do ISV</p>
                </div>
            </div>
            <hr />
            <Form
                layout="vertical"
                className="form-de-envio-docs"
                form={formCamioes}
                onValuesChange={handleFormChange}
            >
                <div className="grid gap-4">
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 text-white">

                        {/* Campo Peso Bruto */}
                        <div className="flex flex-col items-stretch gap-2">
                            <p className="text-white font-semibold">Peso Bruto (kg)</p>
                            <Form.Item
                                name="pesoBruto"
                                rules={[
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value || value === '' || value === '0' || value === 0) {
                                                return Promise.reject('Por favor, insira um valor válido para o peso bruto!');
                                            }
                                            return Promise.resolve();
                                        },
                                    }),
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </div>


                        {/* Campo Configuração de Eixos */}
                        <div className="flex flex-col items-stretch gap-2">
                            <p className="text-white font-semibold">Configuração de Eixos</p>
                            <Form.Item
                                name="configuracaoEixos"
                                rules={[{ required: true, message: 'Por favor, selecione a configuração de eixos!' }]}
                            >
                                <Select>
                                    <Option value="2">2 Eixos</Option>
                                    <Option value="3">3 Eixos</Option>
                                    <Option value="4">4 ou mais Eixos</Option>
                                </Select>
                            </Form.Item>
                        </div>

                        {/* Campo Tipo de Suspensão */}
                        <div className="flex flex-col items-stretch gap-2">
                            <p className="text-white font-semibold">Tipo de Suspensão</p>
                            <Form.Item
                                name="tipoSuspensao"
                                rules={[{ required: true, message: 'Por favor, selecione o tipo de suspensão!' }]}
                            >
                                <Select>
                                    <Option value="pneumatica_ou_equivalente">Pneumática ou equivalente</Option>
                                    <Option value="outro">Outro tipo de suspensão</Option>
                                </Select>
                            </Form.Item>
                        </div>

                        {/* Campo Ano da Primeira Matrícula */}
                        {/* Data 1º Registo */}
                        <div className='flex flex-col items-stretch gap-2'>
                            <p className='text-white font-semibold'>Data 1º Registo</p>
                            <Form.Item
                                name="dataPrimeiroRegisto"
                                rules={[
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value || value === '') {
                                                return Promise.reject('Por favor, insira a data do primeiro registo!'); // Combina a validação de campo obrigatório e validação personalizada
                                            }
                                            return Promise.resolve();
                                        },
                                    }),
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </div>

                        {/* Campo Valor da Viatura c/IVA */}
                        <div className="flex flex-col items-stretch gap-2">
                            <p className="text-white font-semibold">Valor da Viatura c/IVA</p>
                            <Form.Item
                                name="precoViaturaComIva"
                                rules={[
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value || value === '' || value === '0' || value === 0) {
                                                return Promise.reject('Por favor, insira um valor válido para o valor da viatura c/IVA');
                                            }
                                            return Promise.resolve();
                                        },
                                    }),
                                ]}
                            >
                                <Input
                                    placeholder="Valor da Viatura c/IVA (€)"
                                    type="number"
                                    onChange={(e) => handlePriceChange('precoViaturaComIva', e.target.value)}
                                    value={valoresTotais.precoViaturaComIva}
                                    autoComplete="off"
                                />
                            </Form.Item>
                        </div>

                        {/* Campo Valor da Viatura s/IVA */}
                        <div className="flex flex-col items-stretch gap-2">
                            <p className="text-white font-semibold">Valor da Viatura s/IVA</p>
                            <Form.Item
                                name="precoViaturaSemIva"
                                rules={[
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value || value === '' || value === '0' || value === 0) {
                                                return Promise.reject('Por favor, insira um valor válido para o valor da viatura s/IVA!');
                                            }
                                            return Promise.resolve();
                                        },
                                    }),
                                ]}
                            >
                                <Input
                                    placeholder="Valor da Viatura s/IVA (€)"
                                    type="number"
                                    onChange={(e) => handlePriceChange('precoViaturaSemIva', e.target.value)}
                                    value={valoresTotais.precoViaturaSemIva}
                                    autoComplete="off"
                                />
                            </Form.Item>
                        </div>

                    </div>
                </div>
            </Form>

            {propostaInfo.erro && (
                <span className='text-sm text-[#fffd00] opacity-90' dangerouslySetInnerHTML={{ __html: propostaInfo.erro }} />
            )}

        </div>
    );
};
